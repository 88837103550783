/**
 * Plugin name and description
 * 
 * This is jQuery plugin skeleton 'chain methods' type.
 * Allows to use plugin actions in chain as below:
 * 
 *  $(n).mtsoftUiListing()
 * 
 * (c) 2013 mtsoft.pl
 * 
 * @uses 
 *  ( mtsoft.ui.listing.controls ($.mtsoftUiListingControl()))
 *  mtsoft.ui.icon 
 *  mtsoft.ui.ajax
 *  mtsoft.ui.anims
 *  mtsoft.ui ( $.mtsoftUiLock(), $.mtsoftUiState )
 *  jquery.lazyload ( $.lazyload() ) / $.mtsoft.ui.lazyload
 *  jquery.touchSwipe ( $.swipe() )
 *  jQueryUI.sortable 
 * 
 */
;
(function() {

    var cfg = {};   // keep configuration in global variable

    // -------------------------------------------------------------------------
    //
    // Plugin constructor
    //
    $.fn.mtsoftUiListing = function(params) {

        var args = arguments;
        $.extend(this, actions);

        return this.each(function() {

            var $this = $(this);
            if (!$this.data('config.mtsoftUiListing') || params) {

                //
                // Initialize and save configuration for current node
                //                 
                cfg = actions.config.call($this, actions.init.call($this, args));

            } else {

                // only read configuration
                cfg = actions.config.apply($this);
            }
        });
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtsoftUiListing.defaults = {
        // settings as properties or objects
        cssPrefix: 'mtl-',
        paramType: 'querystring', // type of lisitng url parameters [ named | querystring ] (ex. /param:value OR &param=value )
        loadMode: 'paginate', // how to load pages [ pagintate | append ]                
        ajax: 0, // if 1 force ajax mode (always send single page data as JSON object)
        model: null,    // force given model
        scrollToList: 50, // [false | px] if not false scroll document to top of listing after list contents has been loaded; value is additional top space above listing top [px]
        scrollTop: {// scroll to list animation parameters
            duration: 400,
            easing: 'swing'
        },
        updateUrl: true, // if true always update browser url to match real listing state url
        pagesHistory: true, // if true each listing page will be added to browser's history (updateUrl must be set true)
        autoloadOnScroll: false, // if grather than zero - automatically load next page (value is maximum pages to be loaded)
        lockListOnLoad: true, // [Mixed] if true lock listing on load (dim & shown wait icon) or JSON object with mtsoftUiLock parameters        
        lazyload: false, // [Mixed] if true/object($.mtsoft.ui.lazyload parameters) use lazyload to laod only visible listing images
        //lazyLoadAnimation: 'llAnim', // Animation [String] css name OR [JOSN] object with animation definition used when image has been auto-loaded
        handleControlState: true, // [Bool] style controll - target of load action (ex. show 'loading..' label for 'show more' link(only if present))
        useArrowKeys: false, // if true use arrows (left/right) to go to previous/next page
        useGestures: false, // use touch gestures to prev/next page
        formSubmitOnEnter: true, // if true - submit form if on any form's input Enter has been hit
        rows: null, // custom listing rows file sub-path; ex: Cms.List/ContentData/index/rows_default
        url: null,  // custom listing url
        // default list's controls (ex. prev/next) properties            
        ctrl: {
            event: 'click', // run control related action on click by default
            call: 'load', // by default run 'load' action 
            url: null, // ajax request url
            mode: 'post', // ajax read mode: [ get | post ]
            silent: false, // {bool] silently laod listing page (don't show "wait" layer, don't scroll top after finish)            
            waitmeter: 'loading...' // [bool | String] show waitmeter while loading listing page; if String show label
        },
        // list animations; set 'null' to disable
        anims: {
            nextPage: false, // 'page-up' - load next  page css animation name
            prevPage: false, // 'page-down' - load prev page css animation name
            noPage: 'no-page', // animation when no prev/next page available
            appendPage: false // 'page-slide-down' animation when appending page on loadMode = 'append'
        },
        // allow to sort listing element; false to disable, true or jQUeryUI sortable parameters JSON object 
        sort: false,    // NOTE: each list element/row must contain data attribute 'id' (ex. <... data-id="1"> )
        sortUrl: '',    // url of sorting action on server (ex /cms/content_data/sort)
        // callbacks
        beforeLoad: function() {
            return true;    // must return true - otherwise ajax request will not be send
        },
        afterLoad: function() {
        },
        onLoadFailed: function() {
        },
        update: function(t) { // custom function executed on listing update (after data read)            
        },
        noResults: function(t) { // execute when list has no results           
        },
        autoloadMaxPageReached: function() { // only when autoloadOnScroll - executed when maximum allowed number of pages has been read (autoloaded) on view            
        },
        onSubmit: function() { // execute on search form submit (if any) - must return true to submit form
            
            return true;
        },
        // internal callbacks
        _update: function(t) {
            
            // update listing status from server (count, limit, ...)  
            cfg.$this.mtsoftUiListing().config('status', t.status);
            
            // update listing itself
            _updateList(this, t);

            // update all lsiting controls
            //this.$this.mtsoftUiListing().configRestore(); // rollback cfg (if temporary configuration)
            _updateControls(this, t);

            // update current url / handle listing history pages
            _updateUrl(this, t);

            // scroll top if reuqired
            _scrollTop(this, t);

            // execute no results callback
            if (!t.status.count) {

                cfg.noResults();
            }
        },
        txt: {
            loading: 'loading ...'
        },
        icon: {
            wait: 'ico-wait'
        },
        // internal 
        status: {}, // current listing parameters (page, pages, ... equal to CakePHP request->paging; values are always set on server side)
        _listId: null, // list identifier in format: List__modelname__action_name
        _model: null, // model name (ex. SampleModel)
        _action: null, // list controller action
        _prevPage: 1, // previous page number
        _triggedBy: null, // [jQuery] node which trigged laod action (link or button)
        _urlState: {}, // browser url/history state object
        _loading: false, // flag - true means ajax request is active
        _loadQueue: [], // queue of laod ajax request to be performed
        _autoloadStartOff: 0, // start page offset of autoload
        _autoloaded: false, // flag; true if all avilable pages of autoload has been loaded (=autoloadOnScroll value)
        controls: [], // all controls (name & jQuery node) used for this listing (bot types - with html content generated on server side or not)
        _controls: [] // only controls with content (full html code) updated fully after page re-load 
                // (pairs: (helper method) name => (helper method) arguments) used for this listing
                // NOTE: controls which all html contents are not updated after page load are not present here.
                // To not include control to this _controls array - set control 'update' property to false
    };
    $.fn.mtsoftUiListing.def = {};   // buffor where all listings definitions form server are placed




    // -------------------------------------------------------------------------
    //
    // Public actions
    //
    var actions = {
        
        /**
         * Get / set plugin configuration.
         *
         * @param {Mixed} param     Configuration parameter name
         *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
         *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
         *                                    All other then given in JOSN argument parameters will be keep untouch;
         *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
         *                           null - resets current configuration (remove all parameters)
         *
         * @param {Mixed} val       configuration parameter value
         * @param {Bool} update     if true update curent global configuration (cfg) object
         *
         * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
         *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
         */
        config: function(param, val, update) {

            var c = 'config.mtsoftUiListing',
                    $this = $(this), _cfg = $this.data(c);
            // reset current configuration
            if (param === null) {
                $this.data(c, null);
                return _cfg;
            }

            if (val !== undefined || typeof (param) === 'object') { // setter

                // update single parameter value
                if (val !== undefined) { // single parameter value

                    _cfg[param] = val; // assign value
                    $this.data(c, _cfg); // update

                } else {    // multiple parameters values defined as JSON object {}

                    // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                    //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                    _cfg = $.extend(true, {}, _cfg, param);
                    $this.data(c, _cfg); // update
                }
                if (update === undefined || update) { // update global configuration (cfg) object
                    cfg = _cfg;
                }
                return _cfg;
            } else { // getter

                return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
            }
        },
        /**
         * Apply new (temporary) configuration settings.
         * Set here configuration changes can be rolled back using 'configRestore'
         * action if required.
         * 
         * @param {JSON} param     configuration settings to be temporary applied 
         *                          given as JSON object
         */
        configTmpApply: function(param) {   // , val, update

            var $this = $(this), _cfg = $this.mtsoftUiListing().config();

            // remember current configuration
            $this.data('cfg.bck', _cfg);    // full, current configuration settings object
            $this.data('cfg.modifedParams', param); // only temporary applied configuration settings 
            
            // set and apply new configuration 
            actions.config.call($this, param);  // , val, update
        },
        /**
         * Back to configuration applied on configTmpApply action call
         */
        configRestore: function() {

            var $this = $(this);

            // restore previous configuration 
            if ($this.data('cfg.bck')) {

                // restore ONLY modified params 
                var bckParams = $this.data('cfg.bck'), // all configuration settings
                        modParams = $this.data('cfg.modifedParams'), // applied temporary settings
                        restoredParams = {};    // configuration settings to be restored 

                for (var p in modParams) {
                    // get orginal (backuped) parameter value 
                    restoredParams[p] = bckParams[p];  
                }
                // set and apply orginal configuration settings 
                actions.config.call($this, restoredParams);

                $this.data('cfg.bck', null); // remove temporary saved configuration
                $this.data('cfg.modifedParams', null); // remove temporary saved mdofied parameters 
            }
        },

        /**
         * Initialize
         * 
         * @param {type} params
         * @returns {JSON} configuration object
         */
        init: function(params) {

            var $this = $(this); //_getDefinitionParams(args, cfg), cfg;

            //
            // initial configuration
            //
            
            cfg = $.extend(true, {}, {$this: $this},
            $.fn.mtsoftUiListing.defaults,
                    $this.data(),
                    $.fn.mtsoftUiListing.def[$this.attr('id')],
                    params);  // cfg is PLUGIN GLOBAL
            
            // assign list parameters            
            cfg._listId = $this.attr('id');
            var ch = cfg._listId.split('__');            
            cfg._model = cfg.status.model ? cfg.status.model : cfg.model;

            cfg._action = ch[2];
            // current listing state (cfg.status) is set on server side in ListingHelper view() or generate()
            cfg._prevPage = cfg.status.page; // init prev page (to current)

            $this.attr('data-listing', ''); // mark this node is $.mtsoftUiListing 

            // save config for listing
            actions.config.call($this, cfg);

            //
            // attach event handlers
            //

            // controls used to control listing (navigation, search, ...)
            _defineControls();

            // forms used to search data on listing
            _defineForms();

            // define auto-scroll of pages
            _defineAutoscroll();

            // use lazyload to load images on listing
            _lazyload();

            // define keys used to steering listing
            _defineKeys();

            // define touch gestures used to steering listing
            _defineGestures();

            // use browser history to manage listing pagination
            _handleBrowserHistory();
            
            // define sortable behaviour
            _defineSortable();

            // count lisitngs on page (required to know how many listings on view; if single - don't include model name (m) in urls)
            window._mtsoftUiListings = $('[data-listing]').length;

            $(window).resize(); // call resize(for sticky panel(s) and other) 

            return cfg;
        },

        /**
         * Load single listing page 
         * 
         * @param {JSON} params         special parameters
         * @param {function} callback   after single page load custom callback
         * @returns {undefined}
         */
        load: function(params, callback) {

            //var prevCfg = cfg.$this.mtsoftUiListing().config();
            //cfg.$this.mtsoftUiListing().config(params.listing || {});
            
            if (cfg.beforeLoad()) {
                
                params = params !== undefined ? params : {};
                if (!params.url) { // given one or more url parameters (like `page` or `limit`), but no url;
                    
                    // convert given paramters to request url parameters 
                    var currUrl = _deUrl();
                    params.url = $.extend({}, currUrl.parameters, params);                    
                }
                
                _loadByAjax(params, callback);
            }
        },
        /**
         * Re-load listing using current parameters.
         * If no rows found and not first page previous page will b read 
         * 
         * @param {Bool} silent     if true re-load silently
         * @param {JSON} params     special parameters
         */
        reload: function(silent, params) {
            
            //cfg.$this.load($.extend({}, cfg.ctrl, {url: document.location.href}));
            var reloadParams = $.extend({}, cfg.ctrl, {
                url: _appendUrlParams(),
                data: {pageDownIfEmpty: 1} // this will force to read previous/down page if no results for current                 
            }, params);
            if (silent) { // set silent for this request only 
                reloadParams.waitmeter = 0;
                reloadParams.listing = {scrollToList: 0, lockListOnLoad: 0};
            }
            
            // NOTE: for reloadParams DON'T USE BOOLEAN VALUES (true/false) or null
            // All such values are converted to String!!!
            // Use 0,1 or '' (empty) instead (@TODO to fix it) 
            actions.load.call(cfg.$this, reloadParams);
            //{page: cfg.status.page - 1}
        },
        /**
         * Submit parent form of current control
         * 
         * @param {JSON} params     special parameters
         */
        submit: function(params) {
            
            params.target.parents('form').filter(':first').submit();
        },
        deUrl: function(url) {

            return _deUrl(url);
        }       
    };


    // -------------------------------------------------------------------------
    //
    // define private functions (internal use only)
    //

    /**
     * Find all listing CONTROLs and assign event handlers
     */
    function _defineControls() {
        
        $('[data-' + cfg._listId + ']').each(function() {

            var $this = $(this);
            
            if ($this.data(cfg._listId.toLowerCase()).role) { // only if role defined
                
                // assign control to listing
                $this.data('list', cfg.$this);

                // assign event handler (if event defined) 
                var ctrlCfg = _assignEventHandler($this);

                // apply/init extendend behaviour (from mtsoft.ui.listing.controls.js -> $mtsoftUiListingControl())
                //try {
                
                $this.mtsoftUiListingControl(cfg, ctrlCfg).init();
                //} catch (e) {
                //}

                // collect this list controls (needed to update after ajax request ended)
                cfg.controls.push({
                    $n: $this,
                    role: ctrlCfg.role
                });
                // Below controls list will be send with ajax request
                // and their full html code will be replaced with html code
                // returned from server.
                // To include control on below list 'data-<listId>={..., args: ...}' array property must be set for control html <span> code 
                // (use on server side: 'args' => func_get_args())
                if (ctrlCfg.args) {
                    cfg._controls.push({m: ctrlCfg.role, a: ctrlCfg.args});
                }
            }
        });
    }

    /**
     * Find all related to this listing FORMs and attach events handlers
     */
    function _defineForms() {

        $('[data-form-' + cfg._listId + ']').each(function() {

            var $form = $(this);
            // assign form to listing
            $form.data('list', cfg.$this);

            // capture all links, inputs and buttons to know (if required) 
            // which one caused search form submit
            var _submittedBy = function($n) {

                var $frm = $n.parents('form').filter(':first'), $list = $frm.data('list');
                $frm.data('_submittedBy', $n);
                $list.data();
            };
            $form.find('a, button').on('click.mtsoftUiListing', function() {

                _submittedBy($(this));
            });
            $form.find('input, textarea').on('blur.mtsoftUiListing', function() {
                _submittedBy($(this));
            });
            $form.find('select').on('change.mtsoftUiListing', function() {
                _submittedBy($(this));
            });

            if (cfg.formSubmitOnEnter) { // submit form if on any of inputs Enter key has been hit

                $form.find('input, textarea').on('keyup.mtsoftUiListing', function(e) {
                    if (e.which === 13) {

                        $(this).submit(); // submit form
                    }
                });
            }

            // on form submit
            $form.on('submit.mtsoftUiListing', function(e) {
                
                // set right listing
                $(this).data('list').mtsoftUiListing();

                if (cfg.onSubmit()) { // submit only if 'onSubmit' returns true

                    if ($form.attr('data-ajax')) { // submit via AJAX ajax

                        // set request parameters
                        var ctrlCfg = $.extend(true, {}, cfg.ctrl,
                                //{url: $('#Url' + cfg._listId).attr('href')},
                                $(this).data(cfg._listId.toLowerCase()));

                        // add to request url all data set on current form
                        var formParams = {};
                        $(this).find('[name^="data"]').each(function() {

                            var $this = $(this), _n = $this.attr('name'), name;

                            if (_n.indexOf('[f]') > -1) { // this is filter

                                // rip filter name and get value ( form CakePHP format: data[Model][f][filter_param] => f[Model.filter_param] )
                                name = _n.replace(/^data\[([A-Za-z0-9\_]+)\]\[f\]\[([A-Za-z0-9\_]+)\](\[\])*/i, "f[$1" + (cfg.paramType === 'named' ? '__' : '.') + "$2]");// $3use __ instead of . because CakePHP don't thread f as array, but as string
                            } else {
                                // this is listing parameter (page, sort, direction, limit or m, q); (data[Model][param] => param
                                name = _n.replace(/^data\[([A-Za-z0-9\_]+)\]\[([A-Za-z0-9\_]+)\]/i, "$2");
                            }

                            var value = $.trim($(this).val());

                            if (value === '' || // input (text) value empty
                                    ($this.attr('type') && $this.attr('type').toLowerCase() === 'checkbox' && !$this.prop('checked'))) { // checkbox not checked 
                                // set internal marker to '_d_'
                                // which means this value should be deleted and not included in reuqest url
                                value = '_d_';
                            }

                            // rip form's inputs values:
                            // 'f[Model.file_name]' - can be defined more than once 
                            // ( mulitple values: data[Model][field_name][] );
                            // If is defiend more than once - set value as array 
                            // 
                            // Ex:
                            //      data[Model][field_name][] = 1, data[Model][field_name][] = 2
                            // to:
                            //      'f[Model.field_name]' = [1,2] 
                            // 
                            if ($this.attr('type') === undefined || $this.attr('type').toLowerCase() !== 'radio') {

                                if (formParams[name] === undefined) {
                                    // value
                                    formParams[name] = value;
                                } else {
                                    // array of values
                                    if (typeof (formParams[name]) !== 'object') {
                                        formParams[name] = [formParams[name], value];
                                    } else {
                                        formParams[name].push(value);
                                    }
                                }
                            } else { // this is radio - special case

                                if ($this.prop('checked')) { // only checked value 
                                    formParams[name] = value;
                                }
                            }
                        });
                        
                        // convert array values:
                        //      ex. 'f[Model.field_name]' = [1,2] 
                        //  to: 
                        //      'f[Model.file_name][0]' = 1 
                        //      'f[Model.file_name][1]' = 2 
                        for (var name in formParams) {
                            if (typeof (formParams[name]) === 'object') {

                                for (var off in formParams[name]) {
                                    formParams[name + '[' + off + ']'] = formParams[name][off];
                                }
                                delete(formParams[name]);
                            }
                        }

                        // build full request url for target listing
                        // (with form new search parameters applied)
                        var currParams = _deUrl($('#Url' + cfg._listId).prop('href'));

                        if ($(this).data('clearFilters')) {

                            for (var filter in currParams.filters) {                                
                                delete currParams.parameters[filter];
                            }
                        } 
                        if ($(this).data('clearSearch')) {
                            delete currParams.parameters.q;
                        }
                        ctrlCfg.url = _appendUrlParams(formParams, currParams.parameters);                          
                        // ctrlCfg.url = _appendUrlParams(formParams);

                        // set target element (search button ususally)
                        ctrlCfg.target = $(this).data('_submittedBy');

                        // load content using ajax
                        actions.load.call(cfg.$this, ctrlCfg);

                        return false;   // don't submit - ajax request is sending

                    } else {    // submit via HTTP request

                        // stylie list loading
                        _loadingStyleOn($(this).data('_submittedBy'), cfg.ctrl.waitmeter);

                        return true;    // submit form 
                    }
                }
                
            });

        });

    }

    /**
     * Define auto-scroll beahviour (automatically append next page onces user scrolled to teh end for current page )
     */
    function _defineAutoscroll() {

        // auto-load next page if end of page reached
        if (cfg.autoloadOnScroll) {

            // build auto-load marker
            var $autoloadMarker = $('<div class="' + cfg.cssPrefix + 'autoload" data-list="' + cfg._listId + '"><span></span><span class="wait" hidden>'
                    + $.mtsoft.ui.icon('#ico-wait', 'ico-wait', {}, true)
                    + cfg.txt.loading + '</span></div>');
            cfg.$this.parent().append($autoloadMarker);

            // remember autoload control                
            actions.config.call(cfg.$this, {
                $autoloadMarker: $autoloadMarker
            });

            // watch for scroll and load content if required
            if (!window._mtsoftUiListingScroll) {

                _autoloadOnScroll();
                window._mtsoftUiListingScroll = true;
            }
        }
    }

    /**
     * Define keyboard keys used
     * 
     */
    function _defineKeys() {

        // use arrow keys to navigate
        if (cfg.useArrowKeys) {

            $(window).on('keydown.mtsoftUiListing', function(e) {

                if (e.which === 37 || e.which === 39) {

                    var $firstList = $('[data-listing]').filter(':first');
                    $firstList.mtsoftUiListing();

                    if (e.which === 37) {   // arrow left
                        // previous page
                        if (cfg.status.page - 1 >= 1) {
                            //actions.load.call(cfg.$this, $.extend({}, cfg.ctrl, {url: _buildUrl({page: cfg.status.page - 1})}));
                            actions.load.call(cfg.$this, $.extend({}, cfg.ctrl, {url: _appendUrlParams({page: cfg.status.page - 1})}));
                        } else {
                            _animNoPage();
                        }
                    }

                    if (e.which === 39) {   // arrow right
                        // next page
                        if (cfg.status.page + 1 <= cfg.status.pageCount) {
                            //actions.load.call(cfg.$this, $.extend({}, cfg.ctrl, {url: _buildUrl({page: cfg.status.page + 1})}));
                            actions.load.call(cfg.$this, $.extend({}, cfg.ctrl, {url: _appendUrlParams({page: cfg.status.page + 1})}));
                        } else {
                            _animNoPage();
                        }
                    }
                }
            });
        }
    }

    /**
     * Define gestures used
     */
    function _defineGestures() {

        // handle gesture for touch based devices
        if (cfg.useGestures) {

            try {
                cfg.$this.swipe({
                    //Generic swipe handler for all directions
                    swipeLeft: function(event, direction, distance, duration, fingerCount) {

                        cfg.$this.mtsoftUiListing();
                        // next page
                        if (cfg.status.page + 1 <= cfg.status.pageCount) {
                            //actions.load.call(cfg.$this, $.extend({}, cfg.ctrl, {url: _buildUrl({page: cfg.status.page + 1})}));
                            actions.load.call(cfg.$this, $.extend({}, cfg.ctrl, {url: _appendUrlParams({page: cfg.status.page + 1})}));
                        } else {
                            // no next page
                            _animNoPage();
                        }
                    },
                    swipeRight: function(event, direction, distance, duration, fingerCount) {

                        cfg.$this.mtsoftUiListing();
                        // previous page
                        if (cfg.status.page - 1 >= 1) {
                            //actions.load.call(cfg.$this, $.extend({}, cfg.ctrl, {url: _buildUrl({page: cfg.status.page - 1})}));
                            actions.load.call(cfg.$this, $.extend({}, cfg.ctrl, {url: _appendUrlParams({page: cfg.status.page - 1})}));
                        } else {
                            // current page is first
                            _animNoPage();
                        }
                    }
                    //Default is 75px, set to 0 for demo so any distance triggers swipe
                    //,threshold: 0
                });
            } catch (e) {
            }
        }
    }

    /**
     * Handle browser history
     */
    function _handleBrowserHistory() {

        // handle browser history 
        if (cfg.pagesHistory) { // each listing request is keep in browser's history

            /*
             * Necessary hack because WebKit fires a popstate event on document load
             * https://code.google.com/p/chromium/issues/detail?id=63040
             * https://bugs.webkit.org/process_bug.cgi
             */
            window.addEventListener('load', function() {
                setTimeout(function() {
                    // ---

                    // handle browser's back/forward buttons
                    if (!window._mtsoftUiListingPopstate) {

                        $(window).on('popstate.mtsoftUiListing', function() {

                            var targetUrl = _deUrl(), $list = null;

                            if (targetUrl && targetUrl.parameters && targetUrl.parameters.m) {

                                // target url model name is given
                                $list = $("[id^='List__" + targetUrl.parameters.m.toLowerCase() + "__']");
                            } else {

                                // only single listing on page (or no parameters)
                                // use all listing on page
                                $list = $('[data-listing]');
                            }

                            var params = $.extend({}, cfg.ctrl, {url: document.location.href});
                            if ($list.length === 1) { // single list on view

                                $list.mtsoftUiListing().load(params);
                            } else {

                                // send load request for all listings on view
                                $list.each(function() {
                                    var cfg = $(this).mtsoftUiListing().config();
                                    cfg.$this.load($.extend({}, cfg.ctrl, {url: document.location.href}));
                                });
                            }
                        });
                        window._mtsoftUiListingPopstate = true;
                    }
                    // ---
                }, 1);
            });
        }
    }
    
    /**
     * Define sortbale behaviour of listing elements     
     */
    function _defineSortable() {
        
        if (cfg.sort) { //  && cfg.$this.children().length > 1
            
            /**
             * This function fixes sortable related issues
             * @param {Event} e
             * @param {jQUeryObject} tr
             * @returns {unresolved}
             */
            var helperFix = function(e, tr) {
                
                // fix table all rows width (rows are collapsed when one row is dragged)                
                var $originals = tr.children(), $helper = tr.clone();
                $helper.children().each(function(index) {
                  $(this).width($originals.eq(index).outerWidth());
                });
                
                return $helper;
            },
            /**
             * Update list ordering on server 
             * @param {type} ui
             * @returns {undefined}Update list ordering on server 
             */
            _updateServer = function(ui) {
                
                var movedElementId = ui.item.attr('data-id'), // ordered element db id
                    // element next to ordered element (dropped on new postion) 
                    // NOTE: on each listing page row element CAN'T be dropped as last element
                    movedElementNextId = ui.item.next().attr('data-id');  

                $.post($.mtsoft.url(cfg.sortUrl), {
                    movedElementId: movedElementId,
                    movedElementNextId: movedElementNextId
                }, function(t) {
                    //$(config.metersNode).mtsoftUiLock().off();
                });
                        
            };
            
            var def = $.extend(true, {
                // default sortbale behaviour 
                cursor: 'move', 
                //axis: "y",  // move only veritcal
                containment: cfg.$this.parent(), // allow only moving on list area
                placeholder: cfg.cssPrefix+"placeholder",      
                $rows: cfg.$this, // sortable elements container
                revert: 150,
                scroll: true,   // scroll window once end of page reached on dragging 
                helper: helperFix,
                start: function(event, ui) {
                    //console.log(ui.placeholder);
                    //$(".ui-state-highlight")
                    ui.placeholder.css({height: ui.helper.outerHeight()});
                    ui.helper.addClass('dragging');                    
                },
                stop: function(event, ui) {},
                update: function(event, ui) {
                    _updateServer(ui);
                    //ui.helper.removeClass('dragging');
                }
            }, cfg.sort); 
            
            $( def.$rows ).sortable(def).disableSelection();
            
            //def.$rows.css({cursor: 'drag'});
        }
    }

    /**
     * Read single listing page using Ajax
     * @param {JSON} params         load parameters:
     *                                  url [Mixed] JSON object with parameter-values pairs
     *                                      [String] full url 
     *                                  data [JSON] additional data to be send to server
     *                                  listing [JSON] custom listing parameters only for this load
     *                                  
     * @param {JSON} callback           after single page load custom callback function              
     * 
     * @returns {undefined}
     */
    function _loadByAjax(params, callback) {

        if (params.url) {

            if (!cfg._loading) { // if control don't posses url/href - ommit and don't set

                // preapre data to be send by ajax request
                var params = $.extend(true, {}, {mode: 'POST'}, params), // by default always send ajax request as POST 
                    sendParams = $.extend(true, {}, {
                    listId: cfg._listId,
                    rows: cfg.rows,
                    url: cfg.url,
                    c: cfg._controls, // all listing controls definitions                    
                    ajax: params.ajax || cfg.ajax,    // force ajax mode 
                    listing: params.listing || {}   // custom lisitng configuration (for this request only)
                }, params.data);
                
                // send ajax reuqest

                var jqXHR = $.mtsoft.ui.ajax(_buildFullUrl(params.url), {
                    //node: config._submittedBy,
                    showErrors: false, // don't show error messages
                    waitmeter: params.waitmeter, // show waitmeter
                    beforeResult: function(data, textStatus, jqXHR) {

                        // set right listing and update listing configuration 
                        // (if special settings for this request only)
                        //$('#' + data.listId).mtsoftUiListing().config(data.listing);
                        $('#' + data.listId).mtsoftUiListing().configTmpApply(data.listing);
                    },
                    onResultSuccess: function(data, textStatus, jqXHR) {
                
                        // update listing
                        cfg._update(data); // internal callback
                        cfg.update(data);   // custom callback

                        // init lazyload for new loaded content images
                        _lazyload();


                        // handle page title
                        if (cfg.updateUrl) {

                            $('title').html(data.title);
                        }

                        // callback
                        try {
                            callback();
                        } catch (e) {
                        }
                        cfg.afterLoad(data);
                    },
                    onResultFailed: function(data, textStatus, jqXHR) {

                        // callabck                
                        cfg.onLoadFailed(data);
                    },
                    onComplete: function(jqXHR, textStatus) {

                        var data = jqXHR.responseJSON;

                        if (cfg.handleControlState) { // MUST BE before calling configRestore()

                            // restore default sub-label on element which caused load 
                            if (cfg._triggedBy) {
                                cfg._triggedBy.mtsoftUiState();
                                cfg.$this.mtsoftUiListing().config('_triggedBy', null);
                            }
                        }

                        // remove list lock
                        if (cfg.lockListOnLoad && cfg.anims.nextPage === null) { // don't remove if load animation id defined
                            
                            $(cfg.$this).mtsoftUiLock(cfg.lockListOnLoad).off();
                        }

                        // restore default configuration (only if temporary has been set before load action call)
                        cfg.$this.mtsoftUiListing().configRestore();

                        if (data) { // if data returned from server (not aborted)

                            // update listing status with parameters form server side
                            // and reset _loading flag
                            cfg.$this.mtsoftUiListing().config('status', data.status);
                            cfg.$this.mtsoftUiListing().config({
                                _loading: false,
                                _prevPage: data.status.page
                            });
                            
                            // handle autoscroll
                            if (cfg.autoloadOnScroll) {

                                try { // show auto-load marker (if exists)
                                    cfg.$autoloadMarker.show();
                                } catch (e) {
                                }

                                cfg.$this.mtsoftUiListing().config({
                                    _autoloaded: false, // next page will be loaded if end of document reached
                                    _autoloadStartOff: data.status.page === 1 ? 0 : cfg._autoloadStartOff // reset autoload start offset (if sets of autoscrolls allowed)
                                });
                            }
                        }
                        
                        // call resize & scroll (for sticky panel(s), lazyloads and other)                         
                        window.setTimeout(function() {
                            
                            $(window).resize(); 
                            $(window).scroll();                            
                        }, 100);
                        
                    }
                }, {
                    type: params.mode,
                    data: sendParams
                });
                // set _loading flag equal to current request url
                cfg.$this.mtsoftUiListing().config({_loading: {url: params.url, jqXHR: jqXHR}});

                // stylie list loading (include custom configuration for this request only) 
                _loadingStyleOn($.extend({}, cfg, params.listing ? params.listing : {}), params.target);
            } else {

                // if target url is other than already processing ajax request - abort ajax request
                if (_buildFullUrl(params.url) !== _buildFullUrl(cfg._loading.url)) {

                    // abort current request
                    cfg._loading.jqXHR.abort();

                    // _diffUrlParams => get ONLY new or changed parameters compared 
                    // to aborted previously listing url parameters
                    params.url = _appendUrlParams(_diffUrlParams(params.url), cfg._loading.url);

                    cfg.$this.mtsoftUiListing().config('_loading', false);
                    actions.load.call(cfg.$this, params, callback);
                }
            }
        }
    }

    /**
     * Update list contents 
     * @param {type} cfg
     * @param {type} data
     * @returns {undefined}
     */
    function _updateList(cfg, data) {

        if (cfg.loadMode === 'paginate' || data.status.page === 1) { // if page is 1 listing was reseted (new search query or filter or order applied)

            // fill list with single page contents
            if (!cfg.anims.nextPage || data.status.page === cfg._prevPage) { // page not changed (first hit or page refresh)
                
                // without animation
                cfg.$this.html($(data.html));
                
            } else {
                
                // mark current page absolute, so it will be keep below currenlty animating page
                var $prevPage = cfg.$this.children().filter(':first');
                $prevPage.css({position: 'absolute', left: 0, top: 0}); // prevPage will be removed when animation end

                // In case there are more pages then previous
                // (for example when previous animation not finished yet)
                // remove all other than first previous pages
                //cfg.$this.children().not(':first').remove();

                // append new page
                var $newPage = $(data.html).addClass('animating');
                cfg.$this.append($newPage);

                if (cfg.lazyload) {

                    // To make lazyload work properly trigger 
                    // window.scoll event on some period of time until page animation in progress
                    // (will force to show images appears when visible on viewport)
                    var forceLL = window.setInterval(function() {
                        $(window).scroll();
                    }, 200);
                }
                
                var _afterAnim = function($this) {
                    
                    window.clearInterval(forceLL);
                    $(window).scroll();

                    // remove animation class for current page
                    $this.removeClass('animating');

                    // remove previous page
                    $prevPage.remove();

                    // remove wait/lock layer                   
                    $(cfg.$this).mtsoftUiLock(cfg.lockListOnLoad).off();
                };

                if (data.status.page >= cfg._prevPage) {
                    
                    // next page
                    $newPage.mtsoftUiAnim(cfg.anims.nextPage, function() {
                        _afterAnim(this);
                    }).play();
                    
                } else {

                    // prev page
                    $newPage.mtsoftUiAnim(cfg.anims.prevPage, function() {
                        _afterAnim(this);
                    }).play();
                }
                //cfg.$this.append($newPage);
                //$(this).css({visibility: 'visible'}).mtsoftUiAnim(anim).play();
            }

        } else {
            
            // append page rows to already exisitng rows
            var $newPage = $(data.html);
            cfg.$this.append($newPage);

            if (cfg.anims.appendPage) {

                $newPage.mtsoftUiAnim().on({name: cfg.anims.appendPage, restore: true});

                // if user scolls page - finish animation
                $(window).one('scroll.mtsoftUiListing', function() {
                    $newPage.mtsoftUiAnim().stop(true);
                });
            }
        }
        $('#Url' + cfg._listId).prop('href', data.url); // update node with current url value        
    }

    /**
     * Update all lsiting controls:
     * - navigation links
     * - sort links 
     * - search box
     * - filter links/inputs
     * 
     * @param {type} cfg
     * @param {type} data
     */
    function _updateControls(cfg, data) {

        var newCtrls = [], $newCtrl = null;
        
        $.each(cfg.controls, function() { // all list controls

            var $ctrl = $(this)[0].$n, role = $(this)[0].role;

            if (data[role]) { // only if new html contents of controll returned from server

                // replace control html code with current
                $newCtrl = $(data[role].shift());
                // assign control to listing
                $newCtrl.data('list', cfg.$this); // assign to listing
                $ctrl.replaceWith($newCtrl);

                // assign event handler to updated control
                _assignEventHandler($newCtrl);

                // collect new control node
                newCtrls.push({
                    $n: $newCtrl,
                    role: role
                });
            } else {

                // this control html code is NOT replaced with new one - re-print
                newCtrls.push({
                    $n: $ctrl,
                    role: role
                });
                // execute update method of controll
                //$ctrl.mtsoftUiListingControl().update(data);
                $newCtrl = $ctrl;
            }

            // execute extendend functionality update event handler
            try {
                //$ctrl.mtsoftUiListingControl(cfg, {role: role}).update(data);
                
                if ($newCtrl) {
                    $newCtrl.mtsoftUiListingControl(cfg, {role: role}).update(data);
                }
            } catch (e) {
            }

        });

        // update controls list with new nodes (replace html code with new one returned from server)
        cfg.$this.mtsoftUiListing().config('controls', newCtrls);
    }

    /**
     * Update current url to match listing state
     * and handle pages histiory.
     * 
     * @param {JSON} cfg
     * @param {JSON} data
     */
    function _updateUrl(cfg, data) {

        if (cfg.updateUrl) {

            if (cfg.pagesHistory) {
                // make current browser url match current lisitng state (url parameters)
                // and add new entry on browser history
                // ( ONLY if new url is other than current! )
                if (data.url !== window.location.href) {

                    //history.pushState(cfg._urlState, null, _buildUrl({}, data.url));
                    history.pushState(cfg._urlState, null, _buildFullUrl(data.url));
                    //_buildFullUrl(_appendUrlParams(newParams, currParams))
                }
            } else {

                // make current browser url match current lisitng state (url parameters)
                history.replaceState(cfg._urlState, null, _buildFullUrl(data.url));
                //history.replaceState(cfg._urlState, null, _buildUrl({}, data.url));
                //history.replaceState(cfg._urlState, null, _buildFullUrl(_appendUrlParams({}, currParams)));
            }
        }

    }

    /**
     * Assign event handler
     * @param {JSON} $ctrl
     */
    function _assignEventHandler($ctrl) {
        
        // set this control configuration parameters (data- parameters are on top)
        var ctrlCfg = $.extend(true, {}, cfg.ctrl, $ctrl.data(cfg._listId.toLowerCase()));
        
        if (ctrlCfg.event) {

            // try to get url from href attribute (if present) from control or first child 
            // works only if control is equal to link (a href);
            // for sets of links attempt to read href will be made on event handler below
            ctrlCfg.url = ctrlCfg.url || $ctrl.attr('href') || $ctrl.children().filter(':first').attr('href');

            $ctrl.on(ctrlCfg.event + '.mtsoftUiListing', function(e) {
                
                // get right target element; if <span> - this is sub-label -> get parent (link or button)
                var $trg = $(e.target)[0].tagName.toUpperCase() === 'SPAN' ? $(e.target).parent() : $(e.target),
                        $l = $(this).data('list'); // apply temporary config and set right listing

                $l.mtsoftUiListing(); // set right listing

                if (ctrlCfg.listing) {  // custom listing configuration for this control action

                    // set temporary custom configuration
                    // some problmes - removed
                    //$l.mtsoftUiListing().configTmpApply(ctrlCfg.listing);
                }

                // call action defined for this control;
                // if controll posses href attribute use it as ajax request target url
                if (ctrlCfg.call) { // if default event defiend
                    actions[ctrlCfg.call].call(cfg.$this, $.extend(true, {}, ctrlCfg,
                            {url: _deUrl($trg.attr('href') || ctrlCfg.url).parameters, target: $trg}));
                }
                return false; // don't execute default action 
            });
        }
        return ctrlCfg;
    }


    function _urldecode(url) {
        
        return url !== undefined ? decodeURIComponent(url.replace(/\+/g, ' ')) : '';
    }
    
    /**
     * Rip listing parameters form given(current) url string expression
     * NOTES: 
     * - only http / https protocols are supported
     * - username/password in url is NOT supported
     * 
     * @param {String} url      listing url 
     *                          (ex. http://localhost/list/f[M__f_n1]:1/f[M__f_n1_o_]:> )
     * 
     * @retruns {JSON}         url as parameters object 
     *                          (ex.  { protocol: 'http',
     *                                  path: 'localhost/list',
     *                                  parmeters: {
     *                                      f[M__f_n1]: "1"
     *                                      f[M__f_n1_o_]: ">"
     *                                  }
     *                                }
     */
    function _deUrl(url) {
        
        if (url === undefined) {

            // get url of current listing
            url = $('#Url' + cfg._listId).prop('href') || window.location.href;
        }

        if (typeof (url) !== 'object') { // url given as string

            // get protocol
            var url = $('<div />').html(url).text(), // decode html entities (mainly &amp%3 (&amp;)) 
                    proto = url.indexOf('https:') > -1 ? 'https:' : 'http:',
                    _prms = {}, _flts = {}, _list = {};

            // de-compse url and get parameters
            if (cfg.paramType === 'named') { // named parameters (ex. /param1:value/param2/value)

                var chs = url.split("/"), urlPre = '';

                while (chs.length) {
                    var ch = chs.shift(), _ch, idx, v;
                    if (ch !== '' && ch.indexOf(':') < 0) {
                        // build url prefix
                        urlPre += '/' + ch;
                    } else {
                        // split to name & value
                        _ch = ch.split(':');
                        if (_ch[1]) {
                            idx = _urldecode(_ch[0]);
                            v = _urldecode(_ch[1]);
                            _prms[idx] = v;
                        }
                    }
                    if (idx && idx.indexOf('f[') === 0) { // this is filter
                        _flts[idx] = v;
                    } else {
                        if (idx !== 'q')
                        _list[idx] = v;
                    }
                }
            } else { // query string (ex. ?pama=value&param1=value)

                var _chs = url.split("?"), urlPre = (_chs[1] ? _chs[0] : url).replace(proto + '/', '')
                        , chs = _chs[1] ? _chs[1].split("&") : [];

                while (chs.length) {

                    var ch = chs.shift(), _ch, idx, v;

                    // split to name & value
                    _ch = ch.split('=');
                    if (_ch[1]) {
                        idx = _urldecode(_ch[0]);
                        v = _urldecode(_ch[1]);
                        _prms[idx] = v;
                    }

                    if (idx && idx.indexOf('f[') === 0) { // this is filter
                        _flts[idx] = v;
                    } else {
                        if (idx !== 'q')
                        _list[idx] = v;
                    }
                }
            }

            return {
                protocol: proto,
                path: urlPre, // url path (without protocol)
                parameters: _prms, // array of parameters - name/value pairs
                filters: _flts, // array of filters 
                list: _list, // list parameters only (page, sort, direction, limit)
                search: {q: _prms.q}
            };
        } else {

            // url is parameters object only
            return {
                parameters: url
            };
        }
    }

    /**
     * Get ONLY listing parameters that differ from current state listing parameters
     * (ex. 
     *  current url: /p1:v1/p2:v2
     *  in given url: /p3:v3/p2:v1
     *  ==>
     *  /p3:v3/p2:v1 (p2 value changed!)
     * )
     * @param {Array} targetParams      target listing parameters
     * @param {Array} currParams        currently applied listing parameters (or params to compare)
     * 
     * @returns {JSON}          only parameters different form current listing parameters
     */
    function _diffUrlParams(targetParams, currParams) {

        var params = {};
        currParams = currParams || _deUrl($('#Url' + cfg._listId).prop('href')).parameters;

        if (currParams) { // only if any params
            //var targetParams = _deUrl(url).parameters;

            for (var p in targetParams) {

                if (currParams[p] === undefined || // parameter not present on current url
                        currParams[p] !== targetParams[p]  // parameter on current url is different than given                        
                        ) {

                    params[p] = targetParams[p];

                    /*
                    // if this is fiter and in case only parameter value changed 
                    // re-print additional filter parameters
                    if (p.indexOf('f[') === 0) {
                        //var fn = n.replace('f[', '').replace(']', '');
                        //if (currParams[])
                    }
                    */
                }
            }

            return params;
        } else { // no new parameters
            return {};
        }
    }

    /**
     * Append given listing parameters to existing
     * 
     * @param {JSON} newParams      new parameters (to be added) in format:
     *                              {'f[Model.field_name]': 'value1, 'f[Model.field_name][0]': 'value0, ...}
     * @param {JSON} currParams     currently applied listing parameters
     * 
     * @returns {JSON}              target listing parameters
     */
    function _appendUrlParams(newParams, currParams) {

        // current listing parameters
        currParams = currParams || _deUrl($('#Url' + cfg._listId).prop('href')).parameters;
        //var _newParams = {};

        //
        // check new parameters for array values; if found - flatten
        // 
        // ex. p.name[] = [v1,v2]      ==>     p.name[0] = v1, p.name[1] = v2  
        
        if (newParams) {
            /* 
            for (var n in newParams) {
                
                if (typeof (newParams[n]) === 'object') { // this is array - flatten                     

                    if (newParams[n] !== undefined) {

                        // make parameter array values as array of parameters                        
                        for (var i in newParams[n]) {

                            //newParams[n.replace('[]', '') + '[' + i + ']'] = newParams[n][i];
                            _newParams[n] = newParams[n][i];
                        }
                        //delete newParams[n];
                    }
                } else {
                    _newParams[n] = newParams[n];
                }
            }
            */

            // 
            // check if filter value changed (not new added or removed);
            // 
            // ROMOVED - if operator defined for any of filter links for field 
            // - it must be defiend for ALL other filter links
            /*
            for (var n in _newParams) {

                if (n.indexOf('f[') === 0 // this is filter
                        && currParams[n] && decodeURIComponent(currParams[n]) !== _newParams[n] // filter value has been changed
                        ) {

                    // filter value changed, but additional parameters leaved;
                    // If no new filter additional parameters defined - remove it.
                    // remove additional filter parameters
                    // (ONLY if no new values present) 
                    var fn = n.replace('f[', '').replace(']', '');

                    if (_newParams['f[' + fn + '_o_]'] === undefined) {
                        delete currParams['f[' + fn + '_o_]'];
                    }
                    if (_newParams['f[' + fn + '_l_]'] === undefined) {
                        delete currParams['f[' + fn + '_l_]'];
                    }
                    if (_newParams['f[' + fn + '_sl_]'] === undefined) {
                        delete currParams['f[' + fn + '_sl_]'];
                    }                    
                }
            }
            */
        }

        //
        // remove duplicates
        //        
        //return $.extend(true, {}, currParams, _newParams); // removes duplicates
        return $.extend(true, {}, currParams, newParams); // removes duplicates
    }
    /**
     * Remove given listing parameters from existing 
     * 
     * @param {JSON} delParams   parameters to be deleted
     * @param {JSON} currParams     currently applied listing parameters
     * 
     * @returns {JSON}              target listing parameters
     */
    /*
    function _removeUrlParams(delParams, currParams) {

        // current listing parameters
        currParams = currParams || _deUrl(decodeURI($('#Url' + cfg._listId).prop('href'))).parameters;

        if (delParams) {

            for (var n in delParams) {

                if (currParams[n]) {
                    delete currParams[n];
                }
            }
        }

        return currParams; 
    }*/

    /**
     * Build full listing url including all parameters (q, filters, ...)
     * @param {Mixed} params
     * @returns {undefined}
     */
    function _buildFullUrl(params) {

        params = params || {}; // target parameters

        if (typeof (params) !== 'object') { // url is given as full url String
            params = _deUrl(params).parameters; // get listing parameters from url
        }
        
        var currUrl = decodeURI($('#Url' + cfg._listId).prop('href')),
                _currUrlObj = _deUrl(currUrl),
                prms = [], g1, g2, g3;
        
        if (cfg.paramType === 'named') { // named parameters (ex. /param1:value/param2/value)

            g1 = '/';
            g2 = '/';
            g3 = ':';

        } else {    // query string (ex. ?pama=value&param1=value&param2=value)

            g1 = '?';
            g2 = '&';
            g3 = '=';
        }

        //var _params = params;

        // Always sort parameters by key names; this is required for filters in situation
        // when array of values for single filter field is sent.
        // Without sorting CakePHP will lose array keys (CakePHP named parameters bug?)
        var _params = sortJSON(params);

        // Handle single/mulitple listings on view 
        if (window._mtsoftUiListings > 1) { // multiple listings on view

            // always include m (model name) on url
            _params.m = cfg._model.toLowerCase();

        } else {    // single listing on view

            // don't send model name via url if only single listing on page   
            delete _params['m'];
        }

        // 
        // prepare/optimize listing parameters
        // 
        for (var name in _params) {

            // check for special parameters for filters 
            // (not filter name/value, but: _o_, _l_, _sl_)
            if (name.indexOf('_o_') > -1 ||
                    name.indexOf('_l_') > -1 ||
                    name.indexOf('_sl_') > -1) {

                // if filter value for this operator/link/sublink is empty - don't include it on url
                var fName = name.replace('_o_', '').replace('_l_', '').replace('_sl_', ''),
                        v = $.trim(_params[fName]);
                if (v === '' || v === '_d_') { //v === '_d_' && 

                    continue;
                }
            }

            // ommit page 1 (if no page - by defult first is shwon
            if (name === 'page' && parseInt(_params[name]) === 1) {
                continue;
            }

            // this is right filter field name/value
            v = $.trim(_params[name]);
            if (v !== '' && v !== '_d_') { // only if not empty OR assigned to delete(value='_d_') argument values will be added

                prms.push(encodeURIComponent(name) + g3 + encodeURIComponent(_params[name]));
            }
        }
        
        // build full url string
        return _currUrlObj.protocol + '/' + _currUrlObj.path + (prms.length ? g1 + prms.join(g2) : '');
    }

    /**
     * Sort JSON object by key names
     * @param {type} obj 
     * @returns {unresolved}
     */
    function sortJSON(obj) {
        var keys = [];
        var sorted_obj = {};

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                keys.push(key);
            }
        }

        // sort keys
        keys.sort();

        // create new array based on Sorted Keys
        $.each(keys, function(i, key) {
            sorted_obj[key] = obj[key];
        });

        return sorted_obj;
    }

    /**
     * Assign autoload on viewport scroll
     * 
     */
    function _autoloadOnScroll() {

        $(window).on('scroll.mtsoftUiListing', function(e) {

            var $als = $('.' + cfg.cssPrefix + 'autoload');

            $als.each(function() {

                if ($(this).offset().top <= $(window).scrollTop() + $(window).height()) {

                    var listId = $(this).attr('data-list'),
                            //url = $('#Url' + listId).attr('href'),
                            $list = $('#' + listId),
                            //_url = _deUrl(url),
                            page = cfg.status.page + 1;

                    // load  list configuration
                    $list.mtsoftUiListing();

                    // link to load next auto-load set of pages                   
                    if (!cfg._autoloaded && page <= cfg.status.pageCount && page <= cfg._autoloadStartOff + cfg.autoloadOnScroll) {

                        // load next page (apply)
                        $list.mtsoftUiListing().load($.extend(cfg.ctrl, {url: _appendUrlParams({page: page, m: cfg._model.toLowerCase()}), target: cfg.$autoloadMarker}));

                    } else {

                        // maximum auto-loaded number of pages reached
                        if (!cfg._autoloaded) {

                            // execute callbak 
                            cfg.autoloadMaxPageReached();
                            
                            // hide auto-load marker
                            cfg.$autoloadMarker.hide();
                            
                            // if present - show autoload-next-pages link
                            var $autoloadNextPages = $('.' + cfg.cssPrefix + 'autoload-next-pages[data-' + cfg._listId + ']');

                            // show or hide next set of autoload pages
                            if (page <= cfg.status.pageCount) {
                                $autoloadNextPages.show();
                            } else {
                                $autoloadNextPages.hide();
                            }

                            $list.mtsoftUiListing().config({
                                _autoloaded: true, // mark end of pages/itmes for current offset
                                _autoloadStartOff: page - 1 // this will allow to autoload next set of pages/rows (if allowed)
                            });
                        }
                    }
                } else {

                }
            });
        });
    }

    /**
     * Scroll view to listing top if required
     * @param {type} cfg
     * @returns {undefined}
     */
    function _scrollTop(cfg) {
        
        if (cfg.scrollToList && cfg.loadMode !== 'append') {

            try {
            
                if (cfg.scrollToList !== 'top') {

                    // only if required top of listing is outside viewport
                    var listTop = cfg.$this.offset().top - cfg.scrollToList;
                    //var listTop = 0;
                    if (listTop < $(window).scrollTop() || listTop > $(window).scrollTop() + $(window).height()) {
                        
                        $('html, body').animate({scrollTop: listTop}, cfg.scrollUp);
                    }
                } else {
                    $(window).scrollTop(0);
                }
            } catch (e) {
                $(window).scrollTop(0);
            }
        }
    }

    /**
     * Handle lazyload of images
     */
    function _lazyload() {

        if (cfg.lazyload) {
            
            // init lazyload 
            $.mtsoft.ui.lazyload($.extend(true, {}, cfg.lazyload, {$container: cfg.$this}));                
        }
    }
    /**
     * Style on of list/element which trigged list read
     * 
     * @param {JSON} cfg            configuration
     * @param {jQuery} target       target element which trigged list re-load     
     */
    function _loadingStyleOn(cfg, target) {
        
        // lock list
        if (cfg.lockListOnLoad) {

            cfg.$this.mtsoftUiLock(cfg.lockListOnLoad).on();
        }

        // style element (link,button) which trigged load
        if (cfg.handleControlState && target) {

            target.mtsoftUiState('wait');
            cfg.$this.mtsoftUiListing().config({_triggedBy: target}); // remember node which caused load 
        }

        // show wait meter
        if (cfg.waitmeter) {

            $.mtsoft.ui.waitMeter(true, typeof (cfg.waitmeter) === 'string' ? cfg.waitmeter : false);
        }
    }

    /**
     * Animate list when no prev/next page 
     */
    function _animNoPage() {

        cfg.$this.children().filter(':first').mtsoftUiAnim(cfg.anims.noPage).play();
    }

    // ..
})();
