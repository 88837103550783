/**
 * ZAPBOX 3
 */
;
(function($) {

    $('.zapbox .tabs-title > a').on('click tap', function() {
        setTimeout(function(){
            $(window).scroll(); // to load lazy loaded images on tab 
        }, 10);
    });    
    
    $('.zapbox .btn-grid').on('click tap', function(){
        $(this).parents().find('.zapbox').filter(':first').removeClass('list');
        Foundation.reInit('equalizer');
    });
    $('.zapbox .btn-list').on('click tap', function(){
        $(this).parents().find('.zapbox').filter(':first').addClass('list');
        Foundation.reInit('equalizer');
    });
    
    $(function() {
        //$("#zapbox").zapbox(); 
    });

})(jQuery);