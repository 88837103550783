/**
 * Plugin name and description
 * 
 * This is jQuery plugin skeleton 'chain methods' type.
 * Allows to use plugin actions in chain as below:
 * 
 *  $(n).mtsoftUiBanners().actionName().actionOtherName();
 * 
 * (c) 2013 mtsoft.pl
 */
;
(function($) {

    var cfg = {};   // keep configuration in global variable

    // -------------------------------------------------------------------------
    //
    // Plugin constructor
    //
    $.fn.mtsoftUiBanners = function(params) {

        $.extend(this, actions);

        return this.each(function() {

            var $this = $(this);

            if (!$this.data('config.mtsoftUiBanners') || params) {

                //
                // Initialize and save configuration for current node
                //                 
                cfg = actions.config.call($this, actions.init.call($this, params));

            } else {

                // only read configuration
                cfg = actions.config.apply($this);
            }
        });
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtsoftUiBanners.defaults = {
        url: 'advertising/banners/get',
        // settings as properties or objects
        cssPrefix: 'mt-',
        // callbacks
        onSomeAction: function() {
        },
        // flags
        _flag: false
    };
    $.fn.mtsoftUiBanners.def = {};




    // -------------------------------------------------------------------------
    //
    // Public actions
    //
    var actions = {
        /**
         * Get / set plugin configuration.
         *
         * @param {Mixed} param     Configuration parameter name
         *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
         *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
         *                                    All other then given in JOSN argument parameters will be keep untouch;
         *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
         *                           null - resets current configuration (remove all parameters)
         *
         * @param {Mixed} val       configuration parameter value
         * @param {Bool} update     if true update curent global configuration (cfg) object
         *
         * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
         *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
         */
        config: function(param, val, update) {

            var c = 'config.mtsoftUiBanners',
                    $this = $(this), _cfg = $this.data(c) || {};
            // reset current configuration
            if (param === null) {
                $this.data(c, null);
                return _cfg;
            }

            if (val !== undefined || typeof (param) === 'object') { // setter

                // update single parameter value
                if (val !== undefined) { // single parameter value

                    _cfg[param] = val; // assign value
                    $this.data(c, _cfg); // update

                } else {    // multiple parameters values defined as JSON object {}

                    // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                    //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                    _cfg = $.extend(true, {}, _cfg, param);
                    $this.data(c, _cfg); // update
                }
                if (update === undefined || update) { // update global configuration (cfg) object
                    cfg = _cfg;
                }
                return _cfg;
            } else { // getter

                return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
            }
        },
        /**
         * Initialize
         * 
         * @param {type} params
         * @returns {JSON} configuration object
         */
        init: function(params) {

            var $this = $(this); // don't define here cfg bacuse is GLOBAL in this name space
            //
            // initial configuration
            //
            cfg = $.extend(true, {}, {$this: $this},
            $.fn.mtsoftUiBanners.defaults,
                    $this.data(),
                    $.fn.mtsoftUiBanners.def[$this.attr('id')],
                    params);  // cfg is PLUGIN GLOBAL

            //
            // collect all banners from this group and group by refresh times
            //
            var banners = [], categoryId = cfg.$this.attr('data-cat');

            cfg.$this.find('[data-size]').each(function() {

                var $_this = $(this);
                if ($_this.attr('data-rfs')) {

                    var refresh = parseInt($_this.attr('data-rfs'));
                }
                banners.push({
                    $banner: $_this,
                    id: parseInt($_this.attr('data-id')),
                    size: $_this.attr('data-size'),
                    refresh: refresh,
                    categoryId: categoryId});
                
                // to disable banner change on mouseover 
                $_this.on('mouseenter.mtsoftUiBanners', function(){
                    $(this).addClass('hover');
                }).on('mouseleave.mtsoftUiBanners', function(){
                    $(this).removeClass('hover');
                });
            });
            cfg.banners = banners;
            //
            // attach event handlers
            //
            // always use click anyway (for touch devices)
            /*cfg.$this.on('eventName.mtsoftUiBanners', function(e) {
             
             // execute some public action (reads configuration for current($this) node)
             actions.actionName.apply(this, [{param: value}, e]);
             });*/

            //
            // set other initial values
            //
            return cfg;
        }

        /**
         * Sample public action
         * 
         * @param {type} params
         * @param {type} e
         */
        /*actionName: function(params, e) {
         
         // variables:
         // this = $(this) => jQuery node object action is executed for
         
         // execute other public action (for current node configuration)
         actions.actionNameOther.apply(this, [params, e]);
         
         // execute private function
         privateMethod(params);
         
         // update configuration for current node (false parameter is important!)
         cfg.$this.mtsoftUiBanners(false).config(paramName, 'value');
         
         // ALWAYS return node to keep chain
         return this;
         }*/
        // ...
    };


    // -------------------------------------------------------------------------
    //
    // define private functions (internal use only)
    //
    function _changeBaner(existingBanner, newBanner) {

        if (!existingBanner.$banner.hasClass('hover')) { // change banner only if not hovered 
            
            existingBanner.$banner.attr('data-id', newBanner.Banner.id);
            existingBanner.$banner.children('a').attr('href', $.trim(newBanner.Banner.url) !== '' ? newBanner.Banner.url : null);
            $imgs = existingBanner.$banner.find('img').prop('src', newBanner.Banner.img).prop('alt', newBanner.Banner.title);
        }

        return true;




        // animations 
        // check is there both required images 
        var $imgs = existingBanner.$banner.find('img').not('.phantom');

        if ($imgs.length === 1) {

            $imgs.after('<img src="" alt="" style="display:none;" />');
            $imgs = existingBanner.$banner.find('img').not('.phantom');
        }

        //existingBanner.$banner.find('img').prop('src', newBanner.Banner.img).prop('alt', newBanner.Banner.title);
        var $img = $imgs.filter('img[src!=""]').length ? $imgs.filter('img[src!=""]') : $($imgs[0]),
                imgUrl = newBanner.Banner.img;

        if (imgUrl != $.trim($img.prop('src'))) { // new banner - replace existing 

            // load new banner to empty image and cross fade
            var $freeImg = $img.siblings('img').not('.phantom'), rndTime = Math.floor((Math.random() * 3000) + 1);
            $freeImg.on('load', function() {

                $(this).siblings('img').fadeOut(1000+rndTime, 'linear', function() {
                    $(this).prop('src', ''); // empty src
                });
                $(this).fadeIn(1000+rndTime);
            });
            existingBanner.$banner.attr('data-id', newBanner.Banner.id);
            existingBanner.$banner.children('a').attr('href', $.trim(newBanner.Banner.url) !== '' ? newBanner.Banner.url : null);
            $freeImg.prop('src', imgUrl).prop('alt', newBanner.Banner.title);
        }

    }

    /*function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }*/

    /**
     * Rotate all banners on page grouped by this same refersh interval
     */
    function _rotator(bannersArr, sizes, interval) {

        var rotateBanners = function() {

            $.get($.mtsoft.url(cfg.url), {sizes: sizes}, function(data, textStatus, jqXHR) {

                // rotate banners
                //var bns = [];
                for (var i in bannersArr) {

                    //console.log(bannersArr[i]);
                    _changeBaner(bannersArr[i], data[bannersArr[i].size][bannersArr[i].categoryId].shift());
                    // make "wave" (wait until fade finisheds and then go to next banner
                    //bns.push(i);
                }
                // randomize
                /*bns = shuffleArray(bns);
                window.setTimeout(function() {
                    _changeBaner(bannersArr[i], data[bannersArr[i].size].shift());
                }, 1000);*/

            });
        };

        window.setInterval(function() {
            rotateBanners();
        }, interval * 1000);

    }
    // ...

    // -------------------------------------------------------------------------
    //
    // define even handlers common for all instances (for window/document, etc...)
    //
    /*$(window).on('resize.mtsoftUiBanners', function(e) {
     actions.actionName.apply($(this), [e]);
     });*/

    // -------------------------------------------------------------------------
    //
    // apply plugin to default selector (optional)
    //
    $(function() {

        var refershIntervals = [], sizes = {};
        $("[data-bns]").each(function() {
            
            // init banners rotator 
            $(this).mtsoftUiBanners();

            for (var bnr in cfg.banners) {

                if (cfg.banners[bnr].refresh > 0) {
                    
                    // refresh intervals
                    if (refershIntervals['rfr_' + cfg.banners[bnr].refresh] === undefined) {
                        
                        refershIntervals['rfr_' + cfg.banners[bnr].refresh] = [];
                    }
                    refershIntervals['rfr_' + cfg.banners[bnr].refresh].push(cfg.banners[bnr]);

                    // sizes
                    if (sizes['rfr_' + cfg.banners[bnr].refresh] === undefined) {
                        
                        sizes['rfr_' + cfg.banners[bnr].refresh] = {};                        
                    }
                    // count how many baners of each size to rotate 
                    if (sizes['rfr_' + cfg.banners[bnr].refresh][cfg.banners[bnr].size] === undefined) {
                        
                        sizes['rfr_' + cfg.banners[bnr].refresh][cfg.banners[bnr].size] = {};
                        sizes['rfr_' + cfg.banners[bnr].refresh][cfg.banners[bnr].size][cfg.banners[bnr].categoryId] = 1;
                        
                    } else {
                        
                        //sizes['rfr_' + cfg.banners[bnr].refresh][cfg.banners[bnr].size]++;
                        sizes['rfr_' + cfg.banners[bnr].refresh][cfg.banners[bnr].size][cfg.banners[bnr].categoryId]++;
                    }
                }
            }
        }); 
        
        // each refresh period has it's own rotator interval 
        
        for (var i in refershIntervals) {

            _rotator(refershIntervals[i], sizes[i], parseInt(i.replace('rfr_', '')));
        }

    });

})(jQuery);


