/**
 * mtsoft UI Ajax
 * (c) 2013 mtsoft Marek Trybus, Gorlice, Poland, UE
 *
 * Dependencies:
 * @uses jQuery 2.0+
 * @uses $.mtsoft.ui.url();
 * @uses $.mtsoft.ui.alert(), $(n).mtsoftUiAlertClose()
 * @uses $(n).mtsoftUiInputAlert()
 */
;
(function($, window, document, undefined) {

    $.mtsoft = $.mtsoft || {ui: {}};

// -----------------------------------------------------------------------------
//
// Private properties and methods
//

    /**
     * Array of curently active ajax requests
     */
    _active = [];

    /**
     * Generate internal ajax request id (based on request url)
     *
     * @param {String} url      ajax request url
     */
    function _id(url) {

        return url.replace(/[^a-zA-Z0-9]/g, '');
    }

    /**
     * Log error while executiong handlers
     * @param {Object} e    Error object
     */
    function _logCallbackError(e) {

        console.warn('$.mtsoft.ui.ajax callback error:');
        console.log(e);
        console.trace();
    }

    /**
     * Apply visual style(s) on ajax request begin;
     * - show wait meter at top of the screen
     * - for node which trigged ajax request:
     *      - show custom label (if any)
     *      - add custom class (if any)
     *      - disable
     *  - for combined button - show right sub-label ('.wait')
     *
     * @param {JSON} config     ajax request config
     */
    function _styleOn(config) {

        // show wait meter
        if (config.waitmeter) {

            $.mtsoft.ui.waitMeter(true, typeof (config.waitmeter) === 'string' ? config.waitmeter : false);
        }

        var $n = config.node;
        if ($n && $n.length) { // node which trigged ajax request is given and exists

            var wait = $n.children('.' + config.prefixCss + 'wait');
            if (wait.length) {
                $n.mtsoftUiState('wait', {
                    disableOnWait: config.disableNode,
                    cssClass: {wait: config.styles.wait}
                });
            } else { // if no wait, but only success - show it no waiting for ajax request results (assume all ok)
                $n.mtsoftUiState('success', {
                    timeout: {success: config.styles.successTimeout},
                    cssClass: {success: config.styles.success}
                });
            }

            // show wait status alert
            if (config.showWaitStatus) {

                $n.mtsoftUiState(config.showWaitStatus, 'wait', typeof (config.showWaitStatus) === 'string' ? 'right' : 'inner-right');
            }
        }
    }

    /**
     * Apply visual style(s) on ajax request end;
     *
     * @param {JSON} config         finished request configuration
     * @param {String} status       returned request status [ success | error | timeout ]
     * @param {JSON} data           request data returned: 
     *                                  (result - custom result value returend [ true | false ]
     *                                      btnStatus - button status value/sub-label
     *                                  ) 
     */
    function _styleOff(config, status, data) {

        if (config.waitmeter) {

            // hide waitmeter; for abort - hide immediatelly 
            $.mtsoft.ui.waitMeter(false, status === 'abort' ? true : undefined);
        }
        
        var $n = config.node;
        if ($n && $n.length) { // node which trigged ajax request is given and exists
            
            if (data && data.data && data.data.btnStatus) {   // use returned value/sub-label 
                
                $n.mtsoftUiState(data.data.btnStatus.value, {
                    timeout: data.data.btnStatus.timeout,
                    cssClass: data.data.btnStatus.cssClass
                });
            } else {    // use default sub-labels 

                // stylize using custom css success/failed class
                status = status === 'error' || status === 'timeout' ? 'failed' : status; // for 'timeout' use 'error' css class

                $n.mtsoftUiState(status !== 'success' || data && !data.result ? 'failed' : status, {
                    timeout: {success: config.styles.successTimeout, failed: config.styles.failedTimeout},
                    cssClass: {success: config.styles.success, failed: config.styles.failed}
                });
            }

            // show wait status alert
            if (config.showWaitStatus) {

                $n.mtsoftUiAlertClose('status', 'wait');
            }
        }
    }

    /**
     * Show all alerts present in ajax request response from server.
     * Alerts must be defined as array: ... {alerts: [{Alert1ConfigObject}, {Alert1ConfigObject}, ...]}
     * Alert defined as: ... {alerts: {AlertConfigObject} WILL NOT BE SHWON here
     * Such defined alert can be viewed on ajax request "on.." callbacks
     *
     * @param {array} alerts     alerts definitions as JSON objects
     */
    function _showAlerts(alerts) {

        if (alerts && alerts.constructor === Array) {
            for (alert in alerts) {

                if (alerts[alert].formInput === undefined) {
                    // show any kind of alert
                    $.mtsoft.ui.alert(alerts[alert]);
                }
            }
        }
    }

    /**
     * Show alerts for inputs with invalid values
     * (when submiting form via ajax request)
     *
     * @param {JSON} messages   object: id => message
     */
    function _showInvalidInputs(messages) {

        for (id in messages) {

            $('#' + id).mtsoftUiInputAlert('failed', {msg: messages[id]});
        }
    }

    /**
     * Redirect to given url
     *
     * @param {String} url      redirect url
     */
    function _redirect(url) {

        window.location = $.mtsoft.url(url);
    }

    /**
     * Fill DOM nodes defined by given id attributtes with right html content
     *
     * @param {array} data      array of: id => "html code" pairs;
     *                          id is id addtribute of html node which should be
     *                          filled with html contents
     */
    function _fillHtmlContainers(data) {

        for (id in data) {

            $('#' + id).html(data[id]);
        }
    }

    /**
     * Handle ajax errors ( Global Ajax Event Handler )
     *
     * @param {Object} event
     * @param {Object} jqXHR
     * @param {Object} ajaxSettings
     * @param {type} thrownError
     */
    function _ajaxError(event, jqXHR, ajaxSettings, thrownError) {

        var request = _active[_id(ajaxSettings.url)];

        if (request) {  // this request exists and has been sent via $.mtsoftUiAjax();

            // execute custom error callback
            try {
                request.config.onError(jqXHR);
            } catch (e) {
                _logCallbackError(e);
            }

            if (!request.config.silent && request.config.showErrors) {

                // no silent mdoe and show errors not disabled
                if (request.config.errorMsgCustom !== null) {

                    // show custom error message defined for this request
                    $.mtsoft.ui.alert({msg: request.config.errorMsgCustom, type: 'failed'});
                } else {

                    // request timed-out and aborted
                    if (jqXHR.statusText === 'timeout') {

                        $.mtsoft.ui.alert({msg: $.mtsoft.ui.ajax.defaults.errorMsgs[jqXHR.statusText], type: 'failed'});
                    } else {
                        if ($.mtsoft.ui.ajax.defaults.errorMsgs[jqXHR.status]) {
                            $.mtsoft.ui.alert({msg: $.mtsoft.ui.ajax.defaults.errorMsgs[jqXHR.status], type: 'failed'});
                        }
                    }
                    
                    // Only for DEBUG mode
                    dbg(function(event, jqXHR, ajaxSettings) {

                        // log ajax error on console
                        try {
                            console.log('Ajax request error: ' + jqXHR.status + '[' + jqXHR.statusText + '] status: ' + jqXHR.readyState);
                            console.log(event);
                            console.log(jqXHR);
                            console.log(ajaxSettings);
                        } catch (e) {
                        }

                        // throw error to main screen
                        /*if (jqXHR.responseText) {
                            $("html").html(jqXHR.responseText.replace(/\<\!doctype[^\>]*\>\s*\<html[^\>]*\>/gi, '').replace(/\<\/html[^\>]*\>/gi, ''));
                        }*/
                    }, [event, jqXHR, ajaxSettings]);
                }
            }

        } else {

            // error throwed by native jQUery $.ajax() method
        }
    }



// -----------------------------------------------------------------------------
//
// Public functions
//
    /**
     * Send ajax request and perform common ui related actions and custom callbacks
     *
     * @param {String} url              target ajax request url
     * @param {JSON} params             mtsoft.ui.ajax parameters
     * @param {JSON} jQueryAjaxParams   jQuery $.ajax() parameters
     * 
     * @return {jqXHR}                  the jQuery XMLHttpRequest (jqXHR) object
     */
    $.mtsoft.ui.ajax = function(url, params, jQueryAjaxParams) {

        var config = $.extend(true, {}, $.mtsoft.ui.ajax.defaults, params);

        url = $.mtsoft.url(url);    // prepare url

        if (params && params.node) {
            // add node trigged ajax request value to data
            config.data.nodeValue = params.node.val();
        }

        //
        // send ajax request using jQUery $.ajax()
        //
        return $.ajax($.extend(true, {}, {
            // default jQUery $.ajax parameters
            url: url,
            // data to be send
            data: config.data,
            // timeout
            timeout: config.timeout * 1000, // [ms] => [s]
            // on succes status returend from server (not nessesarly result is success)
            success: function(data, textStatus, jqXHR) {

                // execute 'beforeResult' callback for success or failed scenarions
                try {
                    config.beforeResult(data, textStatus, jqXHR);
                } catch (e) {
                    _logCallbackError(e);
                }

                // check if this is really success
                if (textStatus === 'success' && data.result) { // data.result is defined and not null

                    // request AND result on server is success
                    try {
                        config.onResultSuccess(data, textStatus, jqXHR);
                    } catch (e) {
                        _logCallbackError(e);
                    }
                } else {    // data.result is undefined, null or false

                    // request OR result on server failed
                    try {
                        config.onResultFailed(data, textStatus, jqXHR);
                    } catch (e) {
                        _logCallbackError(e);
                    }
                }

                // redirect
                if (data.redirect) {

                    _redirect(data.redirect);
                } else {

                    // show alerts
                    if (!config.silent && (data.alerts || data.invalidInputs)) {

                        if (config.showAlerts) {

                            if (!config.showInvalidInputsAsAlerts) {
                                
                                // show alerts
                                _showAlerts(data.alerts);
                                
                            } else {
                                
                                // as alerts show invalid inputs messages
                                var msgs = [];
                                for (var iin in data.invalidInputs) {
                                    msgs.push({msg: data.invalidInputs[iin], type: 'failed'});
                                }                                
                                _showAlerts(msgs.length ? msgs : data.alerts);
                            }
                        }
                        if (config.showInvalidInputs && !config.showInvalidInputsAsAlerts) {
                            _showInvalidInputs(data.invalidInputs);
                        }
                    }

                    // fill html containers
                    if (data.html) {

                        _fillHtmlContainers(data.html);
                    }
                }
            },
            beforeSend: function(jqXHR) {
                // add current request to list of active request and rememebr it's config/jQuery parameters
                _active[_id(this.url)] = {config: config};

                // stylize all elements related to request
                if (!config.silent) {
                    _styleOn(config);
                }
            },
            // execute on complete ( success, failed or abort )
            complete: function(jqXHR, textStatus) {

                // remove request from list of active request
                _active[_id(this.url)] = null;

                // re-stylize all elements related to request
                if (!config.silent) {

                    _styleOff(config, textStatus, jqXHR.responseJSON ? jqXHR.responseJSON : undefined);

                    if (jqXHR.responseJSON === undefined) { // no JSON formatted answer
                        
                        // This means that server returned result with status 200 (OK)
                        // not JSON but html code (which is not allowed for $.mtsoftUiAjax method)
                        // Show error message
                        if (config.showErrors && jqXHR.statusText !== 'abort') { // on manual abort don't show any error message 
                            $.mtsoft.ui.alert({msg: config.txt.error, type: 'failed'});
                        }
                    }
                }

                // refresh session (only if session timeout set!); re-set window timeout
                try {
                    if ($.mtsoft.refreshSessionTimeout) {
                        $.mtsoft.refreshSession();
                    }
                } catch (e) {
                }


                try {
                    config.onComplete(jqXHR, textStatus);
                } catch (e) {
                    _logCallbackError(e);
                }

            }
        }, jQueryAjaxParams));

    };
// -----------------------------------------------------------------------------

    /**
     * Send ajax request by clicking on defined node
     *
     * @param {type} url
     * @param {type} params
     * @param {type} jQueryAjaxParams
     */
    $.fn.mtsoftUiAjax = function(url, params, jQueryAjaxParams) {

        return this.each(function() {

            var $this = $(this);
            $this.on('click.mtsoftUiAjax', function() {
                $.mtsoft.ui.ajax(url, $.extend({node: $this}, params), jQueryAjaxParams);
            });
        });
    };

// -----------------------------------------------------------------------------
//
// Default settings (public)
//
    $.mtsoft.ui.ajax.defaults = {
        data: {}, // data to be send to server (as JSON object)
        silent: false, // if true work in silent mode - don't show any error messages or wait icons / progress meters
        showErrors: true, // if true show error messages
        showAlerts: true, // if true show alerts returned from server
        showInvalidInputs: true, // if true show status messages for invlaid inputs
        showInvalidInputsAsAlerts: false, // if true show invalid inputs messages on alert
        timeout: 30, // ajax request timeout [s]
        beforeResult: function(data, textStatus, jqXHR) {
        }, // callback executed before 'onResultSuccess' and 'onResultFailed'
        onResultSuccess: function(data, textStatus, jqXHR) {
        }, // callback executed if result returned from server is successed
        onResultFailed: function(data, textStatus, jqXHR) {
        }, // callback executed if result returned from server is undefined, null or false
        onError: function(data, textStatus, jqXHR) {
        }, // callback executed on error
        onComplete: function(jqXHR, textStatus) {
        }, // callback executed on request completed (no matter what result or error)
        // default error messages
        errorMsgs: {
            400: 'Error 400 - Bad Request',
            403: 'Error 403 - Forbidden Request (please login)',
            404: 'Error 404 - Requested url not found',
            500: 'Error 500 - Internal Server Error',
            503: 'Error 503 - Service Unavailable (please try again)',
            timeout: 'Server not responding (timeout).'
        },
        // define custom error message for this request (overrides any of above error messages)
        errorMsgCustom: null, // [String]
        waitmeter: false, // waitmeter label (String) | true (Bool) - show without label | false (Bool) - don't show
        node: null, // node which trigged ajax request (button, link, etc...)
        disableNode: true, // disable node when active ajax request
        showWaitStatus: false, // show status alert of wait type for node [ false | [String] label value)
        // styles css class names ( used for node which trigged ajax request ) for different statuses
        styles: {
            wait: '',
            success: '',
            failed: '',
            // common for 'success' or 'failed' timeout (how long show those styles for node);
            // applies also for combined buttons with sub-labels)
            successTimeout: 1, // [s]
            failedTimeout: 2  // [s]
        },
        txt: {
            error: 'Error on ajax request'
        },
        shortcuts: true, // define shortcuts (ex. $.uiajax)
        // ajax button css prefix; must match prefix of all classes names in buttons css file
        prefixCss: 'mtb-'
                // sample combined ajax button html structure
                /*
                 * button.mtb-ajax
                 *      span
                 *          i.icon-some
                 *          | Label
                 *      span.mtb-wait
                 *          i.ico
                 *          | saving ...
                 *      span.mtb-success
                 *          i.ico
                 *          | Saved
                 *      span.mtb-failed
                 *          i.ico
                 *          | Error!
                 */
    };

// -----------------------------------------------------------------------------
//
// shortcuts (public jQuery functions)
//

// NOTE: below shorctus can cause confilcts with other jQuery plugins !!!
// Use with care ...
    if ($.mtsoft.ui.ajax.defaults.shortcuts) { // define shortcuts?

        $.uiajax = function(url, params, jQueryAjaxParams) {

            return $.mtsoft.ui.ajax(url, params, jQueryAjaxParams);
        };
        $.fn.uiajax = function(url, params, jQueryAjaxParams) {

            return this.each(function() {

                $(this).mtsoftUiAjax(url, params, jQueryAjaxParams);
            });
        };
    }

// -----------------------------------------------------------------------------
//
// Define Global Events callbacks for all jQUery ajax requests (assigned to document)
//
    $(function() {

        // define ajax error handler
        $(document).ajaxError(function(event, jqXHR, ajaxSettings, thrownError) {

            return _ajaxError(event, jqXHR, ajaxSettings, thrownError);
        });
    });
})(jQuery, window, document);
