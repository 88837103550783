/**
 * mtsoft UI core
 *
 * @uses jQUery (/js/jquery/jquery-2.0.0.min.js)
 * @uses modernizr (/js/libs/custom.modernizr.js)
 * @uses spin.js (/js/libs/spin.min.js)
 *
 */

;
(function($, window, document, undefined) {

    // namespace 
    $.fn.mtsoft = $.fn.mtsoft || {ui: {}};
    $.mtsoft = $.mtsoft || {ui: {}};



// -----------------------------------------------------------------------------
//
// Core UI methods
//

    /**
     * Initialize mtsoft UI base components
     */
    $.mtsoft.ui.init = function() {

        // alerts
        //$.mtsoft.ui.alerts.init();
        window.setTimeout($.mtsoft.ui.alerts.init, 250); // wait 0.25 [s] and show server side generted alert then

        // initalize all forms
        //$("form[data-forma]").mtsoftUiForm();


        // At the end - initially execute defined callbacks for current screen
        $.mtsoft.ui.mediaQueries();
    };
    
    /**
     * Execute once page has been loaded and all initial js has been executed.
     * Should be inlcuded as the last method on page load (at the end of the last executed callback of: "$(document).ready(function($) { // ...  // here }"
     */
    $.mtsoft.ui.loaded = function() {
        
        $('html').removeClass('no-ui'); // remove no-ui class from <html class="no-ui">
    };
// -----------------------------------------------------------------------------


// -----------------------------------------------------------------------------
//
// MEDIA QUERIES (mq)
//

    // 1.) To add mq callback executed for given screen size AND
    // all screen sized below (mobile-first apprach - all callbacks for current and below break points are executed)
    // use: $.mtsoft.ui.mqs.small.push(function(){});
    // OR to add named callback use: $.mtsoft.ui.mqs.small['callBackName'] = function(){};
    $.mtsoft.ui.mqs = {
        tiny: [], small: [], large: [], extralarge: []
    };
    // 2.) To add mq callback for given screen size ONLY use: $.mtsoft.ui.mq.small.push(function(){});
    // OR to add named callback use: $.mtsoft.ui.mq.small['callBackName'] = function(){};
    $.mtsoft.ui.mq = {
        tiny: [], small: [], large: [], extralarge: []
    };
    // internal flags; current (named) media query match (Ex. 'tiny', 'small' ,...)
    $.mtsoft.ui._mqCurrent = null;
    $.mtsoft.ui._mqsCurrent = null;
    /**
     * Check defiend break points with defiend media queries
     * and execute all required callback functions for current screen size
     * or all screen sizes till current (going from smallest)
     *
     */
    $.mtsoft.ui.mediaQueries = function() {

        // default media queries break points
        var mqs = $.extend(true, {},
                {
                    tiny: 479,
                    small: 768,
                    large: 979,
                    extralarge: 1200
                }, $.mtsoftConfig.mq || {}  // global break points defined  /js/mtsoft/global.js
        );

        /**
         * Execute all callbacks for current screen size (media query match)
         */
        var _execute = function(mqs, name) {

            // apply this media query callbacks
            for (var f in mqs[name]) {

                if (typeof(mqs[name][f]) === 'function') {
                    // callback function given directly
                    mqs[name][f]();
                } else {    // this is array of "named" callbacks

                    for (var set in mqs[name][f]) { // execute all callbacks from current set

                        mqs[name][f][set]();
                    }
                }
            }
        };

        //
        // process all break points of media queries
        //
        for (var name in mqs) {

            // mobile-first aproach - execute all callbacks begining from those
            // for smallest screen resolutions to current break point
            if (Modernizr.mq('only screen and (min-width: ' + mqs[name] + 'px)')) { // check for media query

                if (this._mqsCurrent !== name) { // callbacks execute only if break point has been reached
                    this._mqsCurrent = name;
                    _execute($.mtsoft.ui.mqs, name);
                }
            }
            // execute ONLY callbacks for current screen size
            if (Modernizr.mq('only screen and (max-width: ' + mqs[name] + 'px)')) { // check for media query

                if (this._mqCurrent !== name) { // callbacks execute only if break point has been reached
                    this._mqCurrent = name;
                    _execute($.mtsoft.ui.mq, name);
                }
                break;
            }
        }
    };
    // on window resize do media queries check and run right callbacks (if reuqired)
    $(window).on('resize.mtsoftUi', function() {

        //clearTimeout($.mtsoft.ui._resizeTo);
        try { $.mtsoft.ui.mediaQueries(); } catch (e) {} // TRY/CATCH in case when window.resize force before $.mtsoft.ui defined 
        // re-run after resize stoped to ensure all media queries callback were executed
        //$.mtsoft.ui._resizeTo = window.setTimeout($.mtsoft.ui.mediaQueries, 500);
    });
// -----------------------------------------------------------------------------


// -----------------------------------------------------------------------------
//
// Base UI methods
//
    /**
     * Lazy load of images with custom animation.
     * Works only on images palced on document and scrolled by window scroll
     * (not works for images inside scrollable containers) 
     * 
     * @uses $n.lazyload (/jquery/jquery.lazyload.js)
     * @uses $n.mtsoftUiAnim   ( /mtsoft/ui/mtosft.ui.anims.js )
     */
    $.mtsoft.ui.lazyload = function(opts) {
        
         var cfg = $.extend(true, {}, {
             $container: window,    // $.lazyload comply 
             // Custom CSS Animation:
             // [String] animation css name OR [JOSN] object with animation definition used when image has been auto-loaded 
             animation: 'llAnim',
             // if true animate only initially not visible images - otherwise - animate all (even visible initially)  
             animateOnlyNotVisible: false,
             // Custom lazyload parameters (ex. effect: 'fadeIn') 
             lazyload: {}
         }, opts);

            // find images inside container             
            var $imgs = (cfg.$container!==window?cfg.$container:$('body')).find("img[data-original]").not('.lld').not('.lldef');
            
            if ($imgs.length) {
                
                $imgs.addClass('lldef');
                
                try { // try to apply lazy load 

                    var _animate = function($imgs) {

                        $imgs.css({visibility: 'hidden'});

                        // be sure image will be always shown; even if loaded immediatelly
                        var anim = cfg.animation;
                        $imgs.on('load.mtsoftUiLazyLoad', function() {

                            if ($(this).attr('src').indexOf('data:image') < 0) {   // only if real image loaded, not empty png

                                // do animation and remove this event handler
                                $(this).css({visibility: 'visible'}).mtsoftUiAnim(anim).play();
                                $(this).off('load.mtsoftUiLazyLoad');
                            }
                        });

                    };

                    // force delayed scroll to show images already visible on screen 
                    // (and fix lazyload bug - sometimes don't initilize till user scroll)
                    window.setTimeout(function() {

                        //$.extend(true, cfg.lazyload, {container: cfg.$container})
                        $imgs.lazyload(cfg.lazyload);
                        $imgs.addClass('lld'); // add .lld to prevent animation of all images on document                    

                        if (cfg.animation) { // use custom animation for not yest shown images 

                            window.setTimeout(function(){

                                $imgs = cfg.$container.find('img[src^="data:image"]');
                                if ($imgs) {
                                    _animate($imgs);
                                }

                            }, cfg.animateOnlyNotVisible ? 1000 : 0);
                            // If cfg.animateOnlyNotVisible is true: 
                            // for not shown initially images (not fit in view port of winodw/scrollable element)
                            // apply custom animation 
                        }

                    }, 100);

                } catch (e) { // lazyload failed -  just load and show all images at once 

                    $imgs.each(function(){

                        $(this).prop('src', $(this).attr('data-original'));
                    });
                }
            }
    };


    //$.mtsoft.ui.lock = {defaults:{}};
    $.fn.extend({
        
        /**
         * Visually mark currnelty active menu item;
         * - menu item must be a.href="some-url.html"
         * - as parameter css class name to be added for current menu item must be defiend ('.active'' by default) 
         * - as jUQery object menu itmes parent node must be given 
         * 
         * Exampple usage: $('ul.menu').mtsoftUiSelectMenuItem({class:'menu-item-active'});
         */
        mtsoftUiSelectMenuItem: function (opts) {
                   
            var stripUrl = function(url) {
                return url.split("?")[0].split("#")[0];
            };
            
            return this.each(function () {

                var $this = $(this), cfg = $.extend(true, {class: 'active', item: 'a'}, $(this).data('config.mtsoftUiSelectMenuItem'), opts);
 
                // Will also work for relative and absolute hrefs
                $this.find(cfg.item).each(function () {
                    
                    if (this.href == stripUrl(window.location.href)) {
                        
                        if ($(this).parent('li')) {
                            
                            $(this).parent('li').addClass(cfg.class);
                            
                        } else {
                            
                            $(this).addClass(cfg.class);
                        }
                    }
                });
            });
        },
        
        /**
         * Lock some element on screen (disable use any of controlls on it);
         * optionally:
         *  - dim given element
         *  - show "loading" spin/icon/animation
         *
         * @param {JSON} opts       options
         */
        mtsoftUiLock: function(opts) {
            
            var actions = {
                /**
                 * Lock
                 */
                on: function() {

                    return this.each(function() {
                        
                        var $this = $(this), cfg = $.extend(true, {}, $(this).data('config.mtsoftUiLock')), // $this - element to be locked 
                                $lock = $('<div class="ui-lock" style="position:absolute;z-index:9;left:0;right:0;top:0;bottom:0;text-align:center;background:' + cfg.bg + ';opacity:' + cfg.opacity + ';overflow:hidden;">');
                        
                        if (!$this.children('.ui-lock').length) { // append only if not already locked

                            if ($this.css('position') !== 'absolute') { // parent MUST BE relative positioned
                                $this.css({position: 'relative'});
                            }

                            var disp = $this.css('display');
                            if (disp !== 'block' && disp !== 'inline-block') {
                                
                                // for table or other display modes find first block or inline-block
                                // element and add distance  as margin between them and target element
                                //var corrTop = 0, corrRight = 0, corrBottom = 0, corrLeft = 0;
                                $this.parents().each(function() {

                                    var disp = $(this).css('display');
                                    
                                    if (disp === 'block' || disp === 'inline-block') {
                                        
                                        $(this).css('position', 'relative');
                                        var diffTop = $this.offset().top - $(this).offset().top,
                                        diffBottom = $(this).outerHeight(true) - diffTop - $this.height();
                                        
                                        if (!!(window.mozInnerScreenX == null)) { // don't apply for firefox
                                            $lock.css({marginTop: diffTop, marginBottom: diffBottom});
                                        }

                                        return false;
                                    }
                                });
                            }

                            $this.append($lock).data('lock.mtsoftUiLock', $lock);
                            $lock.hide()[cfg.open.effect](cfg.open.speed); // use effect to show

                            // spin (uses 'Spinner' external plugin ) 
                            if (cfg.spin) {
                                try {
                                    new Spinner(cfg.spin).spin($lock[0]);
                                } catch (e) {
                                }
                            }

                            // animated icon
                            if (cfg.icon) {

                                $lock.append('<span style="display:inline-block;vertical-align:middle;width:0;border:0;padding:0;margin:0;height:100%;opacity:'+cfg.opacity+';"></span><span class="ui-lock-loader" style="position:relative;display:inline-block;vertical-align:middle;">' +
                                        (cfg.icon ? $.mtsoft.ui.icon('wait', cfg.icon, true) : '')
                                        + '</span>');
                            }

                            // kepp visible loader spinner/icon/animation
                            if ((cfg.icon || cfg.spin) && cfg.loaderAlwaysVisible) {

                                var $loader = $lock.children('.ui-lock-loader'), id = 'll' + Math.random();
                                // kep orginal top offset value (absolute)
                                $loader.data('_orgTopPos', $loader.offset().top);
                                $this.data('_id', id);

                                $(window).on('scroll.mtsoftUiLock' + id, function() {
                                    //log('on scroll!');
                                    var lH = $loader.outerHeight(),
                                            lTop = $loader.data('_orgTopPos'),
                                            leTop = $lock.offset().top,
                                            leH = $lock.height(),
                                            wST = $(window).scrollTop(),
                                            wH = $(window).height();

                                    // should be centered vertically in visible part of locked element
                                    if (cfg.loaderCentered) {

                                        //
                                        // make loader always visible in center of visible part of locked element
                                        //

                                        var visH = 0, corrH = 0; // visible element height, vertical correction (from locked elemetn top)

                                        if ($lock.offset().top > wST) {
                                            
                                            // top of locked element is below viewport top edge
                                            if (leTop + leH > wST + wH) {

                                                // end of locked element is below viewport bottom edge
                                                visH = wST + wH - leTop;
                                                corrH = parseInt(visH / 2) > 0 ? parseInt(visH / 2) : 0;
                                            } else {

                                                // locked element fit on screen
                                                corrH = leH / 2; // just leave vertically centered (leverage)
                                            }

                                        } else {

                                            // top of locked element is above viewport top edge
                                            if (leTop + leH > wST + wH) {

                                                // end of locked element is below bottom viewport edge
                                                visH = wH;
                                                corrH = visH / 2 + wST - leTop;
                                            } else {

                                                // end of locked element is above viewport bottom edge
                                                visH = leTop + leH - wST;
                                                corrH = leH - visH / 2;
                                            }
                                        }

                                        // correct top offset
                                        var topCorr = corrH - leH / 2;
                                        if (topCorr < leH / 2 - lH + 2 * cfg.margin &&
                                                -topCorr < leH / 2 - lH + 2 * cfg.margin) {
                                            $loader.css({top: topCorr});
                                        }
                                        //}
                                    } else {

                                        //
                                        // make loader always visible; once loader reaches viewport top/bottom edge
                                        // lock it nearby top/bottom edge (with cfg.margin distance)
                                        //

                                        // is above top viewport edge ?
                                        if ($loader.offset().top <= wST + cfg.marginTop + cfg.margin) {

                                            // but fit in lock layer ?
                                            if ($loader.offset().top + lH < leTop + leH - cfg.margin) {

                                                $loader.css({top: parseInt(wST + cfg.marginTop + cfg.margin - lTop) + 'px'});
                                            }
                                        } else {

                                            // is not on orginal position ?
                                            if (parseInt($loader.css('top')) > 0) {

                                                $loader.css({top: parseInt(wST + cfg.marginTop + cfg.margin - lTop) + 'px'});
                                            }
                                        }

                                        // is below bottom viewport edge ?
                                        if ($loader.offset().top + lH > wST + wH - cfg.margin &&
                                                $loader.offset().top >= leTop + cfg.margin) {

                                            $loader.css({top: parseInt(wST + wH - cfg.margin - lH - lTop) + 'px'});
                                        }

                                    }
                                });
                                
                                // correct wait icon size (if larger than locked area) to fit
                                var $lockLoader = $lock.children('.ui-lock-loader');
                                if ($lockLoader.height() > $lock.height() || $lockLoader.width() > $lock.width()) {
                                    $lockLoader.children('i.ico').css({height: $lock.height() - 10});
                                }
                                
                                $(window).scroll();
                            }
                        }
                    });
                },
                /**
                 * Un-Lock
                 */
                off: function() {
                    
                    return this.each(function() {
                        var $this = $(this);
                        
                        if ($this && $this.data('lock.mtsoftUiLock')) {

                            var cfg = $this.data('config.mtsoftUiLock');
                            $this.data('lock.mtsoftUiLock')[cfg.close.effect](cfg.close.speed, function() {
                                $(this).remove();
                            });
                            $this.data('lock.mtsoftUiLock', null);
                            $(window).off('scroll.mtsoftUiLock' + $this.data('_id'));
                        }
                    });
                }
            };

            $.extend(this, actions);
            //var $lock = $('<div style="position:absolute;left:0;right:0;top:0;height:100%;background:#fff;opacity:0.5;">'),
            //config.metersNode.css({position: 'relative'}).append($lock);
            if ($.mtsoft.ui.lock === undefined) {
               $.mtsoft.ui.lock = {defaults:{}};
            }
            
            return this.each(function() {
                var config = $.extend(true, {}, {
                    opacity: .3, // use opacity to dim element
                    bg: '#efefef', // background color (used with opacity)
                    loaderAlwaysVisible: true, // if true loader spinner/icon/anim. will be keep visible until elment is visible (not vertically centered)
                    loaderCentered: true, // center loader to be in the middel of visible part of locked element; makes sense only if element is higher than viewport height
                    marginTop: 0, // top margin when keeping always visible; usually header height
                    marginBottom: 0, // bottom margin when keeping always visible; usually footer height
                    margin: 20, // margin from lock layer (dimmed)
                    // use spin (required: spin.js (/js/libs/spin.min.js))
                    spin: false/*{// show spin; false to disable
                        color: '#666'//, // #rgb or #rrggbb or array of colors
                        className: 'spin ui-lock-loader', // The CSS class to assign to the spin
                        //lines: 13, // The number of lines to draw
                        //length: 20, // The length of each line
                        //width: 10, // The line thickness
                        //radius: 30, // The radius of the inner circle
                        //corners: 1, // Corner roundness (0..1)
                        //rotate: 0, // The rotation offset
                        //direction: 1, // 1: clockwise, -1: counterclockwise
                        //speed: 1, // Rounds per second
                        //trail: 60, // Afterglow percentage
                        //shadow: false, // Whether to render a shadow
                        //hwaccel: false, // Whether to use hardware acceleration
                        //zIndex: 2e9, // The z-index (defaults to 2000000000)
                        //top: 'auto', // Top position relative to parent in px
                        //left: 'auto' // Left position relative to parent in px
                    }*/,
                    // use custom icon (set false to disable)
                    icon: {class: 'ico-wait', size: '6rem', color: '#C5C5C5'},
                    open: {// open effect (jQuery)
                        effect: 'fadeIn',
                        speed: 'fast'
                    },
                    close: {// close effect (jQuery)
                        effect: 'fadeOut',
                        speed: 'fast'
                    }
                }, $.mtsoft.ui.lock.defaults, opts);
                
                $(this).data('config.mtsoftUiLock', config);
            });

        },
        /**
         * Handle element (button, link ot any node) with multiple sub-labels 
         * (ex. some ajax action assigend)
         *
         * @param {Mixed} type      label type (empty for default)
         * @param {JSON} opts       button options
         */
        mtsoftUiState: function(type, opts) {
            
            if (typeof(type) === 'object') { // button options given directly

                opts = type;
                type = opts.type;
            }

            var def = $.extend(true, {}, {
                type: 'default', // force sub-label type
                cssPrefix: 'mtb-',
                disableOnWait: true,
                // show given type of button label for given time period [s]
                // 0 means permanent
                timeout: {
                    default: 0,
                    wait: 0,
                    success: 1,
                    failed: 2
                },
                // additional css classes (added to button)
                cssClass: {
                    default: '',
                    wait: '',
                    success: '',
                    failed: ''
                }
            }, opts);

            /**
             * Restore default state/sub-label
             * 
             * @param {jQUery} $n       element
             * @param {Mixed} type      sub-label/state type: [success | wait | success | failed ]
             *                              or custom names
             *                              or value (will be compared with span(data-v) value 
             */
            function _setState($n, type) {

                var css = def.cssClass;

                // clear change sub-label/css class timeout
                window.clearTimeout($n.data('btnTimeout.mtsoftUiBtn'));

                // eanble (in case node disabled)
                if ($n.data('_disabled')) {
                    $n.attr('disabled', false);
                    $n.data('_disabled', null);
                }
                
                if ($n.children('span').length) { // there are sub-labels present

                    $n.children('span').hide();
                    // remove custom, additional class from node
                    $n.removeClass($n.data('_cssClass'));

                    // set default or given sub-label/state
                    if (type === 'default') {
                        $n.children('span').filter(':first').show();
                    } else {

                        var $nv = $n.children('[data-v="' + type + '"]');
                        if ($nv.length) { // type defined by value 
                            $nv.show();
                        } else {    // type defined by class name 
                            var t = $n.children('.' + def.cssPrefix + type); // default class (success, etc...)
                            (t.length ? t : $n.children('.' + type)).show(); // custom class
                        }
                    }

                    // add additional custom css class
                    $n.addClass(css[type]);
                    $n.data('_cssClass', css[type]);

                    if (type === 'wait' && def.disableOnWait) { // if disable node on wait required

                        $n.attr('disabled', true);
                        $n.data('_disabled', true);
                    }
                }

                // set button value ( DON'T USE - for input text interts value)
                //$n.val(type);
            }

            // define
            return this.each(function() {

                var $this = $(this);

                // set inital node width (to keep it for all states/sub-labels)
                // MUST be set to largest of all nodes states
                /*if (!$this.data('_w')) {
                    $this.width($this.width());
                    $this.data('_w', true);
                }*/

                //
                // set right state/show right sub-label
                //
                if (type === undefined || type === 'default') { // restore default label/state

                    _setState($this, 'default');

                } else { // show given type of sub-label

                    _setState($this, type);
                }

                //
                // show sub-label for given period of time
                // ( check is there timeout for this type of sub-label defined)
                //
                if (def.timeout[type]) {

                    // after timeout restore default label
                    if ($this.children('.' + def.cssPrefix + type).length) { // only if sub-label exist

                        $this.data('btnTimeout.mtsoftUiBtn', window.setTimeout(function() {
                            _setState($this, 'default');
                        }, def.timeout[type] * 1000));

                        // if user moves mouse over node - restore default sub-label/state immediatelly
                        $this.one('mouseenter', function() {
                            _setState($(this), 'default');
                        });
                    } else {    // state don't exists - restore default state immeidately 

                        _setState($this, 'default');
                    }
                }
            });
        },
        /**
         * Show/hide extendend portion of text preserving html formatting 
         * @param {type} opts
         */
        mtsoftUiReadMore: function (opts) {
            
            return this.each(function() {

                var $this = $(this), $section = $this.children('section');
                
                $this.find('.btn-trunc-rm').on('click.mtsoftUiReadMore', function(){
                    $section.filter(':first-child').hide();
                    $section.filter(':last-child').show();                    
                });
                $this.find('.btn-trunc-rl').on('click.mtsoftUiReadMore', function(){
                    $section.filter(':last-child').hide();
                    $section.filter(':first-child').show();                    
                });
            });
        }
    });


})(jQuery, window, document);

/**
 * jQUery $.browser function
 * @param {type} ua
 * @returns {jQuery.uaMatch.Anonym$14}
 */
jQuery.uaMatch = function(ua) {
    ua = ua.toLowerCase();
    var match = /(chrome)[ \/]([\w.]+)/.exec(ua) ||
            /(webkit)[ \/]([\w.]+)/.exec(ua) ||
            /(opera)(?:.*version|)[ \/]([\w.]+)/.exec(ua) ||
            /(msie) ([\w.]+)/.exec(ua) ||
            ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua) ||
            [];
    
    // check for IE11 - si identified as mozilla 
    if (/trident\/7\./.test(ua)) {
        match[ 1 ] = 'msie';
    }
    
    return {
        browser: match[ 1 ] || "",
        version: match[ 2 ] || "0"
    };
};
if (!jQuery.browser) {
    matched = jQuery.uaMatch(navigator.userAgent);
    browser = {};
    if (matched.browser) {
        browser[ matched.browser ] = true;
        browser.version = matched.version;
    }
// Chrome is Webkit, but Webkit is also Safari.
    if (browser.chrome) {
        browser.webkit = true;
    } else if (browser.webkit) {
        browser.safari = true;
    }
    jQuery.browser = browser;
}
