/**
 * mtsoft UI Alert / messages / wait progress  indicators
 * (c) 2013 mtsoft Marek Trybus, Gorlice, Poland, UE
 * ver 1.0.0
 *
 * By default #alerts node is used to nest alert elements.
 * If #alerts node don't exists - it is created as first child of body tag.
 *
 * Dependencies:
 * @uses jQuery 2.0+
 * @uses $.mtsoft.ui ($.mtsoft.ui.url(), $.mtsoft.ui.mq())
 * @uses $.mtsoft.ui.icon 
 * @uses $.mtsoftConfig ($.mtsoftConfig.mq.small)
 */
;
(function($, window, document, undefined) {

    $.fn.mtsoft = $.fn.mtsoft || {ui: {}};
    $.mtsoft = $.mtsoft || {ui: {}};
// -----------------------------------------------------------------------------
//
// private properties and methods
//

    /**
     * Array of alerts on queue (to be viewed);
     * separately for each alerts container and mode
     */
    _alertsInQueue = [];
    /**
     * Define default settings
     *
     * @param {JSON} params       parameters given by user
     * @returns {JSON}            user parameters supplemented bby default values
     */
    function _defaults(params) {

        params = params || {};
        // set default mode and type (must be set)
        params.mode = params.mode || 'flash';
        params.type = params.type || 'success';
        // get parent (where to nest alert) depending it is given as string jQuery object
        params.$parent = typeof(params.parent) === 'string' ? $('#' + params.parent) : params.parent;

        var config = $.extend(true, {},
                $.fn.mtsoft.ui.alert.defaults, // overall global defaults
                $.fn.mtsoft.ui.alert.types[params.type], // message types defaults
                $.fn.mtsoft.ui.alert.modes[params.mode], // message modes defaults
                params);

        // temporary
        //config.$node = config.$parent;
        if ($.mtsoftConfig && $.mtsoftConfig.mq) {
            config.mq = {small: $.mtsoftConfig.mq.small ? $.mtsoftConfig.mq.small : config.mq};
        }

        return config;
    }

    /**
     * Construct parameters array from existing html code of alert node
     *
     * @param {jQuery} $alert   alert node
     * @returns {JSON}          object with alert parameters definition
     */
    function _html2params($alert) {

        var params = {};
        params = {
            parent: $alert.data('parent') ? $alert.data('parent') : $alert.parent(), // by default parent is current parent of $alert (if no other defined)
            title: $alert.attr('title'),
            msg: $alert.children('span').html(),
            type: _getParamFromClasses($alert, ['success', 'failed', 'caution', 'info', 'help', 'wait', 'desc']),
            mode: _getParamFromClasses($alert, ['flash', 'msg', 'msgFlash', 'large', 'notify',
                'panel', 'status', 'input-status', 'status-wait', 'status-small', 'waitmeter'], '')
        };
        return params;
    }

    /**
     * Rip param value from alert node classes
     * @param {type} $alert
     * @param {type} set
     * @param {type} def
     * @returns {unresolved}
     */
    function _getParamFromClasses($alert, set, def) {

        var name = '', pre = $.fn.mtsoft.ui.alert.defaults.prefixCss;
        while (set.length) {

            name = set.shift();
            if ($alert.hasClass(pre + name)) {
                return name;
            }
        }
        return def; // unknown/no given value - return default (undefined if no given)
    }

    /**
     * Build alert html code
     *
     * @param {JSON} config     alert parameters
     * @param {jQuery} $alert   alert object
     * @returns {jQUery}        alert object
     */
    function _build(config, $alert) {
        
        switch (config.mode) {

            case 'waitmeter':
                if ($alert === undefined) {
                    // build waitmeter (can be added only on client side via JS)
                    $alert = $('<div id="' + config.id + '" class="' + config.prefixCss + 'wait-meter"><span></span>').hide();
                }
                break;
            default:

                //
                // build alert of given mode & type
                //
                var newNode = false;
                if ($alert === undefined) {

                    // $alert node have to be created (don't exist already)
                    $alert = $('<div class="' + config.prefixCss + 'alert"></div>');
                    newNode = true;
                }

                // set msg
                var msg = null;
                if (typeof(config.msg) === 'string') {

                    msg = config.msg;
                } else {
                    $alert.addClass('small');  // no message  defiend - all class small
                }
                
                // append additional elements/classes/styles depending on configuration
                $alert.attr('id', config.id).addClass(config.prefixCss + config.mode).
                        addClass(config.prefixCss + config.type).
                        addClass(config.position !== '' ? config.prefixCss + config.position : '').
                        addClass(config.class); // class name(s) are added WIHTOUT adding cssPrefix value

                // add custom style
                if (config.style !== '') {
                    $alert.css(config.style);
                }
                // add custom title
                if (config.title !== '') {
                    $alert.attr('title', config.title);
                }
                
                // add type icon (if not already added)
                if (config.icon && config.type != 'desc' && !$alert.children('i.ico').length) {

                    if (config.type !== 'wait') {
                        //$alert.append('<i class="' + config.prefixCss + 'ico"></i>'); // $alert type icon must be before message
                        $alert.append(config.icon ? $.mtsoft.ui.icon(config.icons[config.icon] ? config.icons[config.icon] : config.icon, {}, true) : ''); // $alert type icon must be before message
                    } else {
                        //$alert.append($.mtsoft.ui.icon(config.icoWait, {})); // $alert type icon must be before message
                        $alert.append($.icoWait()); // $alert type icon must be before message
                    }
                }
                
                // add message/header
                if (newNode) {
                    if (msg) {
                        $alert.append('<span>' + (config.header !== '' ? '<h4>' + config.header + '</h4>' : '') + msg + '</span>');
                    }
                } else {
                    // check if alert message is given directly as txt. If so outline it with span
                    /*if (!$alert.children('span').length) {
                        var ico = $alert.children('i.ico').remove();
                        $alert.wrapInner('<span>');
                        $alert.children('span').prepend(ico);
                    }*/
                }
                
                // add close button (if not already exists)
                if (config.btnClose && !$alert.children('.' + config.prefixCss + 'close').length) {
                    $alert.append('<a href="#0" class="' + config.prefixCss + 'close">' + $.mtsoft.ui.icon(config.icons.close, {}, true) + '</button>');
                } else {

                    // remove space for close button if no close button
                    $alert.addClass(config.prefixCss + 'no-close');
                }
                // add progress bar only if timeout set
                if (config.timeout > 0 && (config.mode === 'flash' || config.mode === 'notify') && !$alert.children('.' + config.prefixCss + 'progress').length) {
                    $alert.append('<div class="' + config.prefixCss + 'progress"></div>');
                }
                // all audio if defined
                if (config.audio && !$alert.children('audio').length) { // attach audio file

                    $alert.append('<audio src="' + config.audio + '" preload="auto"></audio>');
                }
                // add alert button below message
                if (config.buttons && !$alert.children('.' + config.prefixCss + 'btns').length) { // add bottom buttons
                    $alert.append(_buttons(config));
                }
                _buttonsActions($alert, config);
                
                // hide - alert will be shown later
                $alert.hide();
               
                // this alert is separated from other alerts of this same mode/type
                if (config.separated) {

                    $alert.addClass(config.prefixCss + 'separated');
                }

                break;
        }

        // initially save config (need if we corrent status alert position on _place method)
        $alert.mtsoftUiAlertConfig(config);        
        return $alert;
    }

    /**
     * Generate buttons (ahsown below alert message) html
     *
     * @param {JSON} config   buttons definition parameters in format:
     *
     *                            ok: {  // button id; it will be used to set button (ex. id = 'mtsoftUiAlertBtn_ok')
     *                              label: "OK",    // button label
     *                              default: true,  // style as default button (bigger, darker bg)
     *                              close: true,    // close alert after click (don't use when callback defined)
     *                              callback: function($alert) {// ...  $alert is jQUery alert html node }   // click event callbak
     *                            }, ...
     * @returns {String}
     */
    function _buttons(config) {

        var btns = config.buttons, html = '<span class="' + config.prefixCss + 'btns">';
        for (var btn in btns) {

            html += '<button id="mtsoftUiAlertBtn_' + btn + '"' +
                    (btns[btn].default ? ' class="' + config.prefixCss + 'default"' : '') +
                    (btns[btn].close ? ' onclick="$(this).parents(\'.' + config.prefixCss + 'alert\').filter(\':first\').mtsoftUiAlertClose();"' : '') +
                    '>' + btns[btn].label + '</button>';
        }
        return html + '</span>';
    }

    /**
     * Add actions to buttons
     * 
     * @param {jQUery} $alert
     * @param {JSON} config
     */
    function _buttonsActions($alert, config) {

        if (config.buttons) { // attach click event callbacks to buttons

            for (var btn in config.buttons) {

                // callback
                if (config.buttons[btn].callback) {

                    $alert.find('#mtsoftUiAlertBtn_' + btn).data('_cb', btn).on('click.mtsoftUiAlert', function() {

                        config.buttons[$(this).data('_cb')].callback($alert);
                    });
                }

                // url
                if (config.buttons[btn].url) {

                    if (config.buttons[btn].url !== 'refresh') {

                        var url = config.buttons[btn].url;
                        $alert.find('#' + config.prefixCss + btn).data('redirectUrl', url).on('click.mtsoftUiAlert', function() {

                            window.location = $(this).data('redirectUrl');
                        });
                    } else {
                        $alert.find('#' + config.prefixCss + btn).on('click.mtsoftUiAlert', function() {

                            window.location.reload(true);
                        });
                    }
                }
            }
        }
    }

    /**
     * Neste/place alert on right container (parent)
     *
     * @param {jQuery} $alert       alert object
     * @param {JSON} config         alert parameters
     * @returns {jQuery}            alert object
     */
    function _place($alert, config) {

        // If alert parent is input/textarea/select/button neste alert on parent node of such element
        // In this case parent node must be positioned relative or absolute
        // and displayed as inline-block
        var $_parent;
        if (_isInputType(config.$parent)) {

            // alert can't be nested on parent node - use parent's parent instead
            // prepare parent node to attach alert
            $_parent = config.$parent.parent();
        } else {
            // neste in given node (not on parent)
            $_parent = config.$parent;
        }

        if (config.alertPosition !== 'inside') {

            //in case $alert is repositiond - remove all arrows css classes
            $alert.removeClass(config.prefixCss + 'arr-left ' +
                    config.prefixCss + 'arr-top ' +
                    config.prefixCss + 'arr-top-right ' +
                    config.prefixCss + 'arr-right ' +
                    config.prefixCss + 'arr-let');

            // place not iside parent, but nearby

            var $n = config.$parent, // node for which staus is shown
                    //$_parent = config.$_parent, // parent node of base node
                    // parent node params (where alert will be nested)
                    p = {w: $_parent.outerWidth(), // width+padding+border
                h: $_parent.outerHeight(),
                l: $_parent.offset(),
                // to correct arrow offset
                bl: parseInt($_parent.css("border-left-width")) + parseInt($_parent.css("padding-left")),
                bt: parseInt($_parent.css("border-top-width")) + parseInt($_parent.css("padding-top")),
                br: parseInt($_parent.css("border-right-width")) + parseInt($_parent.css("padding-right")),
                bb: parseInt($_parent.css("border-bottom-width")) + parseInt($_parent.css("padding-bottom"))},
            // target node params
            n = {
                w: $n.outerWidth(),
                h: $n.outerHeight(),
                bl: parseInt($n.css("border-left-width")),
                bt: parseInt($n.css("border-top-width")),
                br: parseInt($n.css("border-right-width")),
                bb: parseInt($n.css("border-bottom-width"))
            },
            // distance from parent element
            m = config.alertPositionMargin;

            // prepare parent and alert nodes
            $_parent.css({position: 'relative'}); // parent of parent node must be relative or absolute;
            $alert.addClass(config.prefixCss + 'abs').css({position: 'absolute'}); // allert will be absolute positioned

            if (typeof(config.msg) !== 'string' || config.msg === '') {
                $alert.addClass(config.prefixCss + 'small ');
            } // if message emtpy - stylize to avoid wrong icon placement

            // Correct alert position to fit on screen (handle media-queries )
            // In all cases below except 'inner-right' alert is positioned absolutly:
            // if screen size is large - shown on left/right side of parent node,
            // if screen size is small - shwon on top-left/bottom-right
            // and so on ...
            var $notOrginal = false;
            if (config.reposition) { // re-position allowed
                if (config.alertPosition === 'right' && $(window).width() <= config.mq.small) {
                    config.alertPosition = 'bottom-right';
                    $notOrginal = true;
                }
                if (config.alertPosition === 'left' && $(window).width() <= config.mq.small) {
                    config.alertPosition = 'top-left'; // @TODO (if will be required)
                    $notOrginal = true;
                }
            }

            // if parent is input and message to show is empty - show inside right side of input instead of outside of input
            if (config.$_parent !== config.$parent && config.msg === '') {
                //config.alertPosition = 'inner-right';
            }

            var arrClass = null;
            switch (config.alertPosition) {

                case 'left':
                    $alert.css({left: 0, top: 0, bottom: 'auto'});
                    arrClass = config.prefixCss + 'arr-right';
                    $_parent.append($alert);
                    // correct (we can't get $alert outerwidth until it's not nested on DOM structure)
                    $alert.css({left: -m + p.bl - n.bl - $alert.outerWidth()});
                    break;
                case 'top':
                case 'top-left':
                    $alert.css({left: 0, top: 0, bottom: 'auto'});
                    arrClass = config.prefixCss + 'arr-bottom';
                    $_parent.append($alert);
                    // correct
                    $alert.css({left: p.bl, top: -m + p.bt - $alert.outerHeight()});
                    break;
                default:
                case 'right':
                    $alert.css({right: 0, top: 0, bottom: 'auto'});
                    arrClass = config.prefixCss + 'arr-left';
                    $_parent.append($alert);
                    // correct (we can't get $alert outerwidth until it's not nested on DOM structure)
                    var ow = 0;
                    while (ow !== $alert.outerWidth()) {
                        ow = $alert.outerWidth();
                        $alert.css({right: m - ow});
                    }
                    // Loop is fix in situation when alert parent(container) width is narrower than free alert width
                    // (width can be initially smaller than real, free width)

                    break;
                case 'bottom':
                    $alert.css({left: p.bl, top: m + p.bt + n.bb + n.h, bottom: 'auto'});
                    arrClass = config.prefixCss + 'arr-top';
                    $_parent.append($alert);
                    break;
                case 'bottom-right':    // used on form's inputs
                    $alert.css({right: m + p.br, top: 'auto', bottom: 0})
                    arrClass = config.prefixCss + 'arr-top-right';
                    $_parent.append($alert);
                    $alert.css({bottom: -m - $alert.outerHeight() + parseInt($n.css('margin-bottom'))});
                    break;
                    // visually inside (input) on right side (but in reality nested on parent)
                case 'inner-right':
                    $alert.css({right: p.br + n.br + 1, top: 0, bottom: 'auto'}); // to make full input height, height: $n.innerHeight() - 2
                    $_parent.append($alert);
                    // correct position to be in middle of target node (not parent)
                    $alert.css({top: p.bt + n.bt + ($n.innerHeight() / 2 - $alert.outerHeight() / 2)});
                    break;
            }

            if (config.addArrow && arrClass) { // add arrow to alert
                $alert.addClass(arrClass);
            }

            // center status message vs his parent
            if (config.alertPositionCenter && $.inArray(config.alertPosition, ['left', 'top', 'right', 'bottom']) >= 0) {

                if (config.alertPosition === 'top' || config.alertPosition === 'bottom') {

                    $alert.css({left: '50%', marginLeft: -$alert.outerWidth() / 2});
                } else {

                    $alert.css({top: parseInt($alert.css('top')) + n.h / 2 - $alert.outerHeight() / 2});
                }
            }

            // handle media queries
            if (!$alert.hasClass(config.prefixCss + 'input-ico')) { // status icon only alert

                if ($.inArray(config.alertPosition, ['left', 'right', 'bottom-right', 'top-left']) >= 0) {   // only alerts "outside" input needs repositions on screen resolution changes

                    if ($notOrginal || config.alertPosition === 'left' || config.alertPosition === 'right') { // alert position is 'left' or 'right'

                        if (config.reposition) { // re-position allowed
                            // keep right alert position for this input depeneding on screen size
                            $.mtsoft.ui.mq.large[$alert.attr('id')] = function() {

                                $($alert).mtsoftUiAlertReposition({alertPosition: 'right'});
                            };
                            $.mtsoft.ui.mq.small[$alert.attr('id')] = function() {

                                $($alert).mtsoftUiAlertReposition({alertPosition: 'bottom-right'});
                            };
                        }
                    }
                }
            }

        } else {

            // neste inside parent container
            $_parent.append($alert);
        }

        return $alert;
    }

    /**
     * Open (show) given alert
     *
     * @param {jQuery} $alert       alert node
     * @param {JSON} config         alert parameters
     * @returns {jQuery}            alert node
     */
    function _open($alert, config) {

        if (config.modal) {

            // if modal - disable all screen area except dialog
            if (!$('.alert-modal').length) {
                $('body').append('<div class="' + config.prefixCss + 'alert-modal"></div>');
            }
        }

        // indicate failed message to intense attention (only flash mode)
        /*
         function _afterOpen($alert) {

         if (config.mode === 'flash' && config.type === 'failed') {
         $alert.mtsoftUiAlertIndicate();
         }
         }
         window.setTimeout(function() {
         _afterOpen($alert);
         }, config.open.speed);
         // ---
         */
        function _afterOpen($alert) {

            // if alert contains buttons - focus default button
            $alert.find('button.' + config.prefixCss + 'default').focus();
        }

        // show using defiend effect
        switch (config.open.mode) {

            case 'slide':
                $alert.slideDown(config.open.speed, function() {
                    _afterOpen($(this));
                    config.afterOpen($(this));
                });
                break;
            case 'fade':
                $alert.fadeIn(config.open.speed, function() {
                    _afterOpen($(this));
                    config.afterOpen($(this));
                });
                break;
            case 'fade-slide':
                $alert.animate({height: 'toggle', opacity: 'toggle'}, config.open.speed, null, function() {
                    _afterOpen($(this));
                    config.afterOpen($(this));
                });
                break;
            default:
                $alert.show(); // just show immediatelly
                _afterOpen($alert);
                config.afterOpen($alert);
                break;
        }

        // if alert message don't fit on screen - show it at top-left or bottom-right
        if (config.alertPosition === 'right') {

            if ($(window).width() < $alert.offset().left + $alert.outerWidth()) {

                $($alert).mtsoftUiAlertReposition({alertPosition: 'bottom-right'});
            }
        }

        // if audio file defined - play it
        _palyAudio($alert, config);
        // execute onOpen callback
        try {
            config.onOpen();
        } catch (e) {
        }

        return $alert;
    }

    /**
     * Play audio assigned to alert
     *
     * @param {jQUery} $alert   alert node
     * @param {JSON} config     alert parameters
     */
    function _palyAudio($alert, config) {

        if (config.audio) {

            try {
                $($alert).find('audio')[0].play();
            } catch (e) {
            }
        }
    }

    /**
     * Make $alert part of collapsible set
     *
     * @param {jQuery} $alert     alert node to show
     * @param {JSON} config       alert parameters
     */
    function _collapsible($alert, config) {

        // get all exising $alert and move up/down to new comming $alert height
        var $alerts = $(config.$parent).children('.' + config.prefixCss + 'alert.' + config.prefixCss + config.mode);
        // neste on DOM to read real height
        var h = $($(config.$parent).children('#' + $alert.attr('id')).length ? $alert : _place($alert, config)).outerHeight();
        // move up/down other exiting $alerts
        var m = config.position.indexOf('fixed-bottom') > -1 ? 'marginBottom' : 'marginTop', anim = {};
        anim[m] = '+=' + h + 'px';
        // animate all except currently adding
        $alerts.not($alert).each(function() {
            var $this = $(this);
            if ($this.css('display') !== 'none') {
                $(this).animate(anim, {duration: config.open.speed, easing: "swing"});
            }
        });
        // open current $alert
        _open($alert, config);
        // add collapsible behaviour on $alert close
        config._onClose = function($alert, config) {

            // move up/down exiting $alerts
            var m = config.position.indexOf('fixed-bottom') > -1 ? 'marginBottom' : 'marginTop',
                    $alerts = m === 'marginBottom' ? $alert.prevAll('.' + config.prefixCss + 'alert') : $alert.nextAll('.' + config.prefixCss + 'alert');
            anim = {};
            anim[m] = '-=' + $alert.outerHeight() + 'px';
            // move up/down all alert above/below removed alert
            $alerts.each(function() {

                $(this).stop(false, true).animate(anim, {duration: config.open.speed, easing: "swing"});
            });
            // after this callback (_onClose) $alert node will imediatelly removed from DOM
        };
    }


    /**
     * Attach behaviour/event handlers on alert node
     *
     * @param {jQUery} $alert   alert node
     * @param {JSON} config     alert parameters
     */
    function _attachEventHandlers($alert, config) {

        // close $alert on close button click, or any node with .cancel class
        $alert.find('.' + config.prefixCss + 'close, .' + config.prefixCss + 'cancel').on('click.mtsoftUiAlert', function(e) {

            config.close.mode = 'fade'; // for user close force fade effect
            _close($(this).parents('.' + config.prefixCss + 'alert').filter(':first'));
            e.stopPropagation();
            // execute manual close event handler
            try {
                config.onManualClose();
            } catch (e) {
            }
            return false;
        });
        // if timeout set - hide $alert after given period of time
        if (config.timeout) {

            // animate timeout progress indicator
            var timeout = function() {

                // define timeout
                window.clearTimeout($alert.data('mtsoftUiAlert.timeout')); // to be sure
                $alert.data('mtsoftUiAlert.timeout', window.setTimeout(function() {

                    _close($alert);
                }, config.timeout * 1000));
                // start showing progress bar (animate width)
                $alert.children('.' + config.prefixCss + 'progress').animate({width: '100%'},
                {duration: config.timeout * 1000, easing: "linear"});
            };
            // start timeout and progress indicator
            timeout();
            // on mouse over reset timeout
            $alert.on('mouseenter.mtsoftUiAlert click.mtsoftUiAlert', function() {

                // clear timeout
                window.clearTimeout($(this).data('mtsoftUiAlert.timeout'));
                // reset progress animation
                $alert.children('.' + config.prefixCss + 'progress').stop(true).fadeOut('slow', function() {
                    $(this).css({width: 0}).show();
                });
            }).on('mouseleave.mtsoftUiAlert', function() {
                timeout();
            });
        }

        // indicate alert (shake or something in some interval of time)
        if (config.indicateInterval) {

            $alert.on('mouseenter.mtsoftUiAlert click.mtsoftUiAlert', function() {
                $(this).data('mtsoftUiAlert.hovered', true);
            });
            $alert.data('mtsoftUiAlert.interval', window.setInterval(function() {

                if (!$alert.data('mtsoftUiAlert.hovered')) { // indicate only if user didn't move mouse over $alert or click on it
                    $($alert).mtsoftUiAlertIndicate();
                }
            }, config.indicateInterval * 1000));
        }

        // custom event handlers
        $alert.on('click.mtsoftUiAlert', function() { // onclick

            config.onClick($alert);
        });
    }

    /**
     * Process $alert; depending on view mode:
     *
     * - show imediatelly
     * - show replacing existing $alert(s) [replace]
     * - add new $alert to already existing [all]
     * - add $alert to queue [queue]
     *
     * @param {jQUery} $alert       alert node
     * @param {JSON} config         alert parameters
     */
    function _process($alert, config) {
        
        var queued = false;
        switch (config.view) {

            case 'queue':

                //
                // queue
                //                
                if (_anyChildShown(config)) { // there is some other $alert and is shown right now
                    
                    // palce and add to queue $alert which should be shown
                    _place($alert, config);
                    // group alerts for parent node AND alert mode
                    // (on this same parent can be collapsible alerts of different types)
                    var qi = config.$parent.attr('id') + '_' + config.mode;

                    if (_alertsInQueue[qi] === undefined) {
                        // create new queue set
                        _alertsInQueue[qi] = [];
                    }
                    // add to queue
                    _alertsInQueue[qi].push($alert);
                    queued = true;
                } else {    // no other alerts shwon - just show current $alert
                    
                    _open(_place($alert, config), config);
                }

                break;
            case 'replace':

                //
                // replace
                //
                // hide immediatelly all alerts inside parent/container (in this same mode) and show imediatelly current $alert
                if (!config.separated) { // this alert is not separated
                    var alts = _getAlertGroup(config);
                    alts.mtsoftUiAlertClose(true);
                }
                _open(_place($alert, config), config);
                break;
            case 'colapse':

                //
                // collapse
                //
                _collapsible($alert, config);
                break;
            default:
            case 'all':

                //
                // all
                //
                _open(_place($alert, config), config); // just add another $alert in parent container

                break;
        }

        // attach event handlers
        if (!queued) { // don't attach events for queued alerts

            _attachEventHandlers($alert, config);
        }
    }

    /**
     * Is for current alert any sibling alert shwon (on this same container defined by parent node) ?
     *
     * @param {JSON} config     alert parameters
     * @returns {Bool}
     */
    function _anyChildShown(config) {

        var alerts = $(config.$parent).children('.' + config.prefixCss + 'alert.' + config.prefixCss + config.mode),
                anyShown = false;
        // check if any $alert is shwon now
        alerts.each(function() {
            if ($(this).css('display') !== 'none') {
                anyShown = true;
                return true;
            }
        });
        return alerts.length && anyShown;
    }

    /**
     * Close single $alert
     *
     * @param {jQuery} $alert     alert node to close
     * @param {JSON} config       alert parameters
     */
    function _close($alert, config) {

        // get closing $alert configuration
        if (config === undefined) {

            config = $alert.mtsoftUiAlertConfig();
        }

        if ($alert.length && config) { // if $alert exists

            // execute beforeClose callback
            try {
                config.beforeClose($alert);
            } catch (e) {
            }

            // reset time-outs (if any)
            window.clearTimeout($alert.data('mtsoftUiAlert.timeout'));
            window.clearInterval($alert.data('mtsoftUiAlert.interval')); // Indicate interval

            // remove alert from DOM - execute after effect finished (if any)
            var rm = function() {


                // If this is permanent message - remove it from session.
                // To make it work server side action (alerts/remove) is required
                if ($alert.data('permanent')) {

                    try {
                        $.ajax($.mtsoft.url('alerts/remove/' + $alert.attr('id')));
                    } catch (e) {
                    }
                }

                if (config.close.remove === undefined || config.close.remove) {

                    config._onClose($alert, config); // internal onClose callbak; used to handle collapsible behavior

                    // remove alert from media queries watcher (@see $.mtsoft.ui.mq)
                    delete $.mtsoft.ui.mq.small[$alert.attr('id')];
                    delete $.mtsoft.ui.mq.large[$alert.attr('id')];
                    // execute afterCLose callback
                    try {
                        config.afterClose();
                    } catch (e) {
                    }

                    $alert.remove(); // remove from DOM
                }

            };
            // close in given effect (or without)
            switch (config.close.mode) {

                case 'slide':
                    $alert.slideUp(config.close.speed, rm);
                    break;
                case 'fade':
                    $alert.fadeOut(config.close.speed, rm);
                    break;
                case 'fade-slide':
                    $alert.animate({height: 'toggle', opacity: 'toggle'}, config.close.speed);
                    break;
                default:
                case 'hide':
                    $alert.hide();
                    rm();
                    break;
            }

            // for queued view - process queue
            _queue(config);

            // remove modal (if any)
            if (config.modal) {
                $('.' + config.prefixCss + 'alert-modal').fadeOut('normal', function() {
                    $(this).remove();
                });
            }
        }
    }

    /**
     * Show next alert from queue (if any)
     * Queues are separated for each parent container and alert modes
     *
     * @param {jQuery} prevAlertConfig      $alert config of just CLOSED $alert
     */
    function _queue(prevAlertConfig) {

        var qi = prevAlertConfig.$parent.attr('id') + '_' + prevAlertConfig.mode;
        if (_alertsInQueue && _alertsInQueue[qi] && _alertsInQueue[qi].length) {

            // get first element from queue, open and attach event handlers
            // config is next $alert in queue (not just closed $alert)
            var $alert = _alertsInQueue[qi].shift(), config = $alert.mtsoftUiAlertConfig();
            _open($alert, config);
            _attachEventHandlers($alert, config);
        }
    }

    /**
     * Generate unique for each alert idenitifer
     *
     * @param {String} parentId
     * @param {String} mode
     * @param {String} type
     * @param {String} msg
     * @returns {String}
     */
    function _id($parent, mode, type, msg) {

        var parentId = $parent.attr('id');
        if (parentId === undefined) { // if parent node don't have unique id - generate it 

            parentId = _uniqueId($parent);
            //parentId = $parent.attr('id');
        }

        return parentId + '_' + mode + '_' + type + '_' + (msg && msg.length ? msg.substring(0, 45).replace(/[^a-zA-Z0-9]/g, "_") : Math.random());
    }
    
    function _uniqueId($n) {
        
        var uuid = 0;
        
        if (!$n.attr('id')) {
            $n.attr('id', "ui-id-" + (++uuid));
        }
        return $n.attr('id');
    }

    /**
     * Check given node is "input" type
     *
     * @param {jQuery} $n
     * @returns {Boolean}
     */
    function _isInputType($n) {

        var tag = $n.length ? $n[0].tagName.toUpperCase() : null;
        return ($.inArray(tag, ['INPUT', 'TEXTAREA', 'SELECT', 'BUTTON']) > -1);
    }


    /**
     * Get all alerts for given node in given level
     *
     * @param {type} config
     *
     */
    function _getAlertGroup(config) {

        var isInput = _isInputType(config.$parent),
                $alerts = (isInput ? config.$parent.parent() : config.$parent).children('.' + config.prefixCss + 'alert'); // all alerts for given node

        // NOTE: always ommit alerts with '.separate' class as those alerts shouldn't be included in alerts group
        switch (config.viewGroupLevel) {

            case 1:
                return $alerts.not('.' + config.prefixCss + 'separated'); // all alerts for parent node
                break;
            case 2:
                return $alerts.children('.' + config.prefixCss + config.mode).not('.' + config.prefixCss + 'separated');  // alerts for parent node in this same mode as current alert
                break;
            case 3:
                // alert for parent node in this same mode and type of current alert
                return $alerts.children('.' + config.prefixCss + config.mode + '.' + config.prefixCss + config.type).not('.' + config.prefixCss + 'separated');
                break;
        }
    }

    /**
     * Connect/assgin alert to node to which it is directed to;
     * All alerts are keep as object in format:
     *
     * _alerts[alertMode][alertType][alertId] = $alert (object)
     * _alerts.alertMode.alertType.alertId = $alert (object)
     *
     * @param {jQUery} $alert   alert object
     * @param {JSON} config     alert configuration
     */
    function _assignToTarget($alert, config) {

        var als = $.extend(true, {}, config.$parent.data('_alerts')),
                cm = config.mode, ct = config.type, id = config.id;
        if (als[config.mode]) {

            if (als[cm][ct]) {
                als[cm][ct][id] = $alert;
            } else {
                als[cm][ct] = {};
                als[cm][ct][id] = $alert;
            }
        } else {
            als[cm] = {};
            als[cm][ct] = {};
            als[cm][ct][id] = $alert;
        }
        // assign alert node to node it belong to
        config.$parent.data('_alerts', als);
        return als;
    }










// -----------------------------------------------------------------------------
//
// Public functions
//

    /**
     * View alert (or attach alert behaviour if alert already exists)
     *
     * @param {JSON} params    JSON defined alert parameters OR jQuery alert html node (already exisitng)
     *
     * For full list of available parameters @see public property: $.mtsoft.ui.alert.defaults
     *
     * @returns {jQuery}
     */
    $.mtsoft.ui.alert = function(params) {
        
        var $alert = null, config = {}, id = '';
        
        if (params instanceof jQuery) {

            //
            //  Alert is existing DOM node given as jQuery object
            //
            $alert = params; // re-name
            
            if ($alert.mtsoftUiAlertConfig() === undefined) { // alert processed already by $.mtsoft.ui.alert

                // set default configuration including parameters defined on $alert's node 'data-' attributes (params.data())
                // and parameters read form html code and alert classes (_html2params())
                //console.log(_html2params($alert));
                config = _defaults($.extend(true, {}, params.data(), _html2params($alert)));
                //console.log(config);
                // read exisitng message
                var msg = $alert.children('span').length ? $alert.children('span').html() : config.mode + '_' + config.type + '_' + $alert.parent().attr('id');
                // set id if no given
                id = $alert.attr('id') ? $alert.attr('id') : _id($alert.parent(), config.mode, config.type, msg);
                config.id = id; // attach id to configuration

                // upadate exisitng alert node with properties defined for given mode/type
                $alert = _build(config, $alert);                
                
                // view in maner defined on settings or add to queue
                _process($alert, config);
                
                // assign configuration to node
                $alert.mtsoftUiAlertConfig(null); // clear
                $alert.mtsoftUiAlertConfig(config);
                
                // connect/assign alert to target node
                _assignToTarget($alert, config);
            } else {
                // already processed - do nothing
            }

        } else {

            //
            // Alert defiend as JSON parameters
            //

            // set default configuration
            config = _defaults(params);
            
            id = (config.id === undefined ? _id(config.$parent, config.mode, config.type, config.msg) : config.id); // generate safe message id;
            $alert = $('#' + id); // exisitng $alert with this same mode and message (if any)

            if ($alert.length === 0) { // $alert for given definitoin parameters don't exist

                // build new $alert
                config.id = id; // attach id to configuration
                $alert = _build(config);
                // view in maner defined on settings or add to queue
                _process($alert, config);
                // assign configuration to node
                $alert.mtsoftUiAlertConfig(null); // clear
                $alert.mtsoftUiAlertConfig(config);

                // connect/assign alert to target node
                _assignToTarget($alert, config);
            } else {

                // $alert for given definition already exists; shake or bounce to indicate
                $alert.mtsoftUiAlertIndicate();

            }
        }

        return $alert; // return $alert node
    };
    //$.mtsoft.ui.alerts = {
    /**
     * Process Buffor; create alerts for all alerts definitions present on buffor
     * Not used, but can be usable on some cases.
     *
     * @param {Array} buffor
     *//*
      processBuffor: function(buffor) {

      buffor = buffor || window['mtsoftUiAlertsBuffor'];
      while (buffor.length) {

      mtsoftUiAlert(buffor.shift());
      }
      }};*/
    /**
     * View wait meter (single on page)
     *
     * @param {Bool} open       if true show; if false - hide
     * @param {Mixed} label     (String) label, false - don't show label - only meter
     *                          if open is false (or undefined) and labe is true - hide immediatelly (no effects)
     * @param {JSON} params     additional parameters
     */
    $.mtsoft.ui.waitMeter = function(open, label, params) {

        if (open === undefined || open) {

            // show
            var wm = $.mtsoft.ui.alert($.extend(true, {}, {
                id: 'waitmeter_',
                mode: 'waitmeter'
            }, params));
            // if label defined
            if (label !== undefined && label !== false) { // 

                // show lable describing waht's loading
                var waitmeterLabel = function() {
                    $.mtsoft.ui.alert($.extend(true, {}, {
                        id: 'waitmeter_label',
                        msg: label,
                        type: 'wait',
                        mode: 'status',
                        class: 'mta-wait-meter-label',
                        position: '',
                        view: 'all',
                        timeout: 0,
                        open: {
                            mode: 'fade',
                            speed: 'normal'
                        },
                        close: {
                            mode: 'fade',
                            speed: 'fast'
                        },
                        btnClose: false,
                        icon: true // wait
                    }, params));
                };
                var cnfg = wm.mtsoftUiAlertConfig();
                if (cnfg && cnfg.labelDelay) {

                    // show delayed waitmeter label
                    this.to = window.setTimeout(waitmeterLabel, cnfg.labelDelay * 1000);
                } else {

                    // show label imediatelly
                    waitmeterLabel();
                }
            }

        } else {
            
            // close
            window.clearTimeout(this.to); // if lable not shown, but waiting in timeout 
            $('div[id^="waitmeter_"]').mtsoftUiAlertClose(label);            
        }
    };

    /**
     * Initialize  all alerts present already on html code;
     *  - update html code with missing but required elements/classes
     *  - assign all required event handlers.
     *  - in case of flash alert(s) - show them
     *
     *  This method should be executed on page load event
     */
    $.mtsoft.ui.alerts = {
        init: function() {
            return $('.' + $.fn.mtsoft.ui.alert.defaults.prefixCss + 'alert').each(function() {

                var $this = $(this);

                // Check alert is show for some input type node
                // If so - use form input realted method to show input alert, add right css class
                // and set it's status in form (valid/invalid)
                //if ($this.data('parent') && _isInputType($('#' + $this.data('parent')))) {

                // do nothing - form's invalid input alerts are handled by mtsoft.ui.form.js
                // on: $.fn.mtsoftUiForm()
                //$this.remove();
                //} else {

                $.mtsoft.ui.alert($this);
                //}

            });
        }};
// -----------------------------------------------------------------------------






    /**
     * Show status alert/message for given node.
     * Status message will be positioned absolute and nested inside parent, or
     * on parent's parent (if defined parent is input, textarea, select or button)
     *
     * @param {String} msg          alert message
     * @param {String} type         alert type
     * @param {String} position     alert position
     * @param {JSON} params         alert parameters
     * @returns {jQuery}
     */
    $.fn.mtsoftUiStatus = function(msg, type, position, params) {

        return this.each(function() {

            $.mtsoft.ui.alert($.extend(true, {}, {
                parent: $(this),
                alertPosition: position !== undefined ? position : 'right',
                msg: msg,
                type: type,
                mode: 'status'
            }, params));
        });
    };

    /**
     * Show status alert for input types nodes (input, textarea, select ...)
     *
     * @param {type} msg
     * @param {type} type
     * @param {type} params
     * @returns {unresolved}
     */
    $.fn.mtsoftUiAlertInput = function(msg, type, params) {

        return this.each(function() {

            // show on right side or bottom right depending on screen resolution
            // if config.alertPosition is defined (not null) - force given alert position
            // $this is input node            
            $.mtsoft.ui.alert($.extend(true, {}, {
                mode: 'status',
                parent: $(this),
                btnClose: false,
                alertPosition: 'right',
                alertPositionCenter: true,
                msg: msg,
                type: type,
                open: {mode: 'show'},
                close: {mode: 'hide'}
                //,class: config.cssPrefix + 'alert '
                // mtf-alert -> this is alert for form input
                // mta-no-close -> no close button present (remove space for it)
            }, params));
        });

    };
    /**
     * Show status icon near input
     *
     * @param {type} type
     * @param {type} title
     * @param {type} params
     * @returns {unresolved}
     */
    $.fn.mtsoftUiAlertInputIco = function(type, title, params) {

        return this.each(function() {

            // show on right side or bottom right depending on screen resolution
            // if config.alertPosition is defined (not null) - force given alert position
            // $this is input node
            $.mtsoft.ui.alert($.extend(true, {}, {
                class: $.fn.mtsoft.ui.alert.defaults.prefixCss + 'input-ico', //$.fn.mtsoft.ui.alert.defaults.prefixCss
                mode: 'status',
                parent: $(this),
                btnClose: false,
                alertPosition: 'inner-right', //'inner-right', //
                alertPositionCenter: true,
                addArrow: false, // don't add arrows
                msg: null,
                title: title,
                type: type,
                open: {mode: 'show'},
                close: {mode: 'hide'}
                //,class: config.cssPrefix + 'alert '
                // mtf-alert -> this is alert for form input
                // mta-no-close -> no close button present (remove space for it)
            }, params));
        });
    };
    /**
     * Show status icon inside input (on right corner)
     *
     * @param {type} type
     * @param {type} title
     * @param {type} params
     */
    $.fn.mtsoftUiAlertInputIcoInside = function(type, title, params) {

        return $(this).mtsoftUiAlertInputIco(type, title, $.extend(true, {}, params, {
            class: $.fn.mtsoft.ui.alert.defaults.prefixCss + 'input-ico ' + $.fn.mtsoft.ui.alert.defaults.prefixCss + 'inp-inside'
        }));
    };


    /**
     * Close alert.
     *
     * Alert can be given as direct $alert node or as node for which alert is shown.
     * In second case alert(s) can be defined using it's mode, type and id (none of them is obligatory)
     *
     * @param {String} mode     alert mode OR hideImmediatelly value when $alert node given directly
     * @param {String} type     alert type
     * @param {String} id       alert id (remove exactly given alert)
     * @param {Bool} hideImmediately   if true hide immediately (no transition)
     */
    $.fn.mtsoftUiAlertClose = function(mode, type, id, hideImmediately) {

        return this.each(function() {

            var $this = $(this), $alerts = [], direct = false;

            if ($this.data('_alerts')) {

                $alerts = $this.mtsoftUiAlerts(mode, type, id);
            } else {

                // this is alert node
                $alerts = [$this], hideImmediately = mode; // hideImmediatelly is defined using mode attribute
                direct = true;  // flag - alert given directly
            }

            $.each($alerts, function(i, $alert) {

                var config = $alert.mtsoftUiAlertConfig();
                if (config && (!config.separated || direct)) {  // close only if alert is not separated OR separated, but id given
                    // && mode === undefined && !config.separated || (type && id)
                    if (config && hideImmediately) {
                        config.close.mode = 'hide'; // hide imediatelly
                    }

                    // close alert
                    _close($alert, config);
                }
            });
        });
    };


    /**
     * Get alert(s) related for given node(s).
     *
     * If no mode given - get all alerts related.
     * If no type given - get all alerts in given mode.
     * If no id given - get all alerts in given mode and given type.
     * If id is provided - get only single, defiend by id alert.
     *
     * @param {String} mode     alert mode
     * @param {String} type     alert type
     * @param {String} id       alert id
     *
     * @return Array            array of all alerts assigend to node or empty array if none
     */
    $.fn.mtsoftUiAlerts = function(mode, type, id) {

        var $alerts = [];
        this.each(function() {

            var $this = $(this);

            // is this actually alert node of node for which alert is shwon
            if ($this.data('_alerts')) {

                var _alerts = $this.data('_alerts');
                // this is node for which alert is assigned to
                // get alert object
                if (mode) {

                    if (type) {

                        if (id) {

                            // mode & type & id
                            $alerts = _alerts[mode][type][id];
                        } else {

                            // mode & type
                            if (_alerts[mode] && _alerts[mode][type]) {
                                $.each(_alerts[mode][type], function(i, $_alert) {

                                    // get only alerts nested in DOM (which have parent);
                                    // ommit removed from DOM, but still in memory
                                    if ($_alert.parent().length) {
                                        $alerts.push($_alert);
                                    }
                                });
                            }
                        }

                    } else {

                        // mode
                        if (_alerts[mode]) {
                            $.each(_alerts[mode], function(i, _types) {

                                $.each(_types, function(i, $_alert) {
                                    if ($_alert.parent().length) {
                                        $alerts.push($_alert);
                                    }
                                });
                            });
                        }
                    }
                } else {

                    // no params - get all alerts
                    $.each(_alerts, function(i, _modes) {

                        $.each(_modes, function(i, _types) {

                            $.each(_types, function(i, $_alert) {
                                if ($_alert.parent().length) {
                                    $alerts.push($_alert);
                                }
                            });
                        });
                    });
                }
            }
        });

        return $alerts;
    };


    /**
     * Close alert in given mode and given type shown for given node.
     *
     * Apply to node for which alert is shown (NOT for actually alert node)
     * To close alert knowing alert node use: $(n).mtsoftUiAlertClose();
     *
     * @param {String} mode     alert to be closed mode
     * @param {String} type     alert to be closed type
     * @param {type} hide       f true hide alert imediatelly (without any effects)
     *//*
      $.fn.mtsoftUiCloseAlert = function(mode, type, hide) {

      return this.each(function() {

      ($(this).data('_alert.' + mode + '.' + type)).mtsoftUiAlertClose();
      });
      };*/
    /**
     * Re-position already shown alert
     *
     * @param {JSON} params     new position parameters
     * @returns {unresolved}
     */
    $.fn.mtsoftUiAlertReposition = function(params) {

        return this.each(function() {
            //$(this).mtsoftUiAlertClose(true);
            //$.mtsoft.ui.alert($.extend(true, {}, config, params));
            var config = $(this).mtsoftUiAlertConfig();

            // re-position only if alertPosition has been changed
            if (config) {   // && config.alertPosition !== params.alertPosition

                //var _config = $.extend(true, {}, config, params);
                //console.log($(this).attr('id'));
                //console.log(params);
                _place($(this), $.extend(true, {}, config, params));
                /*
                 if ($(window).width() < $alert.offset().left + $alert.outerWidth()) {

                 $($alert).mtsoftUiAlertReposition({alertPosition: 'bottom-right'});
                 }
                 */

                // update config with new position
                $(this).mtsoftUiAlertConfig(params);
            }
        });
    };
    /**
     * Indicate given alert (shake or bounce) to get user's attention
     *
     */
    $.fn.mtsoftUiAlertIndicate = function() {

        return this.each(function() {

            var $this = $(this), config = $(this).mtsoftUiAlertConfig();
            if ($this && config) { // only if exists

                // just add class with right animation and then remove after some period of time
                $this.removeClass(config.prefixCss + 'alert-exists').addClass(config.prefixCss + 'alert-exists');
                $this.data('mtsoftUiAlert.indicate', window.setTimeout(function() {
                    $this.removeClass(config.prefixCss + 'alert-exists');
                }, 1000)); // must be equal to css animation speed

                // if audio file present - play it
                _palyAudio($this, $this.mtsoftUiAlertConfig());
            }
        });
    };

    /**
     * Get / set plugin configuration.
     *
     * @param {Mixed} param     Configuration parameter name
     *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
     *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
     *                                    All other then given in JOSN argument parameters will be keep untouch;
     *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
     *                           null - resets current configuration (remove all parameters)
     *
     * @param {Mixed} val       configuration parameter value
     *
     * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
     *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
     */
    $.fn.mtsoftUiAlertConfig = function(param, val) {

        var c = 'config.mtsoftUiAlert',
                $this = $(this), cfg = $this.data(c);

        // reset current configuration
        if (param === null) {
            $this.data(c, null);
            return cfg;
        }

        if (val !== undefined || typeof(param) === 'object') { // setter

            // update single parameter value
            if (val !== undefined) { // single parameter value

                cfg[param] = val; // assign value
                $this.data(c, cfg); // update

            } else {    // multiple parameters values defined as JSON object {}

                // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                cfg = $.extend(true, {}, cfg, param);
                $this.data(c, cfg); // update
            }
            return cfg;
        } else { // getter

            return param !== undefined ? cfg[param] : cfg; // return single parameter value or whole configuration object
        }
    };

// -----------------------------------------------------------------------------
//
// Default settings (public) and sets of parameters
//

    // create default alerts container (if not exists) BEFORE defaults defined
    if (!$('#alerts').length) {
        $('body').children().filter(':first').before('<div id="alerts"></div>');
    }

    $.fn.mtsoft.ui.alert = {
        defaults: {
            //id: null, // force alert id
            prefixCss: 'mta-', // must match prefix of all classes names in css file
            // node where alert should be nested; if not exists it will be created
            $parent: $('#alerts').length ? $('#alerts') : $('body'),
            //$node: null, // if parent node is input or other of type where we can't nest alert - keep it in $node parameter nad for alert use it's parent
            alertPosition: 'inside', // location relative to parent: [ inside | left | top | right | bottom | inner-right ]
            alertPositionMargin: 5, // distance from parent [px]; (ignored for alertPosition: 'inside')
            alertPositionCenter: true, // center vs parent? [bool]
            msg: '', // alert message text
            header: '', // message header (show as h4 on message top)
            title: '', // alert title attribute; can be used by some tooltips module
            type: 'success', // alert type [ success | failed | caution | info | help | wait | desc ]
            mode: 'flash', // mode: [ '' | flash | msg | msgFlash | large | notify | panel | status | status-wait | staus-ico | status-small | waitmeter ] @see _alerts.css for details
            position: '', // position [ default | fixed-top | fixed-bottom | fixed-bottom-left | fixed-bottom-right ]
            addArrow: true, // add arrow (only for alerts positioned absolutly - ex. status)
            modal: false, // view alert in modal mode [ true | false ]
            view: 'queue', // messages view mode [ all | queue | replace | colapse ]
            viewGroupLevel: 1, // level of alets group-ing:
            // 1 - all alerts for parent node, 2 - alerts for parent node and in this same mode, 3 - alerts for parent node in this same mode and this same type
            separated: false, // if true this alert is separated from other alerts of this; it wil not be closed by other alerts
            class: '', // additional css class(es) SPACE separated (can be used for theming)
            style: '', // custom styles for alert
            //timeout: 0, // time of showing alert [s]; 0 or null for constant alert
            indicateInterval: 0, // period of 'indicate' alert action execution (buzz animation)
            audio: null, // audio file url to be paly on alert first show / indicate action [full path]
            //typeIcon: true, // include message type related icon [bool]
            icon: true, // include message type related icon [bool]
            btnClose: true, // include close button [bool]
            buttons: null, // alert bottom button definitions; @see _buttons private function
            reposition: true, // if true use media queries to change alert position to fit on scrren, otherwise keep alert always in inital position
            mq: {small: 768}, // break point; below it alert will be shown below input; above - on right side
            // syntax:
            /*
             ok: {  // button id; it will be used to set button (ex. id = 'mtsoftUiAlertBtn_ok')
             label: "OK",    // button label
             default: true,  // style as default button (bigger, darker bg)
             close: true,    // close alert after click (don't use when callback defined)
             callback: function($alert) {// ...  $alert is jQUery alert html node }   // click event callbak
             }
             */
            // open alert parameters
            open: {
                mode: 'slide', // mode: [ slide | fade | show | fade-slide ]
                speed: 'fast' // showing speed (only for slide or fade)
            },
            // close alert parameters
            close: {
                mode: 'slide', // mode: [ slide | fade | hide | fade-slide ]
                speed: 'slow', // hiding speed (only for slide or fade)
                remove: true        // remove alert node from DOM
            },
            // callbacks
            onOpen: function() {   // on alert open
            },
            afterOpen: function($alert, cfg) {   // after alert opened (after animation)
            }, // internal use ONLY! - do not redefine!
            boforeClose: function() {   // on alert close
            },
            onManualClose: function() {   // on alert manual close (click on close button)
            },
            afterClose: function() {   // after alert closed
            },
            onClick: function($alert) { // on alert click
            },
            _afterOpen: function($alert, config) {
            },
            _onClose: function($alert, config) {
            }, // internal use ONLY! - do not redefine! (to collapse alert if reuired)
            // if true define shortcuts for commonly used alert types ( ex. $.flash(), $.status(), $.notify() )
            defineShortcuts: true,
            // if true execute alert method: $.mtsoft.ui.alert for all existing alert elements
            // (with css class .mta-alert) on document.ready
            applyOnLoad: false,
            // close button icon 
            //icoClose: '<svg viewBox="0 0 25 32"><path d="M23.179 23.607q0 0.714-0.5 1.214l-2.429 2.429q-0.5 0.5-1.214 0.5t-1.214-0.5l-5.25-5.25-5.25 5.25q-0.5 0.5-1.214 0.5t-1.214-0.5l-2.429-2.429q-0.5-0.5-0.5-1.214t0.5-1.214l5.25-5.25-5.25-5.25q-0.5-0.5-0.5-1.214t0.5-1.214l2.429-2.429q0.5-0.5 1.214-0.5t1.214 0.5l5.25 5.25 5.25-5.25q0.5-0.5 1.214-0.5t1.214 0.5l2.429 2.429q0.5 0.5 0.5 1.214t-0.5 1.214l-5.25 5.25 5.25 5.25q0.5 0.5 0.5 1.214z" /></svg>'
            //icoWait: 'wait',
            //icoClose: 'close',
            icons: {
                close: '<svg viewBox="0 0 25 32"><path d="M23.179 23.607q0 0.714-0.5 1.214l-2.429 2.429q-0.5 0.5-1.214 0.5t-1.214-0.5l-5.25-5.25-5.25 5.25q-0.5 0.5-1.214 0.5t-1.214-0.5l-2.429-2.429q-0.5-0.5-0.5-1.214t0.5-1.214l5.25-5.25-5.25-5.25q-0.5-0.5-0.5-1.214t0.5-1.214l2.429-2.429q0.5-0.5 1.214-0.5t1.214 0.5l5.25 5.25 5.25-5.25q0.5-0.5 1.214-0.5t1.214 0.5l2.429 2.429q0.5 0.5 0.5 1.214t-0.5 1.214l-5.25 5.25 5.25 5.25q0.5 0.5 0.5 1.214z" /></svg>',
                info: '<svg viewBox="0 0 28 32"><path d="M18.286 24.571v-2.857q0-0.25-0.161-0.411t-0.411-0.161h-1.714v-9.143q0-0.25-0.161-0.411t-0.411-0.161h-5.714q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h1.714v5.714h-1.714q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h8q0.25 0 0.411-0.161t0.161-0.411zM16 8.571v-2.857q0-0.25-0.161-0.411t-0.411-0.161h-3.429q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h3.429q0.25 0 0.411-0.161t0.161-0.411zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" /></svg>',
                caution: '<svg viewBox="0 0 32 32"><path d="M18.286 24.554v-3.393q0-0.25-0.17-0.42t-0.402-0.17h-3.429q-0.232 0-0.402 0.17t-0.17 0.42v3.393q0 0.25 0.17 0.42t0.402 0.17h3.429q0.232 0 0.402-0.17t0.17-0.42zM18.25 17.875l0.321-8.196q0-0.214-0.179-0.339-0.232-0.196-0.429-0.196h-3.929q-0.196 0-0.429 0.196-0.179 0.125-0.179 0.375l0.304 8.161q0 0.179 0.179 0.295t0.429 0.116h3.304q0.25 0 0.42-0.116t0.188-0.295zM18 1.196l13.714 25.143q0.625 1.125-0.036 2.25-0.304 0.518-0.83 0.821t-1.134 0.304h-27.429q-0.607 0-1.134-0.304t-0.83-0.821q-0.661-1.125-0.036-2.25l13.714-25.143q0.304-0.554 0.839-0.875t1.161-0.321 1.161 0.321 0.839 0.875z" /></svg>',
                success: '<svg viewBox="0 0 28 32"><path d="M22.929 13.107q0-0.5-0.321-0.821l-1.625-1.607q-0.339-0.339-0.804-0.339t-0.804 0.339l-7.286 7.268-4.036-4.036q-0.339-0.339-0.804-0.339t-0.804 0.339l-1.625 1.607q-0.321 0.321-0.321 0.821 0 0.482 0.321 0.804l6.464 6.464q0.339 0.339 0.804 0.339 0.482 0 0.821-0.339l9.696-9.696q0.321-0.321 0.321-0.804zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" /></svg>',
                failed: '<svg viewBox="0 0 28 32"><path d="M13.714 2.286q3.732 0 6.884 1.839t4.991 4.991 1.839 6.884-1.839 6.884-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839zM16 24.554v-3.393q0-0.25-0.161-0.42t-0.393-0.17h-3.429q-0.232 0-0.411 0.179t-0.179 0.411v3.393q0 0.232 0.179 0.411t0.411 0.179h3.429q0.232 0 0.393-0.17t0.161-0.42zM15.964 18.411l0.321-11.089q0-0.214-0.179-0.321-0.179-0.143-0.429-0.143h-3.929q-0.25 0-0.429 0.143-0.179 0.107-0.179 0.321l0.304 11.089q0 0.179 0.179 0.313t0.429 0.134h3.304q0.25 0 0.42-0.134t0.188-0.313z" /></svg>',
                hourglass: '<svg viewBox="0 0 32 32"><path d="M24.96 7.325c0-3.424 0-3.758 0-3.758 0-1.264-4.011-3.566-8.96-3.566s-8.96 2.302-8.96 3.566c0 0 0 0.334 0 3.758 0 3.424 6.203 6.251 6.203 8.675 0 2.426-6.203 5.251-6.203 8.677 0 3.424 0 3.758 0 3.758 0 1.262 4.011 3.565 8.96 3.565s8.96-2.302 8.96-3.566c0 0 0-0.334 0-3.758 0-3.426-6.202-6.251-6.202-8.677 0-2.422 6.202-5.251 6.202-8.674zM9.504 3.726c1.114-0.702 3.202-1.733 6.582-1.733 3.382 0 6.41 1.733 6.41 1.733 0.227 0.138 1.117 0.614 0.507 0.974-1.342 0.795-3.966 1.632-7.005 1.632s-5.573-0.923-6.915-1.718c-0.61-0.362 0.421-0.888 0.421-0.888zM16.802 16c0 1.909 1.594 3.138 3.283 4.778 1.234 1.198 2.92 2.838 2.92 3.899v2.125c-1.55-0.773-6.194-1.53-6.194-4.006 0-1.254-1.622-1.254-1.622 0 0 2.477-4.643 3.234-6.194 4.006v-2.125c0-1.059 1.688-2.701 2.922-3.899 1.688-1.64 3.282-2.869 3.282-4.778 0-1.909-1.594-3.138-3.282-4.778-1.234-1.198-2.922-2.84-2.922-3.898l-0.075-1.6c1.642 0.883 4.245 1.726 7.080 1.726s5.451-0.843 7.093-1.726l-0.088 1.6c0 1.058-1.688 2.699-2.92 3.898-1.69 1.64-3.283 2.869-3.283 4.778z" /></svg>',
                help: '<svg viewBox="0 0 30 32"><path d="M16 24.571v-3.429q0-0.25-0.161-0.411t-0.411-0.161h-3.429q-0.25 0-0.411 0.161t-0.161 0.411v3.429q0 0.25 0.161 0.411t0.411 0.161h3.429q0.25 0 0.411-0.161t0.161-0.411zM20.571 12.571q0-1.571-0.991-2.911t-2.473-2.071-3.036-0.732q-4.339 0-6.625 3.804-0.268 0.429 0.143 0.75l2.357 1.786q0.125 0.107 0.339 0.107 0.286 0 0.446-0.214 0.946-1.214 1.536-1.643 0.607-0.429 1.536-0.429 0.857 0 1.527 0.464t0.67 1.054q0 0.679-0.357 1.089t-1.214 0.804q-1.125 0.5-2.063 1.545t-0.938 2.241v0.643q0 0.25 0.161 0.411t0.411 0.161h3.429q0.25 0 0.411-0.161t0.161-0.411q0-0.339 0.384-0.884t0.973-0.884q0.571-0.321 0.875-0.509t0.821-0.625 0.795-0.857 0.5-1.080 0.223-1.446zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" /></svg>'
            }
        },
        //
        // pre-defined sets of parameters for different alert types and modes
        //
        // types
        types: {
            success: {timeout: 3, icon: 'success'},
            failed: {timeout: 8, icon: 'failed'},
            caution: {timeout: 5, icon: 'caution'},
            info: {timeout: 4, icon: 'info'},
            help: {timeout: 4, icon: 'help'},
            wait: {timeout: 0, icon: 'wait'},
            desc: {btnClose: false, icon: false}
        },
        // modes
        modes: {
            flash: {view: 'queue', position: 'fixed-top', open: {mode: 'slide'}, close: {mode: 'slide', speed: 'fast'}},
            msg: {mode: '', position: '', timeout: 0, view: 'all', open: {mode: 'fade'}, close: {mode: 'fade'}},
            msgFlash: {mode: '', position: '', timeout: 5, view: 'replace', open: {mode: 'fade'}, close: {mode: 'fade'}},
            status: {position: '', timeout: 0, view: 'replace', open: {mode: 'fade'}, close: {mode: 'fade'}},
            'input-status': {mode: 'status', position: '', alertPosition: 'right', timeout: 0, view: 'replace', open: {mode: 'fade'}, close: {mode: 'fade'}},
            'status-small': {msg: '', class: 'mta-small', mode: 'status', position: '', view: 'replace', open: {mode: 'show'}, close: {mode: 'fade'}, btnClose: false},
            'status-wait': {type: 'wait', position: '', view: 'replace', timeout: 0, open: {mode: 'fade', speed: 'fast'}, close: {mode: 'fade', speed: 'fast'}, btnClose: false},
            waitmeter: {timeout: 0, open: {mode: 'slide', speed: 'fast'}, close: {mode: 'slide', speed: 'fast'}, labelDelay: 2}, // show label after 2 s
            large: {position: '', timeout: 0, view: 'all', open: {mode: 'show'}, close: {mode: 'hide'}},
            panel: {position: '', timeout: 0, view: 'all', open: {mode: 'show'}, close: {mode: 'hide'}, btnClose: false},
            notify: {position: 'fixed-bottom-right', view: 'colapse', close: {mode: 'fade'}} // , modal: true
        }        
    };

    /**
     * Alerts buffor; common for all modes/types of alerts
     */
    //window['mtsoftUiAlertsBuffor'] = [];
// -----------------------------------------------------------------------------
//
// shortcuts (public jQuery functions)
//

// NOTE: below shorctus can cause confilcts with other jQUery plugins !!!
// Use with care ...

    if ($.fn.mtsoft.ui.alert.defaults.defineShortcuts) { // define shortcuts?

        // show top fixed flash alert
        $.flash = function(msg, type, params) {
            
            return $.mtsoft.ui.alert($.extend(true, {}, {
                msg: msg,
                type: type,
                mode: 'flash'
            }, params));
        };
        // show standard, in-page alert
        $.msg = function(msg, type, params) {

            return $.mtsoft.ui.alert($.extend(true, {}, {
                msg: msg,
                type: type,
                mode: 'msg'
            }, params));
        };
        // show standard, in-page alert with defined timeout
        $.msgFlash = function(msg, type, timeout, params) {

            return $.mtsoft.ui.alert($.extend(true, {}, {
                msg: msg,
                type: type,
                mode: 'msgFlash'
            }, params));
        };
        // show small, status alert
        $.status = function(msg, type, params) {

            return $.mtsoft.ui.alert($.extend(true, {}, {
                msg: msg,
                type: type,
                //class: (msg !== undefined ? '' : 'mta-small'),
                mode: 'status'
            }, params));
        };
        // show small, status only icon (with title) alert
        $.statusIco = function(type, title, params) {

            return $.mtsoft.ui.alert($.extend(true, {}, {
                type: type,
                title: title,
                mode: 'status-small'
            }, params));
        };
        // show notification (fiexd, on right bottom corner of the viewport)
        $.notify = function(msg, type, params) {

            return $.mtsoft.ui.alert($.extend(true, {}, {
                msg: msg,
                type: type,
                mode: 'notify',
                view: 'colapse',
                // put on separate container to respect queue
                parent: $('#notifications').length ? $('#notifications') : $('#alerts')
            }, params));
        };
        // show IMPORTANT notification (constant, sticky with sound)
        $.notifyAlert = function(msg, type, params) {

            return $.notify(msg, type, {
                timeout: 0,
                indicateInterval: 10,
                view: 'colapse',
                audio: 'mp3/ui/alerts/ding.mp3'
            }, params);
        };
        // show big panel with alert/message
        $.panel = function(msg, type, params) {

            return $.mtsoft.ui.alert($.extend(true, {}, {
                msg: msg,
                type: type,
                mode: 'panel'
            }, params));
        };
        // show alert box as popup (with optional buttons)
        $.alert = function(msg, type, params) {

            return $.mtsoft.ui.alert($.extend(true, {}, {
                header: '',
                msg: msg,
                type: type,
                mode: 'panel', // status, msg, panel
                position: 'fixed-center',
                modal: true,
                timeout: 0,
                open: {
                    mode: 'fade'
                },
                btnClose: true
            }, params));
        };
        // show wait status alert
        $.wait = function(msg, params) {

            return $.mtsoft.ui.alert($.extend(true, {}, {
                msg: msg,
                type: 'wait',
                mode: 'status-wait',
                //class: (msg !== undefined ? '' : $.mtsoft.ui.alert.defaults.prefixCss + 'small'),
                //typeIcon: (msg !== undefined ? true : false)
                icon: true // wait
            }, params));
        };
        // show wait-meter at page top with optional label shown on top right side
        $.waitmeter = function(open, label, params) {

            return $.mtsoft.ui.waitMeter(open, label, params);
        };
    }













// -----------------------------------------------------------------------------
//
// Stylize and attach behaviour for all already existing alerts
//
    /*$(function() {

     if ($.fn.mtsoft.ui.alert.defaults.applyOnLoad) {

     //$('.' + $.mtsoft.ui.alert.defaults.prefixCss + 'alert').mtsoftUiAlerts();
     }
     });*/
})(jQuery, window, document);
