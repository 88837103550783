/**
 * From
 *
 * @uses jStorage [ $.jStorage.get(), $.jStorage.set(), $.jStorage.deleteKey() ]
 * @uses mtsoft.ui.ajax [$.mtsoft.ui.ajax()]
 * @uses mtsoft.ui.alerts [$(n).mtsoftUiAlertClose(), $(n).mtsoftUiAlertIndicate()]
 * @uses $(n).mtsoftUiSticky()
 *
 */

;
(function($, window, document, undefined) {

    // -------------------------------------------------------------------------
    //
    // plugin constructor
    //
    $.fn.mtsoftUiForm = function(opts) {

        // initialize every form element (input + related HTMl & JS )
        return this.each(function() {

            if (!$(this).data('config.mtsoftUiForm')) { // prevent double form definition

                var $this = $(this), fDef = $.fn.mtsoftUiForm.def[$this.attr('id')], // $.fn.mtsoftUiForm.def JSON form parameters defined on server side
                        // set form configuration
                        config = $.extend(true, {},
                                $.fn.mtsoftUiForm.defaults, // default parameters
                                $this.data(), // in form data attrbibutes
                                fDef, // JSON format form definition (usually from server)
                                opts), // parameters given as plugin attributes
                        // all form's inputs (those with real values, not labels)
                        inputs = [];

                // special default settings for 'add' action (only if custom not defined)
                if (config.action === 'add') {

                    if (config.resetAfterSubmit === null) {
                        config.resetAfterSubmit = true; // after submit reset all form's inputs (makes sense for ajax submit only)
                    }
                    if (config.storeData === null) {
                        // store form's inputs values entered by user
                        // next 'tokenTTL' minutes (in case he leaved incidentally page and get back)
                        //config.storeData = config.tokenTTL;
                    }
                }

                // apply inputs definitions (if any)
                config.inputsDef = fDef && fDef.inputs ? fDef.inputs : {};
                config.$form = $this;
                // is form inside popup?
                config.$popup = $this.parents('[data-popup]').filter(':first');
                
                $this.mtsoftUiFormConfig(null);    // reset config
                $this.mtsoftUiFormConfig(config); // initall configuration
                
                // find all inputs for this form and initialize
                $this.mtsoftUiFormConfig('inputs', inputs); // this is required because some kind of inputs (ex. hasMany) can add another additional inputs to this form
                $this.find('[data-forma]').each(function() {

                    // If inputIdDef definition JSON object defined - use it.
                    // Also assign this from to input
                    var $n = $(this);
                    inputs.push($n.mtsoftUiInput($.extend(true, {}, {$form: $this, modelId: config.modelId}, config.inputsDef[$n.attr('id')])));
                });                
                
                // add collected inputs to configuration
                $this.mtsoftUiFormConfig('inputs', inputs);
                // observe submit elements to detect which submit button has been used to submit form
                $this.find('[type="submit"]').on('click.mtsoftUiForm', function(e) {

                    // data should be validated before submit?
                    if ($(this).data('noValidate') !== undefined) {
                        // turn off validation; (if form will be not submitted validation will be turned on back)
                        $this.mtsoftUiFormConfig('_validate', false);
                    }

                    _setSubmittedBy($this, $(this));
                });

                // add form submit handler
                $this.on('submit.mtsoftUiForm', function(e) {

                    return _submit($this);
                });
                
                // define form/inputs behaviour on client side
                _defineBehaviour($this, config);

                //
                // handle server side generated invalid inputs (set invalid flag, stylize, view alert message)
                //
                /*var invInputsMsgs = $this.find('.error-message');
                 invInputsMsgs.each(function() { // find error messages generated by CakePHP
                 // find input with invalid value (previous with .form-error class generated by CakePHP)
                 $(this).hide().prev('.form-error').mtsoftUiInputSetInvalid('server');
                 });*/
                var anyServerError = false;
                
                if (config.invalidInputs) {
                    // invalidInputs are processed in chain: 
                    // model validation => FormaComponent->failed() => FormaCoreHelper->create() 

                    for (var id in config.invalidInputs) {

                        var $n = $('#' + id);

                        if ($n.length) {

                            var cfg = $n.mtsoftUiInputConfig();
                            cfg.$value.mtsoftUiInputSetInvalid('server', config.invalidInputs[id]); // pass message generated on server
                            cfg.validateOnFailed();
                            anyServerError = true;
                        }
                    }
                }
                /*
                 // utylize CakePHP FOrm helper '.error-message' erros handling shema 
                $.each(inputs, function() {

                    var cfg = $(this).mtsoftUiInputConfig(), err = cfg.$value.siblings('.error-message');
                    if (err.length) {

                        err.hide(); // in case not already hidden                        
                        cfg.$value.mtsoftUiInputSetInvalid('server', err.html()); // pass message generated on server
                        cfg.validateOnFailed();
                        anyServerError = true;
                    }
                });
                */

                if (anyServerError) {
                    // one oe more invalid inputs returned from server - show message, scroll to first invalid
                    _invalid($this, config);
                }
                
                _enable($this, config, true); // always enable form (firefox restores disable attribute after page refresh for all disabled elements); true - enable all 

                //
                // restore form's stored data for all inputs with enabled storeData flag (by default)
                //
                if (config.storeData) {

                    try {
                        //var data = $.jStorage.get('form.' + (config.token !== '' ? config.token : $this.attr('id')));

                        if (data) {
                            //_json2formData($this, data);
                        }
                    } catch (e) {
                    }
                }

                // check for inputs with prefixes or postfixes INSIDE input
                // if any present - set right left (prefix) or right (postfix) padding value.
                $this.find('.in-prefix').each(function() {
                    // get width
                    var $n = $(this), w = $n.innerWidth();
                    if (w !== 0) {
                        $n.siblings('input').css({paddingLeft: w});
                    }
                });
                $this.find('.in-postfix').each(function() {
                    // get width
                    var $n = $(this), w = $n.innerWidth();
                    if (w !== 0) {
                        $n.siblings('input').css({paddingRight: w});
                    }
                });

                // focus first form's input
                if (config.focusFirstInput) {
                    
                    try {
                        if ($.inArray(inputs[0].tagName.toLowerCase(), ['input', 'textarea', 'select']) > -1) {
                            
                            inputs[0].focus();
                        }
                    } catch (e) {

                    }
                }
                
                // on init callback 
                config.onInit();                
            }
        });

    };



    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtsoftUiForm.defaults = {
        model: null, // model related to form
        modelId: null, // db tbale model row id 
        token: '', // form's token value; used only for add (to store/restore right form data if 'storeData' is on)
        ajax: true, // use ajax reuqest to submit form
        action: 'edit', // default action [ add | edit | update ]
        url: null, // custom submit url (only for ajax request)
        cssPrefix: 'mtf-', // form/inputs css prefix
        cssClassAlert: 'mta-', // alert css class name
        scrollToFirstInvalid: true, // if true when validtion fails - page is scrolled to first invalid input (only if outside viewport)
        scrollTopMargin: 0, // when scrolling to first invalid input always include this top margin value (ex. equal to header height)
        focusFirstInvalid: false, // if true focus first invalid input in form
        focusFirstInput: false, // focus first form's input (on form load)
        disableOnSubmit: true, // if true disabled all form's inputs while submitting
        resetAfterSubmit: null, // reset all inputs (set default values) after form submitted succesfully; only for ajax mode
        subbmitOnEnter: false, // by default don't submit on enter hit (focusNextOnEnter must be false)
        focusNextOnEnter: false, // if true focus next form element on Enter hit (subbmitOnEnter must be false)
        useArrowsToNavigate: false, // use up & down arrows (only for input[text]) to move between previous/next input
        descHighlightOnHover: true, // highlight input description when user moves cursor over input's row
        // stickyActionPanel - if true make actions buttons panel sticky; actions panel will be shown after first input in form whole visible; 
        // if jQuery object - after pointed out input in form whole visible; 
        stickyActionsPanel: false, // [ bool | jQuery ] 
        storeData: false, // DISABLED BY DEFAULT due problems [min] if not null/false - store form's data in local storage: if true - permanently, if numeric - for given number of minutes
        tokenTTL: 15, // [min] default store data TTL for add action
        $popup: null, // if form inside popup - this is popup node
        popupCloseOnSuccess: .5, // if 0 or integer - close popup if form submited succesfully after n [s]
        txt: {
            invalid: 'Please correct invalid values',
            error: 'Error while saving form data. Please try again.',
            confirmHeader: 'Please confirm',
            waitmeter: true // show waitmeter while submitting [ 'Saving ...' | '' | true | false ]
        },
        confirmAlert: {
            buttons: {
                cancel: {
                    label: "Cancel"
                            //close: true
                },
                ok: {
                    label: "OK",
                    default: true // this is default choose
                            // Define callback for custom callback executed after user confirms
                            // on _beforeSubmit is defined default callback which submits form
                            //,callback: function($alert) {}
                }
            }
        },
        onInit: function() {
            
        },
        /**
         * Submit form
         */
        submit: function() {
        },
        // callbaks (executed in below order)
        beforeSubmit: function() {
            return true;    // MUST return true, otherwise form will not be submitted
        },
        beforeValidation: function() {
            return true;    // MUST return true, otherwise form will not be validated/submitted
        },
        onSubmit: function() {
        },
        // Only for ajax mode
        afterSubmit: function() {
        },
        onSubmitSuccess: function(data) {
        },
        onSubmitFailed: function(data) {
        },
        // internal data        
        _validate: true, // if true validate form when requested, otherwise disable form validation and assume all inputs valid
        _submittedBy: null, // jQuery node object (button) used to submit form
        _submitConfirmed: false, // used when submit button has defined confirmation alert; true means user confirmed submit
        //,_firstFocused: false    // helps to focus first invalid input on validation/submit
        // internal methods
        _onSubmitSuccess: function(data) {

            if (this.popupCloseOnSuccess !== false && this.$popup) {

                var self = this;
                // hide all popups (if any - delete confirmation or similar) 
                window.setTimeout(function() {
                    try {
                        self.$popup.mtsoftUiPopup().hide(data);
                    } catch (e) {
                    }
                }, this.popupCloseOnSuccess * 1000);
            }
        }
    };
    $.fn.mtsoftUiForm.def = {};   // buffor where all form definitions are placed


    // -------------------------------------------------------------------------
    //
    // define private functions (internal use only)
    //

    /**
     * Add (attach) and initialize new form's input 
     * 
     * @param {type} $form
     * @param {type} $input
     * @param {JSON} inputDefId     custom input id used to get input definition from 
     *                              form's configuration inputsDef object (set on server side).
     *                              (Usable for 'skeletion' type input which are cloned) 
     * @param {JSON} inputDef       input parameters 
     * @returns mtsoftUiInput Object
     */
    function _addInput($form, $input, inputDefId, inputDef) {

        // get all currently assigned to form inputs 
        var cfg = $form.mtsoftUiFormConfig(), currInputs = cfg.inputs,
            inp = $input.mtsoftUiInput($.extend(true, {}, {$form: $form}, cfg.inputsDef[inputDefId !== undefined ? inputDefId : $input.attr('id')], inputDef));
        
        // add new input to form and init it
        currInputs.push(inp);

        // update form's configuration
        $form.mtsoftUiFormConfig('inputs', currInputs);
        
        return inp;
    }

    /**
     * Remove (detatch) input from form 
     * 
     * @param {type} $form
     * @param {type} $input
     * @returns {undefined}
     */
    function _removeInput($form, $input) {

        // get all currently assigned to form inputs 
        var cfg = $form.mtsoftUiFormConfig(), currInputs = cfg.inputs, id = $input.attr('id');
        
        $.each(currInputs, function(key) {

            if ($(this).attr('id') === id) {
                delete currInputs[key];
            }
        });
 
        // update form's configuration
        $form.mtsoftUiFormConfig('inputs', currInputs);
        
    }

    /**
     * Define form's and inputs behaviour:
     *  - use or not Enter to submit form
     *  - move to next input on Enter hit
     *  - use arrows to nvaigate between inputs in form
     *  - highlight 'desc' (description) type messages on row mouseover
     *  - make actions panel aticky 
     *  - allow to toggle/slide input contnets on label click 
     *
     *
     * @param {jQuery} $form
     * @param {JSON} config
     */
    function _defineBehaviour($form, config) {

        // handle Enter hit
        if (!config.subbmitOnEnter) { // don't submit form on enter hit

            // disable form submit on Enter hit
            //$form.find('input, select').on('keydown.mtsoftUiForm', function(e) {
            $form.on('keydown.mtsoftUiForm', function(e) {

                if (e.which !== 13 || // not Enter
                        (e.which === 13 && this.type === 'submit')) {   // Enter, but input type "submit" has been used

                    return true;    // do default action
                }
                else { // Enter has been used - go to next input on form

                    if (config.focusNextOnEnter) {

                        if (!e.shiftKey) {

                            _focusNextInput($form);  // move cursor to next input
                        }
                        else { // Enter + Shift

                            _focusNextInput($form, true);  // move cursor to previous input
                        }
                    }

                    // disable form submit (only allow new line on textarea) 
                    return e.target.nodeName != "TEXTAREA" ? false : true;
                }
            });
        } else { // submit form using Enter allowed - use default submit button

            $form.on('keydown.mtsoftUiForm', function(e) {

                if (e.which === 13) { // Enter has been hit
                    
                    // submit using default submit button
                    $(this).mtsoftUiFormSubmit($(this).find('button[data-submit-default]'));
                    return false;
                }
            });

        }

        // use arrows to navigate between inputs
        if (config.useArrowsToNavigate) {

            $form.find('input').on('keydown.mtsoftUiForm', function(e) {

                if (e.which !== 38) {   // arrow up
                    _focusNextInput($form);   // previous input
                }
                if (e.which !== 40) {   // arrow down
                    _focusNextInput($form, true);     // next input
                }
            });
        }

        // store form's data on local storage
        if (config.storeData) {
            $(window).on('unload.mtsoftUiForm', function(e) {

                try {
                    var sid = config.token !== '' ? config.token : $form.attr('id');
                    //$.jStorage.set('form.' + sid, _formData2json($form));
                    if (config.storeData !== true) {    // set TTL for this storage (true = permament storage(not recommended))
                        //$.jStorage.setTTL('form.' + sid, config.storeData * 60 * 1000); // [min]
                    }
                } catch (e) {
                }
            });
        }

        // highlight 'desc' (description) alert message when user moves cursor over input's row
        if (config.descHighlightOnHover) {

            var inputs = $form.mtsoftUiFormConfig('inputs');

            $.each(inputs, function(i, o) {

                var rowInput = o.parents('.row').filter(':first'), rowDesc = rowInput.next('.desc-out');

                $.each([rowInput, rowDesc], function() {
                    $(this).on('mouseover.mtsoftUiForm', function() {

                        $('#' + o.attr('id') + '-desc').addClass('hover');
                    }).on('mouseout.mtsoftUiForm', function() {

                        $('#' + o.attr('id') + '-desc').removeClass('hover');
                    });
                });
            });
        }

        // make actions panel sticky        
        if (config.stickyActionsPanel && config.action !== 'add') { // for add don't make sticky panel 

            $(window).ready(function() { // use 'ready' no 'load' due Chrome bug
                
                var $actionsPanel = $form.find('.' + config.cssPrefix + 'actions'),
                        $fieldset = $actionsPanel.parents('fieldset').first().length ? $actionsPanel.parents('fieldset').first() : $actionsPanel.parents('form').first();

                /*if (typeof (config.stickyActionsPanel) !== 'object') {

                    // show sticky always below first input in form's fieldset
                    //var $firstInpDesc = $fieldset.children('div.row.input-out').next('div.row.desc-out').filter(':first');
                            //,$firstInpEnd = $firstInpDesc.length ? $firstInpDesc : $fieldset.children('div.row.input-out').filter(':first');
                } else {    // show sticky always below given input

                    //$firstInpEnd = config.stickyActionsPanel;
                }*/
                
                try {
                    $actionsPanel.mtsoftUiSticky({
                        mode: 'bottom',
                        limiter: $fieldset,
                        //limiterPadding: $firstInpEnd,
                        class: 'sticky',
                        margin: 0,
                        opacity: .6
                    });
                } catch (e) {}
            });
        }    
        
        // allow togle of some form inputs (mut have data-togle attribute defined) 
        var inputs = $form.find("[data-toggle]"); // inputs         
        $.each(inputs, function(i, o) {
            
            var $o = $(o);
            
            $o.parents('.input-out').filter(':first').find('label').filter(':first').on('click', function(){

                $o.toggle();
            });
        });

    }


    /**
     * Set which button has submitted form
     * @param {j!uery} $form            form node
     * @param {jQuery} $submitBtn       node which trigged submit
     *
     * @return {jQuery}                 -//-
     */
    function _setSubmittedBy($form, $submitBtn) {

        // re-write value/name or id of button to hidden input (will be passed to server)
        var id = $submitBtn.val() !== '' ? $submitBtn.val() :
                $submitBtn.attr('name') ? $submitBtn.attr('name') :
                $submitBtn.attr('id') ? $submitBtn.attr('id')
                : '';
        // hidden input with name data[model][submitted_by] must exists
        $('input[name="data\[' + $form.mtsoftUiFormConfig('model') + '\]\[submitted_by\]"]').val(id);
        // set intrnal flag
        $form.mtsoftUiFormConfig('_submittedBy', $submitBtn);

        return $submitBtn;
    }

    /**
     * Base actions to be taken before submit
     *
     * @param {type} $form
     * @param {type} config
     *
     * @returns {Boolean}       must return true to form submit
     */
    function _beforeSubmit($form, config) {
        
        // check is there confirmation required for form submit ?
        // and NOT already confirmed (if confirmed don't show alert again)
        //if (config._submitConfirmed && !config._submitConfirmed && config._submittedBy.data('confirm')) {
        if (!config._submitConfirmed && config._submittedBy.data('confirm')) {

            // show confirmation alert
            
            var alert = $.extend(true, {}, config.confirmAlert, config._submittedBy.data('confirm'));

            alert.header = config.txt.confirmHeader;
            alert.buttons.ok.callback = function($alert) {

                $form.mtsoftUiFormConfig('_submitConfirmed', true); // set flag submit was confirmed
                $form.mtsoftUiFormSubmit(config._submittedBy);
                $($alert).mtsoftUiAlertClose();
                return true;
            };
            alert.buttons.cancel.callback = function($alert) {

                $form.mtsoftUiFormConfig('_validate', true); // restore validation; only required when submit by this button do not require validation
                $($alert).mtsoftUiAlertClose();
                return false;
            };

            $.alert(alert.msg, alert.type ? alert.type : 'caution', alert);

            return false;
        } else {

            $form.mtsoftUiFormConfig('_submitConfirmed', false); //reset confirmation flag
            // Restore form validation.
            // In case confirmation was used with option to not validate and user confirmed
            // and form is sumitting
            $form.mtsoftUiFormConfig('_validate', true);
            return true;    // MUST return true, otherwise form will not be submitted
        }
    }

    /**
     * Reset form (set all form's inputs values to defaults)
     *
     * @param {jQuery} $form    form jQuery object
     */
    function _reset($form) {

        var config = $form.mtsoftUiFormConfig();

        // remove form's inputs data from local storage
        if (config.storeData) {

            try {
                //$.jStorage.deleteKey('form.' + (config.token !== '' ? config.token : $form.attr('id')));
            } catch (e) {
            }
        }

        _enable($form, config); // is a must to handle properly reset inputs on form

        // set defaults for all form's input
        for (var i in config.inputs) {
            //$(config.inputs).each(function() {

            var inputCfg = config.inputs[i].mtsoftUiInputConfig();
            if (inputCfg && !inputCfg.formResetDisabled) {
                inputCfg.reset(); // run reset method for this input
            }
        }
        //});

        // focus first input?
        if (config.focusFirstInput) {
            try {
                if ($.inArray($(config.inputs)[0].tagName.toLowerCase(), ['input', 'textarea', 'select']) > -1) {
                    $($(config.inputs)[0]).focus();
                }
            } catch (e) {
            }
        }
    }

    /**
     * Validate form (sequantial each input in form; do not stop on first invalid)
     *
     * @param {jQuery} $form    form jQuery object
     * @param {JSON} config     form configuration
     * @returns {Boolean}       true if vall inputs values are valid, false if any is invalid
     */
    function _validate($form, config) {

        var result = true;

        // Only for DEBUG mode
        dbg(function() {
            log('---> form: ' + $form.attr('id') + ' validating...');
        });

        // var firstFocused = false;           
        //$.each(config.inputs, function() {  // always validate ALL inputs in form
        for (var key in config.inputs) {
            var $inp = config.inputs[key];
            if ($inp !== undefined && !$inp.mtsoftUiInputValidate() && result) { // single input validation
                /*
                 if (!firstFocused) {
                 
                 $form.mtsoftUiFormConfig('_firstFocused', true);
                 firstFocused = true;
                 }
                 */
                dbg(function($inp) {
                    console.warn('FORM not submitted because of: ' + $inp.attr('id'));
                }, [$(this)]);
                result = false; // form validation failed - at least one input has invalid value
            }
        }
    //});
        //$form.mtsoftUiFormConfig('_firstFocused', false); // reset first focused flag
        dbg(function() {
            log('<--- form: ' + $form.attr('id'));
        });
        return result;
    }

    /**
     * Do submit form (executed when any of form's submit type buttons has been used)
     *
     * @param {type} $form
     * @returns {Boolean}
     */
    function _submit($form) {
        
        var config = $form.mtsoftUiFormConfig();
        
        // enable form (if disabled)
        _enable($form, config);

        if (config.beforeValidation()) {

            //
            // perform validation on all inputs (if not turned off)
            //
            var result = config._validate ? _validate($form, config) : true;

            if (result) { // all form inputs has valid values

                //
                // check is there any ajax request active (validation, file upload, etc...)
                //
                var inputsNotReady = false;
                for (var key in config.inputs) {

                    var c = config.inputs[key].mtsoftUiInputConfig();

                    if (!c.isReady()) {

                        inputsNotReady = true;

                        dbg(function() {
                            console.warn('form submit - input not ready:');
                            log(c.$value);
                        });

                        // Note: It causes form not submitted even when all inputs ready 
                        // (User must click one more time on submit button) 
                        //return false;
                    }
                }

                if (config.beforeSubmit() && _beforeSubmit($form, config)) {

                    // close all flash alert messages
                    $('#alerts').mtsoftUiAlertClose('flash');

                    // show waitmeter
                    if (config.txt.waitmeter) {
                        $.mtsoft.ui.waitMeter();
                    }

                    //
                    // Submit
                    //

                    if (config.ajax) {

                        if (!inputsNotReady) {

                            //
                            // submit by ajax asynchronically (don't submit form - just send ajax request)
                            //

                            // send ajax request
                            return _submitAjax($form);
                        } else {

                            // one or more form validation ajax request active
                            // do nothing and re-try form submit in 1 [s]
                            config._formSubmitRetry = window.setTimeout(function() {
                                _submit($form);
                            }, 100);

                            // disable all form's input
                            if (config.disableOnSubmit) {
                                _disable($form, config);
                            }

                            return false;
                        }

                    } else {

                        //
                        // submit form by http (synchronically)
                        //

                        // disable form inputs AFTER form submitted (disabled inputs are not send to server)
                        if (config.disableOnSubmit) {
                            window.setTimeout(function() {
                                _disable($form, config);
                            }, 0.25);
                        }

                        // add 'wait' style to submit button
                        if (config._submittedBy) {
                            config._submittedBy.mtsoftUiState('wait', {
                                disableOnWait: false
                            });
                        }

                        _clearSmartlables(config);

                        // true submits form                        
                        return true;
                    }


                    //config.onSubmit();

                    //return true;

                    /*
                     e.preventDefault(); // don't submit multiple times


                     // disbale form
                     _disable($form);

                     this.submit();
                     return false;
                     */

                } else {

                    return false;
                }
            } else { // beforeSubmit returned false


                //
                // form has one or more inputs with invalid values
                //
                if (config.txt.waitmeter) {
                    $.mtsoft.ui.waitMeter(false);
                }
                _invalid($form, config);
                return false;
            }
        } else {    // beforeValidation returned false

            return false;
        }

        // ONLY AJAX! reset 'submitted_by' hidden field value
        //$('input[name="data\[' + config.model + '\]\[submitted_by\]"]').val(id);
    }

    /**
     * Submit form data usign ajax request
     *
     * @param {jQuery} $form
     * @returns {Boolean}
     */
    function _submitAjax($form) {

        var config = $form.mtsoftUiFormConfig(); // form configuration

        // enable form to read input's values (if disabled)
        _enable($form, config);
        _clearSmartlables(config);

        var data = $form.serialize(), // serialize form data (before disable form)
                url = config.url || $form.attr('action');  // by default use form's action (if no custom submit url defined)

        // disable all form's input
        if (config.disableOnSubmit) {
            _disable($form, config);
        }

        // send ajax reuqest
        $.mtsoft.ui.ajax(url, {
            node: config._submittedBy,
            waitmeter: config.txt.waitmeter, // show waitmeter
            showInvalidInputs: false, // don't indicate invalid inputs - it is handled below by using mtsoftUiInputSetInvalid
            txt: {
                error: config.txt.error
            },
            onResultSuccess: function(data, textStatus, jqXHR) {

                var config = $form.mtsoftUiFormConfig();

                // remove form's inputs data from local storage
                if (config.storeData) {

                    try {
                        //$.jStorage.deleteKey('form.' + (config.token !== '' ? config.token : $form.attr('id')));
                    } catch (e) {
                    }
                }

                // if new token returend from server - set it as current;
                // otherwise set empty token
                var token = '';
                if (data.data && data.data.token) {
                    token = data.data.token;
                }
                $form.mtsoftUiFormConfig('token', token);

                // if there are any new rows inserted try to update existing on form
                // (created dynamically) rows id values;
                // from server: {data: {model: {rnd: id, rnd: id, ...}, ... }}
                // 
                if (data.data && data.data.inserted) {
                    var model, rnd, id;
                    for (model in data.data.inserted) {
                        for (rnd in data.data.inserted[model]) {
                            
                            id = data.data.inserted[model][rnd];
                            $('[data-rnd="' + rnd + '"]').val(id).attr('data-id', id);
                            
                            // for hasMany upload inputs we need to change upload dir parameter to row id related instead of token/rnd related                              
                            var $upld = $("input[type='file'][data-dir-rnd='"+rnd+"']");
                            if ($upld.length) {
                                $upld.mtsoftUiUploadConfig('dir', $upld.mtsoftUiUploadConfig('dir').replace(/(\\|\/)tmp(\\|\/)uploads(\\|\/)([^\\\/]+)/, '').replace(rnd, id));
                            }
                        }
                    }
                }

                // remove succes/info/caution alert messages and input styling
                for (var i in config.inputs) {
                    config.inputs[i].mtsoftUiInputSetNeutral();
                }

                // reset form
                if (config.resetAfterSubmit) {

                    _reset($form);  // reset form's inputs
                }

                // callback
                config._onSubmitSuccess(data);   // internal
                config.onSubmitSuccess(data);    // custom
            },
            onResultFailed: function(data, textStatus, jqXHR) {
                
                var config = $form.mtsoftUiFormConfig();                
                _enable($form, config); // is a must to handle invalid input on form
                // for invalid values:
                // - set inputs as invalid
                // - add invalid class and show failed alert message
                // (defined on input mtsoftUiInput.config.msgs.ajax_failed or message sent from server)
                for (var id in data.invalidInputs) {

                    $('#' + id).mtsoftUiInputSetInvalid('server', data.invalidInputs[id]);
                }
                // NOTE: for non-ajax submit (http) alert messages are handled on constructor:
                // $.fn.mtsoftUiForm 

                // scroll to first invalid input and focus
                _invalid($form, config);

                // callabcks                
                config.onSubmitFailed(data);    // custom
            },
            onComplete: function(jqXHR, textStatus) {

                var config = $form.mtsoftUiFormConfig();

                // enable all form's inputs
                _enable($form, config);

                config.afterSubmit();
            }
        }, {
            type: 'POST', // always use POST to submit form
            data: data
        });

        // return false (don't submit form)
        return false;
    }

    /**
     * Enable all form's  inputs and buttons
     *
     * @param {jQuery} $form
     * @param {JSON} cfg
     * @param {Bool} any        if true enable any form element (no matter it has been disabled by _disable method or not);
     *                          used on initial for definition
     */
    function _enable($form, cfg, any) {
        
        // all buttons
        if (any) { // .mtu-input is upload replacement
            
            $form.find('button, .btn').prop('disabled', false).removeClass('disabled'); // .' + cfg.cssPrefix + 'label'
        } else {
            
            $form.find('button[_disabled], .btn[_disabled]').prop('disabled', false).attr('_disabled', null).removeClass('disabled'); // , .' + cfg.cssPrefix + 'label[_disabled]
        }
        // form's defined inputs
        var _enableInputs = function($inp) {
            try {
                $inp.mtsoftUiInputConfig().enable(any);
            } catch (e) {
                $inp.prop('disabled', false);
            }

            // restore smart labels (if were set empty before form submit) 
            if ($inp.data('smartlabel') && $.trim($inp.val()) === '') {

                $inp.val($inp.data('smartlabel'));
            }
        };
        
        $.each(cfg.inputs, function() {
            
            _enableInputs($(this));
        });
        // in case inputs are not jQuery objects, 
        // but JSON configuration objects (inputId = > configurationJSONobj) 
        for (var id in cfg.inputs) {

            _enableInputs($('#' + id));
        }
    }

    /**
     * Disable all form's inputs and buttons
     *
     * @param {jQUery} $form
     * @param {JSON} cfg
     */
    function _disable($form, cfg) {

        // all buttons
        $form.find('button, .btn').each(function() { //, .' + cfg.cssPrefix + 'label'

            if (!$(this).prop('disabled') && !$(this).hasClass('disabled')) {

                $(this).attr('_disabled', true).prop('disabled', true).addClass('disabled'); // mark currently disabled; _dasable must be .attr NOT .prop!
            }
        });

        
        // form's defined inputs
        //for (var id in cfg.inputs) {            
        //  var $inp = $('#' + id);
        $.each(cfg.inputs, function() {
            var $inp = $(this);

            try {
                $inp.mtsoftUiInputConfig().disable();
            } catch (e) {
                $inp.prop('disabled', true);
            }
            //};
        });
    }

    /**
     * Remove smartlabels from form's input values 
     * (must be submitted empty for validation, otehrwise smartlabel will be threated as value) 
     * 
     * @param {JSON} cfg
     * @returns {undefined}
     */
    function _clearSmartlables(cfg) {

        $(cfg.inputs).each(function() {

            var $this = $(this);
            if ($this.data('smartlabel') && $this.val() == $this.data('smartlabel')) {

                $this.val('');
            }
        });

    }

    /**
     * Handle form invalid state
     *
     * @param {jQUery} $form    form node
     * @param {JSON} config     form config
     */
    function _invalid($form, config) {

        // get all invalid inputs and find first invalid
        var $alert, invInputs = $form.find('.' + config.cssPrefix + 'failed'), firstInvInput = invInputs.filter(':first');

        // if flash message already shown (to calculate it's height) - mark first invalid input
        if ($('#alerts').children().length) {

            _markFirstInvalidInput(firstInvInput, config);
        } else {

            //
            // show common flash message
            //
            if (config.txt.invalid) { // invalid message defined

                $alert = $.mtsoft.ui.alert({msg: config.txt.invalid, type: 'failed', afterOpen: config.scrollToFirstInvalid ? function($alert) {
                        // scroll to first invalid input (AFTER alert shown - to get right flash alerts height)
                        _markFirstInvalidInput(firstInvInput, config);
                    } : function() {
                    }});
            } else {

                // scroll to first invalid input
                _markFirstInvalidInput(firstInvInput, config);
            }
        }
    }

    /**
     * Indicate first invalid input:
     * - scroll page to first invalid input (on top)
     * - focus first invalid input
     * - indicate (shake) first invalid input alert
     *
     * @param {jQuery} $firstInvInput       input jQUery object (first invalid in form)
     * @param {JSON} config                 form configuration
     */
    function _markFirstInvalidInput($firstInvInput, config) {

        /**
         * Indicate first invalid input (focus)  and alert (shake);
         * if config.scrollToFirstInvalid is set - it's made after scroll finish
         */
        var _indicateFirstInvalid = function() {

            //
            // focus first invalid input
            //
            
            if (config.focusFirstInvalid) {
                
                try {
                    
                    if ($.inArray($firstInvInput[0].tagName.toLowerCase(), ['input', 'textarea', 'select']) > -1) {
                        if ($firstInvInput.attr('type') !== 'radio' && $firstInvInput.attr('type') !== 'checkbox') {

                            $firstInvInput.
                                    focus().// focus (clears invalid style/alert)
                                    mtsoftUiInputRestoreState().// restore invalid state
                                    one('keyup.mtsoftUiForm', function() {  // on first keyup set input state to neutral
                                        $(this).mtsoftUiInputSetNeutral();
                                    });
                        }

                    } else {
                        log('Not input!');
                    }

                } catch (e) {
                }
            }

            //
            // indicate invalid input invalid alert message (shake)
            //
            try {
                // get all invalid alert messsages and find first one
                var invAlerts = config.$form.find('.' + config.cssClassAlert + 'alert.' + config.cssClassAlert + 'failed'), firstInvAlert = invAlerts.filter(':first');

                // indicate first invalid alert mesage for first invalid input
                firstInvAlert.mtsoftUiAlertIndicate(); // only failed messages
            } catch (e) {
            }
        };

        //
        // TABS - if tabs (Foundation 5 tab) used - select right tab 
        //
        try {
            var tabId = $firstInvInput.parents('.content').filter(':first').attr('id');
            $('a[href="#' + tabId + '"]').click(); // force select 
        } catch (e) {
        }

        //
        // SCROLL PAGE to first invaid input
        //
        if (config.scrollToFirstInvalid) {
            try {
                var inpVoff = $firstInvInput.parents('.row').filter(':first').offset().top, // absolute top offset of given input PARENT GRID ROW (to include label too)
                        corr = 15, // correction in pxels (additional space from client borders or alert flash message or header) 
                        alertsH = 0;    // hieght of all flash alerts
                $('#alerts').children().each(function() {
                    alertsH += $(this).outerHeight();
                }); // height of all flash messages currently shwon

                var topMargin = alertsH > config.scrollTopMargin ? alertsH : config.scrollTopMargin;

                // do scroll to input (ONLY if invalid input outside viewport)
                if (inpVoff < (parseInt($(window).scrollTop()) + topMargin + corr) || // first invalid input is above browser view port
                        inpVoff > (parseInt($(window).scrollTop()) + $(window).height() - topMargin - corr)) {   // first invalid input is below browser view port

                    // scroll...
                    if ($(document).height() > $(window).height()) { // to ommit delay if no scroll required 

                        $("html,body").animate({scrollTop: inpVoff - topMargin - corr}, 'slow', 'swing', function() {

                            _indicateFirstInvalid();
                        });
                    } else {
                        _indicateFirstInvalid();
                    }

                } else { // scroll not required - first invalid input is on viewport

                    _indicateFirstInvalid();
                }
            } catch (e) {
            }
        }

        if (!config.scrollToFirstInvalid) { // if no scroll required - indicate only

            _indicateFirstInvalid();
        }
    }

    /**
     * Gather all form's inputs data (current) and return as JSON object in format:
     *  {inputId: "input value", ...}
     *
     * @param {jQuery} $form
     * @returns {JSON}
     */
    function _formData2json($form) {

        var data = {}, cfg;
        $($form.mtsoftUiFormConfig('inputs')).each(function() {  // always validate ALL inputs in form

            cfg = $(this).mtsoftUiInputConfig();
            if (cfg.storeData) {    // only if value should be stored in local storage
                data[$(this).attr('id')] = cfg.getValue(); // sets intrnally and gets current value
            }
        });

        return data;
    }

    /**
     * Set given form's inputs values (from JSON data object)
     *
     * @param {jQuery} $this
     * @param {JSON} data
     * @returns {JSON}
     */
    function _json2formData($this, data) {

        for (var id in data) {

            $('#' + id).mtsoftUiInputConfig().setValue(data[id]);
        }
        ;

        return data;
    }

    /**
     * Move focus to next input/textarea/select/button on form
     *
     * @param {jQuery} $form
     * @param {Bool} rev          if true use reverse mode (find previous input)
     */
    function _focusNextInput($form, rev) {

        rev = rev !== undefined ? rev : false;

        var $focused = $(document.activeElement),
                fields = $form.find("button,input[type!='hidden'],textarea,select"), // elements we can move focus to
                index = fields.index($focused);   // currently focused element

        var off = rev ? -1 : 1;
        if (index > -1 && (index + off) < fields.length) {

            $focused.blur();
            fields.eq(index + off).focus();
        }
        return false;
    }

    // ...

    //
    // Public functions
    //


    /**
     * Submit form (used to submit form outside form)
     * @param {jQUery} $submittedBy     node which should be set as node which submitted form
     */
    $.fn.mtsoftUiFormSubmit = function($submittedBy, force) {

        var $this = $(this);
        
        if (force) {    // force submit (set submit as confirmed) 
            $this.mtsoftUiFormConfig('_submitConfirmed', true);                                            
        }
        
        _setSubmittedBy($this, $submittedBy);   // write 'submitted_by' value
        $this.trigger('submit');
    };

    /**
     * Reset form; set all form's inputs default values
     */
    $.fn.mtsoftUiFormReset = function(config) {

        _reset($(this), config);
    };
    /**
     * Disable form inputs
     * @param {type} cfg
     */
    $.fn.mtsoftUiFormDisable = function() {
        var cfg = $(this).mtsoftUiFormConfig();        
        _disable($(this), cfg);
    };
    /**
     * Enable form inputs
     * @param {type} cfg
     */
    $.fn.mtsoftUiFormEnable = function() {
        var cfg = $(this).mtsoftUiFormConfig();
        _enable($(this), cfg);
    };

    /**
     * Add/assign input to form 
     * 
     * @param {jQuery}  $input      input node 
     * @param {String}  inputDefId  input definitions object id (property name) on 
     *                                  form's inputs definitions object generated on server side
     * @param {JSON} inputDef       input defintions object given directly 
     */
    $.fn.mtsoftUiFormAddInput = function($input, inputDefId, inputDef) {
        
        return _addInput($(this), $input, inputDefId, inputDef);
    };

    /**
     * Remove input from form 
     * @param {jQuery}          input root node 
     */
    $.fn.mtsoftUiFormRemoveInput = function($input) {

        _removeInput($(this), $input);
    };

    /**
     * Get / set plugin configuration.
     *
     * @param {Mixed} param     Configuration parameter name
     *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
     *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
     *                                    All other then given in JOSN argument parameters will be keep untouch;
     *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
     *                           null - resets current configuration (remove all parameters)
     *
     * @param {Mixed} val       configuration parameter value
     *
     * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
     *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
     */
    $.fn.mtsoftUiFormConfig = function(param, val) {

        var c = 'config.mtsoftUiForm',
                $this = $(this), cfg = $this.data(c);

        // reset current configuration
        if (param === null) {
            $this.data(c, null);
            return cfg;
        }

        if (val !== undefined || typeof(param) === 'object') { // setter

            // update single parameter value
            if (val !== undefined) { // single parameter value

                cfg[param] = val; // assign value
                $this.data(c, cfg); // update

            } else {    // multiple parameters values defined as JSON object {}

                // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                cfg = $.extend(true, {}, cfg, param);
                $this.data(c, cfg); // update
            }
            return cfg;
        } else { // getter

            return param !== undefined ? cfg[param] : cfg; // return single parameter value or whole configuration object
        }
    };




    // -------------------------------------------------------------------------
    //
    // apply plugin to default selector
    //
    //$(function() {

    // initalize all forms with data-forma atrribute set
    //$("form[data-forma]").mtsoftUiForm();
    //});

})(jQuery, window, document);