/**
 * mtsoft UI - Fullcalendar helper
 *
 * @uses $.mtsoft.ui.ajax 
 * @uses $.mtsoft.ui.waitMeter
 * @uses $.jStorage 
 * @uses jquery.qtip (+ plugins: "Speech bubble 'tips'", "Viewport adjustment") 
 */
;
/**
 *
 * @param {type} $
 * @param {type} window
 * @param {type} document
 * @param {type} undefined
 * @returns {undefined}
 */
(function($, window, document, undefined) {

    var cfg = {};   // keep configuration in global variable

    // -------------------------------------------------------------------------
    //
    // Plugin constructor
    //
    $.fn.mtsoftUiFullcalendar = function(params) {

        $.extend(this, actions);

        return this.each(function() {

            var $this = $(this);

            if (!$this.data('config.mtsoftUiFullcalendar') || params) {

                //
                // Initialize and save configuration for current node
                //                 
                cfg = actions.config.call($this, actions.init.call($this, params));

            } else {

                // only read configuration
                cfg = actions.config.apply($this);
            }
        });
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtsoftUiFullcalendar.defaults = {
        // settings as properties or objects
        cssPrefix: 'mtc-',
        model: null, // calendar events model
        edit: [], // Mixed; false - disable edit; to enable use array with all allowed actions (ex. ['insert' ,'update', 'delete']) 
        disableDayChangeOnEdit: false, // if true only event's begin and end time can be changed (not day) 
        deleteEventOnDblClick: false, // if true delete event on double click on it 
        ajax: false, // Mixed 
        waitmeter: true, // show waitmeter when loading or updating events
        rememberView: false, // if true remeber and restore recent calendar view set by user 
        rememberDate: false, // if true remember calendar date set by user 
        // default events colors 
        adjustTextColor: true, // automatically ajdust event text color depending on background 
        eventBackgroundColor: undefined,
        eventBorderColor: undefined,
        eventTextColor: undefined,
        // available event date/time range 
        minDate: null,
        maxDate: null,
        minTime: null,
        maxTime: null,
        singleEventPerDay: false, // if true only single event per day can be added 
        // calendar events actions urls
        url: {
            delete: null,
            insert: null,
            update: null
        },
        txt: {
            waitmeter: 'loading ...',
            defaultTitle: 'Event'
        },
        // Custom event callback functions for calendar events and days;
        // Defined:
        //      calEventEditForm - opens popup with event edit form; $this->Popup->form('{fCal<off>}EditForm', ...) MUST BE DEFINED on view 
        //      calEventAddForm - opens popup with event add form; $this->Popup->form('{fCal<off>}AddForm', ...) MUST BE DEFINED on view 
        //      calEventTooltip - show Qtip tooltip with some custom data ( @uses jquery qtip2 ) 
        // 
        onEventClick: false,
        onEventDblClick: false,
        onEventMouseOver: false,
        onDayClick: false,
        onDayDblClick: false,
        onDayMouseOver: false,
        //  defined events custom callbacks (must return true to open popups)
        onCalEventForm: function() {
            return true;
        },
        onCalEventEditForm: function() {
            return true;
        },
        //
        // tootltips
        //        
        onCalEventTooltip: function() { // custom callback
            return true;
        },
        calEventTooltip: false,
        qtip: {}    // qtip tooltip parameters  
        /*{  // calEventTooltip parameters
            url: false,  // ajax request url (must return html code to neste inside tooltip container) 
            content: function(data) {return ''}
        }*/
    }; 
    $.fn.mtsoftUiFullcalendar.def = {};




    // -------------------------------------------------------------------------
    //
    // Public actions
    //
    var actions = {
        /**
         * Get / set plugin configuration.
         *
         * @param {Mixed} param     Configuration parameter name
         *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
         *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
         *                                    All other then given in JOSN argument parameters will be keep untouch;
         *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
         *                           null - resets current configuration (remove all parameters)
         *
         * @param {Mixed} val       configuration parameter value
         * @param {Bool} update     if true update curent global configuration (cfg) object
         *
         * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
         *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
         */
        config: function(param, val, update) {

            var c = 'config.mtsoftUiFullcalendar',
                    $this = $(this), _cfg = $this.data(c);
            // reset current configuration
            if (param === null) {
                $this.data(c, null);
                return _cfg;
            }

            if (val !== undefined || typeof (param) === 'object') { // setter

                // update single parameter value
                if (val !== undefined) { // single parameter value

                    _cfg[param] = val; // assign value
                    $this.data(c, _cfg); // update

                } else {    // multiple parameters values defined as JSON object {}

                    // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                    //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                    _cfg = $.extend(true, {}, _cfg, param);
                    $this.data(c, _cfg); // update
                }
                if (update === undefined || update) { // update global configuration (cfg) object
                    cfg = _cfg;
                }
                return _cfg;
            } else { // getter

                return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
            }
        },
        /**
         * Initialize
         * 
         * @param {type} params
         * @returns {JSON} configuration object
         */
        init: function(params) {

            var $this = $(this), cfg;
            //
            // initial configuration
            //            
            cfg = $.extend(true, {}, {$this: $this, id: $this.attr('id'), FC: {}}, $.fn.mtsoftUiFullcalendar.defaults, $this.data(), $.fn.mtsoftUiFullcalendar.def[$this.attr('id')], params,
                { callbacksFC: { eventClick: function(event, jsEvent, view) {},
                        dayClick: function(date, allDay, jsEvent, view) {},
                        eventMouseover: function(event, jsEvent, view) {},
                        viewRender: function(view, element) {},
                        eventAfterRender: function(event, element, view) {}
                }});  // cfg is PLUGIN GLOBAL
            
            //
            // build Fullcalendar configuration based on cfg
            //
           
            // cfg.FC is FullCalendar format configuration object           
            // set events default colors
            cfg.FC.eventBackgroundColor = cfg.eventBackgroundColor;
            cfg.FC.eventBorderColor = cfg.eventBorderColor;
            cfg.FC.eventTextColor = cfg.eventTextColor;


            //
            // Allow INSERT of events
            //
            if ($.inArray('insert', cfg.edit) > -1) {

                cfg.FC.selectable = true;
                cfg.FC.selectHelper = true;
                // callendar date/time slot click
                cfg.FC.select = function(start, end, allDay) {

                    // check date/time inside allowed range 
                    if (_validateRange(start, end) && _validateSingleEventPerDay({start: start, end: end, id: null})) {


                        var renderEvent = function(t) {

                            cfg.$this.fullCalendar('renderEvent', {
                                id: t.data.inserted[cfg.model][0],
                                title: cfg.txt.defaultTitle,
                                start: start,
                                end: end,
                                allDay: allDay,
                                borderColor: cfg.eventBorderColor,
                                backgroundColor: cfg.eventBackgroundColor,
                                textColor: cfg.eventTextColor
                            }, false); // DON'T make the event "stick"

                            cfg.$this.fullCalendar('unselect');
                        };

                        cfg.$this.mtsoftUiFullcalendar().insert({start: start, end: end}, allDay, renderEvent);
                    } else {

                        cfg.$this.fullCalendar('unselect');
                        return false;
                    }
                };

            }

            //
            // allow UPDATE of events (edit/change start, stop times and date)
            //
            if ($.inArray('update', cfg.edit) > -1) {

                cfg.FC.editable = true;
                cfg.FC.eventDrop = function(event, dayDelta, minuteDelta, allDay, revertFunc) {

                    // disable event day changes (can be moved only on this same day) 
                    if (cfg.disableDayChangeOnEdit && dayDelta !== 0) {

                        revertFunc();
                    } else {

                        if (_validateRange(event.start, event.end) && _validateSingleEventPerDay(event)) {

                            cfg.$this.mtsoftUiFullcalendar().update(event, revertFunc, allDay);
                        } else {

                            revertFunc();
                            return false;
                        }
                    }
                };
                // editable - resize 
                cfg.FC.eventResize = function(event, dayDelta, minuteDelta, revertFunc, jsEvent, ui, view) {

                    cfg.$this.mtsoftUiFullcalendar().update(event, revertFunc);
                };
            }


            //
            // allow DELETE of events
            //
            if ($.inArray('delete', cfg.edit) > -1) {

                if (cfg.deleteEventOnDblClick) {

                    //var func = cfg.callbacksFC.eventAfterRender;
                    cfg.callbacksFC.eventAfterRender = function(event, element, view) {

                        //func(event, element, view);
                        element.on('dblclick.mtsoftUiFullCalendar', function() {

                            cfg.$this.mtsoftUiFullcalendar().delete(event);
                        });
                    };
                }
            }
            
            //
            // use tooltip to view calendar events details 
            //
            if (cfg.calEventTooltip) {

                cfg._tooltipApi = $('<div/>').qtip($.extend(true, {}, {
                    id: cfg.$this.attr('id'),
                    prerender: true,
                    content: {
                        text: ' ',
                        title: {
                            button: true
                        }
                    },
                    position: {
                        my: 'bottom center',
                        at: 'top center',
                        target: 'mouse',
                        effect: true,
                        //viewport: cfg.$this,
                        adjust: {
                            mouse: false,
                            scroll: false
                        }
                    },
                    show: false,
                    hide: false,
                    style: {
                        // tip (arrows) style 
                        tip: {                            
                            width: 16,
                            height: 10
                        }
                    }
                }, cfg.qtip)).qtip('api');
 
                /*cfg.FC.dayClick = function() { tooltip.hide() };
		cfg.FC.eventResizeStart = function() { tooltip.hide() };
		cfg.FC.eventDragStart = function() { tooltip.hide() };
		cfg.FC.viewDisplay = function() { tooltip.hide() };*/
            }

            //
            // read events using ajax 
            //
            if (cfg.ajax) {

                if (cfg.waitmeter) {
                    cfg.FC.loading = function(isLoading, view) {

                        if (isLoading) {
                            $.mtsoft.ui.waitMeter(true, cfg.txt.waitmeter); //, typeof (config.waitmeter) === 'string' ? config.waitmeter : false
                            //$('#fCal0').mtsoftUiLock().on();
                        } else {
                            $.mtsoft.ui.waitMeter(false); //, status === 'abort' ? true : undefined
                            //$('#fCal0').mtsoftUiLock().off();
                        }
                    };
                }
            }

            //
            // Adjust text color if light backgorund (to be readable) 
            //
            if (cfg.adjustTextColor) {

                var func = cfg.callbacksFC.eventAfterRender;
                cfg.callbacksFC.eventAfterRender = function(event, element, view) {

                    func(event, element, view);

                    $(element).each(function() {

                        var bgColor = $(this).css("backgroundColor");

                        if (!_isDarkColor(bgColor)) {
                            $(this).find(".fc-event-time, .fc-event-title").css({color: _colorLuminance(_rgbToHex(bgColor), -0.2)});
                            //.addClass("fc-event-dark-text")
                        }
                    });
                };
            }
            
            
            //
            // Restore/remember calendar view 
            //
            if (cfg.rememberView) { 
                
                var storageId = 'mtsoft.ui.fullcalendar.'+$this.attr('id')+'.view';
                
                // restore recent calendar view if defined 
                var recentView = $.jStorage.get(storageId);
                if (recentView) { 
                    //cfg.FC.defaultView = recentView;  // don't works with rememberDate 
                    // Therefore we have to set it using below method (after FUllClaendar initialization) 
                    window.setTimeout(function() {
                         $this.fullCalendar('changeView', recentView);
                    }, 10);
                    // check for version 2.0 ?
                }
                
                // remeber calendar view (day,week,monht,...) once user has changed it 
                cfg.callbacksFC.viewRender = function(view, element) {
                    
                    //cfg.callbacksFC.viewRender.apply(this, arguments);
                    $.jStorage.set(storageId, view.name);
                    //$.jStorage.set('mtsoft.ui.fullcalendar.'+$this.attr('id')+'.date', $.fullCalendar.formatDate(view.start, "yyyy-MM-dd"));
                };
            }
                           
            //
            // Restore/remember calendar date
            //
            if (cfg.rememberDate) {
                
                var storageId = 'mtsoft.ui.fullcalendar.'+$this.attr('id')+'.date';
                // restore recent calendar date if defined 
                var recentDate = $.jStorage.get(storageId);
                if (recentDate) { 

                    cfg.FC.year = recentDate.substring(0,4);
                    cfg.FC.month = parseInt(recentDate.substring(5,7)) - 1; // month -1 (january = 0) 
                    cfg.FC.date = recentDate.substring(8,10); // day
                    // ver 2.0 - use cfg.FC.defaultDate 
                }
                
                // remember calendar date (start) once user has changed it 
                cfg.callbacksFC.viewRender = function(view, element) {                  
                    
                    //cfg.callbacksFC.viewRender.apply(this, arguments);
                    $.jStorage.set('mtsoft.ui.fullcalendar.'+$this.attr('id')+'.view', view.name);
                    $.jStorage.set(storageId, $.fullCalendar.formatDate(view.start, "yyyy-MM-dd"));
                };                
            }
         

            //
            // Assign calendar events and days callbacks
            //
            var evnts = ['onEventClick', 'onEventDblClick', 'onEventMosueOver', 'onDayClick']; // , 'onDayDblClick', 'onDayMosueOver'
            for (var i in evnts) {
                if (cfg[evnts[i]]) {
                    _assignCallback(cfg, evnts[i]);
                }
            }


            // update configuration 
            actions.config.call(this, cfg);

            // concact and assign Fullclanedar callbacks
            for (var cb in cfg.callbacksFC) {

                cfg.FC[cb] = cfg.callbacksFC[cb];
            }

            //
            // Calendar controls
            //
            cfg.$controls = $('[data-fc-' + cfg.id + ']');
            // on control value change - re-load events on calendar
            cfg.$controls.on('change.mtsoftUiCalendar', function() {
                actions.refresh();
            });


            // set source of events as ajax url and assign callback function which 
            // will include all asgined to calendar control inputs values
            var getParams = function() {

                var r = {};
                
                // collect all assinged to calendar control inputs and their values
                cfg.$controls.each(function() {

                    if ($(this).attr('type') !== 'radio') {
                        r[$(this).attr('id')] = $(this).val();
                    } else { // radio - special case 

                        var $self = $(this);
                        $(this).each(function() {
                            if ($(this).is(":checked")) {
                                r[$self.attr('id').replace($(this).val(), '')] = $(this).val();
                                return;
                            }
                            ;
                        });
                    }
                });
                return r;

            }, evntSrc = {};

            if (typeof ($.FCdef[$this.attr('id')].events) === 'object') {

                if ($.FCdef[$this.attr('id')].events instanceof Array) {
                    evntSrc = $.extend(true, [], $.FCdef[$this.attr('id')].events); // view variable; [] - events must be defiend as ARRAY (not Json object) 
                } else {
                    evntSrc = $.extend(true, {}, $.FCdef[$this.attr('id')].events); // ajax request 
                }
                
                evntSrc.data = getParams;
            } else {
                evntSrc = {url: $.FCdef[$this.attr('id')].events, data: getParams};
            }
   
                        
            // update right events source details
            $.FCdef[$this.attr('id')].events = evntSrc;

            // append FullCalendar custom settings from global $.FCdef             
            cfg.FC = $.extend(true, cfg.FC, ($.FCdef ? $.FCdef[$this.attr('id')] : {}));


            // run Fullcalendar
            $this.fullCalendar(cfg.FC);

            return cfg;
        },
        //
        // Full calendar related functions / helpers
        //

        /**
         * Refresh/re-read all events on currently viewed calendar
         * @returns {undefined}
         */
        refresh: function() {

            // remove ALL events
            //cfg.$this.fullCalendar('removeEvents'); 
            // re-load events on calendar
            cfg.$this.fullCalendar('refetchEvents');
        },
        insert: function(event, allDay, successCallback) {

            $.mtsoft.ui.ajax(cfg.url.insert, {
                data: _covPostData(event, allDay),
                waitmeter: true,
                //showWaitStatus: 'loading...',
                onResultSuccess: function(data, textStatus, jqXHR) {
                    try {
                        successCallback(data);
                    } catch (e) {
                    }
                },
                onComplete: function(jqXHR, textStatus) {
                },
                onResultFailed: function(data, textStatus, jqXHR) {
                },
                onError: function(data, textStatus, jqXHR) {
                }
            }, {type: 'POST'});
        },
        update: function(event, revertFunc, allDay) {


            $.mtsoft.ui.ajax(cfg.url.update + '/' + event.id, {
                data: _covPostData(event, allDay),
                waitmeter: true,
                //showWaitStatus: 'loading...',
                onResultSuccess: function(data, textStatus, jqXHR) {
                },
                onComplete: function(jqXHR, textStatus) {
                },
                onResultFailed: function(data, textStatus, jqXHR) {
                    revertFunc();
                },
                onError: function(data, textStatus, jqXHR) {
                    revertFunc();
                }
            }, {type: 'POST'});
        },
        delete: function(event) {

            if (event.id && event.id > 0) {

                $.mtsoft.ui.ajax(cfg.url.delete + '/' + event.id, {
                    waitmeter: true,
                    onResultSuccess: function(data, textStatus, jqXHR) {

                        cfg.$this.fullCalendar('removeEvents', event.id);
                    },
                    onComplete: function(jqXHR, textStatus) {
                    }
                }, {type: 'POST'});
            }
        }
    };

    /**
     * Convert Fullcalendar event data to POST data db table data 
     * 
     * @param {Object} event    Fullcalendar event object
     * @param {Bool} allDay     if true event is all day event (no end date obejct defined)  
     * @param {JSON} map        map - allows to define custom db table field names for date, time_start and time_stop 
     */
    function _covPostData(event, allDay, map) {

        if (map === undefined) {
            map = {
                date: 'date',
                time_start: 'time_start',
                time_stop: 'time_stop'
            };
        }

        // convert FullCalendar event data to data usable on server side 
        // When moving from allDay type event end hour is empty. 
        // By defaut we set end hour to begin hour + 2 hours (default event size on Fullcalendar) 
        var timeEnd = $.fullCalendar.formatDate(event.end, "HH:mm:00");
        if (timeEnd === '') {
            var defaultEventEnd = new Date(event.start).add(2).hours(), timeStop = $.fullCalendar.formatDate(defaultEventEnd, "HH:mm:00");
            // update orginal event end on Fullcalendar 
            event.end = defaultEventEnd;
            cfg.$this.fullCalendar('updateEvent', event);
        } else {
            timeStop = timeEnd; // end time given by event object 
        }

        var r = {};
        r[cfg.model] = {id: event.id ? event.id : undefined};
        r[cfg.model][map.date] = $.fullCalendar.formatDate(event.start, "yyyy-MM-dd");
        r[cfg.model][map.time_start] = allDay ? '' : $.fullCalendar.formatDate(event.start, "HH:mm:00");
        r[cfg.model][map.time_stop] = allDay ? '' : timeStop;

        return r;
    }    

    /**
     * Check given start and end dates are in allowed range
     * @param {type} start
     * @param {type} end
     * @returns {Boolean}
     */
    function _validateRange(start, end) {

        var result = true,
                startYMD = $.fullCalendar.formatDate(start, 'yyyy-MM-dd')
                , startTime = $.fullCalendar.formatDate(start, 'HH:mm:ss')
                , _stopYMD = $.fullCalendar.formatDate(end, 'yyyy-MM-dd')
                , _stopTime = $.fullCalendar.formatDate(end, 'HH:mm:ss')
                , stopYMD = _stopYMD !== null ? _stopYMD : startYMD
                , stopTime = _stopTime !== null ? _stopTime : startTime;

        // check new event is in allowed date/time range 
        if (cfg.minDate && startYMD < cfg.minDate) {

            result = false;
        }
        if (cfg.maxDate && stopYMD > cfg.maxDate) {

            result = false;
        }
        if (cfg.minTime && startTime < cfg.minTime) {

            result = false;
        }
        if (cfg.maxTime && stopTime > cfg.maxTime) {

            result = false;
        }

        return result;
    }

    /**
     * Check given event is only one on given day
     * 
     * @param {type} event
     * @returns {Boolean}
     */
    function _validateSingleEventPerDay(event) {

        if (cfg.singleEventPerDay) {
            // check is there already event defined in this day.
            // If so - unselect and cancel new event adding. 
            var evs = cfg.$this.fullCalendar('clientEvents'),
                    startYMD = $.fullCalendar.formatDate(event.start, 'yyyy-MM-dd');
            for (var i in evs) {

                if ($.fullCalendar.formatDate(evs[i].start, 'yyyy-MM-dd') === startYMD && event.id !== evs[i].id) {

                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Adssign callback function to calendar 
     * 
     * @param {JSON} cfg
     * @param {String}  eventType       Event name for calendar event/day callback 
     * 
     * @param {Mixed} callback      String - standard callback method name
     *                               Function - defiend callback
     *                               undefiend - use defined callback equal to eventType name 
     * @returns {undefined}
     */
    function _assignCallback(cfg, eventType, callback) {

        if (callback === undefined || eventTypeof(callback) !== 'function') {

            if (callback === undefined) {

                callback = cfg[eventType];
            }

            // use defined callback
            switch (callback) {

                //
                // ADD EVENT form
                //
                case 'calEventAddForm':

                    callback = function(date, allDay, jsEvent, view) {

                        // try to reprint date to right model/field input
                        // For single view we have to use different model names for Add/Edit/other forms 
                        var $n = $('[name="data[' + cfg.model + 'Add][date]"]');
                        if (!$n.length) {
                            $n = $('[name="data[' + cfg.model + 'Edit][date]"]');
                            if (!$n.length) {
                                $n = $('[name="data[' + cfg.model + '][date]"]');
                            }
                        }
                        // set date value for date input 
                        if ($n.length) {
                            $n.mtsoftUiInputSetValue($.fullCalendar.formatDate(date, "yyyy-MM-dd"));
                        }

                        var r = true;
                        try {
                            
                            r = cfg.onCalEventAddForm(date, allDay, jsEvent, view);
                            
                            if (r) {
                                $('#pop' + cfg.id + 'AddForm').mtsoftUiPopup().open(date);
                            }
                            
                        } catch (e) {
                            
                            console.log(e);
                        }

                    };
                    break;

                    //
                    // EDIT EVENT form 
                    //
                case 'calEventEditForm':

                    callback = function(event, element, view) {

                        // reprint event model data to edit form 
                        if (event.data) {

                            for (var model in event.data) {
                                for (var field in event.data[model]) {

                                    var $n = $('[name="data[' + model + '][' + field + ']"]');
                                    if ($n.length) {

                                        $n.mtsoftUiInputSetValue(event.data[model][field]);
                                    }
                                }
                            }
                        }

                        var r = true;
                        try {
                            
                            // execute 
                            r = cfg.onCalEventEditForm(event, element, view);

                            if (r) {

                                $('#pop' + cfg.id + 'EditForm').mtsoftUiPopup({onClose: function(transfer) {
                                        
                                    // on event delete 
                                    if (transfer && transfer.data.deleted[cfg.model] && transfer.data.deleted[cfg.model].length) {
                                        cfg.$this.fullCalendar('removeEvents', event.id);
                                    }
                                    
                                }}).open(event);
                            }
                            
                        } catch (e) {
                            
                            console.log(e);
                        }

                    };
                    break;

                    //
                    // EVENT tooltip 
                    //
                case 'calEventTooltip':

                    callback = function(data, event, view) {
                        
                        // execute 
                        var r;
                        r = cfg.onCalEventTooltip(data, event, view);
                        
                        if (r) {
                            
                            var content;
                            if (cfg.calEventTooltip.url) {
                                
                                // use ajax request to load content
                                content = function(event, api) {
                                    $.ajax({url: cfg.calEventTooltip.url+'/'+data.id+'/'+$.fullCalendar.formatDate(data.start, "yyyy-MM-dd")+'/'+$.fullCalendar.formatDate(data.start, "HHmmss")})
                                        .done(function(html) {
                                            cfg._tooltipApi.set('content.text', html);
                                        })
                                        .fail(function(xhr, status, error) {
                                            cfg._tooltipApi.set('content.text', status + ': ' + error);
                                        });

                                    return cfg.txt.waitmeter;
                                };
                                
                            } else {
                                
                                if (cfg.calEventTooltip.content) {
                                    
                                    content = cfg.calEventTooltip.content(data);
                                }
                            }
                            
                            cfg._tooltipApi.set({'position.target': $(event.target).parent(), 'content.text': content}).reposition(event).show(event);
                        }
                        
                        return r;
                    };
                    break;
            }
        }

        // assign to right Fullcalendar event or create custom event handler 
        switch (eventType) {

            case 'onEventClick':
                
                var func = cfg.callbacksFC.eventClick;
                cfg.callbacksFC.eventClick = function(event, jsEvent, view) {

                    func(event, jsEvent, view);
                    callback(event, jsEvent, view);
                };

                break;
            case 'onEventDblClick':

                var func = cfg.callbacksFC.eventAfterRender;
                cfg.callbacksFC.eventAfterRender = function(event, element, view) {

                    func(event, element, view);
                    element.on('dblclick.mtsoftUiFullCalendar', function() {
                        callback(event, element, view);
                    });
                };

                break;
            case 'onEventMouseOver':

                var func = cfg.callbacksFC.eventMouseover;
                cfg.callbacksFC.eventMouseover = function(event, jsEvent, view) {

                    func(event, jsEvent, view);
                    callback(event, jsEvent, view);
                };
                break;
            case 'onDayClick':

                var func = cfg.callbacksFC.dayClick;
                cfg.callbacksFC.dayClick = function(date, allDay, jsEvent, view) {

                    func(date, allDay, jsEvent, view);
                    callback(date, allDay, jsEvent, view);
                };

                break;
                /*case 'onDayDblClick':
                 break;
                 case 'onDayMouseOver':
                 break;
                 */
        }                
    }

    /**
     * Assert color is light or dark; 
     * useable to set text color on dynamic background
     */
    function _isDarkColor(color) {

        var match = (/rgb\((\d+).*?(\d+).*?(\d+)\)/).exec(color);
        return ((match[1] & 255) + (match[2] & 255) + (match[3] & 255) < (3 * (256 / 1.7)));
        // /1.7 is experimental indicator
    }

    /**
     * Make color lighter/ darker
     * @param {String} hex
     * @param {in} lum          ex. 0.1 (lighter 10%) , -0.5 (darker 50%) 
     * @returns {String}
     */
    function _colorLuminance(hex, lum) {

        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }

        return rgb;
    }

    /**
     * Convert color from RGB to HEX.
     * 
     * @param {Mixed} r     Int (if g and b given as int too) or rgb color expressions (ex. 'rgb(51, 204, 255)')
     * @param {Int} g
     * @param {Int} b
     * @returns {String}
     */
    function _rgbToHex(r, g, b) {

        if (typeof (r) === 'string') {
            var match = (/rgb\((\d+).*?(\d+).*?(\d+)\)/).exec(r);
            r = match[1];
            g = match[2];
            b = match[3];
        }

        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }

})(jQuery, window, document);