/**
 * Common set of JS actions to be executed on each page load
 */

// on jQuery document ready
$(document).ready(function($) {

    // refresh window content after session timed out
    $.mtsoft.refreshSession();

    // use foundation framework
    $(document).foundation();
    
    // for schedule zapbox widget; equalizer content is placed on tab; it must be re-int once foundation tabs loaded (above)
    try { Foundation.reInit('equalizer'); } catch (err) {} 
    
    // lazyload of images 
    //$.mtsoft.ui.lazyload();    

    // init mtsoft UI
    $.mtsoft.ui.init();  
    
    try { $('.el-sticky').mtsoftUiSticky(); } catch (err) {} // .sticky class name mixes with foundation sticky 
    $('.menu').mtsoftUiSelectMenuItem(); // select current url on top bar menu     
    
    // top-bar megamenu init 
    $.mtsoft.ui.megamenu.init();
    // search-box - focus when visible
    $('[data-megamenu="serchbox_global"]').on('mouseenter', function() {
        $('#ArchiveQ9Ico').focus();
    });
    
    
    // Featured shows dotdotdot
    //$(".featured-shows .fs-text").dotdotdot({height: 77});
    //$(".ss-text").dotdotdot();    

    //$('.top-bar-container').css({overflow: 'visible'});
    $.mtsoft.ui.lazyload();
    
    // CMS - custom category listing 
    $('.list-box-row h2').dotdotdot({height: 72}); // 72px ~ three lines
    $(window).on('resize', function(){
        $('.list-box-row h2').dotdotdot({height: 72}); // 72px ~ three lines
    });
    
    $('[data-trunc]').mtsoftUiReadMore(); // "read more ..."
    $.mtsoft.ui.loaded();
});