/**
 * Foundation top-abr megamenu extensions
 * 
 * (c) 2013 mtsoft.pl
 *
 * Definition:
 *
 * 1.) Define megamenu item on menu items add (jade):
 *
 # define item with mega menu
 li.has-megamenu(data-megamenu="megaMenuId") 
 a(href = "/mega-menu-contents-full-page.html") Mega Menu label 
 
 *
 * 2.) Define megamenu body (jade) directly after menu <nav> code
 *
 # define mega-menus
 .row.megamenus
 
 # full grid width mega-menu (fluid width)
 .small-12.columns.small-centered.megamenu(id="megaMenuId") 
 
 # constant width mega-menu (will be repositioned to be placed below menu item)
 # class .megamenu-const must be added and 'width' style property must be defined
 small-12.columns.megamenu.megamenu-const(id="megaMenuId2", style="width:480px;")
 
 *
 */


$.mtsoft.ui.megamenu = {
    //
    // settings
    //
    triggerOn: 'mouseenter', // [ mouseenter | click ] show megamenu on this event (executed on menu item)
    triggerOff: 'mouseleave', // [ mouseleave | click ] hide megamenu on this event (executed on menu item)
    animOn: [null, 'normal', 'swing'], // [ type | null, duration, timing ] transition on open, null - none
    animOff: [null, 'normal', 'swing'], //  [ type | null, duration, timing ] transition on close, null - none
    callbackOn: function () {
    }, // callback function to execute on megamenu open; can be used to read megamenu html via ajax requrest

    delay: 0, // [s] - delay when showing megamenu; 0 for none
    timeout: 3, // [s] - close megamenu after this time (after user moves mouse out of megamenu); 0 for none
    menuItemHoverClass: 'has-megamenu-hover', // this class will be used to style menu item until meagamenu is shown
    /**
     * Init mega-menu
     */
    init: function () {

        var self = this;
        
        // on hamburger menu icon click (only for small screens) re-position megamenu;
        // this fixes problem with mega-menu height on small menu
        //$('.top-bar-title').children('.top-bar-btn').on('click', function() {
        var relocateMM = function($n) {
            var $this = $n, $mmParents = $this.parents('.top-bar').filter(':first').find('[data-megamenu]');
            
            $mmParents.each(function(){
                var $mm = $('#' + $(this).attr('data-megamenu')); // mm element
                
                $mm.hide().css({position: 'static'});
                $(this).find('ul.dropdown').append($mm);
                $mm.show();
            });
        };
        
        $('.top-bar-btn').each(function() {            
            relocateMM($(this)); 
        });
        $('.top-bar-btn').on('click', function() {
            relocateMM($(this)); 
        });
        
        $('.has-megamenu').each(function () {    // each mega-menu related menu item

            var $this = $(this), mm = $('#' + $(this).attr('data-megamenu'));
            mm.isFocused = false;
            mm.data('menuItem', $this); // remember which menu item trigged megamenu
            mm.inputs = mm.find('input, textarea');
            mm.inputs.on('focus', function(){
                mm.isFocused = true;
            }).on('blur', function(){
                mm.isFocused = false;
            });

            // show megamenu
            $(this).on(self.triggerOn, function () {

                if (!self.isMobileView($(this))) {
                    
                    // re-locate megamenu
                    //var $n = $(document).find('.megamenus').children().filter(':first');
                    var $n = $(document).find('.megamenus');
                    $n.append(mm);                    
                    
                    self.resetTimeouts();

                    // Re-position menu (if not full grid width, but fixed width mode).
                    // To assign meagamenu width constant width:
                    //  1.) add class .megamenu-const (to .megamenu class)
                    //  2.) define constant width
                    if (mm.hasClass('megamenu-const')) {

                        // get initial megamenu width
                        var mmsw = $(".megamenus").innerWidth(), // all megamenus container width
                                mmw = mm.innerWidth(), // current megamenu container width
                                mila = $(this).offset().left, // menu item (with megamenu assigned) absolute left offset
                                milr = $(this).position().left;    // menu item (with megamenu assigned) RELATIVE left offset

                        var left = mila;
                        if (milr + mmw > mmsw) {
                            // meagamenu don't fit - move left
                            left = mila - (milr + mmw - mmsw);
                        }

                        // set/correct megamenu horizontal position (but not less than zero)
                        mm.css({left: left > 0 ? left : 0, marginLeft: 0});
                    }

                    // if top-bar works in fixed mode
                    //var tbo = $(this).parents('.top-bar-out').filter(':first');
                    var tbo = $(this).parents('.top-bar').filter(':first');
                    
                    if (tbo.css('position') === 'fixed') {
                        // correct position and make megamenu full page width
                        //mm.css({position: "fixed", top: tbo.innerHeight()});
                        mm.css({position: "fixed", top: $(this).innerHeight()});
                    } else {
                        //mm.css({position: "absolute", top: 'auto'});                      
                        mm.css({position: "fixed", top: ($(this).offset().top - $(window).scrollTop()) + $(this).innerHeight()});                      
                    }
                    //mm.css({position: "fixed", top: $(this).offset().top+ $(this).outerHeight()});
                    
                    // ---


                    if (mm.css('display') === 'none') {

                        if (self.delay > 0) {

                            self.toDelay = window.setTimeout(function () {

                                self.resetTimeouts();
                                self.show(mm);

                            }, self.delay * 1000);

                        } else {

                            self.show(mm);
                        }

                        // exectue on open callback
                        try {
                            callbackOn();
                        } catch (e) {
                        }
                    } else {

                        mm.stop(true); // stop effect and clear quene
                        self.show(mm);
                    }
                } else {
                    
                    // re-locate megamenu (put it inside drop-down menu ul) 
                    /*mm.hide().css({position: 'static'}); // hide & static IS A MUST!!!!
                    //mm.css({position: 'initial'}); // IS A MUST!!!!
                    //if (!$(this).find('ul.dropdown').children('.megamenu').length) { // only if not laready added 
                        $(this).find('ul.dropdown').append(mm);
                        //$(this).find('ul.dropdown').append('<li class="mm-out"></li>');
                        //$(this).find('ul.dropdown > li.mm-out').append(mm);
                    //}
                    mm.show();*/                    
                    $('.top-bar-btn').each(function() {            
                        relocateMM($(this)); 
                    });
                }
                               
                var clickOnClose = function() {
                    $(document).one('click', function(event) {

                        if (!$(event.target).parents('.megamenu').length) {
                            self.hide(mm);
                        } else { // postpone on next click 
                            clickOnClose();
                        }
                    }).one('keyup', function(e) {
                        if (e.keyCode === 27) { self.hide(mm); }   // esc
                    });
                };
                clickOnClose();

            })

            // hide megamenu
            .on(self.triggerOff, function () {

                if (!self.isMobileView($(this))) {
                    self.toClose = window.setTimeout(function () {

                        self.hide(mm);
                    }, self.timeout * 1000);
                }
            })
            
            // close megamenu imediatelly after user moves cursor over other menu item(s)
            //.siblings('li').on(self.triggerOn, function () {
            .parents('.top-bar-body').filter(':first').find('li').not($this).on(self.triggerOn, function () {

                if (!self.isMobileView($(this))) {
                    self.resetTimeouts();
                    mm.stop(true);
                    self.hide(mm, true);
                }
            });

            // handle megamenu (leave open until user moves cursor out of megamenu) 
            mm.on('mouseenter', function () {

                if (!self.isMobileView($(this))) {  
                    //$(this).stop(true).hide().show();   // hide to reset animation  (if any) 
                    self.resetTimeouts();
                }

            }).on('mouseleave', function () {
                
                if (!mm.isFocused) {
                    if (!self.isMobileView($(this))) {
                        self.toClose = window.setTimeout(function () {

                            self.hide(mm);
                        }, self.timeout * 1000);
                    }
                }
            });
        });
    },
    /**
     * Show megamenu
     * @param {Object} mm   meagamenu jquery node
     */
    show: function (mm) {

        // close imediatelly all/other megamenus
        $('.megamenu').hide();

        if (this.animOn) {

            var a = this.animOn;
            if (a[0]) {
                mm.hide()[a[0]](a[1], a[2]);
            } else {
                mm.show();
            }
        } else {

            mm.show();
        }

        // close megamenu on Esc hit - bind event
        var self = this;
        $(document).on('keypress', function (e) {

            if (e.keyCode === 27) {

                self.hide(mm, true);
            }
        });


        // stylize menu item
        mm.data('menuItem').addClass(this.menuItemHoverClass);
    },
    /**
     * Hide megamenu
     * @param {Object} mm   meagamenu jquery node
     * @param {boolean} immediatelly     if true just hide immediatelly (don't animate)
     */
    hide: function (mm, immediatelly) {

        immediatelly = immediatelly || false;

        if (this.animOff && !immediatelly) {

            var a = this.animOff, self = this;
            if (a[0]) {
                mm[a[0]](a[1], a[2], function () {

                    // stylize menu item
                    mm.data('menuItem').removeClass(self.menuItemHoverClass);
                });
            } else {
                mm.hide();
            }
        } else {

            mm.hide();
            // stylize menu item
            mm.data('menuItem').removeClass(this.menuItemHoverClass);
        }

        // unbind Esc hit
        $(document).off('keypress');

    },
    /**
     * Reset all mega-menu related timeouts & animations
     *
     */
    resetTimeouts: function () {

        clearTimeout(this.toDelay);
        clearTimeout(this.toClose);
    },
    /**
     * Is menu of current item in mobile view mode ?
     *
     * @param {Object} item   jQuery menu item (li)
     *
     * @returns Boolean   true - yes, false - no
     */
    isMobileView: function (item) {

        //if (item.parents('nav').filter(':first').hasClass('expanded')) {
        if (item.parents('.top-bar').filter(':first').find('.top-bar-title').children('.top-bar-btn').is(':visible')) {

            return true;
        } else {
            
            return false;
        }
    }
};