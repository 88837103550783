/**
 * Box various types definitions 
 * 
 * (c) 2014 mtsoft.pl
 * 
 * @uses iScroll
 * @uses lazyload ($.mtsoft.ui.lazyload) 
 * @uses: dotdotdot 
 */
;
(function ($) {

    var cfg = {};   // keep configuration in global variable

    // -------------------------------------------------------------------------
    //
    // Plugin constructor
    //
    $.fn.mtsoftUiBox = function (params) {

        $.extend(this, actions);

        return this.each(function () {

            var $this = $(this);

            if (!$this.data('config.mtsoftUiBox') || params) {

                //
                // Initialize and save configuration for current node
                //                 
                cfg = actions.config.call($this, actions.init.call($this, params));

            } else {

                // only read configuration
                cfg = actions.config.apply($this);
            }
        });
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtsoftUiBox.defaults = {
        iScroll: {},  // use iScroll to scroll 
        lazyload: {},    // use lazyload to defer load of images 
        dotdotdot: {}   // use dotdotdot to limit number of characters on fluid containers
    };
    $.fn.mtsoftUiBox.def = {};




    // -------------------------------------------------------------------------
    //
    // Public actions
    //
    var actions = {
        /**
         * Get / set plugin configuration.
         *
         * @param {Mixed} param     Configuration parameter name
         *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
         *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
         *                                    All other then given in JOSN argument parameters will be keep untouch;
         *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
         *                           null - resets current configuration (remove all parameters)
         *
         * @param {Mixed} val       configuration parameter value
         * @param {Bool} update     if true update curent global configuration (cfg) object
         *
         * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
         *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
         */
        config: function (param, val, update) {

            var c = 'config.mtsoftUiBox',
                    $this = $(this), _cfg = $this.data(c) || {};
            // reset current configuration
            if (param === null) {
                $this.data(c, null);
                return _cfg;
            }

            if (val !== undefined || typeof (param) === 'object') { // setter

                // update single parameter value
                if (val !== undefined) { // single parameter value

                    _cfg[param] = val; // assign value
                    $this.data(c, _cfg); // update

                } else {    // multiple parameters values defined as JSON object {}

                    // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                    //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                    _cfg = $.extend(true, {}, _cfg, param);
                    $this.data(c, _cfg); // update
                }
                if (update === undefined || update) { // update global configuration (cfg) object
                    cfg = _cfg;
                }
                return _cfg;
            } else { // getter

                return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
            }
        },
        /**
         * Initialize
         * 
         * @param {type} params
         * @returns {JSON} configuration object
         */
        init: function (params) {

            var $this = $(this); // don't define here cfg bacuse is GLOBAL in this name space            
            //
            // initial configuration
            //
            cfg = $.extend(true, {}, {$this: $this},
            $.fn.mtsoftUiBox.defaults,
                    $this.data('mtsoft-ui-box'),
                    $.fn.mtsoftUiBox.def[$this.attr('id')],
                    params);  // cfg is PLUGIN GLOBAL

            //
            // Depending on box type do right box definition
            //
            var _defiScroll = function($list, type) {
                switch (type) {

                    case 'scrollHorizontal':
                        _defineScrollHorizontal($list);
                        break;

                    case 'scrollVertical':
                        _defineScrollVertical($list);
                        break;
                }
            };
            _defiScroll(cfg.$this, cfg.type);
            
            //
            // If box not visible initally (inside tabs) initalize it on tab click
            //
            // Foundation tabs used 
            var $tabContainer = cfg.$this.parents('.content').filter(':first');
            if ($tabContainer.length) {
                
                // find tab for this content 
                var tabId = $tabContainer.attr('id'), $tab = $('[href="#'+tabId+'"]');
                
                
                var $box = cfg.$this, _type = cfg.type;                
                $tab.on('click.mtsoftUiBox', function(){
                    
                    _defiScroll($box, _type);
                    $(window).resize(); // init lazyload 
                });
            }

            //
            // attach event handlers
            //
            // always use click anyway (for touch devices)
            /*cfg.$this.on('eventName.mtsoftUiBox', function(e) {
             
             // execute some public action (reads configuration for current($this) node)
             actions.actionName.apply(this, [{param: value}, e]);
             });*/

            //
            // set other initial values
            //

            return cfg;
        }

        /**
         * Sample public action
         * 
         * @param {type} params
         * @param {type} e
         */
        /*actionName: function(params, e) {
         
         // variables:
         // this = $(this) => jQuery node object action is executed for
         
         // execute other public action (for current node configuration)
         actions.actionNameOther.apply(this, [params, e]);
         
         // execute private function
         privateMethod(params);
         
         // update configuration for current node (false parameter is important!)
         cfg.$this.mtsoftUiBox(false).config(paramName, 'value');
         
         // ALWAYS return node to keep chain
         return this;
         }*/
        // ...
    };


    // -------------------------------------------------------------------------
    //
    // define private functions (internal use only)
    //

    /**
     * Sample private method
     */
    function _defineScrollHorizontal($list) {

        // init episodes list iScroll
        
        var $ul = $list.children('ul'), $li = $ul.children('li');        
        $ul.width($li.length * $li.outerWidth(true));   // + outer padding?  + parseInt($ul.css('paddingLeft')
        _iScrollLazyload($list, 'horizontal');
    }
    
    function _defineScrollVertical($list) {

        // init episodes list iScroll
        
        //var $ul = cfg.$this.children('ul'), $li = $ul.children('li');        
        //$ul.width($li.length * $li.outerWidth(true));   // + outer padding?  + parseInt($ul.css('paddingLeft')
        _iScrollLazyload($list, 'vertical');
    }

    function _iScrollLazyload($list, type, force) {

        
        if (cfg.dotdotdot && type === 'horizontal') {
            
            try {
                $list.children('ul').children('li').dotdotdot(cfg.dotrdotdot);
            } catch(e) {}
        }


        if (cfg.iScroll) {
            
            // NOTE: iScoll requires that element to be scrolled MUST be visible 
            // In case when user don't waits until tab contents loads
            // show list element temporarly to iScroll work properly 
            var wasShown = true;
            if (!$list.is(":visible")) {

                $list.show();
                wasShown = false;
            }

            if ($list.data('iScroll') && force === undefined) {

                // alreay assigned - only refresh 
                $list.data('iScroll').refresh();
            } else {

                var params = {};
                switch (type) {

                    default:
                    case undefined:
                    case 'horizontal':

                        params = {
                            scrollbars: 'custom',
                            //scrollbars: true,
                            mouseWheel: true,
                            interactiveScrollbars: true,
                            resizeScrollbars: true,
                            //snap: 'li',
                            scrollX: true,
                            scrollY: false,
                            eventPassthrough: true // touch devices - allows to swipe vertically whole apge and swipe jhorizontally only box contents
                            //,fadeScrollbars: true
                                    //bindToWrapper: true                                
                                    //,shrinkScrollbars: false                
                        };
                        break;

                    case 'vertical':

                        params = {
                            scrollbars: 'custom',
                            //scrollbars: true,
                            mouseWheel: true,
                            interactiveScrollbars: true,
                            resizeScrollbars: true,
                            //snap: 'li',
                            scrollX: false,
                            scrollY: true
                            //,fadeScrollbars: true
                                    //bindToWrapper: true                                
                                    //,shrinkScrollbars: false                
                        };
                        break;
                }

                try {

                    // define                 
                    var iScroll = new IScroll($list[0], $.extend(true, {}, params, cfg.iScroll));
                    $list.data('iScroll', iScroll).addClass('iScrollOn');

                } catch (e) {

                    // iScorll not available - use native vertical scroll 
                    //$list.css({overflowX: 'scroll'});
                }
            }

            if (!wasShown) {
                //$list.hide();
            }
        }
        
        if (cfg.lazyload) { 

            try {
                
                
                // init lazyload 
                $.mtsoft.ui.lazyload($.extend(true, {}, cfg.lazyload, {$container: $list}));
                
                // to run lazyload after scroll
                iScroll.on('scrollEnd', function() {                    
                    $(window).resize();
                });
            } catch(e) {}
        }
        
        return $list;
    }
    // ...

    // -------------------------------------------------------------------------
    //
    // define even handlers common for all instances (for window/document, etc...)
    //
    /* $(window).on('resize.mtsoftUiBox', function(e) {
     actions.actionName.apply($(this), [e]);
     });*/

    // -------------------------------------------------------------------------
    //
    // apply plugin to default selector (optional)
    //
    $(function () {
        $("[data-mtsoft-ui-box]").mtsoftUiBox();
    });

})(jQuery);


