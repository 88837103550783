/**
 * "Sticky" jQuery plugin.
 * Keeps given DOM element (statically nested on some place inside document) 
 * always visible (fixed) when scrolling document up/down.
 *
 * (c) 2013 Marek Trybus (mtsoft.pl)
 *
 *
 * NOTES:
 * 
 * 1.) This method MUST BE used on or after $(window).load (not $(window).ready) event. 
 * (Because of Webkit - it can calculate right top offset only when all images are loaded; 
 * otherwsie top offset value for target layer will be invalid)
 * 
 * 2.) Sticky element HAVE TO BE: 
 *      - displayed as block (css: "display: block;")
 *      - not floated (css: "float: none;")
 *      - if width/height other than auto it must be set on inline element styles NOT on css
 *      (ex. <div style="width:100px;height:500px;">)
 *
 *
 *
 * EXAMPLE usage:
 * 
 *  1.) for sticky element set 'data-sticky' attribute (don't have to posses any value)
 *      ex. <div data-sticky></div>
 *
 *  2.) activate (only required if custom selector other than [data-sticky])
 *      on or after $(window).load:
 *  
 *      ex. $(window).load(function(){  $("elementSelector").mtsoftUiSticky();  });
 *          $("elementSelector").mtsoftUiSticky();
 *      
 *  3.) If sticky behavour not needed anymore - deactivate:
 *  
 *      ex. $("elementSelector").mtsoftUiSticky(false);
 *      
 *  @param:     
 *
 */
;
(function() {

    // -------------------------------------------------------------------------
    //
    // Plugin constructor
    //
    $.fn.mtsoftUiSticky = function(params) {

        return this.each(function() {

            var $this = $(this);

            if (params !== false) { // set element sticky

                if (!config.call($this)) {  // only if not already activated

                    activate($this, params);
                }

            } else { // if params is false - remove 'sticky' behavior

                deactivate($this);
            }
        });
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtsoftUiSticky.defaults = {
        autoinit: true, // if true activate sticky behavior for all elemetns with 'data-sticky' attribute
        mode: 'top', // mode [ top | bottom ]        
        margin: 0, // [px] margin value from top 
        // limiter - selector of area limiter (container) of sticky element; 
        // element will behave as sticky only inside this container
        limiter: null,
        limiterPadding: 0, // [px] or [jQuery]; for 'bottom' mode only; sticky element will be shown only below this given padding value in limiter
        // relativeTo - other sticky element located above/below current element; 
        // This element top/bottom margin and height will be aded to top/bottom margin of current element
        // and therefore current element will not cover relative element, but it will be shown above/below it.        
        relativeTo: null, // [Mixed: String or jQUery]
        // startOffset - for mode 'bottom' only - delay in showing fixed element; 
        // If document top offset (scrollTop) lower than this value element will not be shown; set 0 to always visible
        startOffset: 0, // [px]
        horizontalAlign: null,  // [ left | right ] if set sticky element will be not in orginal horizontal position, but aligend to left or right border of viewport
        horizontalMargin: 0, // [px] only if 'horizontalAlign' set; distance from left or right viewport border
        minScreenWidth: 0, // [px] below this viewport width sticky behavor will be ignored; 0 to ignore
        minScreenHeight: 0, // [px] below this viewport height sticky behavior will be ignored; 0 to ignore
        maxScreenWidth: 0, // [px] above this viewport width sticky behavor will be ignored; 0 to ignore
        maxScreenHeight: 0, // [px] above this viewport height sticky behavior will be ignored; 0 to ignore
        opacity: 1, // 0 - 1; opacity used when layer not on real place (when on fixed position)
        zIndex: 1000, // default z-index if element is viewed as fixed 
        opacityAnimate: 400, // [ms] opacity animation speed; set 0 to none
        class: 'sticky', // additional css class added to element when on fixed (sticky) position; sticky by default
        // Callbacks
        onPositionFixed: function($n) {     // element has been set fixed (stick)
        },
        onPositionOriginal: function($n) {  // element position restored on orginal place
        },
        onStartOffsetDown: function($n) {   // start offset reached while scrolling down
        },
        onStartOffsetUp: function($n) { // start offset reached while scrolling up
        },
        // Internal use only - do not change 
        $limiter: null,
        $limiterPadding: null,
        $relativTo: null,
        _original: null, // Current element location/size css styles and metrics
        _originalInit: {}, // Initial location/size element css styles and metrics; constant        
        _isFixed: false // if true element is not on original position (fixed position)
        //,_pause: false
    };

    // -------------------------------------------------------------------------
    //
    // define private functions (internal use only)
    //

    /**
     * Get / set plugin configuration.
     *
     * @param {Mixed} param     Configuration parameter name
     *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
     *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
     *                                    All other then given in JOSN argument parameters will be keep untouch;
     *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
     *                           null - resets current configuration (remove all parameters)
     *
     * @param {Mixed} val       configuration parameter value
     *
     * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
     *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
     */
    function config(param, val) {

        var c = 'config.mtsoftUiSticky',
                $this = $(this), _cfg = $this.data(c);
        // reset current configuration
        if (param === null) {
            $this.data(c, null);
            return _cfg;
        }

        if (val !== undefined || typeof (param) === 'object') { // setter

            // update single parameter value
            if (val !== undefined) { // single parameter value

                _cfg[param] = val; // assign value
                $this.data(c, _cfg); // update

            } else {    // multiple parameters values defined as JSON object {}

                // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                _cfg = $.extend(true, {}, _cfg, param);
                $this.data(c, _cfg); // update
            }
            return _cfg;
        } else { // getter

            return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
        }
    }

    /**
     * Activate sticky behavior
     * 
     * @param {jQuery} $this
     * @param {JSON} params
     */
    function activate($this, params) {

        $this.attr('data-sticky', true); // mark this element to easy find when scrolling

        //
        // initial configuration
        //
        var cfg = $.extend(true, {params: params}, $.fn.mtsoftUiSticky.defaults, {$this: $this}, $this.data(), params);  // CFG is PLUGIN GLOBAL

        // check if container element is given and correct value if required
        if (cfg.limiter) {

            cfg.$limiter = $(cfg.limiter);

            if (cfg.$limiter.css('position') !== 'absolute') {
                cfg.$limiter.css({position: 'relative'}); // must be relative or absolute
            }

            // top limiter padding
            if (cfg.limiterPadding) {   

                if (typeof (cfg.limiterPadding) === 'string' || typeof (cfg.limiterPadding) === 'object') {

                    if ($(cfg.limiterPadding).length) {
                        cfg.$limiterPadding = $(cfg.limiterPadding);
                        cfg.limiterPadding = cfg.$limiterPadding.position().top + cfg.$limiterPadding.outerHeight(true);
                    }
                }

                // check is there need for sticky behaviour
                if (cfg.limiterPadding + $this.outerHeight(true) > $this.position().top) {
                    deactivate($this);
                    return false; // don't need to be sticky
                }
            }
        }

        // is there any element current element should be relative to ?
        if (cfg.relativeTo) {

            cfg.$relativeTo = $(cfg.relativeTo);
        }

        // save configuration for current node
        config.call($this, cfg);

        //
        // attach event handlers (only once - common for all 'sticky' elements)
        //
        if (!window.mtsoftUiStickyCallback) {

            // on window scroll keep sticky
            $(window).on('scroll.mtsoftUiSticky', function() {

                $("[data-sticky]").each(function() {
                    doSticky($(this));
                });
                window._stickyLastTopOff = $(window).scrollTop(); // to detect scroll direction
            });

            // on window resize
            $(window).on('resize.mtsoftUiSticky', function() {
                
                $("[data-sticky]").each(function() {
                    
                    var $this = $(this), cfg = config.call($this), params = cfg.params;  // params are custom parameters give as sticky plugin arguments
                    deactivate($this);
                    activate($this, params);
                });
            });

            window.mtsoftUiStickyCallback = true; // set flag to assing sticky callback only once
        }

        // remember recent top offset value (to detect direction of scroll)
        window._stickyLastTopOff = -1;

        // init; if element outside viewport it will be placed in fixed position 
        doSticky($this);

        // If bottom mode inside limiter and no start offset set
        // view element as relative and on top of limiter (if limiter not already shown)
        if (cfg.limiter && cfg.mode === 'bottom' && cfg.limiterPadding <= 0 && cfg.startOffset <= 0) {
            
            // limiter not in viewport
            if (cfg.$limiter.offset().top > $(window).scrollTop() + $(window).height()) {    
            
            config.call($this, {_original: {
                    styles: {bottom: cfg.$limiter.height() - $this.outerHeight(true)}
                }});
                setOrginal($this);
            }
        }

        // animate opacity?
        if (cfg.opacityAnimate && cfg.opacity !== 1) {

            $this.on('mouseenter.mtsoftUiSticky', function() {

                var cfg = config.call($this);
                if (cfg._isFixed) {

                    $(this).stop(true, true).animate({
                        opacity: 1
                    }, cfg.opacityAnimate);
                }

            }).on('mouseleave.mtsoftUiSticky', function() {

                var cfg = config.call($this);
                if (cfg._isFixed) { // set opacity only if element not on it's orginal position

                    $(this).stop(true, true).animate({
                        opacity: cfg.opacity
                    }, cfg.opacityAnimate);
                }
            });
        }
    }

    /**
     * Deactivate sticky
     * 
     * @param {jQuery} $this
     */
    function deactivate($this) {

        var cfg = config.call($this);

        if (cfg) {

            // remove placeholder
            $('#' + cfg._original.id).remove();
            // restore element orginal state
            $this.stop(true, true).css(cfg._originalInit.styles);
        }
        // remove marker
        $this.attr('data-sticky', null);
        // remove sticky class
        $this.removeClass(cfg.class);
        // remove event handlers
        $this.off('mouseenter.mtsoftUiSticky mouseleave.mtsoftUiSticky');

        // clear configuration
        config.call($this, null);

        // check is there any other active sticky elements;
        // if not - remove window.scroll & window.resize event handlers
        if (!$("[data-sticky]").length) {

            $(window).off('scroll.mtsoftUiSticky resize.mtsoftUiSticky');
            window.mtsoftUiStickyCallback = false;
        }
    }

    /**
     * Executed on window scroll event; keep all sticky elements on right place
     * 
     * @param {jQuery} $this
     */
    function doSticky($this) {
        
        var cfg = config.call($this);
        
        if (!cfg._pause) {

            config.call($this, '_pause', true);  // pause calculations until curent calculation will end (can be invoked by scroll event) 

            if (!cfg._original) { // remember element css and location parameters

                cfg = saveElementStyleAndParams($this, cfg);

                // remember initial sticky element parameters (there are keep untouch)
                cfg = config.call($this, {_originalInit: cfg._original});
            }

            if (isOutsideViewport($this, cfg)) {  // element (or part of it) outside viewport

                if (!cfg._isFixed) {
                    
                    addPlaceholderNode($this, cfg);
                    setFixed($this);
                }

            } else {    // element fits on curent screen - hide placeholder and show in original place

                if (cfg._isFixed) {

                    setOrginal($this);
                } else {

                    // in case element should be located on it's orginal position, but still animating 
                    //$this.stop(true, true);
                }
            }
            
            config.call($this, '_pause', false); 
        }
    }

    /**
     * Executed on viewport resize event
     * 
     * @param {jQuery} $this
     */
    /*function handleResize($this) {
        
        var cfg = config.call($this), params = cfg.params;
                
        deactivate($this);
        activate($this, params);
        return true;
        
        
        //saveElementStyleAndParams($this, cfg);

        if (cfg.limiter) { // element imprisoned inside area limiter

            // restore original, initial element styles
            $this.stop(true, true).css(cfg._originalInit.styles);
        }

        // top limiter padding is DOM node 
        if (cfg.$limiterPadding) {

            cfg.limiterPadding = cfg.$limiterPadding.position().top + cfg.$limiterPadding.outerHeight(true);
        }        
        if (cfg._isFixed) { // if element fixed - place it on original position
            
            //if (window._stickyLastTopOff !== $(window).scrollTop()) { // 
                
                // clear restore fixed element timeout
                window.clearTimeout($this.data('_restoreSticky'));

                // place on original position
                setOrginal($this);

                // correct orginal node position 
                config.call($this, '_original', {top: $this.offset().top});

                // Element postion has been chaned from 'fixed' to orginal position;
                // restore to fixed with slight delay
                $this.data('_restoreSticky', window.setTimeout(function() {
                    //$(window).scroll();
                    doSticky($this);
                    window._stickyLastTopOff = $(window).scrollTop(); // to detect scroll direction
                }, 250));
                //doSticky($this);                
            //} else {

                // do nothing
            //}
            
        } else {

            // update original element position / size            
            saveElementStyleAndParams($this, cfg);
        }
        
    }*/

    /**
     * Save sticky element css styles and additional metrics
     * 
     * @param {jQuery} $this
     * @param {JSON} cfg
     */
    function saveElementStyleAndParams($this, cfg) {

        // Set right node to read parameters from;
        // this can be original element or placeholder element
        var $n = !cfg._isFixed ? $this : $('#' + cfg._original.id);

        $n.stop(true, true); //stop any animations

        // get original element css parameters
        var _styles = {
            // display mode
            display: $n.css('display'),
            // position
            position: $n.css('position'),
            top: $n.css('top'),
            right: $n.css('right'),
            bottom: $n.css('bottom'),
            left: $n.css('left'),
            // size (set by css inline style ONLY (don't suport width/height from css class))
            width: $n[0].style.width,
            height: $n[0].style.height,
            // padding
            paddingTop: $n.css('paddingTop'),
            paddingRight: $n.css('paddingRight'),
            paddingBottom: $n.css('paddingBottom'),
            paddingLeft: $n.css('paddingLeft'),
            // border
            borderTopWidth: $n.css('borderTopWidth'),
            borderRightWidth: $n.css('borderRightWidth'),
            borderBottomWidth: $n.css('borderBottomWidth'),
            borderLeftWidth: $n.css('borderLeftWidth'),
            // margin
            marginTop: $n.css('marginTop'),
            marginRight: $n.css('marginRight'),
            marginBottom: $n.css('marginBottom'),
            marginLeft: $n.css('marginLeft'),
            // other
            opacity: $n.css('opacity')
        };

        // generate random id to easy identify placeholder node
        var id = !cfg._original ? '_ph' + Math.floor((Math.random() * 100000000) + 1) : cfg._original.id,
                _original = {
                    // id used to identify right placeholder node
                    id: id,
                    // orgianl element styles (read from css)
                    styles: _styles,
                    // original absoulte top offset (calculated)
                    top: $n.offset().top,
                    // needed only when element container is used; top relative to limiter container
                    topRelative: $n.position().top,
                    // both below needed only if realtiveTo element is set
                    topMarginRelativeTo: calculateRelativeTopMargin($n, 0),
                    bottomMarginRelativeTo: 0,
                    // real, numeric width and height
                    width: $n.css('width'),
                    height: $n.css('height')
                };

        // save on configuration            
        return config.call($this, '_original', _original);
    }

    /**
     * Calculate top margin when sticky element is relative to other sticky element
     * (mulitple sticky elements on this same screen area)
     * 
     * @param {jQuery} $n
     * @param {int} off     top offet to viewpport top/bottom
     * 
     * @return {int}
     */
    function calculateRelativeTopMargin($n, off) {

        var cfg = config.call($n);

        if (!cfg) { // when mode is bottom elements below current element are not actived

            // activate required element
            $n.mtsoftUiSticky();
            cfg = config.call($n);
        }

        if (cfg.relativeTo) {

            off = cfg.$relativeTo.outerHeight(true) - cfg.margin;   // set top offset/margin

            // required for limiter only to get right bottom margin value
            var cfgRelative = config.call(cfg.$relativeTo);
            if (!cfgRelative) {

                // activate required element which is relativeTo node
                cfg.$relativeTo.mtsoftUiSticky();
                cfgRelative = config.call(cfg.$relativeTo);
            }

            // add relativeTo node height+margin to total top/bottom offset value for current element
            var _bmrt = cfgRelative._original.bottomMarginRelativeTo + $n.outerHeight(true) - cfg.margin;
            config.call(cfg.$relativeTo, {_original: {bottomMarginRelativeTo: _bmrt}});
            // ---

            off += calculateRelativeTopMargin(cfg.$relativeTo, off);

        } else {

            off = 0;
        }

        return off;
    }

    /**
     * Check element is outside viewport and if container(area limiter) defined
     * also check element is outside that container
     * 
     * @param {jQuery} $this
     * @param {JSON} cfg
     * 
     * @return {Boolean}
     */
    function isOutsideViewport($this, cfg) {

        var windowW = $(window).width(), windowH = $(window).height(), // viewport width/height
                isOutside = false;

        // check screen width and/or height is above minimum required to set element sticky
        if ((!cfg.minScreenWidth || windowW > cfg.minScreenWidth) && (!cfg.minScreenHeight || windowH > cfg.minScreenHeight) &&
            (!cfg.maxScreenWidth || windowW < cfg.maxScreenWidth) && (!cfg.maxScreenHeight || windowH < cfg.maxScreenHeight)) {

            // check if element will fit on screen
            if ($this.outerWidth(true) <= windowW && $this.outerHeight(true) <= windowH) {

                var scrollTop = $(window).scrollTop(), // top document offset
                        inAreaLimiter = true;   // is element inside area limiter

                if (cfg.limiter) {  // there is defined container(area limiter) for element

                    // element top
                    var limiterPos = cfg.$limiter.offset();
                    
                    inAreaLimiter = limiterPos.top + parseInt(cfg.$limiter.css('height')) - cfg._original.bottomMarginRelativeTo >
                            scrollTop + parseInt(cfg._original.height) + cfg.margin +
                            cfg._original.topMarginRelativeTo - parseInt(cfg.$limiter.css('paddingBottom'));

                    if (cfg.mode === 'top') {

                        //
                        // TOP mode
                        //
                        if (cfg._isFixed && !inAreaLimiter) {

                            // UP
                            // Element in fixed position reaches bottom of parent container (area limiter)
                            // (when scrolling paage up)
                            // read & save original element top (current)
                            config.call($this, {_original: {
                                    // original absoulte top offset (calculated)
                                    styles: {top: cfg.$limiter.height() - $this.outerHeight(true) - cfg._original.topRelative - cfg._original.bottomMarginRelativeTo}
                                }});
                        }
                    } else { // BOTTOM mode
                        
                        inAreaLimiter = inAreaLimiter &&
                                scrollTop + windowH > limiterPos.top + cfg.startOffset + parseInt(cfg._original.height) + cfg.margin + parseInt(cfg.$limiter.css('paddingTop')) + cfg.limiterPadding;
                        
                        if (cfg._isFixed && !inAreaLimiter && cfg.limiterPadding === 0 && cfg.startOffset === 0) {

                            // UP
                            // Element in fixed position reaches top of parent container (area limiter)
                            // (when scrolling paage up)    
                            // read & save original element bottom (current)
                            config.call($this, {_original: {
                                    styles: {bottom: cfg.$limiter.height() - $this.outerHeight(true)}
                                }});
                        }
                    }
                }

                // check is outisde viewport
                if (cfg.mode === 'top') {

                    // top of element is above viewport top
                    isOutside = inAreaLimiter && 
                            scrollTop >= cfg.startOffset && scrollTop + cfg.margin + cfg._original.topMarginRelativeTo > cfg._original.top;

                } else {

                    // top of element is above viewport top
                    isOutside = inAreaLimiter &&
                            scrollTop >= cfg.startOffset && (scrollTop + windowH - parseInt(cfg._original.height) - cfg._original.topMarginRelativeTo - cfg.margin < cfg._original.top);//;
                            
                    //|| cfg.$limiter.outherHeight() < windowH;
                }
                if (!isOutside) {

                    if (cfg.limiter && inAreaLimiter && cfg._isFixed) {

                        // DOWN
                        // Element in fixed position reach it's original position in parent container (area limiter)
                        // (when scrolling paage down)                        
                        if (cfg.mode === 'top') { // TOP mode

                            // remove top relative offset (set default 0)
                            cfg = config.call($this, {_original: {
                                    styles: {top: 0}
                                }});

                        } else { // BOTTOM mode

                            // remove bottom relative offset (set default 0)
                            config.call($this, {_original: {
                                    styles: {bottom: 0}
                                }});
                        }
                    }
                }
            }
        }

        return isOutside;
    }

    /**
     * Add placeholder in place of original element
     * 
     * @param {jQuery} $this
     * @param {JSON} cfg
     */
    function addPlaceholderNode($this, cfg) {

        var placeholder = $('#' + cfg._original.id);

        if (!placeholder.length) {

            // neste placeholder node equal in position and size to original element
            var _styles = $.extend({}, cfg._original.styles, {
                // below border properties must be set to restore border size
                borderStyle: 'solid',
                borderColor: 'transparent',
                height: cfg._original.height
            });

            var placeholder = $('<div id="' + cfg._original.id + '"></div>').css(_styles);
            $this.after(placeholder);

        } else { // just un-hide exisitng placeholder node

            placeholder.css({
                display: cfg._original.styles.display,
                height: cfg._original.height // update height (if viewport re-sized)
            });
        }
    }

    /**
     * Hide placeholder node (sets display none)
     * 
     * @param {jQuery} $this
     * @param {JSON} cfg
     */
    function hidePlaceholderNode($this, cfg) {

        $('#' + cfg._original.id).css({display: 'none'});
    }

    /**
     * Set sticky element on fixed position (relative to viewport, not document)
     * 
     * @param {jQuery} $this
     */
    function setFixed($this) {

        var cfg = config.call($this),
                s = cfg._original.styles,
                _css = {
                    position: 'fixed',
                    display: 'block',
                    left: s.left,
                    width: cfg._original.width,
                    zIndex: cfg.zIndex
                };
                //cfg.zIndex++; // next fixed element will be above previous (if overlap)
                
                // horizontal align (relative to viewport left and right border) 
                if (cfg.horizontalAlign) {
                    
                    if (cfg.horizontalAlign === 'right') {
                        
                        _css.left = 'auto';
                        _css.right = cfg.horizontalMargin;
                    } else {
                        _css.left = cfg.horizontalMargin;
                    }
                }

        switch (cfg.mode) {

            case 'top':
                _css.top = cfg.margin - parseInt(s.marginTop) + cfg._original.topMarginRelativeTo;
                break;

            case 'bottom':
                _css.bottom = cfg.margin - parseInt(s.marginBottom) + cfg._original.topMarginRelativeTo;
                break;
        }

        // apply style for fixed position of element
        $this.css(_css);

        // set opacity for fixed element
        if (cfg.opacityAnimate) { // animate opacity ?

            var scrollTop = $(window).scrollTop(), limiterPosTop = cfg.$limiter ? cfg.$limiter.offset().top : 0;

            $this.stop(true, true); // finish any animations

            // bottom mode; start offset reached - show element in fixed position (moving down)
            if (((scrollTop >= cfg.startOffset && window._stickyLastTopOff < cfg.startOffset) || // no limiter scroll top greater than start offset
                    // only if limiter defined
                            (cfg.mode === 'bottom' && (cfg.startOffset > 0 && limiterPosTop > 0 && scrollTop + $(window).height() > limiterPosTop + cfg.startOffset) ||
                                    cfg.limiterPadding)) &&
                    window._stickyLastTopOff < scrollTop && // scrolling down
                    window._stickyLastTopOff > -1) {  // disable for fresh page view when browser restores recent scrollTop (no scroll top equal to zero)

                $this.css({opacity: 0});

                // execute callback
                cfg.onStartOffsetDown($this);
            }

            $this.animate({
                opacity: cfg.opacity
            }, cfg.opacityAnimate);

        } else {

            $this.css({opacity: cfg.opacity});
        }
        config.call($this, {_isFixed: true});

        $this.addClass(cfg.class);

        // execute callbck
        cfg.onPositionFixed($this);
    }

    /**
     * Set sticky element on it's current (original) position.
     * It can be initial position if window haven't been resized.
     * 
     * @param {jQuery} $this
     */
    function setOrginal($this) {
        
        var cfg = config.call($this);
        
        $this.stop(true, true);
        
        if (cfg.limiter) {    // handle sticky element container (area limiter)

            cfg._original.styles.position = 'relative';
        }

        var scrollTop = $(window).scrollTop(), windowH = $(window).height(), limiterPosTop = cfg.$limiter ? cfg.$limiter.offset().top : 0;

        if (cfg.opacityAnimate &&
                (scrollTop < cfg.startOffset ||
                        // only if limiter defined and mode bottom and start offset defined 
                                (cfg.mode === 'bottom' && (cfg.startOffset > 0 && limiterPosTop > 0 && scrollTop + windowH >= limiterPosTop + cfg.startOffset && scrollTop + windowH < limiterPosTop + cfg.$limiter.height()) && window._stickyLastTopOff >= scrollTop)
                                ) //|| cfg.limiterPadding && window._stickyLastTopOff < scrollTop
                        && 
                window._stickyLastTopOff > -1) {  // disable for fresh page view when browser restores recent scrollTop (no scroll top equal to zero)

            $this.animate({
                opacity: 0
            }, cfg.opacityAnimate, null, function() {

                // restore original element styles
                hidePlaceholderNode($this, cfg);
                $this.stop(true, true).css(cfg._original.styles);
                $this.removeClass(cfg.class);
            });

            // execute callback
            cfg.onStartOffsetUp($this);


        } else {

            // original element styles
            hidePlaceholderNode($this, cfg);
            $this.stop(true, true).css(cfg._original.styles);
            $this.removeClass(cfg.class);
        }

        config.call($this, {_isFixed: false});

        // execute callbck
        cfg.onPositionOriginal($this);
    }

    // -------------------------------------------------------------------------
    //
    // apply plugin to default selector (optional)
    //
    if ($.fn.mtsoftUiSticky.defaults.autoinit) {

        $(window).ready(function() {
            $("[data-sticky]").mtsoftUiSticky();
        });
    }

})();


