//
// Default, common settings for JS modules
//

// ex. someSetting = value;
// ex. $.mtsoft.ui.module.defaults.settingName = value;
//
// MEDIA QUERIES
//

// break points values must be equal to those defined at:
// /theme/<Name>/scss/_variables.scss (ex. $mq-small-screen: 768px !default;)
// NOTE: below values can be overwritten by valuses defined at: /theme/<Name>/js/settings.js
$.mtsoftConfig.mq = {
    tiny: 479,
    small: 0,
    medium: 640,
    large: 1024,
    xlarge: 1200,
    xxlarge: 1440,
};
                    
//$.mtsoft = {ui: {lock: {defaults: {opacity: .3}}}};
//$.fn.mtsoftUiForm.defaults.scrollTopMargin = 92; // 92 is header height 
//$.fn.mtsoftUiInput.defaults.alertType = 'iconInside';
// ...
