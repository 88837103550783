

/**
 * ZAPBOX
 * 
 * (c) 2014 mtsoft.pl
 * 
 * @uses: $n.dotdotdot 
 */  
;
(function($) {

    var cfg = {};   // keep configuration in global variable

    // -------------------------------------------------------------------------
    //
    // Plugin constructor
    //
    $.fn.zapbox = function(params) {

        var args = arguments;
        $.extend(this, actions);

        return this.each(function() {

            var $this = $(this);

            if (!$this.data('config.zapbox') || params) {

                //
                // Initialize and save configuration for current node
                //                 
                cfg = actions.config.call($this, actions.init.call($this, args));

            } else {

                // only read configuration
                cfg = actions.config.apply($this);
            }
        });
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.zapbox.defaults = {
        // settings as properties or objects
        /*cssPrefix: 'mt-',
         // callbacks
         onSomeAction: function() {
         },
         // flags
         _flag: false*/
        //_iScrolls: []
    };
    $.fn.zapbox.def = {};


    // -------------------------------------------------------------------------
    //
    // Public actions
    //
    var actions = {
        /**
         * Get / set plugin configuration.
         *
         * @param {Mixed} param     Configuration parameter name
         *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
         *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
         *                                    All other then given in JOSN argument parameters will be keep untouch;
         *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
         *                           null - resets current configuration (remove all parameters)
         *
         * @param {Mixed} val       configuration parameter value
         * @param {Bool} update     if true update curent global configuration (cfg) object
         *
         * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
         *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
         */
        config: function(param, val, update) {

            var c = 'config.zapbox',
                    $this = $(this), _cfg = $this.data(c);
            // reset current configuration
            if (param === null) {
                $this.data(c, null);
                return _cfg;
            }

            if (val !== undefined || typeof (param) === 'object') { // setter

                // update single parameter value
                if (val !== undefined) { // single parameter value

                    _cfg[param] = val; // assign value
                    $this.data(c, _cfg); // update

                } else {    // multiple parameters values defined as JSON object {}

                    // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                    //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                    _cfg = $.extend(true, {}, _cfg, param);
                    $this.data(c, _cfg); // update
                }
                if (update === undefined || update) { // update global configuration (cfg) object
                    cfg = _cfg;
                }
                return _cfg;
            } else { // getter

                return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
            }
        },
        /**
         * Initialize
         * 
         * @param {JSON} args 
         * @returns {JSON} configuration object
         */
        init: function(args) {

            var $this = $(this), cfg;
            //
            // initial configuration
            //            
            cfg = $.extend(true, {}, {$this: $this},
            $.fn.zapbox.defaults,
                    $this.data(),
                    $.fn.zapbox.def[$this.attr('id')],
                    args);  // cfg is PLUGIN GLOBAL

            //
            // attach event handlers
            //

            $this.find('a[href="#zapbox-live"]').on('click.zapox', function(){
                
                $('#zapbox-live').find('.iScrollOn').each(function(){
                    _iScroll($(this));
                });
            });

            // schedule/live (days of week)
            $this.find('[data-url-day]').on('click.zapbox', function() {

                
                var $sheet = $($(this).attr('href'));
                if (!$sheet.children().length) { // load only if empty 
                    
                    $sheet.load($(this).data('urlDay') + '/' + cfg.channelId, function() {
                        
                        var $li = $sheet.find('.schedule > ul > li');
                        $sheet.children('.schedule').width($li.length * $li.width())+2;
                        _iScroll($(this));  
                        
                        // fit nicely show description on back cover
                        try {
                            cfg.$this.find(".cover-desc").dotdotdot({});
                        } catch(e) {}
                    });
                    _spinner($sheet);
                    
                } else {
                    
                    _iScroll($sheet);
                }
                //$(window).resize();
                // fit nicely show description on back cover (must be exectued each time tab is clicked) 
                try {
                    cfg.$this.find(".cover-desc").dotdotdot({});
                } catch(e) {}
            });


            // upcomming 
            $this.find('[data-url-upcoming]').on('click.zapbox', function(e) {

                var $this = $(this), $container = $('#zapbox-upcoming').find('.container');

                if (!$this.hasClass('loaded')) {
                    
                    $container.show().load($this.data('urlUpcoming') + '/' + cfg.channelId, function() {
                        // set width
                        var $li = $container.find('.upcoming > ul > li');
                        $container.children('.upcoming').width($li.length * $li.width())+2;
                        _iScroll($container);
                        $this.addClass('loaded');
                    });
                    _spinner($container);                    
                } else {
                    _iScroll($container);
                }
                return false;
            });
            
            // hosts
            $('a[href="#zapbox-hosts"]').on('click.zapbox', function(){
                
                var $this = $('#zapbox-hosts').find('a'), $nav = $this.parent().parent().parent(), $container = $nav.siblings('.container');
                if ( $container.data('loaded') === undefined ) {
                    $('#zapbox-hosts').find('a').filter(':first').click();
                }
            });
            // guests
            $('a[href="#zapbox-guests"]').on('click.zapbox', function(){
                
                var $this = $('#zapbox-guests').find('a'), $nav = $this.parent().parent().parent(), $container = $nav.siblings('.container');
                if ( $container.data('loaded') === undefined ) {
                    $('#zapbox-guests').find('a').filter(':first').click();
                }
            });
            // shows
            $('a[href="#zapbox-shows"]').on('click.zapbox', function(){
                
                var $this = $('#zapbox-shows').find('a'), $nav = $this.parent().parent().parent(), $container = $nav.siblings('.container');
                if ( $container.data('loaded') === undefined ) {
                    $('#zapbox-shows').find('a').filter(':first').click();
                }
            });

            // hosts/guests alphabet
            $this.find('#zapbox-hosts nav.alphabet > ul > li > a, #zapbox-guests nav.alphabet > ul > li > a').on('click.zapbox', function(e) {

                var $this = $(this), $nav = $this.parent().parent().parent(), $container = $nav.siblings('.container');

                //if (!$container.children().length) {
                if ($container.data('loaded')!==$this.text()) {
                    
                        $container.show().load($nav.data('url') + $.trim($this.html()) + '/' + cfg.channelId, function() {
                            // set width
                            var $li = $container.find('div > ul > li');
                            $container.children('div').width($li.length * $li.outerWidth(true));
                            _iScroll($container, true);

                            $container.data('loaded', $this.text());
                            // fit nicely show description on back cover
                            try {
                                $li.dotdotdot({});
                            } catch(e) {}
                        });
                        _spinner($container);
                    //}
                    $nav.find('a').removeClass('active');
                    $this.addClass('active');
                }
                return false;
            });
            
            // shows alphabet
            $this.find('#zapbox-shows nav.alphabet > ul > li > a').on('click.zapbox', function(e) {

                var $this = $(this), $nav = $this.parent().parent().parent(), $container = $nav.siblings('.container');
  
                if ($container.data('loaded')!==$this.text()) {
                    $container.show().load($nav.data('url') + $.trim($this.html()) + '/' + cfg.channelId, function() {
                        // set width
                        //var $li = $container.find('div > ul > li');
                        //$container.children('div').width($li.length * $li.outerWidth(true));
                        _iScrollVertical($(this), true);
                        $container.data('loaded', $this.text());
                    });
                    _spinner($container);
                    //_spinner($container);
                    $nav.find('a').removeClass('active');
                    $this.addClass('active');
                }
                return false;
            });

            // categories/category
            $this.find('#zapbox-categories-tab').on('click.zapbox', function(e) {

                if (!$('#zapbox-categories').children().length) {
                var $cat = $(this);
                $('#zapbox-categories').load($cat.data('urlCategories') + '/' + cfg.channelId, function(e) {

                        // categories list loaded - activate particular categories list 
                        $(this).find('a[href^="#cat-"]').on('click.zapbox', function() {

                            var $_this = $(this);
                            // hide other opened sub-categories
                            //$('#zapbox-categories').find('div.category-out').removeClass('active').slideUp();
                            $('#zapbox-categories').find('div.category-out').removeClass('active').hide();
                            _iScrollVertical($('#zapbox-categories')); // re-size iScroll container

                            // load category shows
                            if (!$_this.next().next('div').length) { // build catgory container (if not present) 

                                $_this.next().after('<div class="category-out">');
                                $_this.next().next('div.category-out').hide().load($cat.data('urlCategory') + $_this.attr('href').replace('#cat-', '') + '/' + cfg.channelId, function() {

                                    $(this).show();
                                    _iScrollVertical($('#zapbox-categories')); // re-size iScroll container
                                    /*$(this).slideDown(250, function() {                                    
                                        _iScrollVertical($('#zapbox-categories'));
                                        _scrollToCategoryTop($(this));
                                    });*/
                                    //_scrollToCategoryTop($(this));
                                    $_this.addClass('active');
                                });
                                //_spinner($_this.next().next('div'));
                            } else {

                                if ($_this.hasClass('active')) {

                                    //$_this.next().next('div.category-out').slideUp();
                                    $_this.next().next('div.category-out').hide();
                                    _iScrollVertical($('#zapbox-categories')); // re-size iScroll container
                                    $_this.removeClass('active');
                                } else {

                                    /*$_this.next().next('div.category-out').slideDown(250, function() {
                                        _scrollToCategoryTop($(this));
                                    });*/
                                    $_this.next().next('div.category-out').show();
                                    _iScrollVertical($('#zapbox-categories')); // re-size iScroll container
                                    $_this.addClass('active');
                                }
                            }

                        });                    

                        // activate iScroll
                        _iScrollVertical($('#zapbox-categories'));
                    }); 
                    _spinner($('#zapbox-categories'));
                }
            });
            
            
            // 
            // All channels Zapbox
            //
            /*$this.find('[data-url-channels]').on('click.zapbox', function(e) {
                
                var $this = $(this), $container = $('#zapbox-channels').find('.container');

                if (!$this.hasClass('loaded')) {
                    
                    $container.show().load($this.data('urlChannels'), function() {
                        // set width
                        //var $li = $container.find('.channels > ul > li');
                        //$container.children('.channels').width($li.length * $li.width())+2;
                        //_iScroll($container);
                        $this.addClass('loaded');
                    });
                    _spinner($container);                    
                }
                _iScroll($container);
                return false;
            });*/
            
            
            
            //
            // -----------------------------------------------------------------
            //
            var _channelScheduleInit = function($day) {
                
                // init episodes list iScroll
                var $li = $day.find('.schedule > ul > li');
                $day.children('.schedule').width($li.length * $li.width())+2;                
                _iScroll($day); // make it use iScorll

                // lazyload images
                //$day.find("img").lazyload({container: $day, effect: 'fadeIn'}).addClass('ll');

                // scroll to current live element
                var w = $day.width()/2, 
                        liW = $day.children().children().children().filter(':first').width(), 
                        off = parseInt(w/liW) - 1,
                        currServerTime = $('#currServerTime').val().replace(/\:/g, ''),
                        $currShow = null; // transfered from server 

                // find current live show            
                $day.find('li[data-st]').each(function(){            
                    
                    if (parseInt(($(this).data('st')+'').substr(0,2)) <= parseInt(currServerTime.substr(0,2))) {

                        $currShow = $(this);
                        return;
                    }                
                });

                if ($currShow && $currShow.length) {
                    
                    var $live = $currShow.prevAll().eq(off).length ? $currShow.prevAll().eq(off)[0] : $currShow[0]; 
                    
                    if ($day.data('iScroll')) {
                        $day.data('iScroll').scrollToElement($live, '0s');
                    }
                }
            };

            if ( $('#zapbox-live').length ) {
                
                //
                // Single channel Zapbox
                //
                
                // select Today day on zapbox schedule tab (sheet with pre-filled content on server side is Today)
                var $today = $('#zapbox-live').find('.schedule').parent();
                $('#zapbox-live-days').mtsoftUiTabs().select($today); // select Today TAB 
                _channelScheduleInit($today);
                
            } else {
                
                //
                // All Channels Zapbox 
                //
                // init episodes list iScroll
                var $days = $('#zapbox-live-all-channels').find('.schedule').parent(); //$('#zapbox-live-all-channels').find('.schedule > ul > li');
                
                $days.each(function(){
                    
                    _channelScheduleInit($(this));
                });
                
                // All channels channels horizontal list 
                
                // iscroll
                cfg.$this.find('a[href="#zapbox-channels"]').on('click.zapox', function(){

                    var $allChannels = $('#zapbox-channels > .content'), $li = $allChannels.find('.all-channels-list > ul > li');
                    $allChannels.find('.all-channels-list').width($li.length * ($li.width()+5));                                
                
                    $('#zapbox-channels').find('.content').each(function(){
                        
                        _iScroll($(this));
                        
                        // dotdot
                        try {
                            $('#zapbox-channels').find(".cover-desc").dotdotdot({});
                        } catch(e) {}

                    });
                });
                                
            }
            
            // fit nicely show description on back cover
            try {
                cfg.$this.find(".cover-desc").dotdotdot({});
            } catch(e) {}
            
            // when resizing window and iScroll'ed eleemnt is invisible 
            // we need to make it visible and refresh to still work 
            // (otherwise no scrollbar shown/scroll is generateing errors)
            // Also it must be made with delay to avid conflict with iScroll onResize handler  
            /*var _refreshIscrolls = function() {
                
                for (var isc in cfg._iScrolls) {
                    _iScroll(cfg._iScrolls[isc]);            
                }
            };
  
            $(window).resize(function(){
               setTimeout(_refreshIscrolls, 100);
            });
            */
           //try {            
                cfg.$this.find('.flip-container').swipe({
                    //Generic swipe handler for all directions
                    longtap: function(event, direction, distance, duration, fingerCount) {

                        return false;
                    }
                    //Default is 75px, set to 0 for demo so any distance triggers swipe
                    //,threshold: 0
                });
            //} catch (e) {
            //}
           
  
            return cfg;
        } 


        /**
         * Sample public action
         * 
         * @param {type} params
         * @param {type} e
         */
        /*actionName: function(params, e) {
         
         // variables:
         // this = $(this) => jQuery node object action is executed for
         
         // execute other public action (for current node configuration)
         actions.actionNameOther.apply(this, [params, e]);
         
         // execute private function
         privateMethod(params);
         
         // update configuration for current node (false parameter is important!)
         cfg.$this.zapbox(false).config(paramName, 'value');
         
         // ALWAYS return node to keep chain
         return this;
         }*/
        // ...
    };


    // -------------------------------------------------------------------------
    //
    // define private functions (internal use only)
    //
    function _scrollToCategoryTop($category) {
        $('html, body').animate({
            scrollTop: $category.prev().prev().offset().top
        }, 250);
    }

    function _iScroll($list, force) {

        // NOTE: iScoll requires that elemetn to be scrolled MUST be visible
        // In case when user don't waits until tab contents loads
        // show list element temporarly to iScroll work properly 
        var wasShown = true;
        if (!$list.is(":visible") ) {

            $list.show();
            wasShown=false;
        }                        

        if ($list.data('iScroll') && force === undefined) {

            // alreay assigned - only refresh 
            $list.data('iScroll').refresh();            
        } else {

            try {
                
                // define 
                var iScroll = new IScroll($list[0], {
                    scrollbars: 'custom',
                    //scrollbars: true,
                    mouseWheel: true,
                    interactiveScrollbars: true,
                    resizeScrollbars: true,
                    //snap: 'li',
                    scrollX: true,
                    scrollY: false,  
                    eventPassthrough: true // touch devices - allows to swipe vertically whole apge and swipe jhorizontally only box contents
                        //bindToWrapper: true
                        //,fadeScrollbars: true
                        //,shrinkScrollbars: false                
                });
                $list.data('iScroll', iScroll).addClass('iScrollOn');
            } catch (e) {

                // iScorll not available - use native vertical scroll 
                $list.css({overflowX: 'scroll'});
            }
        }
        
        if (!wasShown) {
            $list.hide();
        }
        
        return $list;
    }
    function _iScrollVertical($list, force) {

        // NOTE: iScoll requires that elemetn to be scrolled MUST be visible
        // In case when user don't waits until tab contents loads
        // show list element temporarly to iScroll work properly 
        var wasShown = true;
        if (!$list.is(":visible") ) {

            $list.show();
            wasShown=false;
        }   
        
        if ($list.data('iScroll') && force === undefined) {

            // only refresh
            $list.data('iScroll').refresh();

        } else {

            try {

                // define 
                var iScroll = new IScroll($list[0], {
                    scrollbars: 'custom',
                    //scrollbars: true,
                    mouseWheel: true,
                    interactiveScrollbars: true,
                    resizeScrollbars: false
                    //snap: 'a'
                    //scrollX: true,
                    //scrollY: false
                        //bindToWrapper: true
                        //,fadeScrollbars: true
                        //,shrinkScrollbars: false                
                });
                $list.data('iScroll', iScroll);
                
            } catch (e) {

                // iScorll not available - use native vertical scroll 
                //$list.css({overflowY: 'scroll'});
            }
        }
        
        if (!wasShown) {
            $list.hide();
        }        
    }
    
    function _spinner($n) {

        if ($n.height()<320) {
            $n.css({minHeight: 320});
        }
        $n.mtsoftUiLock().on();

        /*$n.css({position: 'relative'});
        $n.spinner = new Spinner({
                lines: 13, // The number of lines to draw
                length: 20, // The length of each line
                width: 6, // The line thickness
                radius: 30, // The radius of the inner circle
                corners: 1, // Corner roundness (0..1)
                rotate: 0, // The rotation offset
                direction: 1, // 1: clockwise, -1: counterclockwise
                color: '#fff', // #rgb or #rrggbb or array of colors
                speed: 1, // Rounds per second
                trail: 60, // Afterglow percentage
                shadow: false, // Whether to render a shadow
                hwaccel: true, // Whether to use hardware acceleration
                className: 'spinner', // The CSS class to assign to the spinner
                zIndex: 2e9, // The z-index (defaults to 2000000000)
                top: '50%', // Top position relative to parent
                left: '50%' // Left position relative to parent
            }).spin();
            $n.append($n.spinner.el);
        */
    }
    /*
     function _getDayName() {
     
     var days = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'];
     return days[ new Date().getDay() ];
     }
     */


    // -------------------------------------------------------------------------
    //
    // define even handlers common for all instances (for window/document, etc...)
    //
    /*$(window).on('resize.zapbox', function(e) {
     actions.actionName.apply($(this), [e]);
     });*/

    // -------------------------------------------------------------------------
    //
    // apply plugin to default selector (optional)
    //
    $(function() {
        console.log('ZAPBOX');
        $("#zapbox").zapbox();
    });

})(jQuery);


