// Signin
$(document).ready(function($) {
  $.fn.mtsoftUiForm.def.SigninForm = {
    onSubmitSuccess: function(data) {
      // close popup on successfull login (while redirecting)
      $("[name='popLoginOrSignup']")
        .mtsoftUiPopup()
        .close();
    },
    onSubmitFailed: function(data) {
      $("#SigninCaptcha")
        .mtsoftUiInputConfig()
        .reset();
      if (data.attempt && data.attempt >= 3) {
        // if three times wrong login details entered - show captcha
        $("#SigninCaptchaOut").slideDown();
      }
    }
  };

  $.popupLoginOrSignupDef = {
    maxWidth: 480,
    centerVertically: true,
    beforeOpen: function() {
      // preapre input(s)
      try {
        $("#SigninUsername")
          .mtsoftUiInputSetValue("")
          .mtsoftUiInputSetNeutral();
        $("#SigninPassword")
          .mtsoftUiInputSetValue("")
          .mtsoftUiInputSetNeutral();
        $("#SigninCaptcha")
          .mtsoftUiInputSetValue("")
          .mtsoftUiInputSetNeutral();
      } catch (e) {}
      return true;
    },
    onOpen: function(cfg) {
      $("#SigninUsername").focus();
    },
    onClose: function() {
      if ($._popupResetPassword) {
        $("#popResetPassword")
          .mtsoftUiPopup($.popResetPasswordDef)
          .open();
        $._popupResetPassword = false;
      }
      if ($._popupSignup) {
        $("#popSignup")
          .mtsoftUiPopup($.popupSignupDef)
          .open();
        $._popupSignup = false;
      }
    }
  };

  $("#btnLoginOrSignup").on("click", function() {
    $("[name='popLoginOrSignup']")
      .mtsoftUiPopup($.popupLoginOrSignupDef)
      .open();
  });

  // open signin popup if #signin hash
  if (window.location.hash.match(/^#signin$/)) {
    $("[name='popLoginOrSignup']")
      .mtsoftUiPopup($.popupLoginOrSignupDef)
      .open();
  }
});

// Reset password
$(document).ready(function($) {
  $.popResetPasswordDef = {
    maxWidth: 360,
    centerVertically: true,
    beforeOpen: function() {
      // preapre input(s)
      $("#ResetPasswordEmail")
        .mtsoftUiInputSetValue("")
        .mtsoftUiInputSetNeutral();
      return true;
    },
    onOpen: function(cfg) {
      $("#ResetPasswordEmail").focus();
    },
    onClose: function() {
      if ($._popupLoginOrSignup) {
        $("[name='popLoginOrSignup']")
          .mtsoftUiPopup($.popupLoginOrSignupDef)
          .open();
        $._popupLoginOrSignup = false;
      }
    }
  };

  //-
  //- Button/link ID = "btnResetPassword" MUST BE DEFINED
  //-
  $("#btnResetPassword").on("click", function() {
    if ($("[name='popLoginOrSignup']").length) {
      // close login/signup popup popup (will open reset password popup)

      $._popupResetPassword = true;
      $("[name='popLoginOrSignup']")
        .mtsoftUiPopup(
          $.extend(true, {}, $.popupLoginOrSignupDef, {
            modalHideOnClose: false
          })
        )
        .close();
    } else {
      // just open

      $("#popResetPassword")
        .mtsoftUiPopup($.popResetPasswordDef)
        .open();
    }
  });

  $("#btnResetPasswordBack").on("click", function() {
    $("#ResetPasswordEmail").mtsoftUiInputSetNeutral();
    $._popupLoginOrSignup = true;
    $("#popResetPassword")
      .mtsoftUiPopup(
        $.extend(true, {}, $.popResetPasswordDef, {
          modalHideOnClose: false
        })
      )
      .close();
  });
});

// Signup
$(document).ready(function($) {
  $.popupSignupDef = {
    maxWidth: 480,
    centerVertically: true,
    beforeOpen: function() {
      // preapre input(s)
      try {
        $("#ProfileFirstname")
          .mtsoftUiInputSetValue("")
          .mtsoftUiInputSetNeutral();
        $("#ProfileLastname")
          .mtsoftUiInputSetValue("")
          .mtsoftUiInputSetNeutral();
        $("#UserEmail")
          .mtsoftUiInputSetValue("")
          .mtsoftUiInputSetNeutral();
        $("#UserPassword")
          .mtsoftUiInputSetValue("")
          .mtsoftUiInputSetNeutral();
        $("#UserPasswordConfirmed")
          .mtsoftUiInputSetValue("")
          .mtsoftUiInputSetNeutral();
        $("#UserCaptcha")
          .mtsoftUiInputSetValue("")
          .mtsoftUiInputSetNeutral();
      } catch (e) {}
      return true;
    },
    onClose: function() {
      if ($._popupLoginOrSignup) {
        $("[name='popLoginOrSignup']")
          .mtsoftUiPopup($.popupLoginOrSignupDef)
          .open();
        $._popupLoginOrSignup = false;
      }
    }
  };

  $("#btnSignup").on("click", function() {
    //- $('#popSignup').mtsoftUiPopup($.popupSignupDef).open();

    if ($("[name='popLoginOrSignup']").length) {
      // close login/signup popup popup (will open reset password popup)

      $._popupSignup = true;
      $("[name='popLoginOrSignup']")
        .mtsoftUiPopup(
          $.extend(true, {}, $.popupLoginOrSignupDef, {
            modalHideOnClose: false
          })
        )
        .close();
    } else {
      // just open

      $("#popSignup")
        .mtsoftUiPopup($.popupSignupDef)
        .open();
    }
  });

  $("#btnSignupBack").on("click", function() {
    $._popupLoginOrSignup = true;
    $("#popSignup")
      .mtsoftUiPopup(
        $.extend(true, {}, $.popupSignupDef, {
          modalHideOnClose: false
        })
      )
      .close();
  });

  // open signin popup if #signin hash
  if (window.location.hash.match(/^#signup$/)) {
    $("#popSignup")
      .mtsoftUiPopup($.popupSignupDef)
      .open();
  }
});
