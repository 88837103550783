// Global settings and commonly used functions
$.mtsoftConfig = {};

// namespace
$.fn.mtsoft = $.fn.mtsoft || {ui: {}};
$.mtsoft = $.mtsoft || {ui: {}};

//
// DEBUGGing
//
if (window['DEBUG'] !== undefined && DEBUG) {

    function log(c) {
        
        //var err = new Error();        
        //c = c+' [file: ' + err.fileName + ' line:' + err.lineNumber+']';
        
        return console.log(document.all && typeof (c) === 'object' ? o2s(c) : c);
    }
    function dbg(callback, arguments) {
        return callback.apply(undefined, arguments);
    }
    function o2s(ob) {
        var output = "Object: [\n";

        for (var prop in ob) {
            try {
                output += " " + prop + " = " + ob[prop] + ";\n";
            } catch (e) {
            }
        }
        return output + "]".toString();
    }

} else {
    // to aviod errors if somewhere in code leaved functions:
    dbg = function() {
    };
    log = function() {
    };
    o2s = function() {
    };
}