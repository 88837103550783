/**
 * Widget Player 
 * 
 * @uses IScroll (only when archives)
 * @uses mediaelementplayer
 * 
 * Usage: $(".widget-player").mtPlayer();
 *  
 * 2014 mtsoft.pl
 */
;
(function () {

    var cfg = {};   // keep configuration in global variable

    // -------------------------------------------------------------------------
    //
    // Plugin constructor
    //
    $.fn.mtPlayer = function (params) {

        var args = arguments;
        $.extend(this, actions);

        return this.each(function () {

            var $this = $(this);

            if (!$this.data('config.mtPlayer') || params) {

                //
                // Initialize and save configuration for current node
                //                 
                cfg = actions.config.call($this, actions.init.call($this, args));

            } else {

                // only read configuration
                cfg = actions.config.apply($this);
            }
        });
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtPlayer.defaults = {
        // share iframe html base code 
        share: {
            iframe: '<iframe src="_URL_" width="625" height="369" frameborder="0"></iframe>'
        },
        // media element player default settings
        mediaElementPlayer: {
            // shows debug errors on screen
            enablePluginDebug: true,
            // width of audio player
            audioWidth: "100%",
            // height of audio player
            audioHeight: 69, // check also _widget.scss (_variables.scss) > $player-element-height-px (height must be equal)
            // initial volume when the player starts
            startVolume: 0.5,
            // the order of controls you want on the control bar (and other plugins below)
            features: ['playpause', 'current', 'duration', 'volume', 'progress'],
            plugins: ['flash', 'silverlight'],
            // path to Flash and Silverlight plugins - set via PHP PlayerHelper 
            //pluginPath: '/player/',
            videoVolume: 'horizontal',
            //video: { mode: 'shim' },  // force flash usage 
            //audio: { mode: 'shim' },  // force flash usage 
            mode: 'auto',   // work mode: 'auto' - auto hmtl5/flash; 'shim' disallow HTML5 - attempt either Flash or Silverlight; 'native' - use HTML5 only; 'none' - force fallback
            success: function (mediaElement, domObject) {


                /*// add event listener
                mediaElement.addEventListener('timeupdate', function(e) {
 
                     document.getElementById('current-time').innerHTML = mediaElement.currentTime;
                }, false);
 
                // call the play method
                mediaElement.play();
                */

                // STARTS THE AUDIOIF IT'S FLASH AUDIO FALLBACK.
                //$('.mejs-playpause-button').trigger('click');

                // STARTS THE AUDIO IF IT'S HTML5 COMPATIBLE.
                //mediaElement.play();

                //console.log('success');

            },
            // fires when a problem with stream is detected 
            error: function () {

                //console.log('error');
            }
        },
        banner: {
            url: null,
            refresh: 30  // [s] how often check for new  related to currently playing media banner/cover
        },
        txt: {
            error: {
                1: 'You aborted the media playback',
                2: 'A network error caused the media download to fail',
                3: 'The media playback was aborted due to a corruption problem or because the media used features your browser did not support',
                4: 'The media could not be loaded - the server/network failed, file not found or the format is not supported',
                default: 'An unknown media error occurred'
            }
        },
        //init: true,    // init player (all nodes ".widget-player" will be initialized)
        autoplay: false,    // if true autoplay 
        useKeys: false,
        keepVolume: true, // remember volume set by user 
        pusleIfPause: true, // if true use pulse animation to indicate play button 
        fileOrStream: null, // player file or strem (only if other than defined 'src' attribute of <audio> or <video> tags; usable for live stream when mediaelement won't show up html5 player 
        // internal
        currPage: 1,
        currentEpisode: false,
        _updatingList: false,
        urlBase: null,
        liveStream: null,
        liveStreamBanner: null,
        host: 1
    };
    $.fn.mtPlayer.def = {};


    // -------------------------------------------------------------------------
    //
    // Public actions
    //
    var actions = {
        /**
         * Get / set plugin configuration.
         *
         * @param {Mixed} param     Configuration parameter name
         *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
         *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
         *                                    All other then given in JOSN argument parameters will be keep untouch;
         *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
         *                           null - resets current configuration (remove all parameters)
         *
         * @param {Mixed} val       configuration parameter value
         * @param {Bool} update     if true update curent global configuration (cfg) object
         *
         * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
         *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
         */
        config: function (param, val, update) {

            var c = 'config.mtPlayer',
                $this = $(this), _cfg = $this.data(c);
            // reset current configuration
            if (param === null) {
                $this.data(c, null);
                return _cfg;
            }

            if (val !== undefined || typeof (param) === 'object') { // setter

                // update single parameter value
                if (val !== undefined) { // single parameter value

                    _cfg[param] = val; // assign value
                    $this.data(c, _cfg); // update

                } else {    // multiple parameters values defined as JSON object {}

                    // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                    //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                    _cfg = $.extend(true, {}, _cfg, param);
                    $this.data(c, _cfg); // update
                }
                if (update === undefined || update) { // update global configuration (cfg) object
                    cfg = _cfg;
                }
                return _cfg;
            } else { // getter

                return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
            }
        },
        /**
         * Initialize
         * 
         * @param {type} params
         * @returns {JSON} configuration object
         */
        init: function (args) {

            var $this = $(this);
            //
            // initial configuration
            //
            cfg = $.extend(true, {}, {
                $this: $this, // $this is player element (div.widget-player - ) 
                $audio: $this.find('audio'),
                $widget: $this.parent(),
                $list: $this.find('.list'),
                $playlist: $this.find('.list ul'),
                $transfer: $this.find('[data-transfer]')
            },
                $.fn.mtPlayer.defaults,
                $this.data(),
                $.fn.mtPlayer.def[$this.find('audio, video').attr('id')]
            );  // cfg is PLUGIN GLOBAL

            var $list = cfg.$list, isPlaylist = cfg.$playlist.length;

            // get parameters transfered from server side 
            cfg.urlBase = cfg.$transfer.data('baseUrl');
            cfg.liveStream = cfg.$transfer.data('channel');
            cfg.host = cfg.$transfer.data('host');

            // add class (if not already) 
            $this.addClass('widget-player');

            //
            // list and cover
            //

            //
            // list 
            //
            if (isPlaylist) {
                // define lazyload 
                $list.find("img.th").lazyload({ threshold: 306, container: $list, effect: 'fadeIn' }).addClass('ll');

                // init episodes list iScroll
                try {
                    cfg.iScroll = new IScroll($list[0], {
                        scrollbars: 'custom',
                        mouseWheel: true,
                        interactiveScrollbars: true,
                        resizeScrollbars: false,
                        snap: 'li',
                        keyBindings: cfg.useKeys ? {
                            //pageUp: 33,
                            //pageDown: 34,
                            end: 35,
                            home: 36,
                            up: 40,
                            down: 38
                        } : {}
                        //bindToWrapper: true
                        //,fadeScrollbars: true
                        //,shrinkScrollbars: false                
                    });
                    cfg.iScroll.on('scrollEnd', function () {
                        $list.trigger('scroll');

                        // if we reach end of listing - load next page/set of episodes 
                        if (Math.abs(this.maxScrollY) - Math.abs(this.y) < 250) {

                            // scroll is 250px from the bottom.
                            actions.listAppendEpisodes();
                        }
                    });
                } catch (e) {

                    // iScorll not available - use native vertical scroll 
                    $list.css({ overflowY: 'scroll' });

                    $list.bind('scroll', function () {

                        if ($(this).scrollTop() + $(this).innerHeight() >= this.scrollHeight - 250) {

                            // scroll is 250px from the bottom.
                            actions.listAppendEpisodes();
                        }
                    });
                }

                // play archived episode when click on 'play' button 
                $this.find('a.play').on('click tap', function (e) {

                    actions.play(_getEpisodeData($(this).parent()), true, true);
                    e.preventDefault();
                    return false;
                }).addClass('evnt');


                //
                // cover 
                //
                // back to episodes list from cover linnk
                $('.bck-episodes').on('click', function () {

                    actions.hideCover();
                });
                // init episode cover iScroll
                try {
                    cfg.coverIScroll = new IScroll($this.find('.cover-out')[0], {
                        scrollbars: 'custom',
                        mouseWheel: true,
                        interactiveScrollbars: true,
                        resizeScrollbars: false,
                        bounce: false
                    });
                } catch (e) {
                    $this.find('.cover-out').css({ overflowY: 'auto' });
                }
                //document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);
            }


            if (cfg.keepVolume) { // restore player volume (if set) 

                // read volume
                try {
                    if (localStorage && localStorage.getItem('player.volume')) {
                        cfg.mediaElementPlayer.startVolume = localStorage.getItem('player.volume');
                    }
                } catch (e) {
                }
                // restore if muted 
                try {
                    if (localStorage && localStorage.getItem('player.muted') && parseInt(localStorage.getItem('player.muted')) > 0) {

                        cfg.$audio.attr('muted', true);
                    }
                } catch (e) {
                }
            }

            //
            // INIT mediaplayerelement.js 
            //
            cfg.player = new MediaElementPlayer(cfg.$audio[0], cfg.mediaElementPlayer);


            // to know if other player is playing - each time update put current time to player.playing
            cfg.player.media.addEventListener('timeupdate', function (e) {
                try {
                    localStorage.setItem('player.playing', Math.floor(Date.now() / 1000)); // UNIX TIMESTAMP in SECONDS! 
                } catch (e) { }
            });


            //
            // For live stream instead of pause - do stop and resume (start) live stream (current, not buffored) 
            //                

            if (cfg.isLiveStream) {

                $('.mejs-playpause-button').on('click', function () {

                    if (cfg.player.media.paused) {

                        cfg.player.showErrors = false;
                        cfg.player.setSrc('');
                        c.$widget.find('.cover-current').addClass('clickable');
                        c.$widget.find('.mejs-playpause-button').removeClass('mejs-pause'); //.addClass('pulse');
                        c.$widget.find('.mejs-playpause-button > button').removeClass('playing');
                        cfg.player.showErrors = true;
                    } else {
                        // some stream providers don't allow to add additional parameters like `?rnd=0923849` - error 403 is returned 
                        //cfg.player.setSrc( cfg.fileOrStream + '?rnd=' + Math.floor( ( Math.random() * 1000000 ) + 1 ) ); 
                        cfg.player.setSrc(cfg.fileOrStream);
                        cfg.player.play();
                        //actions.playFlag();
                    }
                });
            }




            // handle initial mute state 
            if (cfg.$audio.attr('muted')) {

                cfg.player.media.muted = true;
                // style palyer mute button and volume 
                cfg.$widget.find('.mejs-mute').removeClass('mejs-mute').addClass('mejs-unmute');
                cfg.$widget.find('.mejs-horizontal-volume-current').width(0);
            }

            if (cfg.keepVolume) { // remember volume value on volume change 
                // save volume level & mute
                try {

                    cfg.player.media.addEventListener('volumechange', function (e) {

                        try {

                            localStorage.setItem('player.volume', cfg.player.media.volume);
                            if (cfg.player.media.muted) {
                                localStorage.setItem('player.muted', 1);
                            } else {
                                localStorage.setItem('player.muted', 0);
                            }

                        } catch (e) {
                        }
                    }, false);
                } catch (e) {
                }
            }



            if (isPlaylist) {

                var $recentEpisode = $this.find('.list').find('li').filter(':first');

                if ($this.find('[data-startup]').length) {

                    // disable cover transition
                    $this.find('.cover-out').addClass('no-trans');

                    var startup = $this.find('[data-startup]'), autoplay = startup.data('autoplay');

                    if (startup.data('startup').id) {

                        // set given episode at startup
                        actions.play(startup.data('startup'), true, autoplay);

                    } else {

                        switch (startup.data('startup')) {

                            case 'list':
                                // do notnhing - just list is shown 
                                //$this.find('.cover-out').addClass('active');
                                break;

                            case 'recent':
                                // play recent episode
                                actions.play(_getEpisodeData($recentEpisode), true, autoplay);
                                break;

                            case 'live':
                                actions.play(cfg.liveStream, true, autoplay, true);
                                break;
                        }
                    }

                    $this.find('.cover-out').removeClass('no-trans');

                } else {

                    // by default set recent episode on player                
                    if ($recentEpisode.length) {

                        //cfg.player.setSrc(recentEpisode);                    
                        actions.play(_getEpisodeData($recentEpisode), false, false);
                    } else { // if no recent episode - start immediately live stream 

                        actions.play(cfg.liveStream, true, true, true);
                    }
                }

                $this.find('.list, .cover-out').show(); // show list and cover 
                if (cfg.iScroll) {
                    cfg.iScroll.refresh();
                }
                // force initial lazyload 
                $(window).resize();
            }

            if (cfg.autoplay) {

                // Monitor if user paused playback (to prevent start autoplay in situation user has stopped palyback)
                cfg.player.media.addEventListener('pause', function (e) {

                    try {
                        localStorage.setItem('player.paused', 1);
                    } catch (e) { }
                });
                cfg.player.media.addEventListener('play', function (e) {

                    try {
                        localStorage.setItem('player.paused', 0);
                    } catch (e) { }
                });

                window.setTimeout(function () { // wait 2 seconds to avid detection of playing current player
                    try {

                        // if any player already has been paused - don't auto-play 
                        if (localStorage.getItem('player.paused') === null || parseInt(localStorage.getItem('player.paused')) === 0) {

                            // if any other player is not playing
                            if (localStorage.getItem('player.playing') === null || Math.floor(Date.now() / 1000) - localStorage.getItem('player.playing') > 2) {

                                cfg.player.play();
                                //actions.playFlag();
                            }
                        }
                    } catch (e) { }
                }, 2000);
            }


            //
            // buttons
            //

            // LIVE STREAM button - play live stream 
            $this.find('.listen-now').on('click', function () {
                actions.hideCover();
                actions.play(cfg.liveStream, true, true, true);
                return false;
            });

            // INFO button - show currenty set in player episode
            $this.find('button.info').on('click tap', function () {
                actions.showCover();
            });

            // SHARE view show 
            $this.find('button.share').on('click tap', function () {
                actions.showShareView();
            });


            //
            // Share view
            //
            $this.find('.shares a.close').on('click tap', function () {
                actions.hideShareView();
            });
            $this.find('.shares a.embed').on('click tap', function () {

                // prepare share urls
                _updateShareUrls();
                $('.embed-out').toggle();
            });

            $this.find('.shares a.email').on('click tap', function () {
                window.location.href = 'mailto:?subject=' + cfg.currentEpisode.title + '&body=' + cfg.currentEpisode.desc + " \n\nUrl: " + cfg.currentEpisode.url;
            });
            // sleect code on textarea focus 
            $('.embed-out > textarea').focus(function () {
                this.select();
            }).mouseup(function () {
                return false;
            });

            // set right file or stream; required when stream url initialy defined as empty mp3, but it have to be real file/stream 
            if (cfg.fileOrStream) {

                cfg.player.setSrc(cfg.fileOrStream);
            }

            var c = cfg;

            //
            // show live banner (currently playing) 
            //
            if (cfg.banner.url) { // there should be shown banner related to currently playing stream/file


                var formatTime = function (d) {
                    function pad(n) { return n < 10 ? '0' + n : n };
                    return [pad(d.getUTCHours()), ':',
                    pad(d.getUTCMinutes()), ':',
                    pad(d.getUTCSeconds())].join("");
                };

                var currServerHMStime = null, nextShowHMStime = '00:00:00', rotateBanner = function () {

                    // send only if show changed    
                    //console.log(currServerHMStime);
                    //console.log(nextShowHMStime);
                    if (currServerHMStime === null || currServerHMStime >= nextShowHMStime) {

                        $.get(c.banner.url, null, function (data, textStatus, jqXHR) {

                            // player banner url 
                            var imgUrl = $.trim(data.banner);
                            if (imgUrl !== '') {
                                _changeCover(c, imgUrl);
                            }
                            var curr = data.current, n = data.next;
                            // current 
                            $('#currScheduleImg').attr('href', curr.urlHost).html(curr.img);
                            $('#currScheduleTime').html(curr.time);
                            $('#currScheduleType').removeClass('live').removeClass('replay').html(curr.type).addClass(curr.type);
                            $('#currScheduleUrl').attr('href', curr.url).html(curr.title);

                            // current extendend
                            $('#phdCurrentShow').html(curr.phdCurrentShow);
                            $('#phdCurrentHosts').html(curr.phdCurrentHosts);
                            $('#phdCurrentGuests').html(curr.phdCurrentGuests);
                            $('#tab-show-info').click();
                            if (curr.phdCurrentGuests !== '') {
                                $('#phdTabGuests').show();
                            } else {
                                $('#phdTabGuests').hide();
                            }

                            // next                         
                            $('#nextScheduleImg').attr('href', n.urlHost).html(n.img);
                            $('#nextScheduleTime').html(n.time);
                            $('#nextScheduleType').removeClass('live').removeClass('replay').html(n.type).addClass(n.type);
                            $('#nextScheduleUrl').attr('href', n.url).html(n.title);

                            // show/hide contact form 
                            if (curr.type === 'live') {
                                $('#rForm').show();
                            } else {

                                // hide only if not focused 
                                if (!$("#FeedbackName").is(":focus") && !$("#FeedbackLocation").is(":focus") && !$("#FeedbackMessage").is(":focus") && !$("#FeedbackCaptcha").is(":focus")) {
                                    $('#rForm').hide();
                                }
                            }

                            // rememebr next show start time 
                            currServerHMStime = data.currTime;
                            nextShowHMStime = n.timeHMS;
                        });
                    } else {

                        // add 5 seconds to server time
                        d = new Date('2016-04-14T' + currServerHMStime + '+00:00');
                        d.setSeconds(d.getSeconds() + 5);
                        currServerHMStime = formatTime(d);
                    }
                };
                rotateBanner(); // in case banner not set 
                //window.setInterval(rotateBanner, cfg.banner.refresh * 1000);
                window.setInterval(rotateBanner, 5000);// check each 5 seconds
            }
 
            // play on banner cover click (if not already playing)           
            cfg.$widget.find('.cover-current').on('click.mtPlayer', function () {
                c.player.play();
                //try { actions.playFlag(); } catch (e) {}
            });

            if (cfg.pusleIfPause) {

                cfg.player.media.addEventListener('play', function (e) {

                    c.$widget.find('.mejs-playpause-button > button').removeClass('pulse').addClass('playing');
                    c.$widget.find('.cover-current').removeClass('clickable');
                    actions.playFlag();

                }, false);
                cfg.player.media.addEventListener('pause', function (e) {
                    c.$widget.find('.cover-current').addClass('clickable');
                    c.$widget.find('.mejs-playpause-button > button').removeClass('playing');
                }, false);

                c.$widget.find('.mejs-playpause-button > button').addClass('pulse');
            }

            //
            // Open player in new tab button
            //
            var popupPlayerClicked = function (e) {
                e.stopPropagation();    // don't fire play if not playing
                c.$widget.find('.mejs-playpause-button > button').removeClass('pulse'); // dont't pulse anymore 
                c.player.pause(); // if playing - pause it (new player will start payback automatically)
            };
            // Player popup player link click
            cfg.$widget.find('.new-tab').on('click.mtPlayer', function (e) {
                popupPlayerClicked(e);
            });
            // any other player popup click
            $('.popup-player-link').on('click.mtPlayer', function (e) {
                popupPlayerClicked(e);
            });

            //
            // keys bindings
            //
            if (cfg.useKeys) {

                $(window).on('keydown.mtPlayer', function (e) {

                    switch (e.keyCode) {

                        case 27:
                            actions.hideCover();
                            actions.hideShareView();
                            break;

                        case 32:

                            try {
                                if (!cfg.player.paused) {
                                    cfg.player.play();
                                    //actions.playFlag();
                                } else {
                                    cfg.player.pause();
                                }
                            } catch (e) {
                            }

                            break;

                        case 73:
                            actions.showCover();
                            break;

                        case 107:
                        case 187: // vlume up
                            cfg.player.setVolume(Math.min(cfg.player.media.volume + 0.1, 1));
                            break;

                        case 109:   // volume down 
                        case 189:
                            cfg.player.setVolume(Math.max(cfg.player.media.volume - 0.1, 0));
                            break;

                    }
                });
            }

            //
            // handle errors
            //
            /*cfg.player.loadeddata(){
                
            }*/

            // close errro message
            cfg.player.media.addEventListener('loadeddata', function () {

                $this.mtsoftUiAlertClose();
            });
            cfg.player.media.addEventListener('progress', function () {

                //$this.mtsoftUiAlertClose();
            });

            $this.find('audio').on('error', function (e) {

                return false;   // showing errors has been disabled bcause error was shwon when seting src = empty url 
                if (cfg.player.showErrors === undefined || cfg.player.showErrors) {

                    if (e.target.error.code !== 1) { // show error messages other than playback aborted 

                        var msg = cfg.txt.error[e.target.error.code] !== undefined ? cfg.txt.error[e.target.error.code] : cfg.txt.error.default;
                        $this.mtsoftUiStatus(msg, 'failed', 'bottom', { style: 'margin-top:10px;' });
                        //$this.mtsoftUiAlertIndicate();
                        c.$widget.find('.mejs-playpause-button > button').removeClass('pulse');
                        //cfg.player.pause();                    
                    }
                }
            });

            // watch for other playing elements and stop if playback started
            try { clearInterval(cfg.to1) } catch (e) { }
            $.mtPlayerFlagStarted = false;
            cfg.to1 = setInterval(function () {
                if (!$.mtPlayerFlagStarted && window['playbackStarted']) {
                    // playback started on other element - stop current
                    try { cfg.player.pause(); } catch (e) { }
                }
            }, 1000);

            return cfg;
        },
        playFlag: function() { 
            // set flag `playback started` to stop other playing elements on current or parent window
            try { clearTimeout(cfg.to2) } catch (e) { }
            window['playbackStarted'] = true; // turn ON  
            $.mtPlayerFlagStarted = true
            cfg.to2 = setTimeout(function () {
                window['playbackStarted'] = false;  // turn OFF
                $.mtPlayerFlagStarted = false
            }, 1000);
            // if nested on iframe 
            try {
                try { clearTimeout(cfg.to3) } catch (e) { }
                window.frames[0]['playbackStarted'] = true; // turn ON                
                cfg.to3 = setTimeout(function () {
                    window.frames[0]['playbackStarted'] = false;  // turn OFF
                    $.mtPlayerFlagStarted = false
                }, 1000);
            } catch (e) { }

        },
        /**
         * Play given episode 
         * 
         * @param {jQuery} $episode
         * @param {bool} showCover
         * @param {bool} autoplay
         */
        play: function (ed, showCover, autoplay, stream) {

            showCover = showCover || false;
            autoplay = autoplay || false;


            // reset cover
            window.clearInterval(cfg.liveBannerInt);
            cfg.$this.find('.cover-episode').show();
            cfg.$this.find('.cover-live').hide();

            // set player source and play imeediatelly if autoplay
            try {
                cfg.player.pause();
                cfg.player.setSrc(ed.media);
            } catch (e) {
            }
            cfg.$this.find('.mejs-duration').show(); // show total time
            if (autoplay) {
                cfg.player.play();
                //actions.playFlag();
            }

            // assign episode data as current
            cfg.currentEpisode = ed;

            // show epidsode cover
            if (showCover) {

                actions.showCover(ed, true);
            }

            if (stream) { // live stream - hide total length display 

                cfg.$this.find('.mejs-duration').hide();

                // show live stream banner
                cfg.$this.find('.cover-episode').hide();
                cfg.$this.find('.cover-live').show();

                _getLiveBanner();

                cfg.liveBannerInt = window.setInterval(_getLiveBanner, 10000);
                /* banner */ 
                /** get current song data  **/
                /* /httpdocs/thetransformationnetworkwebsite/site/player2/_js/player.js */
                //getData();
                //window.setInterval(function(){getData();}, 10000);    
            }
 
        },
        /**
         * Show given episode cover 
         * 
         * @param {type} $episode
         * @returns {undefined}
         */
        showCover: function ($episode, force) {

            if (!cfg.$this.find('.cover-out').hasClass('shown') || force) {

                // show cover
                _buildCover($episode ? _getEpisodeData($episode) : cfg.currentEpisode);
                if (cfg.coverIScroll) {
                    cfg.coverIScroll.refresh(); // height changed! - refresh iScroll
                }
                cfg.$this.find('.cover-out').addClass('shown');

            } else {

                actions.hideCover();
            }
        },
        hideCover: function () {
            cfg.$this.find('.cover-out').removeClass('shown');
        },
        /**
         * Show share view
         */
        showShareView: function () {

            // always use currnt episode
            var ed = cfg.currentEpisode;
            if (!cfg.$this.find('.shares-bg, .shares').hasClass('shown')) {

                _updateShareUrls();
                cfg.$this.find('.shares-bg, .shares').addClass('shown');

            } else {

                cfg.$this.find('.shares-bg, .shares').removeClass('shown');
            }
        },
        hideShareView: function () {

            cfg.$this.find('.shares-bg, .shares').removeClass('shown');
        },
        /**
         * Append next set of episodes (page) to existing episodes list 
         * 
         */
        listAppendEpisodes: function () {

            if (!cfg._updatingList) {

                // send ajax request for next porition on episodes                
                var currPage = cfg.currPage + 1;
                $.ajax({
                    url: cfg.urlBase + 'files/cache/' + cfg.host + '/episodes-' + currPage + '.html',
                    success: function (html) {

                        // append set of episodes to existing list 
                        cfg.$playlist.append(html);
                        if (cfg.iScroll) {
                            cfg.iScroll.options.snap = cfg.$list.find('li');    // fixes bug when snap used and content added to wrapper 
                            cfg.iScroll.refresh();
                        }

                        // play archived episode when click on 'play' button 
                        cfg.$this.find('a.play').not('.evnt').on('click tap', function (e) {

                            actions.play(_getEpisodeData($(this).parent()), true, true);
                            e.preventDefault();
                            return false;
                        });

                        // lazyload 
                        cfg.$playlist.find("img.th").not('.ll').lazyload({ threshold: 306, container: cfg.$list, effect: 'fadeIn' }).addClass('ll');
                        cfg.currPage++;

                    }, complete: function (jqXHR, textStatus) {

                        if (jqXHR.status == 404) { // episodes page not found - end of episodes list reached 
                            actions.msg('End of episodes list reached', 'bottom');
                        }

                        cfg._updatingList = false;
                    }
                });
                cfg._updatingList = true;

            } else {

                // ignore - already updating list 
            }

        },
        msg: function (txt, position, timeout) {

            timeout = timeout || 2000; // timoeut [s]
            if (!$('#playerMsg').length) {

                cfg.$list.append('<div id="playerMsg" class="message" style="' + (position === 'top' ? 'top:5px;' : 'bottom:5px;') + '">' + txt + '</div>');

                $('#playerMsg').hide().fadeIn('slow', function () {

                    cfg.msgTimeout = window.setTimeout(function () {

                        $('#playerMsg').fadeOut('slow', function () {
                            $(this).remove();
                        });
                    }, timeout);
                });
            }
        }
        // ...
    };


    // -------------------------------------------------------------------------
    //
    // define private functions (internal use only)
    //

    function _getEpisodeData($episode) {



        return $episode.data && $episode.data('id') ? {
            id: $episode.data('id'),
            img: $episode.find('img.th').attr('data-original'),
            title: $episode.find('div > h1').html().replace(/<b>..*?<\/b>/gm, ''),
            desc: $episode.find('div > p').html(),
            date: $episode.find('div > span').attr('data-dt'),
            url: $episode.find('div > span').attr('data-url'),
            media: $episode.find('[data-media]').attr('href')
        } : $episode;

    }

    /**
     * Build episode cover
     * 
     * @param {JSON} ed     epidsode data
     * @returns {undefined}
     */
    function _buildCover(ed) {

        if (ed) {
            var cv = cfg.$this.find('.cover > div');

            cv.find('h1 > img').attr('src', 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D');
            cv.find('h1 > img').attr('src', ed.img);
            cv.find('h1 > span').html(ed.title);
            cv.children('p.desc').html(ed.desc);
            cv.children('p.dt').html(ed.date);
            cv.find('a.url').html(ed.url).attr('href', ed.url).attr('target', '_blank');
        }
    }

    function _updateShareUrls() {

        var $s = cfg.$this.find('.shares'),
            // all social buttons links
            $sbs = $s.find('a.sh'),
            // current episode data 
            ce = cfg.currentEpisode;

        // refresh social buttons data
        $sbs.each(function () {
            var $this = $(this);
            try {
                $this.prop('href', $this.attr('data-href').replace('_URL_', ce.url).replace('_TEXT_', ce.title));
            } catch (e) {
            }
        });

        // refresh embed code
        // add episode id only if cover is shwon
        var id;
        if (cfg.$this.find('.cover-out').hasClass('shown')) {
            id = cfg.currentEpisode.live ? 'live' : cfg.currentEpisode.id;
        } else {
            id = false;
        }
        var q = '';
        q += (cfg.host !== 1 ? '?host=' + cfg.host : '');
        q += (id ? (q !== '' ? '&' : '?') + 'episode=' + id : '');
        $s.find('textarea').val(cfg.share.iframe.replace('_URL_', cfg.urlBase + q));
    }

    function _getLiveBanner() {

        if (cfg.liveStreamBanner) {
            var response = $.ajax({
                type: "GET",
                url: cfg.liveStreamBanner + '?' + Math.random(),
                async: false
            }).responseText;
            if ($('.show-cover').length && response == $('.show-cover').css('background-image').replace(/^url|[\(\)]/g, ''))
                return;
            $('.show-cover').css("background-image", "url(\"" + response + "\")");

            // test only 
            //$('.show-cover').css("background-image", "url(\"" + 'http://www.drpatshow.com/player/_banners/73.jpg' + "\")");
        }
    }

    function _changeCover(cfg, imgUrl) {

        var $imgs = cfg.$this.children('.cover-current').children('img').not('.placeholder'),
            $img = $imgs.filter('img[src!=""]').length ? $imgs.filter('img[src!=""]') : $($imgs[0]);

        if (imgUrl != $.trim($img.prop('src'))) { // new banner - replace existing 

            // load new banner to empty image and cross fade
            var $freeImg = $img.siblings('img').not('.placeholder');
            $freeImg.on('load', function () {

                $(this).siblings('img').not('.placeholder').fadeOut('slow', 'linear', function () {
                    $(this).prop('src', ''); // empty src
                });
                $(this).fadeIn('slow');
            });
            $freeImg.prop('src', imgUrl);
        }

    }

    // -------------------------------------------------------------------------
    //
    // apply plugin to default selector (optional)
    //
    $(function () {

        // init player
        //var pl = $(".widget-player").mtPlayer();

        // if rss feed data older than configured - refresh and cache episodes listing pages 
        //var baseUrl = pl.config('urlBase');
        //$.get(baseUrl + 'php/player.php?refresh=1&host=' + pl.config('host'));
    });

})();
