/**
 * mtsoft INPUT; handle form's input behaviour
 *
 * @uses modernizr (for media queries only)
 * @uses $.mtsoft [ $.mtsoft.key2char(), $.mtsoft.url() ]
 * @uses $.mtsoft.ui [ $.mtsoft.ui.alert(), $().mtsoftUiAlertClose(); ]
 * @uses $().mtsoftUiSmartLabel() (included)
 * @uses $.mask (jquery.maskedinput) 
 */

;
(function($, window, document, undefined) {
       
    // -------------------------------------------------------------------------
    //
    // plugin constructor
    //
    $.fn.mtsoftUiInput = function(params) {

        // initialize every element
        return this.each(function() {

            var $this = $(this);
            if ($this.mtsoftUiInputConfig() === undefined) { // input configuration not defined yet

                // base configuraiton
                // try to set input type
                var inpType = params && params.type ? params.type : ($this.data('type') ? $this.data('type') : null),
                        // set configuration
                        config = $.extend(true, {},
                        $.fn.mtsoftUiInput.defaults, // defaults, common for all input types
                        $this.data(), // parameters defined on html code with "data-" attribtues
                        (inpType ? $.mtsoftUiInput[inpType] : {}), // set of default parameters for given input type
                        ($.mtsoftUiInput[$this.attr('id')]!==undefined ? $.mtsoftUiInput[$this.attr('id')] : {}), // set of custom defined  parameters for given input type
                        params  // parameters given as plugin method arguments
                        );
                // set label and value nodes (can be overwriten for special inputs)
                config.$value = $this; // node where real value to submit is keep
                // node where value label is shown for user; by default equal to value node;
                // if node with id equal to value node id + Label - use it
                if ($('#' + $this.attr('id') + 'Label').length) { // label input is other than value input

                    // set value input label
                    config.$label = $('#' + config.$value.attr('id') + 'Label');

                    // on label(for input row, not value label) click focus first of related inputs 
                    $('label[for="' + $this.attr('id') + '"]').on('click.mtsoftUiInput', function() {
                        $(config.$label[0]).focus();
                    });
                } else {
                    config.$label = $this;
                }

                config.$alertParent = config.$alertParent ? config.$alertParent : config.$label;    // node where input related alerts will be shown
                                
                // if input is required and no failed alert message - set default 'required'
                if (config.required && config.msgs.failed === undefined) {
                    config.msgs.failed = config.txt.required;
                }

                // use mask
                if (config.mask) {
                    try {
                        config.$value.mask(config.mask);
                    } catch (e) {
                    }
                }

                // if smart label defined - set default value equal
                if (config.smartlabel) {
                    config.default = config.smartlabel;
                    config.$label.attr('data-smartlabel', config.smartlabel);
                }
                // ---

                // init input (do nothing by default)
                // can contain above configuration changes
                config.oninit($this);
                // fix regex values if given as String (via data- attribute); ex. "^[0-9]$" => /^[0-9]$/
                if (config.allowOnly && typeof (config.allowOnly) === 'string') {

                    config.allowOnly = new RegExp(config.allowOnly);
                }

                // assign config to input node
                $this.mtsoftUiInputConfig(null);
                $this.mtsoftUiInputConfig(config);
            } else {
                
                config = $this.mtsoftUiInputConfig();
            }


            // disable paste (Ctrl+V or Paste Command)
            if (config.disablePaste) {

                config.$label.on("paste.mtsoftUiInput", function(e) {

                    e.preventDefault();

                }).on('keyup.mtsoftUiInput', function() {

                    if (e.keyCode === 86 && e.ctrlKey) {
                        return false;
                    }

                });
            }

            // on input focus hide all alerts and remove style classes - set input neutral
            config.$label.on('focus.mtsoftUiInput', function() {

                var cfg = $this.mtsoftUiInputConfig();
                // set neutral (remove invalid/caution style/messages) only if no valid (status other than success)
                if (cfg._validClient && cfg._validServer) {
                } else {
                    $this.mtsoftUiInputSetNeutral();
                }
            });
            // change input value once input label changed
            _exec($this, 'onchange', 'change');

            // allow to enter only characters defined on regex
            if (config.allowOnly) {

                config.$label.on('keydown.mtsoftUiInput', function(e) {

                    return _keydown(e, config);
                });
            }

            // validate input value on given event
            if (config.validateOn) {
                    
                if (config.validateOn !== 'change') {

                    // validate on custom event
                    config.$label.on(config.validateOn + '.mtsoftUiInput', function() {
                        
                        _validate($this, config.validateOn);
                    });
                            
                    if (config.validateOn === 'keyup' || config.validateOn === 'keydown') {
                        config.$label.on('blur.mtsoftUiInput', function() { 
                            // Despite above validation on custom 'validateOn' event
                            // ALWAYS validate on 'change' no matter what base validation event is defined.
                            _validate($this, 'blur');
                        });
                    }
                    
                } else {

                    // Despite above validation on custom 'validateOn' event
                    // ALWAYS validate on 'change' no matter what base validation event is defined.
                    // If input value is required valiate on each blur;
                    // This will inicate input as 'invalid' when user leaves empty value whithout entering anything (on blur)
                    //var evnt = 'blur'; //config.required ? 'blur' : 'change';
                    //var evnt = config.validateOn === 'keyup' ? 'change' : 'blur';
                    //config.$label.on(evnt + '.mtsoftUiInput', function() {
                    config.$label.on('blur.mtsoftUiInput', function() {
                        //config.$label.on('blur.mtsoftUiInput', function() {
                        
                        _validate($this, 'blur'); // (event) value
                    });
                }
            }

            // show input description            
            if (config.showDescriptionOn) {

                _exec($this, 'description', config.showDescriptionOn, config.$label);
            }

            //
            // Handle inputs which should be validated in paralel 
            // (for multi inputs form values like time/date periods) 
            // @see also config.validateOnFailed 
            //
            if (config.$paralels) {

                // If curent input (one of paralell inputs) is focused 
                // - set all other statues as neutral (in case statues failed or other) 
                config.$label.on('focus.mtsoftUiInput', function() {
                    for (var i = 0, $p; $p = config.$paralels[i]; i++) {
                        $p.mtsoftUiInputSetNeutral();
                    }
                }).on('blur.mtsoftUiInput', function() {

                    // If any of paralell inputs (not current) is invalid 
                    // style current and all other paralell inputs as invalid.
                    for (var i = 0, $p; $p = config.$paralels[i]; i++) {

                        if (!$p.mtsoftUiInputValidate('blur')) { // one of paralell inputs is invalid 

                            // style current as invalid 
                            $(this).addClass(config.cssPrefix + 'failed');
                            // style other paralells as invalid 
                            for (var j = 0, $p; $p = config.$paralels[j]; j++) {
                                
                                $p.mtsoftUiInputConfig().$label.addClass(config.cssPrefix + 'failed');
                            }
                            break;
                        }
                    }
                });
            }

            // assign custom event handlers
            // (except 'onchange' assigned above to re-print input value from label node to value node)
            var events = ['focus', 'blur', 'click', 'keyup', 'keypress'];
            for (var i in events) {

                var e = events[i];
                if (config['on' + e]) {
                    _exec($this, 'on' + e, e);
                }
            }

            /*if (config.max) {
                // set input's "maxlength" attribute if defined
                config.$label.attr('maxlength', config.max);
            }*/
            // select text in input on focus ? 
            if (config.selectOnFocus) {

                config.$label.on('focus.mtsoftUiInput', function() {
                    this.select();
                });
            }

            if (config.translate) {

                config.$label.mtsoftUiInputTranslateCopy();
            }

            // use smart-label MUST BE AT THE END (to aviod problems while validating)
            if (config.smartlabel) {

                $(config.$label).mtsoftUiSmartLabel(config.smartlabel);                
            }
        });
    };
    /**
     * Get / set plugin configuration.
     *
     * @param {Mixed} param     Configuration parameter name
     *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
     *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
     *                                    All other then given in JOSN argument parameters will be keep untouch;
     *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
     *                           null - resets current configuration (remove all parameters)
     *
     * @param {Mixed} val       configuration parameter value
     *
     * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
     *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
     */
    $.fn.mtsoftUiInputConfig = function(param, val) {

        var c = 'config.mtsoftUiInput',
                $this = $(this), cfg = $this.data(c);
        // reset current configuration
        if (param === null) {
            $this.data(c, null);
            return cfg;
        }

        if (val !== undefined || typeof(param) === 'object') { // setter

            // update single parameter value
            if (val !== undefined) { // single parameter value
                
                try {
                    cfg[param] = val; // assign value
                } catch (e) {
                    cfg = {param: val};
                }
                $this.data(c, cfg); // update

            } else {    // multiple parameters values defined as JSON object {}

                // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                cfg = $.extend(true, {}, cfg, param);
                $this.data(c, cfg); // update
            }
            return cfg;
        } else { // getter

            return param !== undefined ? cfg[param] : cfg; // return single parameter value or whole configuration object
        }
    };
    /**
     * Show alert for input
     *
     * @param {String} type             message type [ neutral | success | caution | failed ]
     * @param {JSON} ajaxAlertParams    alert parameters taken from ajax request answer
     * @param {JSON} alertParams        additional alert params
     */
    $.fn.mtsoftUiInputAlert = function(type, alertParams, ajaxAlertParams) {

        var $this = $(this), config = $this.mtsoftUiInputConfig(); // $this is input
        //if (type !== 'neutral') {

        ajaxAlertParams = ajaxAlertParams && ajaxAlertParams.alerts ? ajaxAlertParams.alerts : ajaxAlertParams;

        //
        // get right message for alert type
        //

        // check is there message defined for current invalid validation rule(s)
        var _msg = '', rule, invRs = config.$value.mtsoftUiInputConfig('_invalidRules');
        if ((type === 'failed' || type === config.validateOnKeyupInvalid) && invRs.length) {

            for (var i in config.validationRules) {
                rule = config.validationRules[i];
                if (rule.rule && rule.msg && $.inArray(rule.rule, invRs) > -1) {
                    _msg += ((_msg !== '' ? '<br>' : '') + rule.msg);
                }
            }
        }

        // detect right message
        var msg = alertParams && alertParams.msg ? alertParams.msg : // message given directly via argument
                ajaxAlertParams && ajaxAlertParams.msg ? ajaxAlertParams.msg : // message received from server as ajax validation result
                ajaxAlertParams && config.msgs['ajax_' + type] !== undefined ? config.msgs['ajax_' + type] : // ajax validation default message
                _msg !== '' ? _msg : config.msgs[type]; // special message defined for rule or default message
        
        // pass alert position 
        
        var $label = $(config.$label[0]), //  if more than one labels - show alert for first found (0) 
            params = $.extend(true, {}, {
            //msg: msg,
                    // show status alert for current input label node;
            // custom alert parent can be defined at config.$alertParent
            parent: config.$alertParent ? config.$alertParent : $label,
            reposition: config.alertReposition,
            onClick: function($alert) { // focus related to alert input on alert click
                $alert.mtsoftUiAlertConfig().$parent.focus();
            }
        }, config.alert, ajaxAlertParams, alertParams);
        
        //
        // show alert (with meesage or only icon)
        //
        if (msg !== undefined && msg !== null) { // if message is defined

            if (msg !== '') { // if there is some message text - always use status message

                // This occurs when config.alertType is 'icon' or 'iconInside' but some message text is present
                // show as status alert with message
                params.alertPosition = config.alertPosition; // show in position defined for input
                $label.mtsoftUiAlertInput(msg, type, params);
            } else {

                // show right alert type message
                switch (config.alertType) {

                    case 'icon':
                        $label.mtsoftUiAlertInputIco(type, msg, params);
                        break;

                    case 'iconInside':
                        $label.mtsoftUiAlertInputIcoInside(type, msg, params);
                        break;

                    default:
                    case 'status':
                        if (msg !== '') {
                            params.alertPosition = config.alertPosition; // show in position defined for input
                            $label.mtsoftUiAlertInput(msg, type, params);
                        } else { // if message is empty - just show icon; used when config.alertType is status, but alert message is empty
                            $label.mtsoftUiAlertInputIco(type, msg, params);
                        }
                        break;
                }
            }
        }
        //}
    };
    /**
     * Validate SINGLE input
     *
     * @param {String} valEvent     validate for what event [ blur | submit ]
     */
    $.fn.mtsoftUiInputValidate = function(valEvent) {

        //this.each(function() {
        return _validate($(this), valEvent !== undefined ? valEvent : 'submit');
        //});
    };

    /**
     * Set input value 
     * @param {type} value
     * @returns {unresolved}
     */
    $.fn.mtsoftUiInputSetValue = function(value) {
        
        return this.each(function() {
            var $this = $(this), cfg = $this.mtsoftUiInputConfig();
            if (cfg) {
                $this.mtsoftUiInputSetNeutral();               
                return cfg.setValue(value);
            }
        });
    };

    /**
     * Set input as neutral (inital) state
     *
     * @param {String} valType      validation type: [ client | server ]
     * @param {Mixed} msg          alert message or alert object
     * @param {Bool} silent         if true don't stylize input - just set right validation state
     */
    $.fn.mtsoftUiInputSetNeutral = function(valType, msg, silent) {

        return this.each(function() {

            //log('Neutral ...' + $(this).attr('name'));
            _setState($(this), 'neutral', valType, msg, silent);
        });
    };
    /**
     * Set input as invalid;
     *
     * @param {String} valType      validation type: [ client | server ]
     * @param {Mixed} msg           alert message or alert object
     * @param {Bool} silent         if true don't stylize input - just set right validation state
     */
    $.fn.mtsoftUiInputSetInvalid = function(valType, msg, silent) {

        return this.each(function() {

            _setState($(this), 'invalid', valType, msg, silent);
        });
    };
    /**
     * Set input as valid;
     *
     * @param {String} valType     validation type: [ client | server ]
     * @param {Mixed} msg        alert message or alert object
     * @param {Bool} silent     if true don't stylize input - just set right validation state
     */
    $.fn.mtsoftUiInputSetValid = function(valType, msg, silent) {

        return this.each(function() {

            _setState($(this), 'valid', valType, msg, silent);
        });
    };
    /**
     * Restore input state it was before neutral state
     * (input set css class & alert message)
     * @returns {unresolved}
     */
    $.fn.mtsoftUiInputRestoreState = function() {

        return this.each(function() {

            //log('Restoring ...' + $(this).attr('name'));
            //_setState($(this), 'valid', type, msg);
            var config = $(this).mtsoftUiInputConfig();
            if (config.$label.data('_lastState')) {
                config.$label.addClass(config.cssPrefix + config.$label.data('_lastState'));
                if (config.$label.data('_lastState') === 'failed') {
                    // mark related to input <label> element as invalid
                    config.$form.find('label[for="' + config.$value.attr('id') + '"]').addClass(config.cssPrefix + 'invalid');
                }
            }
            if (config.$label.data('_lastAlert')) {

                config.$value.mtsoftUiInputAlert(config.$label.data('_lastState'), $.extend(true, config.$label.data('_lastAlert'), {open: {mode: 'show'}}));
            }

            // reset
            config.$label.data('_lastState', null);
            config.$label.data('_lastAlert', null);
            // DEBBUG only
            dbg(function($input, value, rule, result) {
                console[result ? 'info' : 'warn']('(CLI-restored) ' + $input.attr('name').replace("data[", '').replace('][', '.').replace(']', '') + ' = "' + value + '" { ' + rule + ' => ' + result + ' }');
            }, [config.$value, config.getValue(), '?', config._validClient && config._validServer]);
        });
    };


    /**
     * For mulitple languages inputs in form
     * (ex. Model.field_name.eng, Model.field_name.pol, Model.field_name.esp ...)
     * Method reprints value from current input node to all related 
     * (this same value in other languages) 
     * For models with translate bahavior (and i18n table).
     */
    $.fn.mtsoftUiInputTranslateCopy = function() {

        return this.each(function() {

            $(this).on('blur.mtsoftUiInput', function() {
                
                var v = $(this).val(),
                        // remove lang id part (Eng) 
                        id = $(this).attr('id').slice(0, -3);

                $('[id^="' + id + '"]').not($(this)).each(function() {
                    if ($.trim($(this).val()) === '') {
                        $(this).val(v);
                        try {
                            $(this).mtsoftUiInputSetNeutral();
                        } catch (e) {
                        }
                    }
                });
            });
        });
    };


    /**
     * Visually stylize single input
     *
     * @param {jQUery} $input       target input to stylize
     * @param {String} config       input config
     * @param {String} msgType      message type [ success | failed | caution | info | caution ]
     * @param {String} msg          custom message; if no given - default message for msgType will be shown (if any)
     * @param {String} valOrigin    validation origin/source of styling: [ server | client ] (validation)
     */
    function _stylize($input, config, msgType, msg, valOrigin) {
        
        var id = config.$value.attr('id'),
        $inputLabel = $('label[for="' + id + '"]'), // input label 
        $rowLabel = $(config.$label).parents('.row-combined').filter(':first').parents('.input-out').filter(':first').find('label[for]').filter(':first'); // inputs row label (if multiple inputs on row) 
        //,$inpLabel = config.$label; // if mulitple labels - take always first //$(config.$label[0])
        // clear all input classess (clear visuals)        
        config.$label.removeClass(config.cssPrefix + 'success ' + config.cssPrefix + 'failed ' + config.cssPrefix + 'caution ' + config.cssPrefix + 'info ' + config.cssPrefix + 'help');
        $inputLabel.removeClass(config.cssPrefix + 'invalid'); // clear invalid label
        // if multiple inputs ofr sinlge label (on forma row) 
        $rowLabel.removeClass(config.cssPrefix + 'invalid');

        // add input status css class
        config.$label.addClass(config.cssPrefix + msgType);
        if (msgType === 'failed') { // if failed - stylize input label too

            $inputLabel.addClass(config.cssPrefix + 'invalid');
            $rowLabel.addClass(config.cssPrefix + 'invalid');
        }

        //if (!config.$value.mtsoftUiAlerts('status', msgType).length || // only if not already shown (removes flicker when validation on 'keyup')
        //      valOrigin === 'server') { // always stylize after server side (ajax) validation
        var anyAlert = config.$value.mtsoftUiAlerts('status', msgType).length, // is there now any alert shown?
                alertParams = {};


        // remove all other status alert messages immediatelly without any animations
        config.$alertParent.mtsoftUiAlertClose('status', null, null, true);  // true - hide immediatelly no matter what config.close.mode is set
        if (anyAlert) {
            alertParams = {open: {mode: 'show'}}; // to prevent flicker due show/hide alert animation force alert to show immediatelly
        }
        
        // show right status type message near input 
        config.$value.mtsoftUiInputAlert(msgType,
                // client side validation result
                valOrigin === 'client' ? $.extend(true, alertParams, {msg: msg}) : null,
                // server side validation result
                valOrigin === 'server' ? typeof(msg) === 'object' ?
                $.extend(true, msg, alertParams) : $.extend(true, {msg: msg}, alertParams) :
                null);
        //}
    }

    /**
     * Set input state:
     *
     *  - set internal 'valid' flag
     *  - add right class to input (label input)
     *  - show right alert message (if any)
     *
     * @param {type} $input
     * @param {mixed} status   [ neutral | valid | invalid ]
     * @param {String} type     [ client | server ]
     * @param {Mixed} msg      alert message (String) or alert object (JSON)
     * @param {Bool} silent     if true don't stylize input - just set right validation state
     *
     */
    function _setState($input, status, type, msg, silent) {

        // set right input used for validation; 
        // if other than real input (ex. type="file") input id is passed on 'data-input-id' for input replacement
        if ($input.attr('data-input-id')) {
            $input = $('#' + $input.attr('data-input-id'));            
        }
        
        var config = $input.mtsoftUiInputConfig(),
            // set invalid only if status is 'invalid' or false; for other statuses assume input value as valid
            valid = !status || status === 'invalid' ? false : true,
            // for neutral status set input as NOT validated
            validated = status !== 'neutral' ? config.getValue() : false,
            //cssClass = !status || status === 'invalid' ? 'failed' : status !== 'neutral' ? 'success' : '',
            msgType = !status || status === 'invalid' ? 'failed' : status !== 'neutral' ? 'success' : '';

        // execute callback 
        _exec($input, config.beforeSetState);

        //
        // set internal flag -> input is invalid
        //
        if (type === 'client') {

            // client side validation result
            $input.mtsoftUiInputConfig({_validClient: valid, _validatedClient: validated});
        } else {

            if (type === 'server') {

                // 'server' side validation (ajax validation) result
                $input.mtsoftUiInputConfig({_validServer: valid, _validatedServer: validated});
                //log('server: ' + valid);
            } else {

                // neutral status - only reset visuals
                // - set client/server as valid and not validated
                $input.mtsoftUiInputConfig({valid: true, _validatedClient: false, _validServer: true, _validatedServer: false});
            }
        }
        // set staus flag [ neutral | valid | invalid ]
        $input.mtsoftUiInputConfig({status: status});

        //
        // stylize input (add right to status class)
        //

        if (status === 'neutral') {

            // if input goes to neutral state - remember current css class
            // and shown alert (to restore in future if required)

            /**
             * Helper - get current input state css class
             * @param {type} set
             * @param {type} def
             * @returns {unresolved}
             */
            var getParamFromClasses = function(set, def) {

                var name = '';
                while (set.length) {

                    name = set.shift();
                    if (config.$label.hasClass(config.cssPrefix + name)) {
                        return name;
                    }
                }
                return def; // unknown/no given value - return default (undefined if no given)
            };
            // get current css class of input state
            config.$label.data('_lastState', getParamFromClasses(['success', 'failed', 'caution', 'info', 'help']));
            // get currently shown alert (if any) and rememeber it
            var $alert = config.$label.mtsoftUiAlerts('status').pop();
            if ($alert) {

                var _config = $alert.data('config.mtsoftUiAlert');
                config.$label.data('_lastAlert', _config);
            }

            // remove styling and all status alert messages
            _stylize($input, config, 'neutral', null, type);
            //$input, config, msgType, msg, cssPrefix
            //config.$label.mtsoftUiAlertClose('status');
        } else {

            // remove previous state and it'e alert message as is not valid now
            config.$label.data('_lastState', null);
            config.$label.data('_lastAlert', null);
        }

        // remove other statuses css classes (reset)


        //
        // Stylize input & show alert (with message)
        //

        //
        // show alert message ONLY if not default value (empty; etc...)
        //

        if (config.required || // input is requried
                config.getValue() !== config.default || // some value other than default
                type === 'server') { //  set state set on server side (force)

            //if (!config.$label.mtsoftUiAlerts('status', msgType).length) { // only if not already show (makes sense when validation on 'keyup')

            // if validation performed on keyup and input value is invalid
            if (config._validatingOn === 'keyup' && !valid) {

                if (config.validateOnKeyupInvalid) {

                    // show defined alert type when input value is not valid
                    if (silent === undefined || !silent) { // only if not silent mode

                        _stylize(config.$label, config, config.validateOnKeyupInvalid, msg, type);
                    }

                } else {

                    // set neutral state / show nothing when input value is not valid
                    // (invalid input value and validation on keyup)
                    _stylize(config.$label, config, 'neutral', null, type);
                }

                // force validation on blur set input value as un-validated
                //$input.mtsoftUiInputConfig({_validatedClient: null, _validatedServer: null});

            } else { // validation NOT on keyup or on keyup but valid

                // show default valid/invalid message
                //console.log(silent);
                if (silent === undefined || !silent) { // only if not silent mode

                    _stylize(config.$label, config, msgType, msg, type);
                }
            }

            //}
        }
        
        //
        // execute right callback
        //
        if (status === 'neutral') {

            _exec($input, config.neutral);
        } else {

            if (type === 'client') {

                if (valid) {
                    _exec($input, config.validateOnSuccess);
                } else {                    
                    _exec($input, config.validateOnFailed);
                }
            } else {

                if (valid) {
                    _exec($input, config.validateOnSuccessAjax);
                } else {
                    _exec($input, config.validateOnFailedAjax);
                }
            }
        }
    }

    /**
     * Button with some action(s) assigned for input.
     *
     * Can posses single label directly on button:
     *  button
     *      label
     * or mulitple labels as span childrends of button:
     *  button
     *      span#inputId-action1
     *          action 1
     *      span#inputId-action2
     *          action 2
     *      span#inputId-action3
     *          action 3
     *
     * To fire callback and change button status to target sub-label use:
     *  $('#inputIdBtnName').trigger('set', ['inputId-action2']);
     *
     *
     * @param {JSON} params
     */
    $.fn.mtsoftUiInputButton = function(params) {

        return this.each(function() {

            var $this = $(this), config = $.extend(true, {}, {
                callback: {
                    // for multiple sub-labels:
                    // ex. 'inputId-action1': function() {},  'inputId-action2': function() {}
                    // or for single label/button
                    // ex. :function() {}
                },
                trigger: null
            }, $this.data(), params);

            /**
             * Go to next state of button (show next in order span sub-label)
             *
             * @param {jQuery} $this    button object
             * @param {jQuery} $curr    currently shown span object (sub-label)
             */
            var _cycle = function($this, $curr) {
                
                var $_curr;
                if ($this.children().length > 1) { // only if more than one sub-label

                    // cycle sub-labels and execute callbacks
                    if ($curr.next('span').length) {

                        $_curr = $curr.next('span');
                    } else {

                        $_curr = $curr.parent().children('span').filter(':first');

                    }

                    if ($curr !== $this) { // only if sub-labels / sub-actions present
                        $curr.hide();
                        $_curr.show();
                        if ($_curr.attr('title')) {
                            $this.attr('title', $_curr.attr('title'));
                        }
                    }
                }
            };

            /**
             * Execute callback for given sub-label
             * Callback can be defined directly on (config.callback())
             * or as sub-label id attribute parameter (config.callback[id]())
             *
             * @param {jQUery} $curr    target sub-label node to identify right action to execute
             */
            var _exec = function($curr) {

                if (typeof(config.callback[$curr.attr('class')]) === 'function') { // mulitple sub-labels

                    // execute callback for button sub-action
                    config.callback[$curr.attr('class')]();
                } else {
                    if (typeof(config.callback) === 'function') {   // single label

                        // execute callback for button action
                        config.callback();
                    }
                }
            };
            // initially re-print title
            $this.attr('title', $(this).children(":visible").attr('title'));
            // assign click event
            $this.on('click.mtsoftUiInputButton', function(e) {

                var $this = $(this), $curr = $this.children(":visible").length ? $this.children(":visible") : $this;
                _cycle($this, $curr); // show right, next to given sub-label (if any)
                _exec($curr); // execute callback for given sub-label
            });
            // bind SET event (to show sub-label / run callback exactly for target sub-label)
            $this.bind('set.mtsoftUiInputButton', function(e, id) {

                var $this = $(this), $curr = $this.children('span.' + id);
                // hide all first
                $this.children('span').hide();
                _cycle($this, $curr); // show right, next to given sub-label (if any)
                _exec($curr); // execute callback for given sub-label
            });
        });
    };
    //
    // related to input methods
    //

    /**
     * Smart label (placeholder hidden once input get focus)
     *
     * @param {String} label      label text
     * @param {String} cssClass   smart label css class (ex. to add image background to label)
     *
     */
    $.fn.mtsoftUiSmartLabel = function(label, cssClass) {

        cssClass = cssClass || '';
        return this.each(function() {

            var $this = $(this);

            // initially set label (if not any value already)
            if ($.trim($this.val()) === '') {   // input has no value

                if (!$this.is(":focus")) {  // input not focused

                    $this.val(label).addClass('mtf-smart-label').addClass(cssClass);
                }
            }

            if ($this.attr('type') === 'password') { // this is password type input - add phantom text input

                var id = $this.attr('id'),
                        name = $this.attr('name'),
                        _class = $this.attr('class'),
                        styles = $this.attr('style');

                $this.after('<input type="text" id="phantom_' + id + '" name="phantom_' + name + '" value="' + label + '" />');
                var n_ = $this.next('#'.id);
                
                n_.addClass('mtf-smart-label ' + cssClass + ' ' + _class).attr('style', styles);
                $this.hide();
                n_.show();

                n_.on('focus.mtsoftUiSmartLabel', function() { // phantom text input

                    $(this).hide();
                    $this.show();
                    $this.focus();
                });
                $this.on('blur.mtsoftUiSmartLabel', function() { // password input

                    if ($(this).val() === '') {

                        $(this).hide();
                        n_.addClass($this.attr('class')).attr($this.attr('style'));
                        n_.show();
                    }
                });                

            }

            $this.on('focus.mtsoftUiSmartLabel', function() { // on focus remove smart-label

                if ($.trim($(this).val()) === label) {

                    $(this).val('');
                }
                $(this).removeClass('mtf-smart-label').removeClass(cssClass);

            }).on('blur.mtsoftUiSmartLabel', function() {   // on blur - add smart label only if no value entered

                if ($.trim($(this).val()) === '') {
                    
                    $(this).val(label).addClass('mtf-smart-label').addClass(cssClass);
                }
                else {

                    $(this).removeClass('mtf-smart-label').removeClass(cssClass);
                }
            }).data('smartlabel', label);   // required on validation to check current value is not smartlabel value

            // inital state
            if ($this.val() === label) {
                $this.val(label).addClass('mtf-smart-label').addClass(cssClass);
            }
        });
    };
    // OR
    //$.extend({
    //mtsoftUIPlugInName: function(params) {}
    //});



    // -------------------------------------------------------------------------
    //
    // define private functions (internal use only)
    //

    /**
     * Execute function from functions defiend for input or as event handler
     *
     * @param {jQuery} $input       input object
     * @param {mixed} callback      [String] callback name (must be defined on conifg object as config.callback = function(){})
     *                              [function]  callback defiend as function
     * @param {String} event        input event name (ex. click or mouseover)
     * #param {jQuery}              if event source is other than $input 
     */
    function _exec($input, callback, event, $eventSrc) {

        if (event) {

            $($eventSrc !== undefined ? $eventSrc : $input).on(event + '.mtsoftUiInput', function() {

                return $input.mtsoftUiInputConfig()[callback](); // this will allow to use 'this' as config object
            });
        } else {

            //return callback();
            return callback.apply($input.mtsoftUiInputConfig(), []);
        }
    }


    /**
     * Validate input value
     *
     * @param {jQUery} $input           processed input (input elemetn value not label) jQuery object
     * @param {String} validatingOn     event which trigged validation [submit | change | blur | keyup]
     *
     * @return Mixed                    true if validation successfull
     *                                   false if validation failed
     *                                   null if validation not performed
     */
    function _validate($input, validatingOn) {
        
        var config = $input.mtsoftUiInputConfig(), value = config.getValue(), result = true;
        $input.mtsoftUiInputConfig('_validatingOn', validatingOn); // rememeber event we validating for

        if (config.doValidate) {    // do validate flag is on; otherwise don't validate (return null)

            //
            // input should be validated
            //

            // always validate if value is required 
            // or value is other than default (some value entered by user)
            // BUT validated input is not hidden (usable when some part of form is not reuired )
            // 
            // To force validation even for not visible input - add 'data-force-validation' attribute to it
            //
            if (($input.is(':visible') && $input.css('visibility')!=='hidden' || $input.attr('data-force-validation')) && 
                    (config.required || value !== config.default)) {

                //
                // value is required or some value (not default) exists
                //

                //
                // validate on client side
                //
                var clientSideValidation = _validateClient($input);
                if (clientSideValidation === true) { // validated succesfully on client side (or val. not defined)

                    // check is there server (ajax) validation url present
                    if (config.validationUrl) {

                        if (config.required && value === '') {

                            // don't validate on server if value is required, but empty
                            // it's required and empty - set invalid for client validation
                            $input.mtsoftUiInputSetInvalid('client');
                            result = false;
                        } else {

                            //
                            // validate on server side using ajax request
                            //
                            result = _validateServer($input, validatingOn); // asynchronically
                        }

                    } else {

                        //
                        // client side validation SUCCESS or NOT DEFINED
                        //

                        // In situation when form has been already submitted
                        // and this input value is invalid - server validation falgs were set;
                        // In this case return false (invalid input)
                        if (config._validatedServer === value && !config._validServer) {

                            $input.mtsoftUiInputSetInvalid('server');
                            return false;
                        }

                        result = true;
                    }

                    // input is required, but no validation method defined and no value has been entered 
                    if (config.required && (value === config.default || value === '')) { // '' if smartlabel used 

                        $input.mtsoftUiInputSetInvalid('client');
                        result = false;
                    }

                } else {

                    //
                    // client side validation FAILED
                    //
                    //$input.mtsoftUiInputSetNeutral();
                    result = false;
                }

            } else {

                //
                // value not required AND default value is set
                //
                $input.mtsoftUiInputSetNeutral();
                result = true;
            }

        } else {

            //
            // validation not performed (ommited - doValidate flag is off)
            //

            //result = null;
            result = true;
        }
                
        $input.mtsoftUiInputConfig('_validatingOn', null); // clear event name we validation for
        return result;
    }

    /**
     * Validate input value
     *
     * @param {jQuery} $input              processing input
     */
    function _validateClient($input) {

        var config = $input.mtsoftUiInputConfig(), value = config.getValue();

        // Only if not already validated for current value
        // OR input is validated on keyup, but current validation is on blur (force validation instead restoring last state
        // because last state can be without any alert or with alert other than failed (caution or other...))
        if (config._validatedClient !== value || (config.validateOn === 'keyup' && config._validatingOn !== 'keyup')) {
            //
            // do client-side validation ( execute config.validate() )
            //

            var result = _exec($input, config.validate);

            if (result !== null) {  // validation performed

                if (result) { // validation successed on client side

                    // if validating on form submit - don't stylize / don't show success alert
                    $input.mtsoftUiInputSetValid('client', undefined, config._validatingOn === 'submit' ? true : false);
                } else {

                    //
                    // validation failed (on client side)
                    //
                    $input.mtsoftUiInputSetInvalid('client');
                }

                return result;
            } else {

                //
                // no client-side validation defined - return scuccess
                //
                return true;
            }
        } else {

            //
            // validation for current input value already performed
            //
            if (config._validatingOn !== 'submit') {
                $input.mtsoftUiInputRestoreState();
            }

            // return validation result
            return config._validClient;
        }
    }

    /**
     * Validate on server side using ajax request
     *
     * @param {type} $input              processing input
     */
    _validateServer = function($input) {

        var config = $input.mtsoftUiInputConfig(), value = config.getValue();
        if (config._validatingOn !== 'submit') { // don't validate using ajax when submitting form

            // only if not already server/ajax validated for current value
            if (config._validatedServer !== value) {

                /**
                 * Function send alidation ajax request in right format
                 */
                var _send = function() {

                    // validate only if value is required
                    // or some value entered (not default/empty)
                    if (config.required || value !== config.default) {

                        // convert post data to CakePHP POST convention (Model.filed_name => data[Model][field_name])
                        var inp = _getInputDetails($input), data = {data: {}}, vData = {data: {}}, ch;
                        
                                                
                        if (config.validationSerialize) {
                            
                            // serialzie this input form's other inputs values
                            data = config.$form.serialize();
                            
                        } else {

                            //console.log(inp);
                            // current input and it's value
                            data.data[inp.model] = {};
                            data.data[inp.model][inp.fieldname] = value;    // value as:    data[Model][field_name]
                            data.value = value; // value as:    data.value (can be overwritten by validationData OR validationAjaxData()

                            // always include current db table/model row id (if set) 
                            if (config.modelId) {
                                data.data[inp.model]['id'] = config.modelId;
                            }

                            // custom data defined on validationData (constant; ususaly generated on server side) 
                            for (var fieldname in config.validationData) {
                                ch = fieldname.split(".");
                                vData.data[ch[0]] = {};
                                vData.data[inp.model] = {};
                                vData.data[ch[1] ? ch[0] : inp.model][ch[1] ? ch[1] : fieldname] = config.validationData[fieldname];
                            }
                            // NOTE: validationAjaxData() method must return right formated JSON object: {name: 'value', name2: 'value2', ...}
                            data = $.extend(true, {}, data, vData, config.validationAjaxData()); // add custom parameters from 'validationData' (constant) or generated by 'validationAjaxData' function
                        }
                        
                        //
                        // send ajax request and handle all visuals
                        //
                        config._processing = $.mtsoft.ui.ajax($.mtsoft.url(config.validationUrl), {
                            node: $input, // button which trigged ajax request
                            disableNode: false, // don't disable input while sending ajax request
                            showWaitStatus: config.valAjaxWaitStatus !== null ? config.valAjaxWaitStatus : false, // show wait status icon (or icon + label)
                            data: data,
                            showInvalidInputs: false, // don't indicate invalid inputs and show alert - this is handled here
                            showErrors: false, // don't show any errors; if validation fails here it will be preforemd on server
                            timeout: 10,
                            silent: true, // wait, etc. styles are handled by mtsoftUiInput
                            onResultSuccess: function(data, textStatus, jqXHR) {

                                $input.mtsoftUiAlertClose('status', 'wait'); // if no wait label
                                $input.mtsoftUiInputSetValid('server', data.msg);
                            },
                            onResultFailed: function(data, textStatus, jqXHR) {

                                $input.mtsoftUiAlertClose('status', 'wait'); // if no wait label
                                $input.mtsoftUiInputSetInvalid('server', data.msg);
                            },
                            onComplete: function(jqXHR, textStatus) {

                                // un-attach currently processed ajax request
                                $input.mtsoftUiInputConfig('_processing', null);
                            }, onError: function(data, textStatus, jqXHR) {

                                // on error assume input value is valid (it can be checked here)
                                $input.mtsoftUiInputSetNeutral('server', data.msg);
                            }
                        }, {
                            type: 'POST' // use POST
                        });

                        // show only wait icon (no label) outside/inside input
                        if (config.valAjaxWaitStatus === '' || config.valAjaxWaitStatus === true) {

                            if (config.alertType === 'iconInside') {
                                
                                config.$label.mtsoftUiAlertInputIcoInside('wait');
                            } else { 
                                
                                config.$alertParent.mtsoftUiAlertInputIco('wait');
                            }                        
                        }
                    } else {

                        //
                        // value not required AND default value is set
                        //
                        $input.mtsoftUiInputSetNeutral();
                    }
                    return true;
                };
                // abort (if exists) current ajax request (for other, previous value)
                try {
                    config._processing.abort();
                } catch (e) {
                }

                if (config.valAjaxDelay > 0 && config._validatingOn === 'keyup') { // delay send ajax request only if validating on keyup

                    // delay send of ajax request
                    clearTimeout(config._valAjaxTimeout); // clear exisitng timeout
                    config._valAjaxTimeout = setTimeout(_send, config.valAjaxDelay * 1000);
                } else {

                    // send ajax request immediately
                    clearTimeout(config._valAjaxTimeout); // clear exisitng timeout (if any)
                    _send();
                }

                // when sending ajax request with value to be validated assume value is valid;
                $input.mtsoftUiInputConfig({_validServer: true, _validatedServer: false});
                return true;
            } else {

                //
                // ajax validation for current input value already performed
                //

                // validating on keyup; if validateOnKeyupInvalid is other than 'failed'
                // we need to view failed  alert (if currently viewed alert is other than 'failed' - it will be replaced)
                if (config.validateOn === 'keyup' && config._validatingOn !== 'keyup') {

                    // on blur or submit
                    if (!config._validServer) {

                        $input.mtsoftUiInputSetInvalid('server');
                    } else {

                        $input.mtsoftUiInputRestoreState();
                    }

                } else {

                    // restore last alert shown before input focus

                    $input.mtsoftUiInputRestoreState();
                    //config.$value.mtsoftUiInputAlert(config.valid ? 'success' : 'failed', config.$label.data('_lastAlert'));
                }

                // return validation result
                return config._validServer;
            }
        } else {

            // form submitting; if ajax validation not occured OR occured and succesully - set valid
            return config._validServer;
        }
    };
    /**
     * Execute on keydown event for input.
     * Used only if config.allowOnly regex value is defined (not null)
     *
     * @param {Event} e         keydown event
     * @param {JSON} config     input donfiguration
     * @returns {Boolean}       true if character allowed; false otherwise
     */
    function _keydown(e, config) {

        var key = e.keyCode; // get key code
        var keyChar = $.mtsoft.key2char(e); // convert to character

        // if this is: Shift, Caps Lock, Tab, Enter, Alt, Ctrl, BckSpace, Delete,
        // Home, End, left/right/top/bottom arrow --> do nothing
        if ($.inArray(key, config._ignoredKeys) > -1 ||
                key === 86 && e.ctrlKey) { // Ctrl+V (paste)s

            // some of ignored keys was hit
            return true;
        }
        else {

            if (config.allowOnly.test(keyChar)) {

                // char is allowed
                return true;
            }
            else {

                // char is disabled
                return false;
            }
        }
    }
    
    /**
     * Get input related layout element.
     * 
     * @param {String} level    [ input | cell | row ] - what input related layout element have to be returned 
     * @returns {jQueryObject}
     */
    function _getInputLayoutElement($input, level) {
        
        var $scope;
        switch (level) {

            case 'row':
                var $scope = $input.parents('.row.input-out').filter(':first');
                $scope = $scope.add($scope.next('.row.desc-out').filter(':first'));
                break;
            case 'cell':
                $scope = $input.parents('.columns').filter(':first');
                break;
            case 'input':
                $scope = $input;
                break;
        }
        
        return $scope;
    }

    /**
     * Get input details: model, fieldname, attributes: name, id
     *
     * @param {jQUery} $this        input jQuery object
     */
    function _getInputDetails($this) {

        // get model and field name
        var n = $this.attr('name'), // data[Model][field_name]
                rexp = new RegExp(/data\[([^\]]+)\]\[([^\]]+)\]/gi), // rip model and fieldname
                matches = rexp.exec(n);
        return {model: matches[1], fieldname: matches[2], name: n, id: $this.attr('id')};
    }

    // ...




    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtsoftUiInput.defaults = {
        $form: null, // reference to form which input belongs to
        cssPrefix: 'mtf-',
        default: '', // default value is empty
        value: null, // current value
        status: 'neutral', // input visual stauts [ neutral | valid | invalid ]
        required: false, // is input required
        allowEmpty: true, // can value be empty?
        formResetDisabled: false, // if false reset this input value when form is reseted; if true - dont/ommit (@see $.mtsoft.ui.form) 
        selectOnFocus: false, // select input text on focus 
        smartlabel: false, // define smart label value; none if false
        mask: false, // use input mask (ex. '999.99') 
        translate: false, // if true re-print this input alue to all related inputs for other languages (ex. Model.input_name.eng ==> Model.input_name.pol, ...) 
        showDescriptionOn: 'focus', // show/high-light help text on this event [ focus | mouseover | false ]
        doValidate: true, // flag; if true validate, if false don't; allows temporary disable validation
        validateOn: 'change', // validate input value on: [ keyup | blur | change | false ]
        validateOnKeyupInvalid: false, // what alert show if validation is keyup and invalid value: [ false (nothing/neutral) | 'caution' | 'info' | 'help' ]
        // Validation rules to be performed on validation;
        // All standard / available rules methods are defined on 'mtsoft.ui.input.validation.js'
        // Rule can be also defiend as function directly on ValidationRules array or in rules property (see below)
        // ex. ['rule1', {rule: 'rule2', params: [1,2,3]}, function(v, a1, a2...) { return BOOL; } ]
        //  ['ruleName'] - only rule name - rule with given name will be executed in order: config.rules, $.fn.mtsoftUiInput.validation
        //  [{rule: 'rule2', params: [1,2,3], msg: 'This particular rule invalid message'}, on: 'eventName', ...] - rule with parameters array
        // [function(v, a1, a2...) { return BOOL; }] - rule as function which returns Bool value
        validationRules: [],
        // Custom input related rules definitions
        rules: {}, // rules definitions ex. {ruleName: function(v, a1, a2, ...){ /* this = $input.config {JSON} */ return BOOL}, ...}
        validationUrl: null, // server side validation url (used to send ajax request)
        validationData: {}, // additional data (constant values) send by ajax validation (pairs: {name: 'value'})
        validationSerialize: false,  // if true serialzie and send all inputs in form data while sending ajax validation request; NOTE: validationData / validationAjaxData are not processed in this case!
        validationAjaxData: function() {
            return {};
        }, // read and set additional data send by ajax validation
        valAjaxWaitStatus: true, // if true or String (label value)  show 'wait' status mode alert on input (only icon or icon with label)
        $paralels: null, // inputs (must be instances of mtisftUiInput) validated togehter whith current(this) input
        valAjaxDelay: .5, // [s] time of delay to send validation ajax request; used only if validateOn is 'keyup'
        allowOnly: null, // regex of allowed characters possibile to enter in input (ex. "numbers only" expression: /^[0-9]$/; NOTE: on defJson define without slashes (ex. "^[0-9]$" only )
        disablePaste: false, // if true disable value pasting on input (Ctrl+V or Paste Command)
        alertPosition: 'right', // by default show alert messages for input on right or bottom right for smaller screens [ left | top | right | bottom | bottom-right ]
        alertType: 'status', // type of alert: [ status | icon | iconInside ]
        alertReposition: true, // if true change any input alert position using media queries, if false - don't (always in inital position)
        $alertParent: null, // set custom parent for alert; the alret will be shown near this node (jQUery object)
        // default input alert definition
        alert: {
            open: {
                mode: 'fade', // mode: [ slide | fade | show | fade-slide ]
                speed: 'fast' // showing speed (only for slide or fade)
            },
            // close alert parameters
            close: {
                mode: 'hide' // mode: [ slide | fade | hide | fade-slide ]
            }
        },
        storeData: true, // if true store this input data in local storage (on $.mtsiftUiForm - to re-store after page re-load)
        //mq: {small: $.mtsoftConfig.mq.small || 768}, // break point; below it alert will be shown below input; above - on right side
        //
        // default actions
        //
        // for each action handler:
        //      this => is current input configuration JSON object
        //      this.$value => node where real value is keep
        //      this.$label => node where input value as label is shown for user
        //
        setValue: function(v) {

            // by default re-print label value (if no value given directly)
            if (v === undefined) {

                // re-print value from label
                this.reprintValue(this.$label.val());

            } else { // input value given directly - set value and label

                this.reprintValue(v);
            }

            if (this.smartlabel) { // if smartlabel set

                if (this.value === this.smartlabel) { // current value is smart label - ignore it

                    this.$value.val('');
                    this.value = '';
                }
            }

            return this.value;
        },
        _setValue: function() { // to be removed             
        },
        /**
         * Reprint value from shwon label ($label) to hidden value ($value)
         * NOTE: value must be added in label format (not direct value)  
         */
        reprintValue: function(v) {

            this.$value.val(v);
            this.$label.val(v);
            this.value = v;
        },
        getValue: function() {

            this.setValue(); // set value (gather all data from input's "label" or other, related nodes)          
            return this.value;  // return real value            
        },
        reset: function(cfg) {
            // re-configure (if required)
            if (cfg) {
                this.$value.mtsoftUiInputConfig(cfg);
            }
            // set input default value
            this.setValue(this.default);
            // value equal to label - below not required, but REQUIRED if label is other than value
            this.$value.mtsoftUiInputSetNeutral(); // remove all types of alerts/input styling and set neutral
            //_validate(this.$value, 'blur');
        },
        // isReady method is used by $.mtsoftUiform object on form submit to detect input value is set
        // If method returns false $.mtsoftUiform suspend form submit until isReady will return true.
        // This method is used when ajax validation in progress or file(s) upload in prgress, etc...
        isReady: function() {
            return !this._processing; // if _processing is true - ajax validation request is processing
        },
        /**
         * Adds custom validation rule for input. 
         * If rule don't exists - it is added.
         * If rule already exist - rule definitions is extendend (new params are added) 
         * 
         * @param {Mixed} rule              array of rules objects or rule object 
         * @param {Boolean} overwrite       if true overwrite existing rule 
         */
        addValidationRule: function(rule, overwrite) {

            // get current validation rules 
            var _vrs = {}, rules;
            for (var i in this.validationRules) {

                var vr = this.validationRules[i];
                _vrs[vr.rule] = i;
            }
            if (rule.constructor !== Array) {
                rules = [rule];
            }
            else {
                rules = rule;
            }
            
            for (var i = 0; i < rules.length; i++) {
                
                if (_vrs[rules[i].rule] === undefined) {
                    this.validationRules.push(rules[i]);
                } else {

                    if (overwrite || rules[i].overwrite) {
                        this.validationRules[_vrs[rules[i].rule]] = $.extend(true, this.validationRules[_vrs[rules[i].rule]], rules[i]);
                    }
                }
            }            
        },
        // validate method can return only true or false
        validate: function() {
            
            var v = this.getValue(), result = null, _dbg = function($n, v, r, result) {
                dbg(function($input, value, rule, result) {
                    console[result ? 'info' : 'warn']('(CLI) ' + $input.attr('name').replace("data[", '').replace('][', '.').replace(']', '') + ' = "' + value + '" { ' + rule + ' => ' + result + ' }');
                    if (typeof(value) === 'object') {
                        log(value);
                    }
                }, [$n, v, (typeof(r) === 'object' ? r.rule : r), result]);
            };            
            // reset current invalid rule(s)
            this.$value.mtsoftUiInputConfig('_invalidRules', []);
            var _invalidRules = [];

            // validate using defined rules (if any)
            // if rule name not found on set of rules - do nothing
            for (i in this.validationRules) {

                var rule = null, r = this.validationRules[i]; // all validation rules (ex. ['rule1', {rule: 'rule2', params: [1,2,3]}])
                result = null;  // reset result

                if (typeof(r) === 'object') { // validation rule name and parameters

                    //
                    // rules as object (rule name and parameters)
                    //
                    if (this.rules[r.rule]) {

                        // custom rule defind for this input exists?
                        rule = this.rules[r.rule];
                    } else {

                        if ($.fn.mtsoftUiInput.validation[r.rule]) {

                            // rule exists is set of common rules on $.fn.mtsoftUiInput.validation
                            rule = $.fn.mtsoftUiInput.validation[r.rule];
                        }
                    }

                    // convert parameters from JSON object to array
                    // (ex. {1: 1, 2:2, 3:3} => [1,2,3])
                    var params = [v];
                    for (var i in r.params) {
                        params.push(r.params[i]);
                    }

                    // if 'on' property is set - validate only if 'on' property match current validationOn value 
                    if (rule && (r.on === undefined || r.on === this._validatingOn)) {
                        // exec rule with parameters
                        result = rule.apply(this, params);
                    }

                } else {

                    if (typeof(r) === 'function') { // validation rule defiend as function

                        //
                        // rule as function
                        //
                        result = r(v);
                    } else { 
                    // only validation rule name (String)

                        //
                        // rule given as rule name only
                        //
                        if (this.rules[r]) { // custom rule defind for this input exists?

                            // exec custom rule
                            result = this.rules[r](v);
                        } else {

                            if ($.fn.mtsoftUiInput.validation[r]) { // rule exists is set of common rules on $.fn.mtsoftUiInput.validation

                                // exec common rule
                                result = $.fn.mtsoftUiInput.validation[r](v);
                            }
                        }
                    }
                }

                if (result !== null) {

                    _dbg(this.$value, v, r, result);

                    if (!result) {  // break on first not passed rule

                        // remember invalid rule name
                        _invalidRules.push(r.rule ? r.rule : r);

                        // don't validate next rules
                        break;
                    }
                }
            }

            // update invalid rules in configuration
            this.$value.mtsoftUiInputConfig('_invalidRules', _invalidRules);

            // return result of execution all rules in validation chain
            // if null - no validated (no validation rules defined or given rule don't exists)
            return result;
        },
        // define actions/callbak to perform if validation is success
        validateOnSuccess: function() {
            this.success();
        },
        validateOnSuccessAjax: function(data) { // for ajax validation

            // data.result is defined and not null or false
            if (data && typeof(data.result) === 'string') {

                this[data.result](data);
            } else {

                this.success(data);
            }
        },
        // define actions/callbak to perform if validation failed
        validateOnFailed: function() {

            this.failed();

            // if any paralell inputs - style them as invalid             
            if (this.$paralels) {
                
                for (var i = 0, $p; $p = this.$paralels[i]; i++) {
                    $p.mtsoftUiInputConfig().$label.addClass(this.cssPrefix + 'failed');
                }
            }
        },
        validateOnFailedAjax: function(data) { // for ajax validation

            this.failed(data);
        },
        // change input state
        indicate: function() {
        },
        enable: function(any) {
            
            if (any) { // .mtu-input is upload replacement
                
                this.$label.attr('disabled', false).removeClass('disabled'); // .' + cfg.cssPrefix + 'label'
                this.$value.attr('disabled', false).removeClass('disabled'); // .' + cfg.cssPrefix + 'label'
                
            } else {

                if (this.$label.attr('_disabled')) {
                    
                    this.$label.attr('disabled', false).attr('_disabled', null).removeClass('disabled'); // , .' + cfg.cssPrefix + 'label[_disabled]
                    this.$value.attr('disabled', false).attr('_disabled', null).removeClass('disabled'); // , .' + cfg.cssPrefix + 'label[_disabled]
                }
            }
        },
        disable: function() {
            
            //this.$label.attr('_disabled', true).attr('disabled', true).removeClass('disabled');
            if (!this.$label.attr('disabled')) { // only if not already disabled 
                
                this.$label.attr('_disabled', true).attr('disabled', true).addClass('disabled');
                this.$value.attr('_disabled', true).attr('disabled', true).addClass('disabled');
            }
        },
        /**
         * Show input / input cell / input row 
         * @param {Bool} enable    if true enable input
         * @param {String} level    [ input | cell | row ] - what element related to input should be shown 
         */
        show: function(enable, level) {
            
            enable = enable || true;
            level = level || 'row';
            
            if (enable) {
                this.enable();
            }
            _getInputLayoutElement(this.$label, level).show();            
        },
        /**
         * Hide input / input cell / input row 
         * @param {Bool} disable    if true disable input
         * @param {String} level    [ input | cell | row ] - what element related to input should be hidden  
         */
        hide: function(disable, level) {
            
            disable = disable || true;
            level = level || 'row';
            
            if (disable) {
                this.disable();
            
            }
            _getInputLayoutElement(this.$label, level).hide();
        },
        // set different input status(es) and message(s)
        neutral: function() {

        },
        success: function(data) {

        },
        failed: function(data) {
            
        },
        caution: function(data) {

        },
        info: function(data) {

        },
        help: function(data) {

        },
        /**
         * Executed before neutral/success/failed/caution staes set.
         * Usable to customize status alert position
         */
        beforeSetState: function(data, status) {
            
        },
        /**
         * Make active / inactive (show/hide) input help/description
         * Use .active css class to define how description should be presented
         *
         * @param {jQuery} $n       object which trigged description activ
         * @param {String} id       $value node/fieldname id 
         */
        description: function($n, id) {
            
            // hide all  others
            $('div.active[class*="-desc"]').removeClass('active');

            var $n = $n !== undefined ? $n : this.$label, desc = $('#' + (id !== undefined ? id : this.$value.attr('id')) + '-desc');
            desc.addClass('active');

            // to remove on blur
            var event = this.showDescriptionOn === 'focus' ? 'blur' : 'mouseleave';
            $n.one(event + '.mtsoftUiInput', function() {
                desc.removeClass('active');
            });
        },
        // event handlers
        // execute on $.mtsoft.ui.input init (only once)
        oninit: function($input) {
            // this => config object
        },
        onchange: function() {

            this.setValue(); // by default reprint value from label node to value node
        },
        // ...
        // default validation messages
        msgs: {
            //neutral: null,
            //failed: 'required'
            //success: null,
            //caution: null,
            //info: null,
            //help: null
            // --- for ajax validation
            //ajax_success: null
            //ajax_failed: null
            // ...
        },
        txt: {
            required: 'required'
        },
        // private

        // flags and internal variables
        _validatingOn: null, // what event used to invoke validation [ keyup | focus | submit ]
        _invalidRules: [], // array with single name of rule (if validation stops on first invalid) or rule names (if validation don't break after first invalid rule)
        _validClient: true, // flag; last client side validation result (true by default)
        _validServer: true, // flag; last client side (ajax) validation result (true by default)
        _validatedClient: null, // last value used to validation on client side; null (none) by defult
        _validatedServer: null, // last value used to validation on server side (ajax); null (none) by default
        _processing: null, // falg - if true input value is processing (ex. ajax validation request is sending); it can be ajax request (XMLHttpRequest (jqXHR)) object
        _valAjaxTimeout: null, // current timeout handle of validation ajax request
        //_alert: null, // currently shown alert for input (jQuery object)
        _ignoredKeys: [8, 9, 13, 16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 46] // ignored key codes (for internal _keydown method)

                // methods
    };
    //
    // Public functions
    //
    /*
     $.fn.hilight.pubFunc1 = function(n) {
     // ...
     };*/


    // -------------------------------------------------------------------------
    //
    // apply plugin to default selector (optional)
    //

})(jQuery, window, document);