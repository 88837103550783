;
(function() {

    var cfg = {};   // keep configuration in global variable

    // -------------------------------------------------------------------------
    //
    // Plugin constructor
    //
    $.fn.playerPreview = function(params) {

        var args = arguments;
        $.extend(this, actions);

        return this.each(function() {

            var $this = $(this);

            if (!$this.data('config.playerPreview') || params) {

                //
                // Initialize and save configuration for current node
                //                 
                cfg = actions.config.call($this, actions.init.call($this, args));

            } else {

                // only read configuration
                cfg = actions.config.apply($this);
            }
        });
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.playerPreview.defaults = {};
    $.fn.playerPreview.def = {};
    
    
    // -------------------------------------------------------------------------
    //
    // Public actions
    //
    var actions = {
        progressCurrPercent: 0,
        timeStart: 0,
        timeEnd: 0,
        /**
         * Get / set plugin configuration.
         *
         * @param {Mixed} param     Configuration parameter name
         *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
         *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
         *                                    All other then given in JOSN argument parameters will be keep untouch;
         *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
         *                           null - resets current configuration (remove all parameters)
         *
         * @param {Mixed} val       configuration parameter value
         * @param {Bool} update     if true update curent global configuration (cfg) object
         *
         * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
         *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
         */
        config: function(param, val, update) {

            var c = 'config.playerPreview',
                    $this = $(this), _cfg = $this.data(c);
            // reset current configuration
            if (param === null) {
                $this.data(c, null);
                return _cfg;
            }

            if (val !== undefined || typeof (param) === 'object') { // setter

                // update single parameter value
                if (val !== undefined) { // single parameter value

                    _cfg[param] = val; // assign value
                    $this.data(c, _cfg); // update

                } else {    // multiple parameters values defined as JSON object {}

                    // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                    //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                    _cfg = $.extend(true, {}, _cfg, param);
                    $this.data(c, _cfg); // update
                }
                if (update === undefined || update) { // update global configuration (cfg) object
                    cfg = _cfg;
                }
                return _cfg;
            } else { // getter

                return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
            }
        },
        /**
         * Initialize
         * 
         * @param {type} params
         * @returns {JSON} configuration object
         */
        init: function(args) {

            var $this = $(this), self = this;
            //
            // initial configuration
            //
            var cfg = $.extend(true, {}, $.fn.playerPreview.defaults, {
                $this: $this,                
                url: $this.attr('data-url')
            });  // cfg is PLUGIN GLOBAL

            var formatTime = function (d) {
                function pad(n) {
                    return n < 10 ? '0' + n : n
                }
                ;
                return [pad(d.getUTCHours()), ':',
                    pad(d.getUTCMinutes()), ':',
                    pad(d.getUTCSeconds())].join("");
            };

            var currServerHMStime = null, nextShowHMStime = '00:00:00', rotateBanner = function (cfg) {

                // send only if show changed    
                //console.log(currServerHMStime);
                //console.log(nextShowHMStime);
                if (currServerHMStime === null || currServerHMStime >= nextShowHMStime) {

                    $.get(cfg.url, null, function (data, textStatus, jqXHR) {

                        // player banner url 
                        /*var imgUrl = $.trim(data.banner);
                        if (imgUrl !== '') {
                            _changeCover(c, imgUrl);
                        }*/
                        var curr = data.current, n = data.next;
                        
                        $('#pprevBanner').html(curr.bannerThumb);
                        $('#pprevStartTime').html(curr.time);
                        $('#pprevEndTime').html(curr.timeEnd);
                        $('#pprevTitle').html(curr.title);
                        $('#pprevHosts').html(curr.hosts);
                        $('#pprevGuests').html(curr.guests);
                        $('#pprevType').removeClass('live').removeClass('replay').html(curr.type).addClass(curr.type);
                        
                        // set progress bar parameters 
                        self.progress = { timeStart: curr.timestampStart, timeEnd: curr.timestampEnd };   
                        actions.updateProgress(self.progress);
                        /*
                        // current 
                        $('#currScheduleImg').attr('href', curr.urlHost).html(curr.img);
                        $('#currScheduleTime').html(curr.time);
                        $('#currScheduleType').removeClass('live').removeClass('replay').html(curr.type).addClass(curr.type);
                        $('#currScheduleUrl').attr('href', curr.url).html(curr.title);

                        // current extendend
                        $('#phdCurrentShow').html(curr.phdCurrentShow);
                        $('#phdCurrentHosts').html(curr.phdCurrentHosts);
                        $('#phdCurrentGuests').html(curr.phdCurrentGuests);
                        $('#tab-show-info').click();
                        if (curr.phdCurrentGuests !== '') {
                            $('#phdTabGuests').show();
                        } else {
                            $('#phdTabGuests').hide();
                        }

                        // next                         
                        $('#nextScheduleImg').attr('href', n.urlHost).html(n.img);
                        $('#nextScheduleTime').html(n.time);
                        $('#nextScheduleType').removeClass('live').removeClass('replay').html(n.type).addClass(n.type);
                        $('#nextScheduleUrl').attr('href', n.url).html(n.title);

                        // show/hide contact form 
                        if (curr.type === 'live') {
                            $('#rForm').show();
                        } else {

                            // hide only if not focused 
                            if (!$("#FeedbackName").is(":focus") && !$("#FeedbackLocation").is(":focus") && !$("#FeedbackMessage").is(":focus") && !$("# FeedbackCaptcha").is(":focus")) {
                                $('#rForm').hide();
                            }
                        }
                        */
                        // rememebr next show start time 
                        currServerHMStime = data.currTime;
                        nextShowHMStime = n.timeHMS;
                    });
                } else {

                    // add 5 seconds to server time                         
                    d = new Date('2016-04-14T' + currServerHMStime + '+00:00');
                    d.setSeconds(d.getSeconds() + 5);
                    currServerHMStime = formatTime(d);
                }
            };
            rotateBanner(cfg); // in case banner not set 
            //window.setInterval(rotateBanner, cfg.banner.refresh * 1000);
            window.setInterval(function() { rotateBanner(cfg); }, 5000);// check each 5 seconds
            
            window.setInterval(function() {
                actions.updateProgress(self.progress);
            }, 60*1000);// each minute     
            
            $('#pprevPlayerBtn > i.ico').mtsoftUiAnim({name: 'pulse', count: 'infinite'}).play();
        },
        updateProgress: function(progress) {
            
            this.progressCurrPercent =  ( ( Math.floor(Date.now() / 1000) - progress.timeStart) * 100 )  / (progress.timeEnd - progress.timeStart);                        
            $('#pprevProgress > div').css({width: this.progressCurrPercent+'%'});
        }
    };


    // -------------------------------------------------------------------------
    //
    // apply plugin to default selector (optional)
    //
    $(function() {

        $('.pprev').playerPreview();        
    });

})();







