/**
 * mtsoft core
 */

;
/**
 *
 * @param {type} $
 * @param {type} window
 * @param {type} document
 * @param {type} undefined
 * @returns {undefined}
 */
(function($, window, document, undefined) {

    // namespace
    $.mtsoft = $.mtsoft || {ui: {}};

    //
    // Core methods
    //

    /**
     * Determine right url; url can be given as:
     *  - full url (ex. http://www.domain.com/subdir/controller/action/arguments),
     *  - relative to server address; must contain valid path (ex. /subdir/controller/action/arguments)
     *  - controller/action pair (ex. controller/action/arguments)
     *
     *  @param {String} url     target url
     *
     *  @uses   URL gloabal variable (MUST exists and contains full, base path set on server side)
     */
    $.mtsoft.url = function(url) {

        if (!url.match(/^(http|https):.*/i)) {

            if (!url.match(/^\/.*/i)) {

                url = URL + url.replace(/^\/{1}/, '');    // url as controller/action or plugin/controller/action
            }
            else {

                var l = window.location;
                url = l.protocol + '//' + l.hostname + (l.port !== '' ? ':' + l.port : '') + url; // url relative path
            }
        }
        return url;
    };

    /**
     * Set timeout to refresh browser window after session expired
     * 
     * @uses global variables (set on server):
     *                              STO (session time out value)
     *                              UID (current user identifier; if undefined - no session refresh occurs)
     * 
     * @param {Int} timeout     timeout [s]; set false or null to disable window refresh after session time out
     */
    $.mtsoft.refreshSession = function(timeout) {

        if (window['UID'] && (timeout === undefined || timeout)) {    // if UID > 0 some user logged-in

            // clear current timeout
            try {
                window.clearTimeout($.mtsoft.refreshSessionTimeout);
            } catch (e) {
            }

            // (re)set timeout
            var to = timeout || STO * 60; // STO in minutes

            if (STO > 0 || timeout > 0) { // given timeout must be greater than 0

                $.mtsoft.refreshSessionTimeout = window.setTimeout(function() {

                window.location = $.mtsoft.url('user/auth/timeout?url=' +
                        encodeURIComponent(window.location.href));
                }, to * 1000); // [s => ms];
            }
        }
    };


    /**
     * Convert key code to character value (ex. 97 => a)
     * Must be used with keyup event
     *
     * @param {Object} key	key code
     * @param {Object} e	keyup event (jQuery)
     */
    $.mtsoft.key2char = function(e, key) {

        var c = key || e.keyCode; // get key given direclty or get from event

        var _to_ascii = {
            '188': '44',
            '109': '45',
            '190': '46',
            '191': '47',
            '192': '96',
            '220': '92',
            '222': '39',
            '221': '93',
            '219': '91',
            '173': '45',
            '187': '61', //IE Key codes
            '186': '59', //IE Key codes
            '189': '45'  //IE Key codes
        };

        var shiftUps = {
            "96": "~",
            "49": "!",
            "50": "@",
            "192": '@', // mtsoft fix; conflict with ~ on some notebooks;
            "51": "#",
            "52": "$",
            "53": "%",
            "54": "^",
            "55": "&",
            "56": "*",
            "57": "(",
            "48": ")",
            "45": "_",
            "61": "+",
            "91": "{",
            "93": "}",
            "92": "|",
            "59": ":",
            "39": "\"",
            "44": "<",
            "46": ">",
            "47": "?"
        };

        var numLocks = {// NumLock number codes
            "96": "0",
            "97": "1",
            "98": "2",
            "99": "3",
            "100": "4",
            "101": "5",
            "102": "6",
            "103": "7",
            "104": "8",
            "105": "9"
        };

        //normalize keyCode
        if (_to_ascii.hasOwnProperty(c)) {

            c = _to_ascii[c];
        }

        if (!e.shiftKey && (c >= 65 && c <= 90)) {

            c = String.fromCharCode(c + 32);
        } else if (e.shiftKey && shiftUps.hasOwnProperty(c)) {

            //get shifted keyCode value
            c = shiftUps[c];

        } else if (numLocks.hasOwnProperty(c)) {

            // this is number on NumLock panel
            c = numLocks[c];

        } else {

            c = String.fromCharCode(c);
        }

        return c;
    };

})(jQuery, window, document);
