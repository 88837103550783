/**
 * Tabs / tab sheets jQUery plugin.
 * 
 * (c) 2013 mtsoft.pl
 */
;
(function($) {

    var cfg = {};   // keep configuration in global variable

    // -------------------------------------------------------------------------
    //
    // Plugin constructor
    //
    $.fn.mtsoftUiTabs = function(params) {

        var args = arguments;
        $.extend(this, actions);

        return this.each(function() {

            var $this = $(this);

            if (!$this.data('config.mtsoftUiTabs') || params) {

                //
                // Initialize and save configuration for current node
                //                 
                cfg = actions.config.call($this, actions.init.call($this, args));

            } else {

                // only read configuration
                cfg = actions.config.apply($this);
            }
        });
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtsoftUiTabs.defaults = {
        hashNavigate: false // if ture add tabs hashes to browser url (prev/next browser buttons will navigate throu tabs)
        // settings as properties or objects
        /*cssPrefix: 'mt-',
        // callbacks
        onSomeAction: function() {
        },
        // flags
        _flag: false*/
    };
    $.fn.mtsoftUiTabs.def = {};




    // -------------------------------------------------------------------------
    //
    // Public actions
    //
    var actions = {
        

        /**
         * Get / set plugin configuration.
         *
         * @param {Mixed} param     Configuration parameter name
         *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
         *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
         *                                    All other then given in JOSN argument parameters will be keep untouch;
         *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
         *                           null - resets current configuration (remove all parameters)
         *
         * @param {Mixed} val       configuration parameter value
         * @param {Bool} update     if true update curent global configuration (cfg) object
         *
         * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
         *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
         */
        config: function(param, val, update) {

            var c = 'config.mtsoftUiTabs',
                    $this = $(this), _cfg = $this.data(c);
            // reset current configuration
            if (param === null) {
                $this.data(c, null);
                return _cfg;
            }

            if (val !== undefined || typeof (param) === 'object') { // setter

                // update single parameter value
                if (val !== undefined) { // single parameter value

                    _cfg[param] = val; // assign value
                    $this.data(c, _cfg); // update

                } else {    // multiple parameters values defined as JSON object {}

                    // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                    //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                    _cfg = $.extend(true, {}, _cfg, param);
                    $this.data(c, _cfg); // update
                }
                if (update === undefined || update) { // update global configuration (cfg) object
                    cfg = _cfg;
                }
                return _cfg;
            } else { // getter

                return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
            }
        },

        /**
         * Initialize
         * 
         * @param {JSON} args 
         * @returns {JSON} configuration object
         */
        init: function(args) {

            var $this = $(this), cfg;
            //
            // initial configuration
            //            
            cfg = $.extend(true, {}, {$this: $this, $sheets: $("[data-tabs='"+$this.attr('id')+"']")},
                $.fn.mtsoftUiTabs.defaults,
                $this.data(),
                $.fn.mtsoftUiTabs.def[$this.attr('id')],
                args);  // cfg is PLUGIN GLOBAL
            
            //
            // attach event handlers
            //
            $this.children('dd').children('a').on('click.mtsoftUiTabs', function(e) {
                
                $this.mtsoftUiTabs();

                var $sheet = $('#' + this.href.split('#')[1]);
                
                // select tab-shet pair 
                actions.select($sheet);
                
                // load ajax contents ?
                if ($(this).data('url')) {
                    
                    $sheet.load($(this).data('url'), $(this).data('callback'));                    
                }
                
                if (!cfg.hashNavigate) {
                    e.preventDefault();
                    return false;
                }
            });
            
            // always use click anyway (for touch devices)
            /*cfg.$this.on('eventName.mtsoftUiTabs', function(e) {

                // execute some public action (reads configuration for current($this) node)
                actions.actionName.apply(this, [{param: value}, e]);
            });*/

            //
            // set other initial values
            //

            return cfg;
        },
        
        select: function($n) {
            
            _selectTab($n);
            _hideAllSheets();
            _showSheet($n);
        }

        /**
         * Sample public action
         * 
         * @param {type} params
         * @param {type} e
         */
        /*actionName: function(params, e) {

            // variables:
            // this = $(this) => jQuery node object action is executed for

            // execute other public action (for current node configuration)
            actions.actionNameOther.apply(this, [params, e]);

            // execute private function
            privateMethod(params);

            // update configuration for current node (false parameter is important!)
            cfg.$this.mtsoftUiTabs(false).config(paramName, 'value');

            // ALWAYS return node to keep chain
            return this;
        }*/
        // ...
    };


    // -------------------------------------------------------------------------
    //
    // define private functions (internal use only)
    //
    
    function _getTabForSheet($n) {
        
        return cfg.$this.children('dd').children('a[href="#'+$n.attr('id')+'"]').parent();
    }
    
    function _selectTab($n) {

        // add active class
        cfg.$this.children().removeClass('active');
        _getTabForSheet($n).addClass('active');
    }
    
    function _showSheet($n) {
        
        $n.show().addClass('active');
    }
    
    function _hideAllSheets() {
        
        cfg.$sheets.children().hide().removeClass('active');
    }

    /**
     * Convert to JSON format parameters from given arguments.
     * Possible 'arguments' syntax:
     * 
     *  p1 [,p2 [,p3 ...]] [,function(){callback}]
     *  {p1: 'v1', p2: 'v2', ...}
     *  {p1: 'v1', p2: 'v2', ...} [,function(){callback}]
     * 
     * @param {Array} args      input arguments
     * @param {JSON} defaults
     * 
     * @returns {JSON}      final parameters as JSON object
     *//*
    function _getDefinitionParams(args, defaults) {

        defaults = defaults || {};
        var params = $.extend({}, defaults);

        if (typeof (args[0]) === 'object') {

            // used syntax: ({...});
            $.extend(params, args[0]);

            if (args[1] && typeof (args[1]) === 'function') {

                // used syntax: ({...}, callback);
                params.callback = args[1];
            }

        } else {

            // define properties
            var props = ['param1',
                'param2',
                'params3',
                // ...
                // ---
                'callback'];

            for (var i = 0; i < props.length; i++) {

                if (typeof (args[i]) !== 'function') {

                    // used syntax: (a1, a2, ...);
                    if (args[i] !== undefined) {

                        params[props[i]] = args[i];

                    } else {
                        break;
                    }
                } else {

                    // used syntax: (a1, a2, ..., callback);
                    params.callback = args[i];
                    break;
                }
            }
        }

        return params;
    }*/


    /**
     * Sample private method
     */
    /*function privateMethod() {

        // execute other private function
        privateMethodOther(params);
    }*/
    // ...

    // -------------------------------------------------------------------------
    //
    // define even handlers common for all instances (for window/document, etc...)
    //
    /*$(window).on('resize.mtsoftUiTabs', function(e) {
        actions.actionName.apply($(this), [e]);
    });*/

    // -------------------------------------------------------------------------
    //
    // apply plugin to default selector (optional)
    //
     $(function() {
        $("dl[data-tabs]").mtsoftUiTabs();
     });

})(jQuery);


