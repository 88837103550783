/**
 * mtsoft INPUT - definitions of different input types
 *
 * Must be included BEFORE mtsoft.ui.input.js
 */
;
/**
 *
 * @param {tyafterRowInsertpe} $
 * @param {type} window
 * @param {type} document
 * @param {type} undefined
 * @returns {undefined}
 */
(function ($, window, document, undefined) {

    $.mtsoftUiInput = {};
    //
    // Skeleton
    //
    /*$.mtsoftUiInput.sample = {
     oninit: function($input) {
     // NOTE: this is config object
     }
     };*/

    $.mtsoftUiInput.hidden = {
        // do nothing on reset (otherwise value will be set set to default value (empty)) 
        reset: function () {
        }
    };

    /**
     * Base text input 
     */
    $.mtsoftUiInput.text = {
        charsLimiter: false,
        oninit: function () {

            // Use chars counter  
            if (this.charsLimiter) {
                this.$value.charsLimiter(this.charsLimiter);
            }
        }
    };
    //
    // Text-area box 
    //
    $.mtsoftUiInput.textarea = {
        charsLimiter: false,
        elastic: true,
        oninit: function () {

            // Use chars counter  
            if (this.charsLimiter) {

                this.$label.charsLimiter(this.charsLimiter);
            }

            // Use elastic plugin (auto-grow/resize textarea as user types)  
            if (this.elastic) {
                this.$label.addClass('elastic');
                if (this.$value.val() === '') {
                    this.$value.val("\n");
                }
                this.$value.elastic();
            }
        }
    };
    //
    // Select-box (standard, chosen) 
    //
    $.mtsoftUiInput.select = {
        showDescriptionOn: 'mouseover',
        titles: false, // add titles to select options 
        default: '',
        oninit: function () {

            // if no default set - set it as first option
            if (this.default === '') {

                this.default = this.$label.children('option').filter(':first').val();
            }

            if (this.titles) { //  show titles on mouse over select options

                this.$label.children('option').each(function () {
                    $(this).attr('title', $(this).text());
                });
            }
        },
        reset: function (cfg) {

            // set default
            this.$value.val(this.default);
        }
    };
    
    //
    // Select-box (standard, chosen) 
    //
    $.mtsoftUiInput.selectGoogleFont = {
        showDescriptionOn: 'mouseover',
        titles: false, // add titles to select options 
        default: '',
        oninit: function () {


            $('#'+this.$value.attr('id')).fontselect();
            
            // if no default set - set it as first option
            if (this.default === '') {

                this.default = '';
            }

            if (this.titles) { //  show titles on mouse over select options

                this.$label.children('option').each(function () {
                    $(this).attr('title', $(this).text());
                });
            }
        },
        reset: function (cfg) {

            // set default
            this.$value.val(this.default);
        }
    };

    //
    // email address
    //
    $.mtsoftUiInput.email = {
        validationRules: ['email'],
        msgs: {
            success: '',
            failed: 'Wrong email address'
        }
    };
    $.mtsoftUiInput.emailNamed = $.extend(true, $.mtsoftUiInput.email, {
        validationRules: [{rule: 'email', params: [false]}]
    });
    //
    // checkbox
    //
    $.mtsoftUiInput.checkbox = {
        default: false, // un-checked by default
        validateOn: 'change',
        showDescriptionOn: 'mouseover',
        alertReposition: false,
        // if checkbox has related input(s) on checkbox select/unselect do action 
        behaviour: 'hide', // [ disable | hide ] 
        oninit: function () {

            var self = this;
            // inputs related to checkbox (to show/hide when checkbox is checked/unchecked) 
            this.$relInput = this.$value.siblings('.chbox-inputs');

            // show input description
            if (this.showDescriptionOn) {

                var $n = this.$label.parent();

                $n.on(this.showDescriptionOn + '.mtsoftUiInput', function () {

                    self.description($n);
                });
            }

            // show alert messages on grid cell
            this.$alertParent = this.$value.parent();

            // on check/unchek set neutral state
            this.$value.on('change.mtsoftUiInput', function () {

                self.$value.mtsoftUiInputSetNeutral();

                // do validate on checkbox click/change
                self.$value.mtsoftUiInputValidate('blur');
                self._relatedInputs();
            });
            this._relatedInputs();
        },
        validate: function () {

            return this.required && !this.default && this.$value.is(':checked') || // reuiqred to be checked
                    this.required && this.default && !this.$value.is(':checked') || // required to be un-checked
                    !this.required ? true : false;  // not required
        },
        reset: function (cfg) {
            // re-configure (if required)
            if (cfg) {
                this.$value.mtsoftUiInputConfig(cfg);
            }

            // unset or set default
            this.$value.prop('checked', this.default);
        },
        _relatedInputs: function () {

            // if related to checkbox inputs exists execute right action
            // (hide/show or disable/enable on checkbox checked or not)  
            if (this.$relInput.length) {

                // prevent checking / unchecking checkbox input 
                this.$relInput.children().on('click.mtosftUiInput', function () {
                    return false;
                });

                if (this.$value.is(':checked')) {

                    if (this.behaviour === 'disable') {
                        this.$relInput.children().attr('disabled', false);
                    } else {
                        this.$relInput.children().show();
                    }

                } else {

                    if (this.behaviour === 'disable') {
                        this.$relInput.children().attr('disabled', true);
                    } else {
                        this.$relInput.children().hide();
                    }
                }
            }
        }
    };
    //
    // set of checkboxes; to access mtsoftUiInput use '#InputIdSet' ( add 'Set' postfix to 'InputId') 
    //
    $.mtsoftUiInput.checkboxes = {
        minSelected: 0, // minumum number of checkboxes required to select
        maxSelected: null, // maximum available number of checkboxes to select 
        defaults: [], // array of identifiers (ex. ['ModelFieldName', ...]) selected by default checkboxes
        $alertParent: null,
        msgs: {
            //success: '', uncomment to shwo OK alert icon
            failed: 'required'
        },
        validateOn: 'change',
        showDescriptionOn: 'mouseover',
        txt: {
            minSelected: null,
            maxSelected: "You can't select more items"
        },
        oninit: function () {

            var self = this, chbxs = this.$value.parent().find('input[type="checkbox"]');

            // show input description
            if (this.showDescriptionOn) {

                var $n = this.$label.parent();

                $n.on(this.showDescriptionOn + '.mtsoftUiInput', function () {

                    self.description($n);
                });
            }

            this.chbxs = chbxs;

            // show alert messages on grid cell
            this.$alertParent = this.$value.parent().parent();

            // on check/unchek set neutral state

            chbxs.on('change.mtsoftUiInput', function () {

                /*var v = parseInt(self.$value.val());
                 if ($(this).is(':checked')) {
                 self.$value.val(v + 1);
                 } else {
                 self.$value.val(v - 1 >= 0 ? v - 1 : 0);
                 }*/
                var v = self.getValue(),
                        tooLittle = v < self.minSelected ? true : false,
                        tooMany = (self.maxSelected !== null && v > self.maxSelected) ? true : false;

                self.$value.mtsoftUiInputSetNeutral();

                // do validate on checkbox click/change
                self.$value.mtsoftUiInputValidate('blur');

                if (tooMany) {
                    // uncheck - too many selcted checkboxes 
                    $(this).prop('checked', false);
                    if (self.txt.maxSelected) { // show message
                        $.flash(self.txt.maxSelected, 'caution');
                    }
                }

            });

            // show/hide unchecked functionality 
            if ($('#btnShowHide' + this.$value.attr('id')).length) {

                $('#btnShowHide' + this.$value.attr('id')).on('click.mtsoftUiInput', function () {

                    // get all checkboxes 
                    var chboxes = self.$value.parent().find('input[type="checkbox"]'), chboxesOuts = chboxes.parent();

                    if (chboxesOuts.filter('[hidden]').length) {    // any checkbox hidden? - show all 

                        chboxesOuts.attr('hidden', null).show();
                    } else {    // none hidden ? - hide all checked 

                        chboxesOuts.each(function () {

                            if (!$(this).children('input').is(':checked')) {

                                $(this).attr('hidden', true).hide();
                            }
                        });
                    }

                    $(window).resize();
                });

                $('#btnShowHide' + this.$value.attr('id')).mtsoftUiInputButton({});

            }

        },
        setValue: function () {

            var self = this;

            // As value set total number of checked checkboxes 
            this.value = 0;
            this.chbxs.each(function () {

                if ($(this).is(':checked')) {
                    self.value++;
                }
            });

            return this.value;
        },
        validate: function () {

            var v = this.getValue();
            return v >= this.minSelected && (this.maxSelected === null || v <= this.maxSelected);
        },
        reset: function (cfg) {

            // re-configure (if required)
            if (cfg) {
                this.$value.mtsoftUiInputConfig(cfg);
            }

            var chbxs = this.$value.parent().find('input[type="checkbox"]');
            // un-check all checkboxes
            chbxs.prop('checked', false);
            this.$value.val(0);
            // select defaults (Default should be array of full identifiers based on filednames, ex. [ModelFieldId, ...])
            var checked = 0;
            if (this.defaults.length) {

                for (var i in this.defaults) {
                    $('#' + this.defaults[i]).prop('checked', true);
                    checked++;
                }
            }
            this.$value.val(checked);
        },
        disable: function () {
            this.chbxs.attr('_disabled', true).attr('disabled', true).addClass('disabled');
        },
        enable: function (any) {
            if (any) { // .mtu-input is upload replacement
                this.chbxs.attr('disabled', false).removeClass('disabled'); // .' + cfg.cssPrefix + 'label'
            } else {

                if (this.chbxs.attr('_disabled')) {
                    this.chbxs.attr('disabled', false).attr('_disabled', null).removeClass('disabled'); // , .' + cfg.cssPrefix + 'label[_disabled]
                }
            }
        }
    };
    //
    // radio
    //
    $.mtsoftUiInput.radio = {
        validateOn: 'change',
        showDescriptionOn: 'mouseover',
        oninit: function () {

            var self = this;

            // defined radios
            this.$rds = this.$value.parent().find('input[type="radio"]');
            
            // set value on radio checked
            this.$rds.on('change.mtsoftUiInput', function () {                
                self.setValue($(this).val());
            });

            // show input description
            if (this.showDescriptionOn) {

                var $n = this.$label.parent().parent();

                $n.on(this.showDescriptionOn + '.mtsoftUiInput', function () {

                    self.description($n);
                });
            }
        }, 
        setValue: function(v) {

            // by default re-print label value (if no value given directly)
            if (v === undefined) {
                
                // re-print value from value
                this.reprintValue(this.$value.val());

            } else { // input value given directly - set value and label

                this.reprintValue(v);
            }

            return this.value;
        },
        reprintValue: function (v) {
            
            // check right radio on labels            
            this._doSelectValue(v);            
            this.$value.val(v);
            this.value = v;
        },
        disable: function () {
            this.$rds.attr('disabled', true);
        },
        enable: function () {
            this.$rds.attr('disabled', false);
        },
        reset: function (cfg) {

            // re-configure (if required)
            if (cfg) {
                this.$value.mtsoftUiInputConfig(cfg);
            }

            // check default value or first if no default
            if (this.default !== '') {
                // default value given (Default should be identifier for filedname, ex. ModelFieldId)
                this._doSelectValue(this.default);
                //this.setValue(this.default);


            } else {

                // check first
                this._doSelectValue($(this.$rds[0]).val());
                //$(this.$rds[0]).prop('checked', true);
                //this.setValue($(this.$rds[0]).val());
            }
        },
        _doSelectValue: function (v) {
            
            this.$rds.each(function () {

                var $this = $(this);
                
                if ($this.val() == v) { // can be integer given as String - use == not ===

                    $this.prop('checked', true); 
                    //log($this);
                    return false;
                }
            });
        }
    };
    //
    // file upload / .image / .images uploads;
    // As value returns object: { deletingCount:0, files: [], filesCount: 0, processingCount: 0, queuedCount: 0 }
    // where:
    //  'files' is array of uploaded file objects
    //  filesCount is number of uploaded files 
    //
    $.mtsoftUiInput.file = {
        // properties
        validateOn: false,
        showDescriptionOn: false,
        alert: {
            alertPosition: 'top'
        },
        // methods
        oninit: function ($file) {

            //console.log($file.data());

            // set labels
            // see also mtsoft.ui.upload.js => _init() --> set right config.dropNode value
            this.$label = $file.parent().parent().parent().find('.mtu-input, .mtu-drop-area, .mtu-button-mask');

            // to show alert status message on right palce             
            this.$alertParent = $(this.$label[0]).parent();
            //this.$label.addClass(this.cssPrefix + 'label');
            this.alertPosition = 'bottom-right';
            this.alertReposition = false; // laways show on bottom-right
            //
            //
            // Update validation rules for upload module (taken from model validation rules from server)
            // (to add right validation when selecting/uploading files)
            //
            for (var i in this.validationRules) {

                var vr = this.validationRules[i];
                if (vr.rule === 'filesCount') { // files min/max count
                    $file.mtsoftUiUploadConfig({filesMin: vr.params[1], filesMax: vr.params[2]});
                }
                if (vr.rule === 'fileType') { // file types
                    $file.mtsoftUiUploadConfig({fileTypesAllowed: vr.params[1]});
                }
                if (vr.rule === 'fileSize') {   // file size min/max
                    $file.mtsoftUiUploadConfig({fileSizeMin: vr.params[1], fileSizeMax: vr.params[2]});
                }
            }

            //
            // handle description: show/hide on mouseover/out or dragover/leave
            //
            var self = this, id = self.$value.attr('id'), desc = $('#' + id + '-desc');
            if (!desc.length) {
                desc = $('#' + self.$value.next().attr('id') + '-desc');
            }


            self.$label.on('mouseover.mtsoftUiInput', function () {

                desc.addClass('active');
                // to remove on mouseout
                self.$label.one('mouseout.mtsoftUiInput', function () {
                    desc.removeClass('active');
                });
            }).on('dragover.mtsoftUiInput', function (e) {
                desc.addClass('active');
                $file.mtsoftUiInputSetNeutral('client');
            }).on('dragleave.mtsoftUiInput', function (e) {
                desc.removeClass('active');
            }).on('click.mtsoftUiInput', function () {
                $file.mtsoftUiInputSetNeutral('client');
            });
            // after any file uploaded - set file input neutral
            $file.mtsoftUiUploadConfig({onFileUploadSuccess: function () {
                    $file.mtsoftUiInputSetNeutral('client');
                }});
            // on file 'Browse' button set netural formatting (in form input)
            $file.on('change.mtsoftUiUpload', function () {
                $file.mtsoftUiInputSetNeutral('client');
            });
            // DON'T WORK: to aviod valiation if file input is not required, but minimum files is greater than 0
            // this.default = $file.mtsoftUiUploadConfig('dir');            
        },
        setValue: function (v) {

            this.value = this.$value.mtsoftUiUploadStatus(); // get upload module status
            return this.value;
        },
        isReady: function () {

            var sts = this.$value.mtsoftUiUploadStatus();
            // is ready when queued and processing files count is zero
            return sts.queuedCount === 0 && sts.processingCount === 0 && sts.deletingCount === 0;
        },
        failed: function (data) {

            // mark <label> as invalid 
            $('label[for=' + this.$value.nextAll('input[type]').filter(':first').attr('id') + ']').addClass(this.cssPrefix + 'invalid');
        },
        neutral: function () {

            // unmark <label> as invalid
            $('label[for=' + this.$value.nextAll('input[type]').filter(':first').attr('id') + ']').removeClass(this.cssPrefix + 'invalid');
        },
        reset: function (cfg) {

            // re-configure (if required)
            if (cfg) {
                this.$value.mtsoftUiInputConfig(cfg);
            }

            // for add ajax mode - set new token (if any) recevied from server
            this.$value.mtsoftUiUploadReset({dir:
                        this.updateTokenDir(
                                this.$value.mtsoftUiUploadConfig('dir'),
                                this.$form.mtsoftUiFormConfig('token')
                                )
            });

            /*var newDir = {}, token = this.$form.mtsoftUiFormConfig('token');
             if (token && token !== '') {
             
             // update upload directory with new token
             var currDir = this.$value.mtsoftUiUploadConfig('dir'); // $form.mtsoftUiFormConfig('token') 
             console.log(currDir);
             //newDir = {dir: currDir.replace(/(\\|\/)[^\\\/]+(\\|\/)([a-z0-9\_\-]+)(\\|\/)$/i, "$1" + token + "$2$3$4")};
             newDir = {dir: currDir.replace(/(\\|\/)tmp(\\|\/)uploads(\\|\/)([^\\\/]+)/i, "/tmp/uploads/" + token)};
             }
             console.log(newDir);
             // reset upload input
             this.$value.mtsoftUiUploadReset(newDir);*/

            // set input default value
            this.setValue(this.default);
            // value equal to label - below not required, but REQUIRED if label is other than value
            this.$value.mtsoftUiInputSetNeutral(); // remove all types of alerts/input styling and set neutral
            //_validate(this.$value, 'blur');
        },
        /**
         * Update temporary uplaod dir (when uploading file(s) for new row) 
         * 
         * @param {String} currDir      current uplaod dir 
         * @param {Strin} token         new token 
         * @returns String 
         */
        updateTokenDir: function (currDir, token) {

            var newDir = '';
            if (token && token !== '') {

                // update upload directory with new token (replace old token with new one) 
                newDir = currDir.replace(/(\\|\/)tmp(\\|\/)uploads(\\|\/)([^\\\/]+)/i, "/tmp/uploads/" + token);
            }
            return newDir;
        },
        disable: function () {

            this.$label.attr('_disabled', true).attr('disabled', true).addClass('disabled');


            // disable uploaded files download
            this.$label.find('a, img').on('click.mtsoftUiFile keydown.mtsoftUiFile', function () {
                return false;
            });

            // don't select file on upload button click 
            $(this.$label[1]).find('label').on('click.mtsoftUiFile', function () {
                return false;
            });

            // disable sortable
            if (this.allowSorting) {

                $(this.$value.mtsoftUiUploadConfig().sortable.containment.children('.mtu-thumbs')).sortable('disable');
            }
            this.$value.attr('disabled', true);

        },
        enable: function (any) {

            if (any) { // .mtu-input is upload replacement
                this.$label.attr('disabled', false).removeClass('disabled'); // .' + cfg.cssPrefix + 'label'

            } else {

                if (this.$label.attr('_disabled')) {

                    this.$label.attr('disabled', false).attr('_disabled', null).removeClass('disabled'); // , .' + cfg.cssPrefix + 'label[_disabled]
                }
            }
            // enable uploaded files download
            this.$label.find('a, img').off('click.mtsoftUiFile keydown.mtsoftUiFile');
            $(this.$label[1]).find('label').off('click.mtsoftUiFile');

            // enable files selection 
            if (this.allowSorting) {
                $(this.$value.mtsoftUiUploadConfig().sortable.containment.children('.mtu-thumbs')).sortable("enable");
            }
            this.$value.attr('disabled', false);
        }
    };
    //
    // password
    //
    // simple password
    $.mtsoftUiInput.password = {
        storeData: false, // don't restore value from local storage
        msgs: {
            capslock: 'Caps Lock is on'
        },
        oninit: function () {
            $.mtsoftUiInput.passwordEdit.capsLockNotify(this);
            this.$label.val(''); // always reset value on init
        },
        failed: function (data) {

            // handle invalid styling when smartlabel used
            if (this.smartlabel && this.getValue() === this.default) {

                this.$label.blur();
                $('#phantom_' + this.$label.attr('id')).addClass(this.$label.attr('class')).attr(this.$label.attr('style'));
            }
        }
    };
    // confirm password input
    $.mtsoftUiInput.passwordConfirm = $.extend(true, {}, $.mtsoftUiInput.password, {
        required: true,
        alertType: 'iconInside',
        disablePaste: true,
        validateOn: 'keyup',
        msgs: {
            success: "",
            failed: "Passwords don't match"
        },
        validationRules: [{rule: 'checkPassword', params: []}],
        rules: {
            checkPassword: function (v) {

                var pass = $('#' + this.$value.attr('id').replace('Confirmed', '')).val();
                return v === pass;
            }
        }
    });
    // add/edit password
    $.mtsoftUiInput.passwordEdit = {
        required: true,
        storeData: false, // don't restore value from local storage
        alertType: 'iconInside',
        validateOn: 'keyup',
        //validationRules: [{rule: 'notEmpty', on: 'change'}],
        rules: {
            checkPassword: function (v, regex) {

                return $.fn.mtsoftUiInput.validation.regex(v, regex);
            }
        },
        //validateOnKeyupInvalid: 'caution',
        msgs: {
            success: '',
            //failed: 'Invalid password',
            capslock: 'Caps Lock is on',
            // passmeter messages
            prefix: 'Quality ',
            passShort: 'Too short password',
            passWeek: 'Week - use letters and numbers',
            passMedium: 'Medium - use special charecters',
            passStrong: 'Strong password'
        },
        // methods
        oninit: function () {

            var id = this.$label.attr('id');
            //
            // inform user that CapsLock is on
            //
            this.capsLockNotify(this);
            //
            // if password meter present - use it to show password strength
            //
            if ($('#' + id + '-passmeter').length) {

                this.passStrengthMeter($('#' + this.$label.attr('id')), this.min);
            }

            //
            // plain / masked password view switch
            //
            if ($('#' + id + 'BtnView').length) {

                var cbs = {}, self = this;
                cbs[id + '-show'] = function () {

                    var p = $('#' + id), pp = $('#' + id + '-plain');
                    pp.val(p.val());
                    if (p.mtsoftUiInputConfig('status') === 'valid') {

                        pp.mtsoftUiInputSetValid('client');

                    } else {

                        pp.mtsoftUiInputSetNeutral('client');
                    }
                    p.mtsoftUiInputSetNeutral('client').hide();
                    pp.show().focus();
                };
                cbs[id + '-mask'] = function () {

                    $('#' + id + '-plain').hide();
                    $('#' + id).val($('#' + id + '-plain').val()).show().focus();
                };

                $('#' + id + 'BtnView').mtsoftUiInputButton({
                    callback: cbs
                });

                // show description/help also for plain password view input too
                $('#' + id + '-plain').on(this.showDescriptionOn, function () {

                    $('#' + id + '-desc').addClass('active');

                    // to remove on blur
                    var event = self.showDescriptionOn === 'focus' ? 'blur' : 'mouseout';
                    $(this).one(event + '.mtsoftUiInput', function () {

                        $('#' + id + '-desc').removeClass('active');
                    });
                }).on('keyup.mtsoftUiInput', function () { // mirror plain text value to masked input on keyup/blur

                    $('#' + id).val($(this).val()).keyup();
                    // mirror input state
                    if ($('#' + id).mtsoftUiInputConfig('status') === 'valid') {

                        $(this).mtsoftUiInputSetValid('client');

                    } else {

                        $(this).mtsoftUiInputSetNeutral('client');
                    }

                }).on('blur.mtsoftUiInput', function () {

                    $('#' + id).val($(this).val()).blur();
                    // mirror input state
                    if ($('#' + id).mtsoftUiInputConfig('status') === 'invalid') {

                        $(this).mtsoftUiInputSetInvalid('client');
                    }

                }).mtsoftUiInput($.extend(true, {}, $('#' + id).mtsoftUiInputConfig(), {msgs: {failed: null}, required: true}));
            }

            //
            // password generator
            //
            if ($('#' + id + 'BtnGenerate').length) {

                $('#' + id + 'BtnGenerate').mtsoftUiInputButton({
                    callback: function () {

                        var p = $('#' + id), pp = $('#' + id + '-plain');
                        // if plain password input - show it
                        if (pp.length) {

                            // plain text password input
                            if (pp.not(":visible").length) { // plain password not visible

                                // switch to plain first
                                $('#' + id + 'BtnView').trigger('set', [id + '-show']);
                            }
                            pp.val($.mtsoftUiInput.passwordEdit.generatePass()).focus().blur();
                            p.keyup(); // to update passmeter
                        } else {
                            // masked password input
                            p.val($.mtsoftUiInput.passwordEdit.generatePass()).keyup().focus();
                        }
                    }
                });
            }

            //
            // Password confirm
            //
            if ($('#' + id + 'Confirmed').length) { // confirm password input exist

                $('#' + id + ', #' + id + '-plain').on('keyup.mtsoftUiInput', function () {

                    // if confirmation input is not empty - check passwords match
                    var c = $('#' + id + 'Confirmed'), cv = c.val();
                    if (cv !== '') {

                        if (cv === $(this).val()) {

                            c.mtsoftUiInputSetValid('client');
                        } else {
                            c.mtsoftUiInputSetNeutral('client');
                        }
                    }
                }).on('focus.mtsoftUiInput', function () {

                    // show password confirmation box if not shown already 
                    var c = $('#' + id + 'Confirmed').parents('.input-out').filter(':first');

                    if (!c.is(':visible')) {
                        c.slideDown();
                    }
                });
            }
        },
        reset: function (cfg) {
            if (cfg) {
                this.$value.mtsoftUiInputConfig(cfg);
            }
            this.setValue(this.default);
            this.$value.mtsoftUiInputSetNeutral();
            $('#' + this.$value.attr('id') + '-plain').val('').mtsoftUiInputSetNeutral('client');   // reset plain text password input
            this.$value.keyup();    // to reset passmeter
        },
        /*onblur: function() {
         //this.set($('#' + id + '-plain').val());
         },*/
        /**
         * Generate password of given length
         *
         * @param {Int} len     target password length
         * @returns {String}    random generated password
         */
        generatePass: function (len) {

            len = len || 8; // eight characters by default

            // define groups of required chars (on at least)
            var reqKeys = [], tmp = '';
            reqKeys[0] = "abcdefghijklmnopqrstuvwxyz";
            reqKeys[1] = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            reqKeys[2] = "123456789";
            reqKeys[3] = "!@#$%^&*()_+{}[]";
            var keylist = reqKeys[0] + reqKeys[1] + reqKeys[2] + reqKeys[3];
            for (var i = 0; i < len; i++) {

                if (i < 3) {

                    tmp += reqKeys[i].charAt(Math.floor(Math.random() * reqKeys[i].length));
                } else {

                    tmp += keylist.charAt(Math.floor(Math.random() * keylist.length));
                }
            }

            return tmp;
        },
        /**
         * Notify user that CapsLock is ON
         * @param {type} config
         * @returns {unresolved}
         */
        capsLockNotify: function (config) {

            config.$label.on('keypress.mtsoftUiInput', function (e) {

                var c = e.which, isCapsLockOn =
                        ((c >= 65 && c <= 90) && !e.shiftKey) || // A-Z and NOT SHIFT key
                        ((c >= 97 && c <= 122) && e.shiftKey); // a-z and SHIFT key

                // use gui msg
                if (isCapsLockOn) {

                    if (!config.$label.data('_cn')) {

                        $(config.$label).mtsoftUiAlertInput(config.msgs.capslock, 'info', {
                            alertPosition: 'top',
                            btnClose: true,
                            separated: true // show this alert separately
                        });

                        config.$label.data('_cn', $(config.$label).mtsoftUiAlerts()[0]);
                    }

                    //$(this).data('_cl_warning', true);
                } else {

                    // close CapsLock Notify
                    if (config.$label.data('_cn')) {

                        config.$label.data('_cn').mtsoftUiAlertClose();
                        config.$label.data('_cn', null);
                    }
                }

                return true;
            }).on('blur.mtsoftUiInput', function (e) {
                try {
                    config.$label.data('_cn').mtsoftUiAlertClose();
                    config.$label.data('_cn', null);
                } catch (e) {
                }
            });
        },
        /**
         * Visualize password msgsength and show hints.
         * Note: input have to be dispalyed!
         * @param {type} $n
         * @param {type} minLen
         */
        passStrengthMeter: function ($n, minLen) {

            minLen = minLen || 4, msgs = this.msgs;
            /**
             * Get password msgsength
             * @param {Object} pass
             * @param {Object} minLen
             */
            function _msgsength(pass, minLen) {

                var s = 0, r = {}; // s is score, r is return object
                minLen = minLen || 1;
                // pass < length
                if (pass.length < minLen) {

                    return {
                        score: msgs.passShort,
                        percent: 0
                    };
                }

                // pass length
                s += pass.length * 4;
                s += (_checkRepetition(1, pass).length - pass.length) * 1;
                s += (_checkRepetition(2, pass).length - pass.length) * 1;
                s += (_checkRepetition(3, pass).length - pass.length) * 1;
                s += (_checkRepetition(4, pass).length - pass.length) * 1;
                //pass has 3 numbers
                if (pass.match(/(.*[0-9].*[0-9].*[0-9])/))
                    s += 5;
                //pass has 2 sybols
                if (pass.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/))
                    s += 5;
                //pass has Upper and Lower chars
                if (pass.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/))
                    s += 10;
                //pass has number and chars
                if (pass.match(/([a-zA-Z])/) && pass.match(/([0-9])/))
                    s += 15;
                //
                //pass has number and symbol
                if (pass.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && pass.match(/([0-9])/))
                    s += 15;
                //pass has char and symbol
                if (pass.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && pass.match(/([a-zA-Z])/))
                    s += 15;
                //pass is just a nubers or chars
                if (pass.match(/^\w+$/) || pass.match(/^\d+$/))
                    s -= 10;
                //verifing 0 < s < 100
                if (s < 0)
                    s = 0;
                if (s > 100)
                    s = 100;
                return {
                    score: s < 34 ? msgs.passWeek : (s < 68 ? msgs.passMedium : msgs.passStrong),
                    percent: s
                };
            }

            /**
             * Check char repetions number
             * @param {Object} pLen
             * @param {Object} msgs
             */
            function _checkRepetition(pLen, msgs) {

                var res = "";
                for (var i = 0; i < msgs.length; i++) {

                    var repeated = true;
                    for (var j = 0; j < pLen && (j + i + pLen) < msgs.length; j++) {
                        repeated = repeated && (msgs.charAt(j + i) === msgs.charAt(j + i + pLen));
                    }

                    if (j < pLen) {
                        repeated = false;
                    }

                    if (repeated) {
                        i += pLen - 1;
                        repeated = false;
                    } else {
                        res += msgs.charAt(i);
                    }
                }
                return res;
            }

            // build meter
            var $meter = $('<div class="pm-bar"></div>'), $pm = $('#' + $n.attr('id') + '-passmeter'), self = this;
            $pm.append($meter);
            $n.on('keyup.mtsoftUiInputPass', function () { // update meter

                var pass = _msgsength($(this).val(), minLen); // get password parameters
                var R = parseInt((188 * (100 - pass.percent)) / 100), G = parseInt((188 * pass.percent) / 100), B = 0;
                // password meter
                $meter.css({width: pass.percent + '%', backgroundColor: 'rgba(' + R + ',' + G + ',' + B + ',0.8)'});
                // password meter label
                $pm.parent().children('.pm-label').html(self.msgs.prefix + ' ' + pass.percent + '% (' + pass.score + ')');

                //this.msgs.prefix
                //pass.score
                // pass.percent

            });
        }
    };
    //
    // captcha
    //
    $.mtsoftUiInput.captcha = {
        // limit allowed characters to number and letters
        allowOnly: /^[a-zA-Z0-9]$/,
        storeData: false, // don't restore value from local storage
        msgs: {
            neutral: null,
            failed: 'required',
            ajax_failed: 'Entered code is invalid'
        },
        oninit: function ($input) {

            var id = $input.attr('id'),
                    query = 'w=' + this.width + '&h=' + this.height + '&c=' + this.codeLength + '&rnd=' + $('#' + id + 'Rnd').val();
            // refresh captcha code image button
            $('#' + id + 'Btn').on('click.mtsoftUiInput', function () {

                var $img = $('#' + id + 'Img');
                $img.attr('src', $.mtsoft.url('captcha/generate/?' + query) + '&rrnd=' + Math.random());
                // lock while re-loading
                $img.parent().mtsoftUiLock({icon: {size: '3rem'}}).on();
                $img.one('load', function () {
                    $(this).parent().mtsoftUiLock().off();
                });
                // focus captcha code input
                $input.val('').focus();
            });
        },
        validationAjaxData: function () {
            // send rnd value to identity right Captcha code
            return {params: [$('#' + this.$value.attr('id') + 'Rnd').val()]};
        },
        reset: function (cfg) {
            // re-configure (if required)
            if (cfg) {
                this.$value.mtsoftUiInputConfig(cfg);
            }
            var id = this.$value.attr('id'),
                    query = 'w=' + this.width + '&h=' + this.height + '&c=' + this.codeLength + '&rnd=' + $('#' + id + 'Rnd').val();
            // refresh captcha code image button
            $('#' + id + 'Img').attr('src', $.mtsoft.url('captcha/generate/?' + query) + '&rrnd=' + Math.random());
            $('#' + id).val('');
            this.$value.mtsoftUiInputSetNeutral();
        }
    };

    //
    // view (read only) input value 
    //
    $.mtsoftUiInput.view = {
        showDescriptionOn: 'mouseover',
        oninit: function ($inp) {

            // use dotdot to fit text contents if not fit
            /*try {
             this.$value.find('a').dotdotdot({});
             } catch(e) {}*/
        },
        setValue: function (v) {

            if (v !== null) {
                this.$value.html(v);
            }
        }
    };

    //
    // date input
    //
    $.mtsoftUiInput.date = {
        // properties
        maskDate: true,
        validateOn: 'change',
        allowOnly: /^[0-9\-\/\.]$/,
        datepicker: false, // if true or JSON object use datepicker to select date 
        showOn: 'focus', // datepicker show on input focus or button click [ focus | button ]
        readonly: false, // if true date can be only entered using datepicker
        format: 'd-m-Y', // date format - equal to PHP date format 
        smartlabel: true, // if true show smart label for choosen date format; if string - directly given smartlabel value 
        from: undefined,
        to: undefined,
        /*msgs: {
         failed: 'required'
         },*/
        alert: {
            alertPosition: 'bottom'
        },
        // methods
        oninit: function ($inp) {

            // set input labels to all time period inputs 
            /*if ($('#' + this.$value.attr('id') + 'Label').length) {
             this.$label = $('#' + this.$value.attr('id') + 'Label');
             }*/
            var self = this;//, $inp = this.$value;
            // to show alert status message on right palce             
            //this.$alertParent = this.$value.parent();

            // use custom mask depending on date format
            if (this.maskDate && !this.readonly) {

                try {
                    this.$label.mask(this._setDateMask(this.format));
                } catch (e) {
                }
            }

            // use smart label depending on date format
            if (this.smartlabel === true) {

                this.smartlabel = this._smartlabel(this.format);
            }


            // assign validation 
            //var DateJsF = this._convFormat2Datejs(this.format);
            this.addValidationRule([
                {rule: 'date', msg: 'Wrong date'},
                {rule: 'dateBetween', params: ['yyyy-MM-dd', this.from, this.to], msg: 'Date outside range'}
            ], true);


            if (this.$value.attr('data-period-end')) {

                // two inputs for period of time - this is start input for period (shown first) 

                // this is time start input of time period inputs set
                // validate only on submit 
                this.addValidationRule([
                    {rule: 'isPeriod', params: [this.$value.attr('data-period-end'), true, 'day'], msg: 'Wrong period'}, // is right period; true - allows single day selection  
                    {rule: 'periodBetween', params: [this.$value.attr('data-period-end'), 'day', this.minLen, this.maxLen], msg: 'Period too short or too long'} //is period in range 
                ], true);

            }
            if (this.$value.attr('data-period')) { // This is one (first or second) of time period inputs

                this.validateOn = 'blur'; // validate on blur (in case connected second input was changed) 

                // inputs to be validated paralelly 
                this.$paralels = [this.$value.attr('data-period-begin') ?
                            $('input[name="' + this.$value.attr('data-period-begin') + '"]') :
                            $('input[name="' + this.$value.attr('data-period-end') + '"]')];

            } else {    // this is single time input

            }
            //this.validationRules = [];

            // use jQueryUI Datepicker 
            if (this.datepicker) {

                this.$label.datepicker($.extend({
                    dateFormat: this._convFormat2Datepicker(this.format),
                    minDate: Date.parseExact(this.from, 'yyyy-MM-dd'),
                    maxDate: Date.parseExact(this.to, 'yyyy-MM-dd'),
                    hideIfNoPrevNext: true,
                    showOtherMonths: true,
                    selectOtherMonths: false,
                    showAnim: false,
                    showOn: this.showOn, //buttonImageOnly: false,
                    onSelect: function (dateText, inst) {

                        // this - refers to related input
                        //self.setValue(dateText);    // to validate new pikced date value                         
                        self.$value.mtsoftUiInputValidate('submit');
                        self.$value.change(); // force change event for hidden date vlaue input 
                        $(this).removeClass('mtf-smart-label');
                    },
                    beforeShow: function () {

                        // always update current datepicker value with date from input date
                        self.$label.datepicker("setDate", Date.parseExact(self.$value.val(), 'yyyy-MM-dd'));
                        setTimeout(function () { // set right z-index (above invalid status messages) 
                            $('.ui-datepicker').css('z-index', 2001);
                        }, 0);
                    }
                }, this.datepicker));
                // on calendar icon click - show datepicker
                var dateInp = this.$label;
                this.$label.parent().find('.in-postfix').on('click.mtsoftUiInput', function () {
                    dateInp.focus();
                });

                if (this.readonly) {

                    this.$label.on('focus.mtsodtUiInput', function () {
                        $(this).blur();
                    });
                }

                if (this.showOn === 'focus') { // if show calendar on focus - show it also on click on claendar icon in input 

                    var $l = this.$label;
                    this.$label.siblings('.in-prefix').on('click.mtsoftUiInput', function () {
                        $l.focus();
                    });
                }
            }

        },
        reprintValue: function (v) {

            var date = this._convDate(v, this.format).date,
                    dateFormatted = this._convDate(date, 'Y-m-d', this.format).date;

            // put on label         
            if (this.$label.val() !== dateFormatted) {
                this.$label.val(dateFormatted);
            }

            // set value 
            this.$value.val(date);
            this.value = date;
        },
        beforeSetState: function () {

            if (this.$value.attr('data-period')) { // only if two inputs for period 

                // by default invalid status messages shwon on bottom of each input
                this.$alertParent = this.$label;
                this.alertPosition = 'bottom';
                this.alertReposition = false;

                if (this._invalidRules.length) {  // this is invalid status 

                    if ($.inArray('isPeriod', this._invalidRules) > -1 ||
                            $.inArray('periodBetween', this._invalidRules) > -1) {

                        // validation period related rules failed (common for both inputs) 
                        this.$alertParent = this.$label.parent().parent();
                        this.alertPosition = 'right';
                        this.alertReposition = true;
                    }
                }
            }
        },
        /*neutral: function() {
         },
         reset: function(cfg) {
         },
         disable: function() {
         },
         enable: function(any) {
         },*/
        /**
         * Convert date between formats 
         * 
         * @param {type} date
         * @param {type} input
         * @param {type} out
         * @returns {Boolean|_L15.$.mtsoftUiInput.date._convDate.Anonym$22}
         */
        _convDate: function (date, input, out) {

            out = out || 'Y-m-d';
            input = input || 'd-m-Y';

            var _d, y, m, d, ymd, msk,
                    parseDate = function (date, formats) {

                        var _f, _d;
                        for (var i in formats) {
                            var _f = formats[i];
                            _d = Date.parseExact(date, _f);
                            if (_d !== null) {

                                return _d;
                            }
                        }
                    };

            // from
            switch (input) {

                case 'Y-m-d':
                    _d = parseDate(date, ["yyyy-MM-dd", "yyyy-MM-d", "yyyy-M-dd", "yyyy-M-d"]);
                    msk = '9999-99-99';
                    break;

                case 'd-m-Y':
                    _d = parseDate(date, ["dd-MM-yyyy", "d-MM-yyyy", "dd-M-yyyy", "d-M-yyyy"]);
                    msk = '99-99-9999';
                    break;

                case 'd/m/Y':
                    _d = parseDate(date, ["dd/MM/yyyy", "d/MM/yyyy", "dd/M/yyyy", "d/M/yyyy"]);
                    msk = '99/99/9999';
                    break;

                case 'm/d/Y':
                    _d = parseDate(date, ["MM/dd/yyyy", "MM/d/yyyy", "M/dd/yyyy", "M/d/yyyy"]);
                    msk = '99/99/9999';
                    break;
            }

            // to
            if (_d) {
                d = _d.toString("dd");
                m = _d.toString("MM");
                y = _d.toString("yyyy");

                switch (out) {

                    case 'Y-m-d':
                        ymd = y + '-' + m + '-' + d;
                        break;

                    case 'd-m-Y':
                        ymd = d + '-' + m + '-' + y;
                        break;

                    case 'd/m/Y':
                        ymd = d + '/' + m + '/' + y;
                        break;

                    case 'm/d/Y':
                        ymd = m + '/' + d + '/' + y;
                        break;
                }

                return {date: ymd, year: y, month: m, day: d, mask: msk};
            } else {

                return {date: date}; // return input value 
            }
        },
        /**
         * Convert PHP date format to Date.js date format
         * @param {type} f
         * @returns 
         */
        _convFormat2Datejs: function (f) {

            var _f;
            switch (f) {

                case 'd-m-Y':
                    _f = 'dd-MM-yyyy';
                    break;
                case 'd/m/Y':
                    _f = 'dd/MM/yyyy';
                    break;
                case 'm/d/Y':
                    _f = 'MM/dd/yyyy';
                    break;
                case 'Y-m-d':
                    _f = 'yyyy-MM-dd';
                    break;

            }
            return _f;
        },
        _convFormat2Datepicker: function (f) {

            var _f;
            switch (f) {

                case 'Y-m-d':
                    _f = 'yy-mm-dd';
                    break;
                case 'd-m-Y':
                    _f = 'dd-mm-yy';
                    break;

                case 'd/m/Y':
                    _f = 'dd/mm/yy';
                    break;

                case 'm/d/Y':
                    _f = 'mm/dd/yy';
                    break;

            }
            return _f;
        },
        _setDateMask: function (format) {
            var msk;

            // set custom mask for days and months
            /*if ($.mask.definitions["1"] === undefined) {
             $.mask.definitions["1"] = "[0-2]";
             }*/

            switch (format) {

                case 'Y-m-d':
                    msk = '9999-99-99';
                    break;

                case 'd-m-Y':
                    msk = '99-99-9999';
                    break;

                case 'd/m/Y':
                    msk = '99/99/9999';
                    break;

                case 'm/d/Y':
                    msk = '99/99/9999';
                    break;
            }
            return msk;
        },
        _smartlabel: function (format) {
            var msk;

            switch (format) {

                case 'Y-m-d':
                    msk = 'yyyy-mm-dd';
                    break;

                case 'd-m-Y':
                    msk = 'dd-mm-yyyy';
                    break;

                case 'd/m/Y':
                    msk = 'dd/mm/yyyy';
                    break;

                case 'm/d/Y':
                    msk = 'mm/dd/yyyy';
                    break;
            }
            return msk;
        }
    };

    //
    // time input; can be start/stop time of period 
    //
    $.mtsoftUiInput.time = {
        format: 24,
        minutes: true,
        seconds: false,
        maskTime: true,
        smartlabel: true,
        from: undefined, // minimum time value
        to: undefined, // maximum time value
        minLen: undefined, // minimum period length (if any)
        maxLen: undefined, // maximum period length (if any) 
        // methods
        oninit: function () {


            // set input labels to all time period inputs 
            /*if ($('#' + this.$value.attr('id') + 'Label').length) {
             // this is text input for time
             this.$label = $('#' + this.$value.attr('id') + 'Label');
             }*/
            // var self = this, $inp = this.$value;

            //this.$alertParent = this.$value.parent();

            // use custom mask depending on date format
            if (this.maskTime) {
                try {
                    if (this.$label.length) {
                        this.$label.mask(this._setTimeMask());
                    }
                } catch (e) {
                }
            }

            // use smart label depending on date format
            if (this.smartlabel === true) {

                this.smartlabel = this._smartlabel(this.format);
            }

            //var validTime = $(this).val().match(/^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/)
            this.addValidationRule([
                {rule: 'time', msg: 'Wrong time'},
                {rule: 'timeBetween', params: [this.from, this.to], msg: 'Time outside allowed range'}
            ], true);

            if (this.$value.attr('data-period-end')) {

                // two inputs for period of time - this is start input for period (shown first) 

                // this is time start input of time period inputs set
                // validate only on submit 
                this.addValidationRule([
                    {rule: 'isPeriod', params: [this.$value.attr('data-period-end')], msg: 'Wrong period'}, // is right period 
                    {rule: 'periodBetween', params: [this.$value.attr('data-period-end'), 'minute', this.minLen, this.maxLen], msg: 'Period too short or too long'} //is period in range 
                ], true);

            } else {

                // single time input (or second on period)  
                //this.alertReposition = false; // single on row - status messages always view on right side 
            }

            if (this.$value.attr('data-period')) { // This is one (first or second) of time period inputs

                this.validateOn = 'blur'; // validate on blur (in case connected second input was changed) 
                //this.alertPosition = 'bottom'; // view stauts alerts related to time input

                // related inputs (begin or end of period text input
                //this.$inputs = this.$value.parent().parent().find('input[type="text"]').not(this.$label);

                // inputs to be validated paralelly 
                this.$paralels = [this.$value.attr('data-period-begin') ?
                            $('input[name="' + this.$value.attr('data-period-begin') + '"]') :
                            $('input[name="' + this.$value.attr('data-period-end') + '"]')];

            } else {    // this is single time input

                // if select boxes there can be more than single input (generated by CakePHP Helper)        
                //this.$inputs = this.$value.siblings('select');

            }
            //this.validationRules = [];
            /*
             this.$value.siblings('select, input[type="text"]').on('focus.mtsoftUiInput change.mtsoftUiInput', function() {
             $inp.mtsoftUiInputSetNeutral();                
             self._reprintTime($inp);
             });*/
        },
        /*validateOnFailed: function(data) {
         this.failed();
         //this.$inputs.addClass(this.cssPrefix + 'failed');
         },*/
        beforeSetState: function () {

            if (this.$value.attr('data-period')) { // only if two inputs for period 

                // by default invalid status messages shwon on bottom of each input
                this.$alertParent = this.$label;
                this.alertPosition = 'bottom';
                this.alertReposition = false;

                if (this._invalidRules.length) {  // this is invalid status 

                    if ($.inArray('isPeriod', this._invalidRules) > -1 ||
                            $.inArray('periodBetween', this._invalidRules) > -1) {

                        // validation period related rules failed (common for both inputs) 
                        this.$alertParent = this.$label.parent().parent();
                        this.alertPosition = 'right';
                        this.alertReposition = true;
                    }
                }
            }
        },
        failed: function () { // on server side validation 
            /*if (this.$paralels) {
             for (var i = 0, $p; $p = this.$paralels[i]; i++) {
             $p.mtsoftUiInputConfig().$label.addClass(this.cssPrefix + 'failed');
             }
             }*/
        },
        neutral: function () {
        },
        reprintValue: function (v) {

            var time = Date.parse(v), time24 = time !== null ? time.toString('HH:mm:ss') : time;
            this.$value.val(time24);
            this.value = time24;
        },
        _setTimeMask: function () {
            var msk = '99' + (this.minutes ? ':99' : '') + (this.seconds ? ':99' : '');

            if (parseInt(this.format) === 12) {
                msk += ' aa';
            }

            return msk;
        },
        _smartlabel: function (format) {
            var sl = '';

            switch (parseInt(format)) {

                case 12:
                    sl = 'hh:mm me';
                    break;

                case 24:
                    sl = 'hh:mm';
                    break;
            }
            return sl;
        }
    };

    //
    // time select box; can be start/stop time of period 
    //
    $.mtsoftUiInput.timeSelect = {
        format: 24,
        from: undefined, // minimum time value
        to: undefined, // maximum time value
        //minLen: null, // minimum period length (if any)
        //maxLen: null, // maximum period length (if any) 
        showDescriptionOn: 'mouseover',
        // methods
        oninit: function ($inp) {


            // set input labels to all time period inputs 
            var id = this.$value.attr('id');
            // this is select boxes inputs
            this.$label = $('#' + id + 'Hour, #' + id + 'Min, #' + id + 'Sec, #' + id + 'Meridian');
            var self = this;

            //this.$alertParent = this.$value.parent();
            this.$alertParent = this.$label;

            this.addValidationRule([
                {rule: 'timeBetween', params: [this.from, this.to], msg: 'Time outside allowed range'}
            ], true);

            if (this.$value.attr('data-period-end')) {

                // two inputs for period of time - this is start input for period (shown first) 

                // this is time start input of time period inputs set
                // validate only on submit 
                this.addValidationRule([
                    {rule: 'isPeriod', params: [this.$value.attr('data-period-end')], msg: 'Wrong period'}, // is right period 
                    {rule: 'periodBetween', params: [this.$value.attr('data-period-end'), 'minute', this.minLen, this.maxLen], msg: 'Period too short or too long'} //is period in range 
                ], true);

            } else {

                // single time input (or second on period)  
                //this.alertReposition = false; // single on row - status messages always view on right side 
            }

            if (this.$value.attr('data-period')) { // This is one (first or second) of time period inputs

                this.validateOn = 'change'; // validate on blur (in case connected second input was changed) 
                //this.alertPosition = 'bottom'; // view stauts alerts related to time input

                // related inputs (begin or end of period text input
                //this.$inputs = this.$value.parent().parent().find('input[type="text"]').not(this.$label);

                // inputs to be validated paralelly 
                this.$paralels = [this.$value.attr('data-period-begin') ?
                            $('input[name="' + this.$value.attr('data-period-begin') + '"]') :
                            $('input[name="' + this.$value.attr('data-period-end') + '"]')];

            } else {    // this is single time input

                // if select boxes there can be more than single input (generated by CakePHP Helper)        
                //this.$inputs = this.$value.siblings('select');

            }
            //this.validationRules = [];

            // update value on any select value changed 
            this.$label.on('change.mtsoftUiInput', function () {
                self.setValue();
            });

            // on label click focus first of related inputs 
            $('label[for="' + this.$value.attr('id') + '"]').on('click.mtsoftUiInput', function () {
                $(self.$label[0]).focus();
            });
        },
        reprintValue: function (v) {

            // re-print value from label
            var id = this.$value.attr('id'),
                    // this is select boxes inputs
                    h = $('#' + id + 'Hour'),
                    m = $('#' + id + 'Min'),
                    s = $('#' + id + 'Sec'),
                    mer = $('#' + id + 'Meridian');

            var time = v, _t = (h.length ? h.val() : '00') + ':' + (m.length ? m.val() : '00') + ':' + (s.length ? s.val() : '00') + (mer.length ? ' ' + mer.val() : ''),
                    _time = Date.parse(_t);

            if (_time !== null) { // if label choosen 

                time = _time.toString('HH:mm:ss');
            }
            if (_t === '::') {
                time = ''; // no time selected (labels) 
            }

            this.value = time; // set input's value config property
            this.$value.val(time); // set input value node value
        },
        beforeSetState: function () {

            if (this.$value.attr('data-period')) { // only if two inputs for period 

                // by default invalid status messages shwon on bottom of each input
                this.$alertParent = this.$value.parent();
                this.alertPosition = 'bottom';
                this.alertReposition = false;

                if (this._invalidRules.length) {  // this is invalid status 

                    if ($.inArray('isPeriod', this._invalidRules) > -1 ||
                            $.inArray('periodBetween', this._invalidRules) > -1) {

                        // validation period related rules failed (common for both inputs) 
                        this.$alertParent = this.$value.parent().parent();
                        this.alertPosition = 'right';
                        this.alertReposition = true;
                    }
                }
            }
        }
    };

    //
    // dateselect box; can be begin/end date of period 
    //
    $.mtsoftUiInput.dateSelect = {
        //format: 24,
        from: undefined, // minimum time value
        to: undefined, // maximum time value
        //minLen: null, // minimum period length (if any)
        //maxLen: null, // maximum period length (if any) 
        showDescriptionOn: 'mouseover',
        // methods
        oninit: function ($inp) {


            // set input labels to all time period inputs 
            var id = this.$value.attr('id');
            // this is select boxes inputs
            this.$label = $('#' + id + 'Day, #' + id + 'Month, #' + id + 'Year');
            var self = this;

            this.$alertParent = this.$label;

            this.addValidationRule([
                {rule: 'date', msg: 'Wrong date'},
                {rule: 'dateBetween', params: ['yyyy-MM-dd', this.from, this.to], msg: 'Date outside range'}
            ], true);
            //this.validationRules = [];

            // update value on any select value changed 
            this.$label.on('change.mtsiftUiinput', function () {
                self.setValue();
            });

            // on label click focus first of related inputs 
            $('label[for="' + this.$value.attr('id') + '"]').on('click.mtsoftUiInput', function () {
                $(self.$label[0]).focus();
            });
        },
        reprintValue: function (v) {

            // re-print value from label
            var id = this.$value.attr('id'),
                    // this is select boxes inputs
                    d = $('#' + id + 'Day'),
                    m = $('#' + id + 'Month'),
                    y = $('#' + id + 'Year');

            var date = (y.length && $.trim(y.val()).length ? y.val() : '0000')
                    + '-' + (m.length && $.trim(m.val()).length ? m.val() : '00')
                    + '-' + (d.length && $.trim(d.val()).length ? d.val() : '00');

            this.value = date; // set input's value config property
            this.$value.val(date); // set input value node value
        }
    };

    $.mtsoftUiInput.dateTimeSelect = {
        showDescriptionOn: 'mouseover',
        oninit: function () {

            var self = this;
            if (this.showDescriptionOn) {
                // get real, single id 
                var id = this.$value.attr('id').replace(/Year|Month|Day|Hour|Min|Sec|Meridian/i, '');
                // show description on whole input row mouseover 
                this.$label.parents('.input-out').filter(':first').on(this.showDescriptionOn + '.mtsoftUiInput', function () {
                    self.description($(this), id);
                });
            }
        }
    };


    //
    // quote input (monetary) 
    //
    $.mtsoftUiInput.quote = {
        min: null,
        max: null,
        negative: false,
        selectOnFocus: true,
        // jQuery plugin $.formatCurrency() formatting 
        format: {
            decimal: 2,
            symbol: "", // currency symbol 
            digitGroupSymbol: " ", // between thousands 
            decimalSymbol: '.',
            negativeFormat: "-%n",
            colorize: false, // if true negative numbers are red
            roundToDecimalPlace: 2
        },
        // methods
        oninit: function () {

            var self = this;

            //this.$alertParent = this.$value.parent();

            // set alowed characters depending on quote format
            if (this.allowOnly === null) {
                this.allowOnly = '^[0-9]' + (this.negative ? '|\\-' : '') + '|\\' + (this.symbol ? '|\\' + this.symbol : '') + this.format.decimalSymbol + '|\\' + this.format.digitGroupSymbol + '$';
            }

            // validation rules (don't overwrites values from model)

            this.addValidationRule([
                {rule: 'regex', params: ['/^' + (this.negative ? '(\-{1}|)' : '') + '([0-9]+|[0-9|\\' + this.format.digitGroupSymbol + ']+' + this.format.decimalSymbol + '[0-9]{1,' + this.format.roundToDecimalPlace + '})$/'], msg: 'Invalid quote'},
                {rule: 'valRange', params: [this.min, this.max], msg: 'Quote outside range'}
            ]);

            // format currency 
            if (!this.smartlabel) {
                this.$label.formatCurrency(this.format);
            }
            this.$label.on('change.mtsoftUiInput', function () {
                $(this).formatCurrency(self.format);
            });
        },
        reprintValue: function (v) {

            if (v === undefined) {
                var v = this.$label.asNumber();
                if (parseInt(this.$label.val()) !== 0 && !v) { // wrong formatted quote entered 
                    v = this.$label.val(); // we need entered value for validation 
                }
            } else {
                this.$label.val(v).formatCurrency(this.format);
                v = this.$label.asNumber();
            }
            this.$value.val(v); // remove non numeric characters 
            this.value = v;
        }
    };


    //
    // quote input (monetary) 
    //
    $.mtsoftUiInput.int = {
        min: null,
        max: null,
        negative: false,
        selectOnFocus: true,
        // jQuery plugin $.formatCurrency() formatting 
        // methods
        oninit: function () {

            var self = this;

            // set alowed characters depending on quote format
            if (this.allowOnly === null) {
                this.allowOnly = '^[0-9]' + (this.negative ? '|\\-' : '') + '$';
            }

            // validation rules (don't overwrites values from model)
            this.addValidationRule([
                {rule: 'regex', params: ['/^' + (this.negative ? '(\-{1}|)' : '') + '([0-9]+)$/'], msg: 'Invalid number'},
                {rule: 'valRange', params: [this.min, this.max], msg: 'Number outside range'}
            ]);

            if (this.min) {

                // if value is lower than min or empty  - change it autmatically to minimum value
                this.$label.on('change.mtsoftUiInput', function () {

                    var v = parseInt($(this).val());

                    if (isNaN(v) || v < self.min) {

                        self.setValue(self.min);
                    }
                    return true;
                });
            }
        }
    };

    //
    // percent input (NO DECIMALS!) 
    //
    $.mtsoftUiInput.percent = {
        min: null,
        max: null,
        default: 0,
        negative: 'max',
        selectOnFocus: true,
        // methods
        oninit: function () {

            // set alowed characters depending on quote format
            if (this.allowOnly === null) {
                this.allowOnly = '^[0-9]' + (this.negative ? '|\\-' : '') + '$';
            }

            this.addValidationRule([
                {rule: 'regex', params: ['/^' + (this.negative ? '(\-{1}|)' : '') + '([0-9]+)$/'], msg: 'Invalid percent'},
                {rule: 'valRange', params: [this.min, this.max], msg: 'Percent outside range'}
            ]);
        }
    };



    //
    // has many - set of inputs ; can be grid or table of inputs 
    //
    $.mtsoftUiInput.hasMany = {
        minRows: null, // minimum number of rows
        maxRows: null, // maximum number of rows 
        scrollOnEdit: true, // scroll down (on add) or up (on delete) current page where table is nested
        newRowOnEnter: null, // add new row when user hits enter - String object selector; ex. "#id, #name" 
        sortable: false, // hasMany elements should be sortable [ Bool | JSON sortable definitions object); 
        // `hasMany` model must use 'Sortable' behaviour 
        // `hasMany` association must haave order=off clausule defined 
        sortUrl: 'forms/sort/', // sort url
        parent: {model: null, field: null, id: null}, // parent model and field and id
        txt: {
            minRows: false,
            maxRows: false
        },
        // callbacks
        beforeRowInsert: function () {
        },
        afterRowInsert: function ($row, init) {
        },
        beforeRowDelete: function ($row) {
        },
        afterRowDelete: function ($row) {
        },
        // methods
        oninit: function () {

            var $n = $('#' + this.id), self = this;
            this.isTable = $n.find('table').length;
            this.model = $n.data('model');
            // define elements 
            this.$tbody = this.isTable ? $n.find('table').children('tbody') : $n.find('div.row.' + this.cssPrefix + 'dg-compact');
            this.$addBtn = $('#' + this.id + 'Add');
            this.$deleteBtns = this.isTable ? $n.find('table').find('tbody > tr > td > button.delete') : $n.find('button.delete');
            this.$deletedRowsIds = $('#' + this.model + 'Deleted');

            // add existing rows to rows collection 
            this.$rows = [];
            var $rows = this.isTable ? $n.find('table').find('tbody > tr').not('[data-off="SkelOffset"]') : $n.find('div.row.' + this.cssPrefix + 'dg-compact').children('div').not('[data-off="SkelOffset"]');

            $rows.each(function () {
                self.$rows.push($(this));
            });
            this.$value.mtsoftUiInputConfig('$rows', this.$rows);

            //
            // find action buttons and assigne event handlers
            //

            // ADD row button 
            this.$addBtn.on('click.mtsoftUiInput', function () {

                self._addRow(undefined, true);
            });
            // delete row button 
            this.$deleteBtns.on('click.mtsoftUiInput', function () {

                //self._deleteRow($(this).parents('tr').filter(':first'));
                self._deleteRow($(this).parents('[data-off]').filter(':first'));
            });

            // On Enter hit add new row if current row is the last one and not empty 
            if (this.newRowOnEnter) {
                
                $(this.newRowOnEnter).each(function () {
                    self._assingNewRowOnEnterHit($(this));
                });
            }

            this._buildMinRows();
            this._reindexRows();

            if (this.sortable) {

                this._defineSortable();
            }
        }, setValue: function (v) {

            // v (value) must be array of objects with at leaset required field names / values pairs (no Model name) 
            // ex. [{id: 12, name: "Me name"}, ...] 
            // 
            // by default re-print label value (if no value given directly)
            if (v !== undefined) {

                // create new row and re-print values for inputs  
                for (var i in v) {
                    this.addRow(undefined, undefined, undefined, v[i]);
                }
            }

            return this.value;
        },
        reset: function () {

            // remove all visible hasMany table rows (first row is skeleton) 
            this.$tbody.children().not(':first').remove();
            //this.$value.mtsoftUiInputConfig('$rows', 0);
            this.$rows = [];
            this._buildMinRows(true);
        },
        _buildMinRows: function () {

            //this.$rows = this.$value.mtsoftUiInputConfig('$rows') || [];
            // build minimum required number of rows
            if (this.minRows !== null && this.$rows.length < this.minRows) {

                while (this.$rows.length < this.minRows) {
                    this._addRow(true, false, false); // don't focus and don't execute callback
                }
            }
        },
        _assingNewRowOnEnterHit: function ($n) {

            var self = this;
            $n.on('keydown.mtsoftUiInput', function (e) {

                if (e.which === 13 && $.trim($(this).val()) !== '') {   // Enter hit and input value not empty 

                    // add new row 
                    //if (!$(this).parents('tr').filter(':first').next().length) {
                    if (!$(this).parents('[data-off]').filter(':first').next().length) {
                        // row where Enter has been hit is the last one
                        // add new row
                        self._addRow(true, true);
                    }
                }
            });
        },
        addRow: function (init, focusFirstInput, callback, values) {
            return this._addRow(init, focusFirstInput, callback, values);
        },
        _addRow: function (init, focusFirstInput, callback, values) {

            focusFirstInput = focusFirstInput || false;
            // get current number of rows
            this.$rows = this.$value.mtsoftUiInputConfig('$rows') || [];

            // only if less then maximum 
            if (this.maxRows === null || this.maxRows > this.$rows.length) {

                var self = this, $clonedRow = this.$tbody.children().filter(':first').clone();

                // get new row offset
                var off = 0;
                this.$tbody.children().each(function () { // find max row offset

                    var _off = parseInt($(this).attr('data-off'));
                    if (_off > off) {
                        off = _off;
                    }
                });
                off++; // next 

                // show and place on grid/table 
                $clonedRow.attr('data-off', off).css({display: this.isTable ? 'table-row' : 'block'}).appendTo(this.$tbody);
                if (!this.isTable) {
                    $clonedRow.addClass('columns end'); // to keep grid layout 
                }

                // generate and assign random row identifier
                //var rnd = (Math.random() + '').replace('0.', ''), firstCell = $clonedRow.children('td').filter(':first');
                var rnd = (Math.random() + '').replace('0.', ''), firstCell = $clonedRow.children().filter(':first');
                $clonedRow.attr('data-rnd', rnd);   // neeed for sort 
                firstCell.find('input[name$="[rnd]"]').val(rnd); // will be send to server on form submit
                firstCell.find('input[name$="[id]"]').attr('data-rnd', rnd); // will be used to identify right input after new inserted row saved

                // Set right all row's inputs id / name values.
                // To prevent submiting input's data with Model data there are changed:
                // ModelName => ModelNameSkelModel ( must be removed ) 
                // offset => SkelOffset ( replace with input offset value) 
                $clonedRow.find('input, select, textarea, button, label').each(function () {

                    var $this = $(this), defId = null;
                    if ($this.attr('id')) { // change id 

                        defId = $this.attr('id');
                        $this.attr('id', $this.attr('id').replace("SkelModel", '').replace("SkelOffset", off));
                    }
                    if ($this.attr('name')) { // change name 
                        $this.attr('name', $this.attr('name').replace("SkelModel", '').replace("SkelOffset", off));
                    }
                    if ($this.attr('for')) { // change name 
                        $this.attr('for', $this.attr('for').replace("SkelModel", '').replace("SkelOffset", off));
                    }

                    //
                    // special cases
                    // 

                    //
                    // upload file(s) image(s) 
                    //
                    if ($this.attr('data-input-id')) {
                        $this.attr('data-input-id', $this.attr('data-input-id').replace("SkelModel", '').replace("SkelOffset", off));
                    }
                    if ($this.attr('data-dir')) { // uploads dir

                        $this.attr('data-dir', $this.attr('data-dir').replace("SkelModel", '').replace("SkelOffset", rnd)); // rnd NOT off !
                        $this.attr('data-dir-rnd', rnd); // to replace token based upload dir to real ID upload dir after form submit 

                        // init upload (is NOT executed at $.mtsoftUiInput.file !!! )                         
                        $this.mtsoftUiUpload();

                        // reset and update upload dir with new token (in case form has been saved)                                               
                        $this.mtsoftUiUploadReset({dir:
                                    $.mtsoftUiInput.file.updateTokenDir(
                                            $this.mtsoftUiUploadConfig('dir'), // get current upload dir (can posses expired upload token) 
                                            self.$form.mtsoftUiFormConfig('token')  // get up to date token value (changes after each form submit) 
                                            )
                        });
                    }

                    //
                    // attach to and init right input type (client side) 
                    //
                    if ($this.attr('data-forma-def')) {

                        // add/attach to form and init (to include validation and related to input logic)
                        self.$form.mtsoftUiFormAddInput($this, defId);
                    }

                });

                // assign delete callback on cloned row 
                $clonedRow.find('button.delete').on('click.mtsoftUiInput', function () {

                    //self._deleteRow($(this).parents('tr').filter(':first'));
                    self._deleteRow($(this).parents('[data-off]').filter(':first'));
                });

                // add to collection
                this.$rows.push($clonedRow);
                this.$value.mtsoftUiInputConfig('$rows', this.$rows);

                if (this.scrollOnEdit) {

                    var y = $(window).scrollTop();
                    $(window).scrollTop(y + $clonedRow.height());
                }

                if (focusFirstInput) {

                    // find first input in new added row and focus it
                    var $firstInput = $clonedRow.find('input[type!="hidden"]').filter(':visible:first');

                    if ($firstInput && $firstInput.val()) {

                        // if there is already some content - put cursor at the end of it 
                        var strLength = $firstInput.val().length;
                        $firstInput.focus();
                        $firstInput[0].setSelectionRange(strLength, strLength);
                    }
                }

                // assign new row on enter hit behaviour 
                if (this.newRowOnEnter) {

                    this._assingNewRowOnEnterHit($clonedRow.find(this.newRowOnEnter));
                }

                // callback
                if (callback === undefined || callback) {
                    this.afterRowInsert($clonedRow, init);
                }
                this._reindexRows();

                //
                // set row inputs values (if defined) 
                //
                if (values) {

                    $clonedRow.find('input, select, textarea, button, label').each(function () {

                        var $this = $(this);

                        // get field name
                        var name = $this.attr('name'),
                                rexp = new RegExp(/data.*\[([^\]]+)\]$/),
                                matches = rexp.exec(name);  // matches[1] is model name; matches[2] is field name 

                        if (matches && values[matches[1]] && matches[1] !== 'id') { // don't update id (must be random) 

                            $this.mtsoftUiInputSetValue(values[matches[1]]);
                        }
                    });
                }


                $(window).resize();
                return true;

            } else {

                if (this.txt.maxRows) {
                    $.flash(this.txt.maxRows, 'info');
                }
                return false;
            }
        },
        deleteRow: function ($row, callback) {

            if ($row === 'last') { // rmove last row 
                $row = this.$rows[this.$rows.length - 1];
            }
            return this._deleteRow($row, callback);
        },
        _deleteRow: function ($row, callback) {

            // get current number of rows
            this.$rows = this.$value.mtsoftUiInputConfig('$rows') || [];

            // only if less then maximum 
            if (this.minRows === null || this.minRows < this.$rows.length) {

                var self = this, deleted = this.$deletedRowsIds.val(), id = this._getRowId($row);

                // add deleted row id to hidden input deleted rows
                if (id !== null) {
                    this.$deletedRowsIds.val(deleted + (deleted !== '' ? ',' : '') + id);
                }

                // find all base inputs on row and detactch all from form (will be no longer validated/submitted)             
                $row.find('[data-forma-def]').each(function () {

                    self.$form.mtsoftUiFormRemoveInput($(this));
                });

                // remove whole row from table  
                this.$rows = $.grep(this.$rows, function ($value) {
                    return $value.attr('data-off') !== $row.attr('data-off');
                });
                this.$value.mtsoftUiInputConfig('$rows', this.$rows);

                if (this.scrollOnEdit) {

                    var y = $(window).scrollTop();
                    $(window).scrollTop(y - $row.height());
                }

                $row.remove();

                // callback
                if (callback === undefined || callback) {
                    this.afterRowDelete($row);
                }
                this._reindexRows();

                $(window).resize();
                return true;

            } else {

                if (this.txt.minRows) {

                    $.flash(this.txt.minRows);
                }
                return false;
            }
        },
        _getRowId: function ($row) {

            //var id = parseInt($row.children().filter(':first').children().filter(':first').val());
            var id = parseInt($row.find('input[type="hidden"]').filter(':first').val());
            return !isNaN(id) ? id : null;
        },
        /**
         * Add index numbers to index nodes (with data-index attribute set) 
         */
        _reindexRows: function () {

            try {
                for (var i in this.$rows) {

                    var $this = this.$rows[i];
                    // reprint index to row index label                  
                    $this.find($('[data-index]')).html(($this[0].rowIndex - 1) + '.');
                }
                ;
            } catch (e) {
            }
        },
        getRows: function () {
            return this.$rows;
        },
        /**
         * Define sortbale behaviour of hasMany elements     
         */
        _defineSortable: function () {

            //if (cfg.sort) { //  && cfg.$this.children().length > 1

            /**
             * This function fixes sortable related issues
             */
            var helper = function (e, $div) {

                // fix table all rows width (rows are collapsed when one row is dragged)                
                var $helper = $div.clone();
                //return $helper;
                return $div;

            }, helperTableFix = function (e, $tr) {

                // fix table all rows width (rows are collapsed when one row is dragged)                
                var $originals = $tr.children(), $helper = $tr.clone();
                $helper.children().each(function (index) {
                    $(this).width($originals.eq(index).outerWidth());
                });

                return $helper;
            },
                    /**
                     * Update rows ordering on server 
                     * @param {type} ui
                     * @returns {undefined}
                     */
                    _updateServer = function (ui) {

                        var movedElementId = ui.item.attr('data-id'), // ordered element db id
                                // element next to ordered element (dropped on new postion) 
                                // NOTE: on each listing page row element CAN'T be dropped as last element
                                movedElementNextId = ui.item.next().attr('data-id');

                        $.post($.mtsoft.url(self.sortUrl), {
                            movedElementId: movedElementId,
                            movedElementNextId: movedElementNextId,
                            parent: self.parent,
                            model: self.model
                        }, function (t) {
                            //$(config.metersNode).mtsoftUiLock().off();
                        });

                    };

            var self = this, def = $.extend(true, {
                // default sortbale behaviour 
                cursor: 'move',
                //axis: "x",  // move only veritcal
                containment: this.isTable ? this.$tbody.parent() : null, // allow only moving on list area
                placeholder: "placeholder",
                //forceHelperSize: true,
                //forcePlaceholderSize: true,
                $rows: this.$tbody, // sortable elements container
                revert: this.isTable ? 150 : null,
                items: this.isTable ? ">tr:not([data-off='SkelOffset'])" : ">div:not([data-off='SkelOffset'])",
                scroll: true, // scroll window once end of page reached on dragging 
                helper: this.isTable ? helperTableFix : null, // helper
                start: this.isTable ? function (event, ui) {

                    ui.placeholder.css({height: ui.helper.outerHeight()});
                    ui.helper.addClass('dragging');
                } : function (event, ui) {

                    //ui.placeholder.css({width: ui.helper.outerWidth(), height: ui.helper.outerHeight()});
                    ui.helper.addClass('dragging');
                },
                stop: function (event, ui) {
                },
                update: function (event, ui) {
                    _updateServer(ui);
                    //ui.helper.removeClass('dragging');
                }
            }, this.sortable);

            $(def.$rows).sortable(def).disableSelection();

            //def.$rows.css({cursor: 'drag'});
            //}
        }

    };


    //
    // Color selector
    //
    $.mtsoftUiInput.color = {
        colorPicker: true,
        input: false,
        // default colors allowed to select
        colors: [],
        oninit: function () {

            var id = this.$value.attr('id'), cpId = this.$value.attr('id') + 'CP';

            if (this.colorPicker) {

                $('#' + cpId).picker(function () {
                    var col = this.selected.substr(1);
                    // set color on button/box
                    $('#' + cpId).css({color: "#" + col
                        , backgroundColor: "#" + col
                        , borderColor: "#" + (col.substr(0, 2) !== "FF" ? col : "DEDEDE")});
                    // re-print color to hidden field with value
                    $('#' + id).val(col);
                }, {elements: {
                        values: this.colors,
                        html: '&nbsp;',
                        style: 'background:_VALUE_;',
                        cssClass: '',
                        returnValue: ''
                    }
                });
            }
        },
        reprintValue: function (v) {

        },
        reset: function (cfg) {
        }
    };
    
    //
    // Color picker
    //
    $.mtsoftUiInput.colorPicker = {
        colorPicker: true,
        oninit: function () {

            var id = this.$value.attr('id'), $inp = $('#'+id), $preview = $('#'+id+'PREVIEW');
            
            if (this.colorPicker) {
                
                $inp.colorpicker({
                    customClass: 'colorpicker-2x',
                    component: $preview,
                    sliders: {
                        saturation: {
                            maxLeft: 200,
                            maxTop: 200
                        },
                        hue: {
                            maxTop: 200
                        },
                        alpha: {
                            maxTop: 200
                        }
                    }
                });
                $inp.colorpicker().on('changeColor', function(e) {
                    $preview.css({backgroundColor: e.color.toString('rgba')});
                });
                $preview.on('click', function(){
                    $inp.focus();
                });
            }
        },
        reprintValue: function (v) {
        },
        reset: function (cfg) {
        }
    };



    //
    // Map pointer
    //
    $.mtsoftUiInput.map = {
        addressInputsIds: [], // array of id attributes of inputs with map related address; order is important
        resolveAddress: false, // if true resolve address from above inputs and set marker on map (on any input value changed) 
        resolveAddressZoom: 18, // default zoom used when map location set on resolve address
        showDescriptionOn: false,
        oninit: function ($inp) {

            var self = this;
            $inp.parent().on('mouseenter.mtsoftUiInput', function () {

                self.description($(this), self.$value.attr('id'));
            });

            //
            // set map pointer to location eneterd by user
            //
            if (this.resolveAddress && this.addressInputsIds.length) {

                var self = this, success = function (lat, lng, address) {
                    // move marker to new location, stop animation and reprint location (true)
                    window[self.gmObjName].moveMarker(lat, lng, self.resolveAddressZoom, null, true);
                }, failed = function () {
                }, inps = $('#' + this.addressInputsIds.join(',#')); // all address related inputs 

                // resolve address and change marker position
                // once defiend address input(s) values changed.
                inps.on('change.mtsoftUiInput', function () {

                    var v = inps.map(function () {
                        return $(this).val();
                    }).get().join();
                    GM.resolveAddress(v, success, failed);
                });

            }


        },
        enable: function () {
            this.$value.parent().mtsoftUiLock().off();
        },
        disable: function () {
            this.$value.parent().mtsoftUiLock({icon: false}).on();
        },
        reset: function (cfg) {

            // set to default position 
            window[this.gmObjName].moveMarker(this.default.lat, this.default.lng, this.default.zoom, 'BOUNCE');
        }
    };


    //
    // Set of common select-boxes (combo inputs)  
    //
    $.mtsoftUiInput.combo = {
        oninit: function () {
            
            var self = this, off = 0, data = self.$value.data('data'), params = [];

            this.$value.parent().find('select[data-forma]').each(function () {

                var $this = $(this), _cfg = $this.mtsoftUiInputConfig();
                
                params.push({
                    $node: $this,
                    data: data[off],
                    value: _cfg.value !== null ? _cfg.value : _cfg.default
                            //,default: _cfg.default ???
                });
                off++;
            });

            $.mtsoft.ui.combo(params, this.$value.data('hideIfEmpty'));
        },
        reset: function (cfg) {
            // @TODO 
        }
    };


    $.mtsoftUiInput.tokenInput = {
        url: null, // ajax request url where toekn input will read data for drop-down contents 
        tokens: [], // if no ajax request url defined - use this array data format: [{id: n, name: "Some Name"}, ...]
        habtm: false, // This is HasManyBelongToMany input         
        tokeninput: {
                searchDelay: 300, // [ms]
                minChars: 2,
                resultsLimit: 7,
                animateDropdown: false,
                preventDuplicates: true,
                hintText: '',
                noResultsText: null,
                searchingText: null,
                // The maximum number of results allowed to be selected by the user. Use null to allow unlimited selections. 
                tokenLimit: null,
                highlightTerm: false, // don't hightlight term on autosuggest list
                onAdd: function (item) {
            }
            //, 'resultsFormatter' : n
        },
        // callbacks (val is string with comma-separated values) 
        onfocus: function(val) {},
        onblur: function(val) {},
        onadd: function(item, val) {},
        ondelete: function(item, val) {},
        oninit: function () {
            
            var self = this;//, initialValue = this.$value.val();

            // add icons to labels
            this.tokeninput.searchingText = $.icoWait({size: 16, color: '#fff', style: 'top:-2px;'}, true) + ' ' + this.tokeninput.searchingText;
            this.tokeninput.noResultsText = $.mtsoft.ui.icon('caution', {size: 16, color: '#000'}, true) + ' ' + this.tokeninput.noResultsText;

            // HABTM type of input 
            if (this.habtm) {

                // reprint value to $value node on token add
                this.tokeninput.onAdd = function (item) {

                    self.$value.parent().append('<input type="hidden" value="' + item.id + '" id="' + self.$value.attr('id') + '_' + item.id + '" name="' + self.$value.attr('name') + '[]" />');
                    try { self.onadd(item, self.getValue()) } catch (e) {}
                };
                // reset input value on token delete 
                this.tokeninput.onDelete = function (item) {

                    self.$value.parent().find('#' + self.$value.attr('id') + '_' + item.id).remove();
                    try { self.ondelete(item, self.getValue()) } catch (e) {}
                };
            }

            /*if (this.required) {
             console.log(this.required);
             this.addValidationRule([
             {rule: 'notEmpty', params: [], msg: 'Required'} // is right period 
             ], true);
             }*/


            // init token input
            this.$value.attr('data-force-validation', true); // to force validation of hidden value inuput of toeknInput
            this.$value.tokenInput(this.url ? this.url : this.tokens, this.tokeninput);

            // set rigth label node 
            this.$label = this.$value.prev('ul.token-input-list');

            // on token input focus and blur 
            this.$label.find('input[type="text"]').on('focus.mtsoftUiInput', function () {
                self.$value.mtsoftUiInputSetNeutral();
                $(this).parent().addClass('focus').parent().addClass('focus');
                self.description($(this), self.$value.attr('id'));
                try { self.onfocus(self.getValue()) } catch (e) {}

            }).on('blur.mtsoftUiInput', function () {
                $(this).parent().removeClass('focus').parent().removeClass('focus');
                self.$value.mtsoftUiInputValidate('blur');
                try { self.onblur(self.getValue()) } catch (e) {}
            });

            $(".token-input-dropdown").width(this.$value.prev().width());
            $(window).on('resize.mtsoftUiTokenInput', function () {
                $(".token-input-dropdown").width(self.$value.prev().width());
            });

            //
            // initially set value as comma separated values identifiers  
            // 
            /*if (initialValue) {
             
             if (typeof(initialValue) === Array) {
             
             } else { // single value only 
             //this.$value.tokenInput();
             }
             }*/
            /*         
             var tokens = this.$value.tokenInput("get"), v = '';
             for (var i in tokens) {
             v += ((v !== '' ? ',' : '') + tokens[i].id);
             }
             log(v);
             this.setValue(v);            
             */
        },
        setValue: function (v) {

            // by default re-print label value (if no value given directly)
            if (v === undefined) {

                // re-print value from label
                this.reprintValue(this._getSelectedTokens());

            } else { // input value given directly - set value and label

                this.reprintValue(v);
            }

            return this.value;
        },
        reprintValue: function (v) {

            this.$value.val(v);
            this.$label.val(v);
            this.value = v;
        },
        reset: function (cfg) {
        },
        /**
         * Get selected token idnetifiers as comma separated string
         * @returns String      ex. "1,2,8'
         */
        _getSelectedTokens: function () {
            var tokens = this.$value.tokenInput("get"), v = '';
            for (var i in tokens) {
                v += ((v !== '' ? ',' : '') + tokens[i].id);
            }
            return v;
        }
    };

    $.mtsoftUiInput.tagsInput = {
        tagsInput: {
            delimiter: ',',
            removeWithBackspace: false,
            defaultText: '',
            height: 'auto',
            width: '100%'
        },
        oninit: function () {

            var self = this;

            // init tags input
            this.$label.tagsInput(this.tagsInput);

            // focus styling
            $('#' + this.$label.attr('id') + '_tag').on('focus.mtsoftUiInput', function () {
                $(this).parents('.tagsinput').filter(':first').addClass('focused');
                self.description($(this));
            }).on('blur.mtsoftUiInput', function () {
                $(this).parents('.tagsinput').filter(':first').removeClass('focused');
            });
        },
        setValue: function (v) {

            if (v !== undefined) {

                this.$label.importTags('');
                this.$label.importTags(v ? v : '');
            }
        },
        reset: function () {
            this.$label.importTags('');
        },
        disable: function () {

            $('#' + this.$label.attr('id') + '_tagsinput').addClass('disabled');
        },
        enable: function (any) {
            $('#' + this.$label.attr('id') + '_tagsinput').removeClass('disabled');
        }/*, 
         setValue: function(v) {
         
         // by default re-print label value (if no value given directly)
         if (v === undefined) {
         
         // re-print value from label
         this.reprintValue(this._getSelectedTokens());
         
         } else { // input value given directly - set value and label
         
         this.reprintValue(v);
         }
         
         return this.value;
         },
         reprintValue: function(v) {
         
         this.$value.val(v);
         //this.$label.val(v);
         this.value = v;
         },
         reset: function(cfg) {
         },*/
        /**
         * Get selected token idnetifiers as comma separated string
         * @returns String      ex. "1,2,8'
         *//*
          _getSelectedTokens: function() {
          var tokens = this.$value.tokenInput("get"), v = '';
          for (var i in tokens) {
          v += ((v !== '' ? ',' : '') + tokens[i].id);
          }
          return v;
          }*/
    };

    $.mtsoftUiInput.rte = {
        // RTE parameters
        rte: {
            toolbar: {
                buttons: [
                    // first row
                    ['Bold', 'Italic', 'Underline', 'Strikethrough']
                ]
            },
            min: null,
            max: null
                    /*,
                     class: 'rte',
                     min: null,
                     max: null,
                     disablePaste: false,
                     height: 250,
                     allowHtmlEditor: false,
                     //safeHtml: true,
                     scriptBlocks: true,
                     markupOnEvents: true,
                     styleBlocks: true,
                     docOutline: null,
                     keywords: null,
                     buffor: null,
                     resize: true,
                     onloadDocBody: null,
                     htmlCodeBefore: null,
                     htmlCodeAfter: null*/
        },
        txt: {
            minLen: 'Text is too short. Minimum <b>%s</b> characters are required.',
            maxLen: 'Text is too long. Maximum <b>%s</b> characters are allowed.'
        },
        oninit: function () {

            var self = this;

            this.$rte = $('#' + this.$value.attr('id') + 'RTE');

            this.rte.onDocFocus = function (w) {
                self.$rte.children().removeClass('invalid');
                self.$label.mtsoftUiInputSetNeutral();
            };
            if (this.validateOn === 'change' || this.validateOn === 'blur') {
                this.rte.onDocBlur = function (w) {
                    self.$value.mtsoftUiInputValidate('blur');
                };
            }


            this.rteObj = new RTE(this.$rte[0], this.$value[0], this.rte);
            this.rteObj.init();

            if (this.min || this.required) {
                var minLen = this.min ? this.min : this.required ? 5 : 0;
                this.addValidationRule([
                    {rule: 'lenMin', params: [minLen], msg: this.txt.minLen.replace("%s", minLen)}
                ]);
            }
            if (this.max) {
                this.addValidationRule([
                    {rule: 'lenMax', params: [this.max], msg: this.txt.maxLen.replace("%s", this.max)}
                ]);
            }

        },
        rules: {
            lenMin: function (v, min) {
                // remove html 
                var txt = v.replace(/<\/?[^>]+(>|$)/g, '').replace(/\s*/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g, ''),
                        l = txt.length;
                return l >= min;
            },
            lenMax: function (v, max) {
                // remove html 
                var txt = v.replace(/<\/?[^>]+(>|$)/g, '').replace(/\s*/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g, ''),
                        l = txt.length;
                return l <= max;
            }
        },
        getValue: function (stripHtml) {

            this.setValue();
            return stripHtml === true ? this.value.replace(/<\/?[^>]+(>|$)/g, '').replace(/\s*/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g, '') : this.value;  // return real value            
        },
        setValue: function (v) {

            // by default re-print label value (if no value given directly)
            if (v !== undefined && $.trim(v) !== '' && $.trim(v) !== '<br>') {

                // re-print value from label
                // put value on RTE editor 
                this.rteObj.docFill(v);

            } else { // input value given directly - set value and label

                this.reprintValue(v);
            }

            return this.value;
        },
        reprintValue: function (v) {

            this.value = this.rteObj.updateBuffor(); // re-prints RTE editor contents to hidden textarea buffor node
            /*if (v !== undefined && $.trim(v) !== '' && $.trim(v) !== '<br>') {
             
             // put value on RTE editor 
             this.rteObj.docFill(v);
             } else {*/
            //}
        },
        reset: function (cfg) {
            this.rteObj.docClear();
        },
        disable: function () {
            this.$rte.mtosftUiLock().on();
        },
        enable: function (any) {
            this.$rte.mtosftUiLock().off();
        },
        failed: function (data) {

            this.$rte.children().addClass('invalid');
        }
        /**
         * Get selected token idnetifiers as comma separated string
         * @returns String      ex. "1,2,8'
         *//*
          _getSelectedTokens: function() {
          var tokens = this.$value.tokenInput("get"), v = '';
          for (var i in tokens) {
          v += ((v !== '' ? ',' : '') + tokens[i].id);
          }
          return v;
          }*/
    };

    $.mtsoftUiInput.tinymce = {
        // TinyMCE default configuration 
        tinymce: {
            menubar: false,
            // define toolbar: 
            // insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | removeformat | code | fullscreen',
            plugins: ['link image code fullscreen']
        },
        // minimum and/or maximum number of characters 
        min: null,
        max: null,
        txt: {
            minLen: 'Text is too short. Minimum <b>%s</b> characters are required.',
            maxLen: 'Text is too long. Maximum <b>%s</b> characters are allowed.'
        },
        $editor: null,
        //showDescriptionOn: 'foucs',
        oninit: function () {

            var self = this;

            try {

                this._tinymce = tinymce.init($.extend(true, this.tinymce, {
                    selector: '#' + this.$value.attr('id'),
                    setup: function (editor) {
                        editor.on('focus', function (e) {
                            self.description();
                        }).on('blur', function (e) {

                            self.$value.blur();
                        }).on('load', function (e) {

                            self.$value.$editor = $(editor.editorContainer);
                        });
                    }
                }));

                // min / max rules 
                if (this.min || this.required) {
                    var minLen = this.min ? this.min : this.required ? 5 : 0;
                    this.addValidationRule([
                        {rule: 'lenMin', params: [minLen], msg: this.txt.minLen.replace("%s", minLen)}
                    ]);
                }
                if (this.max) {
                    this.addValidationRule([
                        {rule: 'lenMax', params: [this.max], msg: this.txt.maxLen.replace("%s", this.max)}
                    ]);
                }



            } catch (e) {

                console.error('TinyMCE library is missing!');
            }
        },
        /**
         * get tinymce value
         * 
         * @param bool stripHtml    if ture return textual value of html editor 
         */
        getValue: function (stripHtml) {

            this._update();
            return stripHtml === true && this.value ? this.value.replace(/<\/?[^>]+(>|$)/g, '').replace(/\s*/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g, '') : this.$value.val();  // return real value            
        },
        rules: {
            lenMin: function (v, min) {
                // remove html 
                var txt = v.replace(/<\/?[^>]+(>|$)/g, '').replace(/\s*/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g, ''),
                        l = txt.length;
                return l >= min;
            },
            lenMax: function (v, max) {
                // remove html 
                var txt = v.replace(/<\/?[^>]+(>|$)/g, '').replace(/\s*/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g, ''),
                        l = txt.length;
                return l <= max;
            }
        },
        enable: function () {
            this.$value.$editor.mtsoftUiLock().off();
            this.$value.$editor.css({position: ""});
        },
        disable: function () {

            this.$value.$editor.mtsoftUiLock({icon: false}).on();
        },
        success: function () {
            this.$value.$editor.removeClass('invalid');
        },
        failed: function () {

            this.$value.$editor.addClass('invalid');
        },
        /**
         * Update textarea value from tinymce editor 
         */
        _update: function () {

            for (var edId in tinymce.editors) {
                if (tinymce.editors[edId].id === this.$value.attr('id')) {
                    tinymce.editors[edId].save();
                }
            }
        }
    };

    /**
     * jsTree tree inupt 
     */
    $.mtsoftUiInput.jstree = {
        url: null,
        // jstree default configuration 
        jstree: {
            core: { 
                themes: { icons: false} //, stripes: true
            },
            types: {
                "#": {
                    //"max_children": 1,
                    //"max_depth": 4,
                    //"valid_children": ["root"]
                    //"valid_children": ["default"]
                    // "icon": "glyphicon glyphicon-file",
                },
                root: {
                    icon: false
                },
                default: {
                    icon: false
                },
                file: {
                    icon: false
                }
            },            
            plugins: ["dnd", "contextmenu"], 
                // custom contents menu items (to remove cut, copy, paste) 
                contextmenu: {         
                    items: function($node) {
                        return {
                            Create: {
                                label: "Create",
                                action: function (data) {
                                    var ref = $.jstree.reference(data.reference);
                                        sel = ref.get_selected();
                                    if(!sel.length) { return false; }
                                    sel = sel[0];
                                    sel = ref.create_node(sel, {type:"file"});
                                    if(sel) {
                                        ref.edit(sel);
                                    }
                                }
                            },
                            Rename: {
                                label: "Rename",
                                action: function (data) {
                                    var inst = $.jstree.reference(data.reference);
                                        obj = inst.get_node(data.reference);
                                    inst.edit(obj);
                                }
                            },
                            Delete: {
                                label: "Delete",
                                action: function (data) {
                                    var ref = $.jstree.reference(data.reference),
                                        sel = ref.get_selected();
                                    if(!sel.length) { return false; }
                                    ref.delete_node(sel);
                                }
                            }
                        };
                    }
                } 
        },
        showDescriptionOn: 'mouseover',
        oninit: function () {

            var self = this;
            this.$label = this.$value.next();

            try {

                this.$label.jstree($.extend(true, this.jstree, {core: {check_callback: function (operation, node, parent, position, more) {

                    if (operation === 'delete_node' && node.dontUpdateServer === undefined) {

                        // remove from tree only after successfully deleted on server
                        self._update(operation, node, parent.id, function (t, node) {

                            if (t.result) {
                                node.dontUpdateServer = true;
                                self._jstree.delete_node(node);
                            }
                        });
                        return false;
                    } else {

                        node.dontUpdateServer = undefined;
                        return true;
                    }
                }}}));
        
                this._jstree = this.$label.jstree(true);
                //this._jstree.open_all(); // initally open all 


                //
                // Handle tree events
                //
                this.$label.on('create_node.jstree rename_node.jstree delete_node.jstree move_node.jstree', function (e, data) {

                    self._update(e.type, data.node, data.parent);
                });
                
                if ($('#btnShowHide' + this.$value.attr('id')).length) {

                    $('#btnShowHide' + this.$value.attr('id')).on('click.mtsoftUiInput', function () {
                        
                        var id = self.$value.attr('id'), $sh = $('.'+ id +'-shrink'), $ex = $('.'+ id +'-expand');
                        if (self.opened) {
                            
                            self._jstree.close_all();
                            self.opened = false;
                            $ex.show();
                            $sh.hide();
                            
                        } else {
                            
                            self._jstree.open_all();
                            self.opened = true;
                            $sh.show();
                            $ex.hide();                            
                        }
                    });
                }

            } catch (e) {

                console.error('jsTree library is missing!');
            }
        },
        /**
         * Update tree data on server
         * 
         * @param {String} type                 tree event type [create_node | rename_node | delete_node | move_node]
         * @param {Object} node                 node which trigged event
         * @param {String} treeParentId         parent node 
         * @param {Function} onSuccess          callback to be executed on success server udata update
         * @param {Function} onFailed           callback to be executed on failed server udata update
         */
        _update: function(type, node, treeParentId, onSuccess, onFailed) {
            
            // send ajax request 
            var self = this, treeNodeId = node.id;

            $.mtsoft.ui.ajax($.mtsoft.url(self.url), {
                showWaitStatus: true, // show wait status icon (or icon + label)
                data: {
                    operation: type,  // ex. create_node 
                    id: node.data ? node.data.id : null, // is null when creating node 
                    parent_id: treeParentId && treeParentId !== '#' ? (
                            $('#' + treeParentId).data('id') ? $('#' + treeParentId).data('id') : self.$label.data('map_' + treeParentId)
                        ) : null,
                    name: node.text
                },
                showErrors: true,
                waitmeter: 'saving...',
                onResultSuccess: function (transfer, textStatus, jqXHR) {

                    // assign new instered row id 
                    var treeNode = self._jstree.get_node(treeNodeId);
                    if (transfer && transfer.id) {  // assign only if id given 

                        if (!treeNode.data) { // assign only if not assigned already 
                            treeNode.data = {id: transfer.id};
                            // below is required to read right parent node row id 
                            // when adding new child for just created new tree node 
                            // (assigned to node [see above] data.id DON'T WORKS)
                            self.$label.data('map_'+treeNodeId, transfer.id);
                        }
                    }
                    
                    // execute callback
                    try {
                        onSuccess(transfer, treeNode, treeNodeId);
                    } catch (err) {}
                },
                onResultFailed: function (transfer, textStatus, jqXHR) {
                    
                    var treeNode = self._jstree.get_node(treeNodeId);
                    // execute callback
                    try {
                        onFailed(transfer, treeNode, treeNodeId);
                    } catch (err) {}
                },
                onComplete: function (jqXHR, textStatus) {},
                onError: function (data, textStatus, jqXHR) {}
            }, {
                type: 'POST' // use POST
            });
        }
    };

    /**
     * HTML input; @uses CodeMirror
     */
    $.mtsoftUiInput.html = {
        // Common parameters
        highlightSyntax: true, // if true highlight syntax and format code using CodeMirror
        allowResize: true, // if true allow vertical resize of editor
        minHeight: 240, // minimum editor height (only if 'allowResize' enabled) 
        previewElement: null,  // edited html code preview element ID value (viewHtml input is attribute)
        previewElementType: 'html',  // edited content type: [ html | css | js ] 
        // CodeMirror parameters
        codeMirrorAutoformat: false, // auto-format viewed html code; NOTE: CodeMirror bug when displaying php code!!! 
        codemirror: {
            //lineNumbers: true,
            matchBrackets: true,
            //mode: 'htmlmixed',
            mode: "application/x-httpd-php",
            //indentUnit: 4,
            //indentWithTabs: true,
            //enterMode: "keep",
            //mode: 'htmlembedded'
            theme: 'ambiance',
            //lineWrapping: true, // wrap lines
            autofocus: false // MUST BE FALSE - otherwise it cuses page to scroll to top of codeMirror editor(even if hidden) 
        },
        _codeMirror: null, // CodeMirror object
        $codeMirror: null, // CodeMirror HTML node element 
        _resizable: null,
        //txt: {},
        oninit: function () {

            var self = this;

            //if (this.$label.is(':visible')) {    // can cause bug when seting value for hidden editor
            if (true) { // see above, but removed due tabs (html input on tab) 

                if (this.allowResize && this._resizable === null) { // resize allowed and not yet initialized 

                    // use jQUery UI resizable
                    this._resizable = this.$value.css({padding: 0}).resizable({
                        handles: "s",
                        minHeight: this.minHeight,
                        // fix for loosing focus when moved above iframe
                        start: function (event, ui) {                            
                        },
                        stop: function (event, ui) {                            
                        },
                        resize: function () {

                            try { // executed only if CodeMirror used                            
                                self._codeMirror.setSize(null, self.$value.height() + 'px');
                            } catch (e) {
                            }
                        }
                    });

                    // on widnow resize keep textarea/CodeMirror right width 
                    $(window).on('resize', function () {

                        var $editorParent = self.$value.parent().parent();

                        if ($editorParent.is(':visible')) {

                            // hide resizable wrapper and handle - otherwise horizontal resize is locked! 
                            $editorParent.find('.ui-wrapper, .ui-resizable-handle').hide();
                            var w = $editorParent.innerWidth();

                            $editorParent.find('.ui-wrapper, .ui-resizable-handle').css({width: w});
                            $editorParent.find('.ui-wrapper, .ui-resizable-handle').show();
                        }
                    });
                }

                if (this.highlightSyntax && this._codeMirror === null) { //  Use CodeMirror to highlight syntax; prevent double init 

                    // keyup event - required to live html preview (if defiend) 
                    if (this.previewElement) {
                        
                        this.codemirror.onKeyEvent = function(e , s){

                            if (s.type === 'keyup') {
                                
                                var c = [];
                                c[self.previewElementType] = self._codeMirror.getValue();
                                $('#'+self.previewElement).mtsoftUiInputConfig().update(c);
                            }
                        };
                    }

                    this._codeMirror = CodeMirror.fromTextArea(this.$value[0], this.codemirror);
                    this.$codeMirror = this.$value.parent().find('.CodeMirror');
                    
                    // set inital editor size
                    this.$codeMirror.css({
                        //display: 'none',
                        position: 'absolute',
                        left: parseInt(this.$value.parent().css('paddingLeft')) + 1,
                        top: 1
                    });
                    this._codeMirror.setSize('100%', this.$value.height() + 'px');
                }
            }
        },
        setValue: function (v) {

            try {

                if (v === undefined) {

                    // value not given - get value form CodeMirror and assgin to hidden textarea 
                    v = this._codeMirror.getValue();

                } else {

                    // value is given - set CodeMirror editor content
                    this._codeMirror.setValue(v); // inject text on CodeMirror 
                }

            } catch (e) {
            }
            this.reprintValue(v);

            try {

                // do additional formatting (if required) 
                if (this.codeMirrorAutoformat) {

                    // CodeMirror - automatically format html code 
                    CodeMirror.commands["selectAll"](this._codeMirror);
                    var range = {
                        from: this._codeMirror.getCursor(true),
                        to: this._codeMirror.getCursor(false)};

                    this._codeMirror.autoFormatRange(range.from, range.to);
                    this._codeMirror.setCursor(0);
                }

            } catch (e) {
            }

            return this.value;
        },
        focus: function () {
            this.$value.focus(); // if no CodeMirror used 
            try {
                this._codeMirror.focus();
            } catch (e) {
            }
        },
        reset: function (cfg) {
            this.setValue('');
        },
        disable: function (wait) {

            this.$value.prop('disabled', true);
            try {
                this.$codeMirror.mtsoftUiLock({icon: wait === undefined ? false : {}, opacity: 1}).on();
            } catch (e) {
            }
        },
        enable: function (any) {
            this.$value.prop('disabled', false);
            try {
                this.$codeMirror.mtsoftUiLock().off();
            } catch (e) {
            }
        },
        failed: function (data) {

            //this.$rte.children().addClass('invalid');         
        }
    };
    
    
    /**
     * View html view type input 
     */
    $.mtsoftUiInput.viewHtml = {
        page: {},   // html page elements ( html, css, js, css_external, js_external ) 
        keywords: null, // array of keywords nested on html to be replaced with given values ( ["keyword": "replacement value", ...] ) 
        allowResize: true, // if true allow vertical resize of viewer 
        minHeight: 300, // minimum height 
        _resizable: null, 
        //_prevH: 0,
        oninit: function ($inp) {
            
            var self = this, frm = this.$value[0];
            var doc = frm.contentWindow ? frm.contentWindow.document : frm.contentDocument;
            this.$head = $(doc.head);
            this.$body = $(doc.body);
            this.keywords = this.$value.data('keywords') || this.keywords;
            // use jQUery UI resizable 
            if (this.allowResize) {
                
                this._resizable = this.$value.parent().css({padding: 0}).resizable({
                    handles: "s",
                    minHeight: this.minHeight,
                    start: function(event, ui) {

                        self.$value.each(function() {
                            $('<div class="ui-resizable-iframeFix" style="background: #fff;"></div>').css({width: $(this).width(), height: "100%", position: "absolute", opacity: "0.001", zIndex: 1000}).css($(this).offset()).appendTo("body");
                        });
                },
                stop: function(event, ui) {

                    $("div.ui-resizable-iframeFix").each(function() {
                        this.parentNode.removeChild(this);
                    });
                }, 
                resize: function () {
                        
                        try {
                            self.$value.height(self.$value.parent().height()-1);
                        } catch (e) {} 
                    }
                });
            }
            
            this.update();
        },
        /** 
         * Prepare HTML head code 
         */
        _buildHtmlHead: function(data) {
            
            var externalStyles = '', externalScripts = '';
            if (data.css_external) {
                
                for (var i in data.css_external) {
                    
                    externalStyles += '<link href="'+data.css_external[i]+'" type="text/css" rel="stylesheet"></link>';
                }
            }
            if (data.js_external) {
                
                for (var i in data.js_external) {
                    
                    externalScripts += '<script src="'+data.css_external[i]+'" type="text/javascript"></script>';
                }
            }
            
            return '<meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1.0">' + 
                externalStyles + externalScripts + 
                (data.css ? '<style type="text/css">'+data.css+'</style>':'')+
                (data.js ? '<script type="text/javascript">'+data.js+'</script>':'');
        },
        /**
         * Prepare HTML body code
         */
        _buildHtmlBody: function(data) {
            
            var html = data.html;            
            if (this.keywords) { // keywords are present - replace to right values                 
                
                for (var key in this.keywords) {

                    html = html.replace(new RegExp('#'+key.toLowerCase(), 'g'), this.keywords[key]);
                }
            }
            
            return html;
        },
        update: function(pageData) {
            
            var page = $.extend(true, {}, this.page, this.$value.data('page'), pageData);
            
            // update current value
            this.page = page;
            
            // update head
            this.$head.html('');
            this.$head.append(this._buildHtmlHead(page)); 
            
            // update body
            this.$body.html('');
            this.$body.append(this._buildHtmlBody(page));             
            
        },
        disable: function (wait) {

            this.$value.parent().prop('disabled', true);
            try {
                this.$value.parent().mtsoftUiLock({icon: wait === undefined ? false : {}, opacity: .75}).on();
            } catch (e) {
            }
        },
        enable: function () {
            
            this.$value.parent().prop('disabled', false);
            try {
                this.$value.parent().mtsoftUiLock().off();
            } catch (e) {
            }
        }
    };


    //
    // Helpers
    //



    /**
     * Characters counter (shown remaining)
     * NOTE: characters counter node must exists:
     *  <span id="InputIdCounter"> Allowed: <b>0</b> / Words: <b>0</b></span>
     *  OR
     *  <span class="chars-c"> Some label: <b>0</b></span>
     *  ex. (Forma helper) 
     *  array('desc' => '<span class="chars-c">Characters left: <b>125</b></span>') 
     * 
     * @param {Object} cfg
     *
     * @uses key2char (jquery.mtsoft.js)
     */
    $.fn.charsLimiter = function (cfg) {

        // default parameteres
        var defaults = {
            counterBuilder: function ($this) { // assign right counter container (relative to input/texxtarea node)

                var $_n = $this.parent().parent().next().find('.chars-c'); // chars counter shown on description of input form 
                this.$n = $_n.length ? $_n : $('#' + $this.attr('id') + 'Counter');
                return this.$n;
            },
            counter: function (n) {  // route to counter

                return this.$n.find('b').filter(':first');
            },
            counterWords: function (n) {  // route to counter

                return this.$n.find('b').filter(':last');
            },
            available: function (n) {

                // chrome counts \r\n as two characters; other browsers as single character
                var v = window.chrome ? $(n).val().replace(/(\r\n|\n|\r)/gm, '--') : $(n).val(), count = v.length;
                return cfg.allowed ? cfg.allowed - count : count;
            },
            words: function (n) {

                // chrome counts \r\n as two characters; other browsers as single character
                return countWords($(n).val());
            },
            allowed: false, // by default no limit - just count characters and words  
            warning: 5,
            css: 'counter',
            cssWarning: 'chars-c-warn',
            cssFull: 'chars-c-full',
            animFull: 'shake'
        }, cfg = $.extend(defaults, cfg);

        this.each(function () {

            var $this = $(this);
            
            // limit number of allowed characters (maxlength) for input form
            if (cfg.allowed) {
                $this.attr('maxlength', cfg.allowed);
            }
            cfg.counterBuilder($this);

            $(this).keyup(function (e) {

                if (cfg.allowed) { 
                    check(this, e);
                }
                count(this);
                return true;

            }).blur(function (e) {

                cfg.counter().removeClass(cfg.cssWarning).removeClass(cfg.cssFull);
                return true;

            });
            count($(this)); // initially count number of characters
        });

        /**
         * Block input of character if all available characters already entered
         *
         * @param {Object} n	input/textarea node
         * @param {Object} e	keydown event
         */
        function check($n, e) {

            var available = cfg.available($n);

            if (available <= 0) {

                cfg.counter().addClass(cfg.cssFull);
                if (cfg.animFull) {
                    try {
                        cfg.$n.parent().parent().anims(cfg.animFull).play();
                    } catch (e) {
                    }
                }
            } else {

                cfg.counter().removeClass(cfg.cssFull);

                // handle 'warning' behavior
                if (available <= cfg.warning && available >= 0) {

                    cfg.counter().addClass(cfg.cssWarning);
                } else {

                    cfg.counter().removeClass(cfg.cssWarning);
                }
            }

            // no more characters available
            if (available <= 0) {

                // check for control keys (all have to be handled ALWAYS, even if
                // allowed number of characters are exceeded)
                if (e && $.inArray(e.keyCode, [8, 9, 16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 46]) > -1) {

                    return true;
                } else {

                    return false;
                }
            }
        }

        /**
         * Calculate and update number of characters on given node (input, textarea)
         * @param {Object} n
         */
        function count($n) {

            // update counter with number of oustatnding characters
            cfg.counter().html(cfg.available($n));
            cfg.counterWords().html(cfg.words($n));
            return true;
        }
        
        function countWords(s){
            
            s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
            s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
            s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
            
            return s.length ? s.split(' ').length : 0; 
        }
    };


})(jQuery, window, document);