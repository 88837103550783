/**
 * mtsoft INPUT validation cases
 * 
 * @uses Date.js 
 */

;
(function($, window, document, undefined) {

    // -------------------------------------------------------------------------
    //
    // definitions of different validations
    // (usually match CakePHP validation rules names)
    //
    $.fn.mtsoftUiInput.validation = {
        //
        // base
        //
        notEmpty: function(v) {            
            return !((v === null) || (v.length === 0));
        },
        minLength: function(v, min) {
            return v.length >= min;
        },
        between: function(v, min, max) {
            return v.length >= min && v.length <= max;
        },
        valRange: function(v, min, max) {

            var r = true, min = parseFloat(min), max = parseFloat(max);
            if (min) {
                r = v >= min;
            }

            if (max) {
                r = r && v <= max;
            }
            
            return r;
        },
        numeric: function(v) {
            var r = /^([0-9\.]+)$/;
            return r.test(v);
        },
        alphaNumeric: function(v) {
            var r = /^([0-9a-z]+)$/i;
            return r.test(v);
        },
        url: function(v) { // http(s), ftp, media protocols
            
            var r = /^(https?|ftp|mms|rtsp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
            //var r = /^\S$|^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/; // short way            
            return r.test(v);
        },
        /**
         * Apply regex test
         *
         * @param String strExp     regular expression AS STRING or JS regex!
         *                              Must contain leading and ending slash (/)
         *                              If any flags - should be placed directly after last slash (/)
         *                              ex.  /^[0-9a-zA-Z]+$/i
         */
        regex: function(v, strRexp, flags) {

            var r;
            if (typeof(strRexp) === 'string') {

                var ch = strRexp.split("/"), rexp;
                if (ch[ch.length - 1] !== '') { // flags given in regex string

                    flags = ch[ch.length - 1];
                    rexp = strRexp.slice(1).slice(0, -(flags.length + 1)); // remove first and last slahs and flags

                } else {    // no flags in regex string

                    rexp = strRexp.slice(1).slice(0, -1);
                }
                r = new RegExp(rexp, flags);
            } else {

                r = strRexp;
            }
            try {
                return r.test(v);
            } catch (err) {
                return true;
            }
        },
        //
        // special types
        //
        email: function(v, strict) {
            var r = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

            if (strict === undefined || strict) {   // only allowed: user@host.ext

                return r.test(v);

            } else {    // allowed:  user@host.ext OR User Name <user@host.ext>

                if (!r.test(v)) {

                    _r = /([^\ <]+\@[^\ >]+)/;   // find first email address
                    var emails = _r.exec(v);
                    return emails ? r.test(emails[0]) : false; // check against first found email address
                } else {

                    return true;
                }
            }

        },
        /*password: function(v) {
         // Password must contain 8 characters and at least one number, one letter and one unique character such as !#$%&? "
         //var r = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&? "]).*$/;
         var r = /^.*(?=.{4,}).*$/; // minimum 4 characters
         return r.test(v);
         },*/
        //
        // files
        //
        filesCount: function(v, min, max) {

            if (max !== undefined) {
                return v.filesCount >= min && v.filesCount <= max;
            } else {
                return v.filesCount >= min;
            }
        },
        fileType: function(v, allowedTypes) {

            for (var i in v.files) {
                if ($.inArray(v.files[i].type, allowedTypes) < 0) {
                    return false;
                }
            }

            return true;
        },
        fileSize: function(v, min, max) { // min & max sizes in [ MB ]

            if (max !== undefined) {

                for (var i in v.files) {
                    var sizeMB = (v.files[i].size / 1048576).toFixed(1);
                    if (sizeMB < min || sizeMB > max) {
                        return false;
                    }
                }

            } else {

                for (var i in v.files) {
                    var sizeMB = (v.files[i].size / 1048576).toFixed(1);
                    if (sizeMB < min) {
                        return false;
                    }
                }
            }

            return true;
        },
        time: function(v) {
            
            // check date value is valid 
            var _d = Date.parse(v);
            if (_d || $.trim(v) === '') { // valid syntax 
                return true;
            } else {
                return false;
            }
        },
        /**
         * Check time is between two values
         * @param {type} v
         * @param {type} format
         * @param {type} min
         * @param {type} max
         * @returns {Boolean}
         */
        timeBetween: function(v, min, max) {

            if ($.trim(v) === '') {
                return true;
            }
            // check date value is valid 
            var _t = Date.parse(v);
            if (_t) { // valid syntax 

                var t = _t.toString('HH:mm:ss');

                // min 
                if (min !== undefined) {
                    if (min > t) {
                        return false;
                    }
                }

                // max 
                if (max !== undefined) {
                    if (t > max) {
                        return false;
                    }
                }
                return true;
            } else {
                return false;
            }
        },
        date: function(v) {
            
            // check date value is valid 
            var _d = Date.parseExact(v, 'yyyy-MM-dd');
            if (_d || $.trim(v) === '') { // valid syntax 
                return true;
            } else {
                return false;
            }
        },
        /**
         * Check date is between two values
         * @param {type} v
         * @param {type} format
         * @param {type} min
         * @param {type} max
         * @returns {Boolean}
         */
        dateBetween: function(v, format, min, max) {

            if ($.trim(v) === '') {
                return true;
            }
            // check date value is valid 
            var _d = Date.parseExact(v, format);
            if (_d) { // valid syntax 

                var d = _d.toString('yyyy-MM-dd');
  
                // min 
                if (min !== undefined) {
                    if (min > d) {
                        return false;
                    }
                }

                // max 
                if (max !== undefined) {
                    if (d > max) {
                        return false;
                    }
                }
                return true;
            } else {
                return false;
            }
        },
        /**
         * Check period is valid (stop AFTER start time) 
         * @param {type} v
         * @param {type} endTimeNodeName
         * @param {Boolean} allowSingle     allow single day selection (otherwise minimum 2 days)
         * @returns {Boolean}
         */
        isPeriod: function(v, endTimeNodeName, allowSingle, unit) {
            
            if ($.trim(v) === '') {
                return true;
            }
            if (unit === 'day') {
                var start = Date.parseExact(v, 'yyyy-MM-dd'), stop = Date.parseExact($('[name="' + endTimeNodeName + '"]').val(), 'yyyy-MM-dd');
            } else {
                var start = Date.parse(v), stop = Date.parse($('[name="' + endTimeNodeName + '"]').val());
            }

            if (start && stop) {
                if (stop.isAfter(start) || (allowSingle && (stop.isAfter(start) || Date.equals(start, stop)))) { // valid syntax 
                return true;
            } else {
                return false;
                }
            } else {
                return true;
            }
        },
        /**
         * Check time period is between two values
         * @param {type} v
         * @param {type} format
         * @param {type} min
         * @param {type} max
         * @returns {Boolean}
         */
        periodBetween: function(v, endTimeNodeName, unit, minLen, maxLen) {
 
            if ($.trim(v) === '') {
                return true;
            }

            var start, stop, period;
            
            // check date value is valid
            if (unit === 'day') {
                start = Date.parseExact(v, 'yyyy-MM-dd');
                stop = Date.parseExact($('[name="' + endTimeNodeName + '"]').val(), 'yyyy-MM-dd');
            } else {
                start = Date.parse(v);
                stop = Date.parse($('[name="' + endTimeNodeName + '"]').val());
            }


            if (start && stop) { // valid syntax 

                if (unit === 'day') {
                    period = Math.floor(((stop.getTime() - start.getTime()) / 1000) / 60 / 60 / 24 + 1);
                } else {
                    period = ((stop.getTime() - start.getTime()) / 1000) / 60;
                }

                // min 
                if (minLen !== undefined) {
                    if (minLen > period) {
                        return false;
                    }
                }

                // max 
                if (maxLen !== undefined) {
                    if (period > maxLen) {
                        return false;
                    }
                }
                return true;
            } else {
                return true;
            }
        }
    };

})(jQuery, window, document);