/**
 * Listing extendend controls
 * 
 * @uses $.mtsoft.ui.icon 
 */
;
(function($) {

    var cfg = {};

    $.fn.mtsoftUiListingControl = function(listCfg, ctrlCfg, params) {
        
        if (controls[ctrlCfg.role]) { // only if control of given role defined

            $.extend(this, controls[ctrlCfg.role]);

            return this.each(function() {

                var $this = $(this);
                
                cfg = $.extend({$this: $this, ctrlCfg: ctrlCfg, listCfg: listCfg}, controls[ctrlCfg.role].defaults, $this.data(listCfg._listId.toLowerCase()), params);
                $this.data('config.mtsoftUiListingControl', cfg);
                //controls[role]._init.call($this, params);
            });
        } else {
            //
            // NOTE: control MUST HAVE defiend data-role attribute equal to control name;
            // ex. data-role="filterLink" for controls.filterLink control definition
            //            
            $.extend(this, {
                _init: function() {
                },
                // executed on listing initialization
                // ( $.mtsoftUiListing._defineControls() )
                init: function() {

                    // SAMPLE: extend exising callback (common for all controls in listing)
                    /*
                     var _onSubmit = cfg.listCfg.onSubmit;
                     cfg.listCfg.$this.mtsoftUiListing().config('onSubmit', function() {
                        
                            // ... do some actions ...
                            // return parent callback results
                            return _onSubmit(arguments);
                     });
                     */
                },
                // executed after listing page has been loaded (only on ajax mode)
                update: function() {
                }
                // add private mtehod(s)
                // , _methodName: function(cfg, param) {}
            });

            return this.each(function() {
            });
        }
    };

    var controls = {
        // ---------------------------------------------------------------------
        //
        // Listing search expression input (search box)
        //
        search: {
            defaults: {
                btnClear: true, // show clear (x) button inside search text input
                btnClearActivOnly: true, // show clear button only if filter is applied for listing (not only entered) 
                bgColor: true, // aplly search input bg color dependign on search resutls (some results, no results)                
                keepExpression: true, // if true keep search expresion value in text input
                allowEmpty: true, // if true allow to submit search even if search expression is empty
                iconRemove: '<svg viewBox="0 0 27 32"><path d="M20.518 20.036q0-0.464-0.339-0.804l-3.232-3.232 3.232-3.232q0.339-0.339 0.339-0.804 0-0.482-0.339-0.821l-1.607-1.607q-0.339-0.339-0.821-0.339-0.464 0-0.804 0.339l-3.232 3.232-3.232-3.232q-0.339-0.339-0.804-0.339-0.482 0-0.821 0.339l-1.607 1.607q-0.339 0.339-0.339 0.821 0 0.464 0.339 0.804l3.232 3.232-3.232 3.232q-0.339 0.339-0.339 0.804 0 0.482 0.339 0.821l1.607 1.607q0.339 0.339 0.821 0.339 0.464 0 0.804-0.339l3.232-3.232 3.232 3.232q0.339 0.339 0.804 0.339 0.482 0 0.821-0.339l1.607-1.607q0.339-0.339 0.339-0.821zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" /></svg>',
                iconWait: false // remove filter wait icon (use 'cion-wait')
            },
            _init: function() {
            },
            init: function() {

                //
                // Can empty expression invoke search? 
                //
                //if (!cfg.allowEmpty) {
                var $this = cfg.$this, iconWait = cfg.iconWait, smartlabel = cfg.smartlabel;
                cfg.$this.parents('form').filter(':first').on('submit.mtsoftUiListing', function(e) {
                    
                    var v = $this.val().trim();
                    if (iconWait) {

                        _icoWaitOnInput($this);
                    }

                    if (v === '' || v === smartlabel) {

                        // reset search value 
                        $this.val('');
                        // don't submit form if search expression is empty or equal to smart label 
                        e.preventDefault();
                        return false;
                    }
                    $this.blur(); // due some issue (no cursor is shown after form submit on serch input) 
                });
                //}
                
                _inputValue(cfg, cfg.$this, cfg.listCfg.status.q);
                inpBgColor.set(cfg, cfg.$this, cfg.listCfg.status.q, cfg.listCfg.status);
                /*if (cfg.bgColor) {

                    // on input blur always remove bg colors
                    cfg.$this.on('focus.mtsoftUiListing', function() {
                        $(this).data('bgColors', $(this).attr('class'))
                                .data('val', $(this).val())
                                .removeClass('results-on no-results');
                    }).on('blur.mtsoftUiListing', function() {
                        
                        if ($(this).data('val') === $(this).val()) { // value not changed
                            $(this).prop('class', $(this).data('bgColors'));
                        }
                    });
                }*/ 
                //_inputBtnClear(cfg);
                inpBtnClear.init(cfg);
                // smart label
                if (cfg.smartlabel) {

                    $(cfg.$this).mtsoftUiSmartLabel(cfg.smartlabel);
                }
            },
            update: function(data) {
                
                //if (this._isInputForm(cfg)) {
                if (data.status.q && data.status.q.length) {

                    _inputValue(cfg, cfg.$this, data.status.q);
                    inpBgColor.set(cfg, cfg.$this, data.status.q, data.status);
                    // restore default icon
                    _icoOnInput(cfg.$this, $.mtsoft.ui.icon(cfg.iconRemove), 'clear-expr');

                } else {
                    
                    _inputReset(cfg);
                }
            },
            /**
             * Check curent text input is inside currently submitting form
             * There can be mulitple search boxes on this same view
             * check element submitted this same form as this search input text box
             * 
             * @param {JSON} cfg
             */
            _isInputForm: function(cfg) {
                
                var $list = cfg.listCfg.$this.mtsoftUiListing();

                if ($list.config('_triggedBy')) {

                    var $frm = $list.config('_triggedBy').parents('form').filter(':first'),
                        $inpFrm = cfg.$this.parents('form').filter(':first');

                    return $frm.attr('id') === $inpFrm.attr('id');
                } else {
                    return true;
                }
            }                      
        },        
        // ---------------------------------------------------------------------
        //
        // Listing filter as link (a.href)
        //
        filterLink: {
            defaults: {
                cssPrefix: 'mtl-',
                iconWait: true,
                iconClose: true,
                toggle: true, // if tur make link work in toggle mode
                //cssClass: 'flt-link',
                cssClassActive: 'flt-link-active'   // active link css class name; set false to dnot style
            },
            _init: function() {
            },
            init: function() {

                var _cfg = cfg;
                //
                // on link click 
                //
                _cfg.$this.on('click.mtsoftUiListing', function() {
                    
                    var curr = _cfg.listCfg.$this.mtsoftUiListing().deUrl(),                            
                            currStateFiltersDefs = $.mtsoftUiListingConv.filters.urlParams2def(curr.filters);

                    //
                    // Re-load list with current filter applied (or removed if toggle mode and filter already applied)
                    //
                    var url = $.extend(true, {}, curr.list, curr.search,
                            _filterUrlParams(_cfg.filter, currStateFiltersDefs, _cfg.toggle));
                    
                    // force first page 
                    if (url.page) {
                        url.page = 1;
                    }
                    
                    _cfg.listCfg.$this.mtsoftUiListing().load($.extend(_cfg.ctrlCfg,
                            {url: url}
                    //{url: _filterUrlParams(_cfg.filter, [], _cfg.toggle)}
                    ));
                    
                    //
                    // style all listing related link type filters;
                    //
                    if (_cfg.cssClassActive) {
                        // style by default on click; 
                        // after listing re-load it will be styled again 
                        // to be sure right state style is applied                        
                        var _loading = _cfg.listCfg.$this.mtsoftUiListing().config('_loading'),
                                _loadingUrl = _loading ? $.mtsoftUiListingConv.filters.urlParams2params(_loading.url) : false,
                                targetStateFiltersDefs = $.mtsoftUiListingConv.filters.params2def($.extend(true, {}, _loadingUrl || curr.filters, $.mtsoftUiListingConv.filter.def2params(_cfg.filter)));

                        controls.filterLink._styleFilters.call(this, _cfg, targetStateFiltersDefs, _cfg.filter);
                    }

                    return false;   // don't re-driect on link click
                });

            },
            update: function(data) {

                //controls.filterLink._styleFilters1.call(this);
                if (cfg.cssClassActive && data._styled === undefined) {
                    controls.filterLink._styleFilters.call(this, cfg, $.mtsoftUiListingConv.filters.params2def(data.status.f));
                    data._styled = true; // set flag to perform styling only once 
                }
            },
            /**
             * Style all link type filter controls 
             * 
             * @param {type} cfg
             * @param {type} filters
             * @param {type} filter
             * @returns {undefined}
             */
            _styleFilters: function(cfg, filters, filter) {

                var c = cfg.cssPrefix + cfg.cssClassActive;

                // if not any filters
                if (!filters.length) {
                    $("a[data-" + cfg.listCfg._listId + "][class*='flt-link']").removeClass(c);
                }

                $("a[data-" + cfg.listCfg._listId + "][class*='flt-link']").each(function() {

                    var _filter = $(this).data('config.mtsoftUiListingControl').filter;
                    
                    //
                    // target (currently added/toggled filter) if set 
                    //
                    // if clicking on filter control and filter can have only single value 
                    // - remove 'active' class from other controls of this filter
                    if (filter && filter.field === _filter.field) {

                        if (filter.off === undefined) { // single filter value

                            // remove active style from all controls for this filter filed 
                            if (filter.value == _filter.value
                                    && (filter.operator === undefined
                                            || (_filter.operator && filter.operator === _filter.operator))
                                ) {
                                // currently clicked filter control
                                if (cfg.toggle) {
                                    $(this).toggleClass(c);
                                } else {
                                    $(this).addClass(c);
                                }
                            } else {

                                // un-mark active filter
                                $(this).removeClass(c);
                            }
                        } else {    // mulitple filter values

                            // remove only form control with this same value
                            // ( mulitple values for filter) 
                            if (filter.value == _filter.value) {
                                if (cfg.toggle) {
                                    $(this).toggleClass(c);
                                } else {
                                    $(this).removeClass(c);
                                }
                            }
                        }
                        return;
                    }

                    //
                    // check all exsiting filter links
                    //
                    for (var i in filters) {

                        if (_filter.field === filters[i].field) { // this filter is applied for current listing                            
                            // 
                            // exactly this filter and value is applied                          
                            if (_filter.value == filters[i].value
                                    // this filter, value and operator is applied on listing
                                    && (_filter.operator === undefined || _filter.operator == filters[i].operator)
                                    ) {

                                // mark as active 
                                $(this).addClass(c);

                            } else {

                                // un-mark inactive filter
                                if (_filter.off === undefined) {
                                    $(this).removeClass(c);
                                }
                            }
                        }
                    }
                });
            }
        },
        // ---------------------------------------------------------------------
        //
        // Listing filter as link (a.href)
        //
        filterInput: {
            defaults: {
                btnClear: true, // show clear (x) button inside search text input
                btnClearActivOnly: true, // show clear button only if filter is applied for listing (not only entered) 
                bgColor: true, // aplly search input bg color dependign on search resutls (some results, no results)
                loadOnBlur: false, // re-load list on input blur
                loadOnKeyup: 0, // [s] re-load list after given number of seconds after last keyup; set 0 to disable
                keepExpression: true, // if true keep search expresion value in text input
                //iconRemove: 'clear-expr', // remove filter icon
                //iconWait: 'ico-wait', // remove filter wait icon ('icon-wait')
                allowEmpty: true, // if true allow to submit search even if search expression is empty
                filter: {}, // filter definition: field - filter field name, operator - filter expression operator
                iconRemove: '<svg class="clear-expr" viewBox="0 0 32 32"><path d="M25.6 3.2h-19.2c-1.76 0-3.2 1.44-3.2 3.2v19.2c0 1.76 1.44 3.2 3.2 3.2h19.2c1.76 0 3.2-1.44 3.2-3.2v-19.2c0-1.76-1.44-3.2-3.2-3.2zM20.898 23.662l-4.898-4.896-4.898 4.898-2.765-2.766 4.896-4.898-4.896-4.896 2.765-2.766 4.898 4.896 4.896-4.896 2.766 2.765-4.896 4.898 4.898 4.898-2.766 2.765z" /></svg>',
                iconWait: true

            },
            _init: function() {
            },
            init: function() {

                //
                // Set input value and bg color
                //
                if (cfg.listCfg.status.f && cfg.listCfg.status.f[cfg.filter.field]) {

                    var v = _inputServerValue(cfg);

                    _inputValue(cfg, cfg.$this, v);
                    inpBgColor.set(cfg, cfg.$this, v, cfg.listCfg.status);
                }

                // init bg color blur event
                inpBgColor.init(cfg);

                // handle clear button (PRIOR to load on blur/keyup)
                inpBtnClear.init(cfg);

                // define blur/keyup callback (to laod list)
                var _load = function($this, timeout) {

                    // submit form (re-loads listing)
                    // Do it via timeout. This will prevent form submit with input value changed
                    // when clear button has been used (filter will be reseted, not updated)
                    window.clearTimeout($this.data('_loadTimeout'));
                    $this.data('_loadTimeout', window.setTimeout(function() {

                        var cfg = $this.data('config.mtsoftUiListingControl'),
                                sv = _inputServerValue(cfg),
                                v = $this.val();

                        // if filter value on input is other than fitler value on server
                        if (v !== '' && v !== sv || (sv !== undefined && v === '')) {

                            if (cfg.iconWait) {
                                //_icoOnInput(cfg.$this, cfg.iconWait);
                                _icoWaitOnInput(cfg.$this);
                            }
                            $this.parents('form').filter(':first').submit();
                        }

                    }, timeout * 1000));
                };

                //
                // (re)laod input on blur
                //
                if (cfg.loadOnBlur) {
                    
                    // re-laod listing on input blur
                    cfg.$this.on('blur.mtosftUiListing', function() {
                        
                        _load($(this), 0.01);
                    });
                }

                //
                // (re)load input on keyup
                //
                if (cfg.loadOnKeyup) {

                    // re-load listing after user has stopped typing
                    var delay = cfg.loadOnKeyup;

                    cfg.$this.on('keyup.mtosftUiListing', function() {
                        
                        _load($(this), delay);
                    });
                }

                // smart label 
                if (cfg.smartlabel) {

                    $(cfg.$this).mtsoftUiSmartLabel(cfg.smartlabel);
                }

            },
            update: function(data) {

                if (data.status.f && data.status.f[cfg.filter.field]) {

                    var v = _inputServerValue(cfg, data.status), r = null;
                    
                    if (v !== undefined) {

                        if (!cfg.loadOnKeyup) { // update value only if not load on keyup
                            _inputValue(cfg, cfg.$this, v);
                        } else {
                            cfg.$this.data('valueOnFocus', cfg.$this.val()); // to disable load when no loade has been perfored before
                        }
                        r = inpBgColor.set(cfg, cfg.$this, v, data.status);

                        // restore default icon or hide 'wait' icon
                        if (!cfg.btnClearActivOnly) {

                            if (r !== null) {
                                // restore
                                _icoOnInput(cfg.$this, $.mtsoft.ui.icon(cfg.iconRemove), 'clear-expr');
                            } else {
                                // hide
                                inpBtnClear.hide(cfg.$this);                                
                            }

                        } else {
                            // restore
                            _icoOnInput(cfg.$this, $.mtsoft.ui.icon(cfg.iconRemove), 'clear-expr');
                        }
                    }
                } else {    // no longer this filter is applied for current listing 

                    _inputReset(cfg);
                }
            }
        },
        // ---------------------------------------------------------------------
        //
        // Listing filter as checkbox (input type="checkbox")
        //
        filterCheckbox: {
            defaults: {
                loadOnChange: false, // re-load list on input blur
                filter: {} // filter definition: field - filter field name, operator - filter expression operator
            },
            _init: function() {
            },
            init: function() {

                //
                // (re)laod on checkbox changed 
                //
                if (cfg.loadOnChange) {

                    // re-laod listing on input blur
                    cfg.$this.on('change.mtosftUiListing', function() {

                        $(this).parents('form').filter(':first').submit();
                    });
                }
            },
            update: function(data) {

                // check rigth checkbox value
                var v = cfg.$this.val(), value = _inputServerValue(cfg, data.status);
                if (data.status.f && data.status.f[cfg.filter.field]  // filter is applied 
                        && (value !== null && value == v) || (v === null || v === '')) {  // filter value is equal to radio value 

                    cfg.$this.prop('checked', true);
                } else {    // no longer this filter is applied for current listing 

                    cfg.$this.prop('checked', false);
                }
            }
        },
        // ---------------------------------------------------------------------
        //
        // Listing filter as radio (input type="radio")
        //
        filterRadio: {
            defaults: {
                loadOnChange: false, // re-load list on input blur
                filter: {} // filter definition: field - filter field name, operator - filter expression operator
            },
            _init: function() {
            },
            init: function() {

                //
                // (re)laod on radio changed 
                //
                if (cfg.loadOnChange) {

                    // re-laod listing on input blur
                    cfg.$this.on('change.mtosftUiListing', function() {

                        $(this).parents('form').filter(':first').submit();
                    });
                }
            },
            update: function(data) {

                // check right radio value                 
                var v = cfg.$this.val(), value = _inputServerValue(cfg, data.status);
                if (data.status.f && data.status.f[cfg.filter.field]  // filter is applied 
                        && (value !== null && value == v) || (v === null || v === '')) {  // filter value is equal to radio value 

                    cfg.$this.prop('checked', true);
                } else {    // no longer this filter is applied for current listing 

                    cfg.$this.prop('checked', false);
                }
            }
        },
        // ---------------------------------------------------------------------
        //
        // Listing filter as select-box (input type="radio")
        //
        filterSelect: {
            defaults: {
                loadOnChange: false, // re-load list on select
                bgColor: true, // use bg color to indicate select filter applied 
                filter: {} // filter definition: field - filter field name, operator - filter expression operator
            },
            _init: function() {
            },
            init: function() {

                if (cfg.listCfg.status.f && cfg.listCfg.status.f[cfg.filter.field]) {

                    var v = _inputServerValue(cfg);
                    inpBgColor.set(cfg, cfg.$this, v, cfg.listCfg.status);
                }

                //
                // (re)laod on select changed 
                //
                function setSelected($s) {
                    if ($s.val() > 0) {
                        $s.addClass('selected');
                    } else {
                        $s.removeClass('selected');
                    }
                }
                // re-laod listing on input blur
                var _cfg = cfg;
                cfg.$this.on('change.mtosftUiListing', function() {

                    var $this = $(this);
                    // remove bg color 
                    //inpBgColor.clear($(this)); - don't style using bg color
                    setSelected($this);

                    if (_cfg.loadOnChange) {
                        $this.parents('form').filter(':first').submit();
                    }
                });
                setSelected(cfg.$this);
                //cfg.$this.change();
                
            },
            update: function(data) {

                // select right select-box option 
                var v = _inputServerValue(cfg, data.status);
                
                if (data.status.f && data.status.f[cfg.filter.field] && v!=='') {  // filter is applied //  removed && cfg.$this.val() == v

                    inpBgColor.set(cfg, cfg.$this, v, data.status);
                    cfg.$this.val(v);
                } else {
                    cfg.$this.val('');
                    inpBgColor.clear(cfg.$this);
                }
            }
        },
        // ---------------------------------------------------------------------
        //
        // Info including Go to Page (gotoPage) navigation input (contenteditable) placed on info panel
        //
        info: {
            defaults: {
            },
            _init: function() {
            },
            init: function() {
                controls.info._gotoPage.call(this, cfg);                
            },
            update: function(data) {
                var curr = cfg.listCfg.$this.mtsoftUiListing().deUrl();
                controls.info._gotoPage.call(this, cfg);
            },
            _gotoPage: function(cfg) {

                var $this = cfg.$this;               
                if (cfg.gotoPage && cfg.listCfg.status.pageCount > 1) { // goto page allowed and number of pages more than one
                    
                    // find current page label
                    var $currPage = $this.find('b');

                    $currPage.prop('contenteditable', true);
                   
                    $currPage.on('keydown', function(e) {
                        
                        var key = e.keyCode; // get key code
                        
                        if (key === 13) {
                            
                            // check entered page number in exisitngs pages range 
                            var pageNo = parseInt($(this).text()), validPageNo = controls.info._checkPageNumb(pageNo);
                            $(this).text(validPageNo);
                            $(this).data('enter', true).blur().data('enter', false);
                            
                            var currUrl = cfg.listCfg.$this.mtsoftUiListing().deUrl();
                            currUrl.parameters.page = validPageNo;
                            cfg.listCfg.$this.mtsoftUiListing().load({url: currUrl.parameters});
                            
                            return false;
                        }
                        if (key === 27) {
                            
                            // restore current page
                            $(this).text(cfg.listCfg.status.page);
                            $(this).blur();
                            return true;
                        }

                        if ($.inArray(key, [8, 9, 16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 46]) > -1) {

                            // some of ignored keys was hit
                            return true;
                        }
                        
                        // only numbers allowed 
                        var keyChar = $.mtsoft.key2char(e); // convert to character                        
                        if (!/^[0-9]+$/i.test(keyChar)) {
                            return false;
                        }
                        
                    }).on('focus', function(e) {
                        // TURNED OFF - select contetneditable contents - DON'T works on Chrome - selects all page contents
                        //try { document.execCommand('selectAll',false,null); } catch(e) {}
                    }).on('blur', function(e) {

                        // restore current page 
                        if (!$(this).data('enter')) {
                            
                            $(this).text(cfg.listCfg.status.page);
                        }
                    });
                }
            },
            _checkPageNumb: function(page) {
                console.log(cfg.listCfg.status.pageCount);
                if (page < 1 || isNaN(page) || !page) {
                    
                    return 1;
                } else {
                    
                    if (page > cfg.listCfg.status.pageCount) {
                        
                        return cfg.listCfg.status.pageCount;
                    }
                }
                
                return page;
            }
        },
        //
        // Listing limit (row per page) select box (<select>) 
        //
        limitSelect: {
            defaults: {
                mode: 'post'
            },
            _init: function() {
            },
            init: function() {

                controls.limitSelect._changeLimit.call(this, cfg);
            },
            update: function(data) {

                controls.limitSelect._changeLimit.call(this, cfg);
            },
            _changeLimit: function(cfg) {
                
                // re-laod listing on input blur
                //var _cfg = cfg;
                cfg.$this.find('select').on('change.mtosftUiListing', function() {

                    //var $this = $(this);
                    var currUrl = cfg.listCfg.$this.mtsoftUiListing().deUrl();
                    currUrl.parameters.limit = parseInt($(this).val()); // changes limit 
                    currUrl.parameters.page = 1; // back to first page 
                    cfg.listCfg.$this.mtsoftUiListing().load({url: currUrl.parameters});
                });
            }
            
        }
    };


    /**
     * Set url parameters for ajax request while applying filter
     * 
     * @param {JSON} flt        filter definition: 
     *                              {
     *                                  field: 'Model.field_name', 
     *                                  value: 'some value', 
     *                                  operator: '>=', 
     *                                  link: 'AND',
     *                                  sublink: 'OR'
     *                               }
     * @param {Bool} toggle     if true - use toggle filter mode
     * 
     * @returns {Object|JSON}   filter parameters in format:
     *                              'f[Model.field_name]': 'value'
     *                              'f[Model.field_name_o_]': 'operator'
     *                              OR
     *                               'f[Model.field_name][0]': 'value'
     *                               'f[Model.field_name_o_][0]': 'operator'
     *                              
     */
    function _filterUrlParams(flt, currUrlFiltersDefs, toggle) {

        var d, removed = false;
        currUrlFiltersDefs = currUrlFiltersDefs !== undefined ? currUrlFiltersDefs : [];

        if (toggle) {   // add filter only if not already applied 

            // check is there given filter already in current listing url 
            for (var i in currUrlFiltersDefs) {

                d = currUrlFiltersDefs[i];

                if (flt.field === d.field
                        && flt.value == d.value
                        && (d.operator === undefined
                                || flt.operator && flt.operator === d.operator)) {

                    // this filter is already applied - remove from target filters (will turn off this filter)
                    // DON"T USE: delete currUrlFiltersDefs[i] - if queue of deleted filters - will not work;
                    // mark (set value to '_d_') to remove from url (on _buildUrl method) 
                    currUrlFiltersDefs[i].value = '_d_';
                    removed = true;
                    break;
                }
            }

            if (!removed) { // not on existing url - add filter
                currUrlFiltersDefs.push(flt);
            }
        } else { // always add 
            currUrlFiltersDefs.push(flt);
        }        
        return $.mtsoftUiListingConv.filters.def2urlParams(currUrlFiltersDefs);
    }    
    /**
     * Keep/Clear search expression 
     * 
     * @param {JSON} cfg        control configruation object
     * @param {Mixed} value
     */
    function _inputValue(cfg, $inp, value) {

        if (!cfg.keepExpression) {

            // clear filter/search expression from search/filter input box 
            $inp.val('');
        } else {
            // set value only if returned form server
            $inp.val(value);
        }
        return $inp.data('valueOnFocus', $inp.val()); // needed for clear btn
    }

    /**
     * Get filter current value (applied on listing) 
     * @param {type} cfg
     * @returns {status..f|String..f|String|status.f|String.f}
     */
    function _inputServerValue(cfg, status) {

        var fn, srvV;
        status = status || cfg.listCfg.$this.mtsoftUiListing().config('status');

        if (cfg.filter) {
            
            fn = cfg.filter.field, //.replace('.', '__'),
                    srvV = status.f && status.f[fn] ? status.f[fn] : undefined; // current filter value on server

            if (typeof (srvV) === 'object') { // this is array (mulitple filters for this same filter field)

                // secondary source of off value from id attribute WORKS ONLY FOR OFFSETs 0 - 9! 
                // (off values should be always set on filter.off and secondary methos shoudn't be used any time) 
                var off = cfg.filter.off ? cfg.filter.off : parseInt(cfg.$this.attr('id').slice(-1));

                srvV = status.f[fn] && status.f[fn][off] ? status.f[fn][off] : undefined;
            }
        } else {

            if (status.q) {
                srvV = status.q; // current filter value on server
            } else {
                srvV = undefined;
            }
        }
  
        return _urldecode(srvV);
    }

    /**
     * Filter/search input bg color
     * @type type
     */
    var inpBgColor = {
        init: function(cfg) {

            var self = this, _cfg = cfg;
            cfg.$this.on('focus.mtsoftUiListing', function() {

                $(this).data('_inpBgColorClass', $(this).hasClass('results-on') ? 'results-on' : $(this).hasClass('no-results') ? 'no-results' : '');
                self.clear($(this));

            }).on('blur.mtsoftUiListing', function() {

                if ($(this).val() === _inputServerValue(_cfg)) {

                    if ($(this).data('_inpBgColorClass')) {
                        // restore bg color removed on focus 
                        $(this).addClass($(this).data('_inpBgColorClass'));
                    }
                }
            });
        },
        /**
         * set input box bg color depending on results
         * 
         * @param {JSON} cfg
         * @param {Mixed} val       search/filter value
         * @param {JSON} status     listing pagination status
         */
        set: function(cfg, $inp, val, status) {
            
            var r = null;
            if (cfg.bgColor) {

                $inp.removeClass('results-on no-results');

                if (val && (val + "").trim() !== '' && status) { // search query applied

                    if (status.pageCount > 0) {

                        // at least one result found
                        $inp.addClass('results-on');
                        r = true;
                    } else {

                        // no results
                        $inp.addClass('no-results');
                        r = false;
                    }
                } else {

                    // no search query
                    $inp.removeClass('results-on no-results');
                    $inp.data('_inpBgColorClass', null);
                    r = null;
                }
            }
            return status;
        },
        clear: function($inp) {
            $inp.removeClass('results-on no-results');
        },
        /**
         * Are any filter appleid?
         * 
         * @param {type} $inp
         */
        applied: function($inp) {

            return $inp.hasClass('results-on') || $inp.hasClass('no-results');
        }
    };
    //_inputBgColor

    /**
     * Show clear button inside filter/search box input text
     * 
     */
    var inpBtnClear = {
        init: function(cfg) {

            if (cfg.btnClear) {

                _icoOnInput(cfg.$this, $.mtsoft.ui.icon(cfg.iconRemove), 'clear-expr'); // set icon on input
                var $inp = cfg.$this;

                //
                // on clear button click - remove search expression (and re-load list if required)                  
                //
                $inp.on('focus.mtsoftUiListing', function() { //blur.mtsoftUiListing

                    $inp.data('valueOnFocus', $inp.val());
                });
                $('#' + $inp.attr('id') + 'Ico').on('click.mtsoftUiListing', function() {
                    
                    // if blur timeout is set - reset it
                    // this will prevent form submition if filter value on server is other than
                    // filter value in input, but clear button has been used
                    window.clearTimeout($(this).data('_loadTimeout'));

                    // reset search expression
                    $inp.val('');
                    inpBtnClear.toggle($inp);

                    if ($inp.data('valueOnFocus') !== '') {

                        //if (cfg.loadOnBlur || cfg.loadOnKeyup) { // re-load listing only if loadOnBlur or loadOnKeyup (not in larger form)
                            $inp.parents('form').filter(':first').submit(); // submit search form
                        //}
                        inpBgColor.set(cfg, cfg.$this, '', {}); // clear bg color                                
                    }
                });

                if (!cfg.btnClearActivOnly) {
                    // hide clear button if no search/filter expression
                    $inp.on('keyup.mtsoftUiListing', function() {

                        //_handleIcon();
                        inpBtnClear.toggle($inp);
                    });
                } else {

                    // hide clear button if no search/filter expression
                    $inp.on('focus.mtsoftUiListing', function() {

                        inpBtnClear.hide($inp, true);
                    }).on('blur.mtsoftUiListing', function() {

                        // hide if current input value it other rthan set on server
                        if ($inp.val() !== _inputServerValue(cfg)) {
                            inpBtnClear.hide($inp);
                        } else {

                            inpBtnClear.toggle($inp);
                        }
                    });
                }
                // initial state
                inpBtnClear.toggle($inp);
            }
        },
        hide: function($inp) {

            this._getIcon($inp).hide();
        },
        toggle: function($inp) {

            var $ico = this._getIcon($inp);

            if ($inp.val() === '') {
                $ico.hide();
            } else {
                $ico.show();
            }
        },
        _getIcon: function($inp) {
            return $('#' + $inp.attr('id') + 'Ico');
        }
    };

    /**
     * Reset listing related input:
     * - clear input value, 
     * - clear input bg color, 
     * - hide clear button
     * 
     * @param {JSON} cfg        input control configuration
     */
    function _inputReset(cfg) {

        if (inpBgColor.applied(cfg.$this)) {

            _inputValue(cfg, cfg.$this, '');
            inpBgColor.clear(cfg.$this);
        }

        if (!cfg.btnClearActivOnly) {
            inpBtnClear.toggle(cfg.$this);
        } else { // filter is not applied - hide clear button 
            inpBtnClear.hide(cfg.$this);
        }
        //set smart label
        if (cfg.smartlabel) {

            cfg.$this.mtsoftUiSmartLabel(cfg.smartlabel);
        }
    }

    /**
     * Put icon (image, svg, css class) inside text input
     *
     * @param {jQuery} $inp             input jQuery object
     * @param {String} icon             icon class name
     * @param {String} cssClass         css Class name
     * @param {String} title            title
     * @param {String} id               icon id;  automatically is assigned inupt id + Ico (ex. 'inputNameIco')
     * @param {String} corr             correction object (relative values to correct position/size):
     *                                      width
     *                                      height
     *                                      top
     *                                      right
     * @return {jQuery}
     */
    function _icoOnInput($inp, icon, cssClass, title, id, corr) {
        
        var $n, icoId = id ? id : $inp.attr('id') + 'Ico',                
                $ico = $inp.parent().find('#' + icoId);

        corr = $.extend({top: 0, right: 0, width: 0, height: 0}, corr);

        $('#' + icoId + 'Wait').hide(); // if wait icon shown - remove 
        // check is there alreay icoOnInput applied?
        if ($ico.length) {

            $ico.show();    // just show
            return false;
        }

        // wrap only if parent element is not relative or absolute;
        if ($inp.parent().css('position') !== 'relative' && $inp.parent().css('position') !== 'absolute') {

            $inp.wrap('<div style="display:inline-block;position:relative;' + ($inp.css('float') !== '' ? 'float:' + $inp.css('float') + ';' : '') + '">');
        }

        // append icon
        if (typeof (icon) === 'object' || icon.indexOf('<') > -1) {
            // html code / svg object
            $n = $(icon);
            $n.attr('id', icoId).addClass(cssClass); // adding css class don't works for svg? 
            $inp.after($n);
        } else {
            // css class (ex. background image)
            $n = $('<i id="' + icoId + '" class="' + (icon ? icon : '') + (cssClass ? cssClass : '') + '"' + (title ? ' title="' + title + '"' : '') + '></i>'); //style="font-size:' + size + '"
            $inp.after($n);
        }

        // set neseted icon parameters
        var _getProp = function($inp, prop) {

            if ($inp.css(prop) && $inp.css(prop) !== '') {
                return parseInt($inp.css(prop));
            } else {
                return 0;
            }
        };
        
        $n.css({
            display: 'block',
            position: 'absolute',
            top: corr.top + _getProp($inp, 'margin-top') + _getProp($inp, 'border-top-width') + _getProp($inp, 'padding-top') + 'px',
            right: corr.right + _getProp($inp, 'border-right-width') + _getProp($inp, 'padding-right') + 'px', //_getProp($inp, 'margin-right') + 
            height: corr.height + $inp.height(), // fit "inside" input 
            width: corr.width + $inp.height()    // don't allow to fill all space
        });

        return $inp;
    }
    /**
     * Add wait icon insode input
     * 
     * @param {jQuery} $inp
     * @returns {jQuery}
     */
    function _icoWaitOnInput($inp) {

        var id = $inp.attr('id');
        
        // hide current icon (if any)        
        $('#' + id + 'Ico').hide();

        return _icoOnInput($inp, $.mtsoft.ui.icon('#ico-wait', 'ico-wait'), null, null, id + 'IcoWait', {width: -3, height: -3, top: 2, right: 2});
    }

    function _urldecode(url) {
        
        return url !== undefined ? decodeURIComponent(url.replace(/\+/g, ' ')) : '';
    }

    //
    // Utils
    //

    // set of listing filter parameters converters
    $.mtsoftUiListingConv = {
        /**
         * Mulitple filters converters
         */
        filters: {
            def2params: function(defs) {
                var r = {};
                for (var i in defs)
                    $.extend(r, $.mtsoftUiListingConv.filter.def2params(defs[i]));
                return r;
            },
            params2urlParams: function(params) {
                return  $.mtsoftUiListingConv.filter.params2urlParams(params);
            },
            def2urlParams: function(defs) {
                var r = {};
                for (var i in defs)
                    $.extend(r, $.mtsoftUiListingConv.filter.def2urlParams(defs[i]));
                return r;
            },
            urlParams2params: function(params) {
                return $.mtsoftUiListingConv.filter.urlParams2params(params);
            },
            params2def: function(params) {
                return $.mtsoftUiListingConv.filter.params2def(params);
            },
            urlParams2def: function(params) {

                return $.mtsoftUiListingConv.filter.urlParams2def(params);
            }
        },
        /**
         * Single filter converters
         */
        filter: {
            /**
             * Convert single filter definition object to filter parameters object
             * Example:
             *  input:
             *      {field_name: 'fieldValue'}
             *      OR
             *      {
             *          field: 'Model.field_name', 
             *          value: 'field some value', 
             *          operator: '>=', 
             *          link: 'AND', 
             *          sublink: 'OR', 
             *          off: 1
             *      }
             *  output:
             *      {'Model.field_name': {1: 'field some value'}}
             *      
             * @param {JSON} filter
             */
            def2params: function(def) {

                if (def) {

                    //
                    // get assined def field name 
                    // 

                    // 1.) def given in format: {name: 'Model.field_name', value: 'field some value'})
                    var field = def.field ? def.field : null, r = {};

                    // 2.) def in format: {'Model.field_name': 'field some value'}
                    if (field === null) {
                        for (field in def)
                            break;
                        var value = def[field];
                        def = {field: field, value: value};
                    }

                    var conv = function(param, smb, def) {

                        var r = {}, o, v;
                        // set additional field parameters
                        if (def[param] !== undefined) {

                            if (def.off !== undefined) {
                                o = {};
                                o[def.off] = def[param];
                                v = o;
                            } else {
                                v = def[param];
                            }
                            r[field + smb] = v;
                        }

                        return r;
                    };

                    return $.extend(r,
                            conv('value', '', def),
                            conv('operator', '_o_', def),
                            conv('link', '_l_', def),
                            conv('sublink', '_sl_', def));

                } else {

                    return def;
                }
            },
            /**
             * Convert single filter parameters object to filter url parameters object
             * Example:
             *  input:
             *      {'Model.field_name': {1: 'field some value'}}
             *  output:
             *      {'f[Model.field_name][1]': 'field some value'}
             */
            params2urlParams: function(params) {

                var r = {};

                for (var p in params) {

                    if (typeof (params[p]) === 'object') {

                        for (var off in params[p]) {

                            r['f[' + encodeURIComponent(p) + '][' + off + ']'] = params[encodeURIComponent(p)][off];
                        }

                    } else {

                        r['f[' + encodeURIComponent(p) + ']'] = params[encodeURIComponent(p)];
                    }
                }

                return r;
            },
            /**
             * Convert single filter definition object to filter url parameters object
             * 
             * Example:
             *  input:
             *      {field_name: 'fieldValue'}
             *      OR
             *      {
             *          field: 'Model.field_name', 
             *          value: 'field some value', 
             *          operator: '>=', 
             *          link: 'AND', 
             *          sublink: 'OR', 
             *          off: 1
             *      }
             *  output:
             *      {'f[Model.field_name][1]': 'field some value'}
             *      
             * @param {JSON} filter
             */
            def2urlParams: function(def) {

                var prms = $.mtsoftUiListingConv.filter.def2params(def);
                return $.mtsoftUiListingConv.filter.params2urlParams(prms);
            },
            /**
             * Convert single filter url parameters object to filter parameters object
             * Example:
             *  input:
             *      {'f[Model.field_name][1]': 'field some value'}
             *  output:
             *      {'Model.field_name': {1: 'field some value'}}
             */
            urlParams2params: function(params) {

                var r = {};
                for (var p in params) {

                    var rexp = new RegExp(/^f\[([^\]]+)\](\[([0-9]+)\])*/),
                            matches = rexp.exec(p);

                    if (matches) {
                        if (matches[3] !== undefined) { // this is array value
                            var off = parseInt(matches[3]);
                            if (r[matches[1]] === undefined) {
                                r[_urldecode(matches[1])] = [];
                            }
                            r[_urldecode(matches[1])][off] = _urldecode(params[p]);

                        } else { // single value

                            r[_urldecode(matches[1])] = _urldecode(params[p]);

                        }
                    } else {
                        // this is not a filter parameter
                    }
                }
                return r;
            },
            /**
             * Convert single filter parameters object to filter parameters object;
             * Works for mulitple filter values too
             * 
             * Example:
             *  input:
             *      {'Model.field_name': {1: 'field some value'}, 'Model.field_name_o_': {1: '>='}}, ...
             *  output:
             *      [{
             *          field: 'Model.field_name'
             *          value:  'field some value',
             *          operator: '>=',
             *          off: 1
             *      }, ...] 
             * @param {Array}   array of JSON filter definitions
             *      
             */
            params2def: function(params) {

                var f = [], _f = {};
                for (var name in params) {

                    // only file name = value pr will be processed 
                    if (name.indexOf('_o_') < 0 && name.indexOf('_l_') < 0 && name.indexOf('_sl_') < 0) {

                        if (typeof (params[name]) === 'object') { // mulitple values

                            for (var off in params[name]) {

                                _f = {field: name, value: params[name][off], off: off};

                                if (params[name + '_o_'])
                                    _f.operator = params[name + '_o_'][off];
                                if (params[name + '_l_'])
                                    _f.link = params[name + '_l_'][off];
                                if (params[name + '_sl_'])
                                    _f.sublink = params[name + '_sl_'][off];

                                f.push(_f);
                            }

                        } else {

                            _f = {field: name, value: params[name]};

                            if (params[name + '_o_'])
                                _f.operator = params[name + '_o_'];
                            if (params[name + '_l_'])
                                _f.link = params[name + '_l_'];
                            if (params[name + '_sl_'])
                                _f.sublink = params[name + '_sl_'];

                            f.push(_f);
                        }
                    }
                }
                return f;
            },
            /**
             * Convert single filter url parameters  to filter parameters object
             * @param {JSON} params     
             */
            urlParams2def: function(params) {

                return $.mtsoftUiListingConv.filter.params2def($.mtsoftUiListingConv.filter.urlParams2params(params));
            }

        }
    };



})(jQuery);