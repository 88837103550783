/**
 * Universal Popup 
 * 
 * Shows various types of popups (popup, dialog, dialog modal, wait modal, form, etc...)
 * 
 * (c) 2013 mtsoft.pl
 * 
 * @uses:
 * @uses $.mtsoft.ui.icon 
 *  mtosft.ui.anim.js
 *  jquery.ui.core.min.js 
 *  jquery.ui.widget.min.js
 *  jquery.ui.mouse.min.js
 *  jquery.ui.draggable.min.js (only if popup draggable behaviuor used)
 *  jquery.ui.resizable.min.js (only if popup resizable behaviour used) 
 *  
 */
;
(function() {

    var cfg = {};
    // -------------------------------------------------------------------------
    //
    // Plugin constructor 
    //
    $.fn.mtsoftUiPopup = function(params) {

        $.extend(this, actions);

        return this.each(function() {

            var $this = $(this);

            if (!$this.data('config.mtsoftUiPopup') || params) {
  
                //
                // define & save configuration for current node  
                //
                actions.init.apply($this, [params]);
                actions.config.apply($this, [cfg]);

            } else {

                if (params !== false) {

                    //
                    // read configuration for current node 
                    //
                    cfg = $this.mtsoftUiPopup(false).config();
                }
            }
        });
    };

    /**
     * Generate popup via JavaScript 
     * 
     * @param {Mixed} params
     */
    $.mtsoftUiPopup = function(params) {

        var pfx = $.fn.mtsoftUiPopup.defaults.cssPrefix;
        return $('<div class="' + pfx + 'popup ' + pfx + 'default" data-dynamic="true" data-popup  data-theme="default" data-class=""><div class="' + pfx + 'content"></div></div>').mtsoftUiPopup(params);
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtsoftUiPopup.defaults = {
        // settings as properties or objects
        cssPrefix: 'mtp-',
        $parent: $('body'), // parent element (node where popup code will nested as first child) 
        type: '', // popup pre-fdefiend type identifier [ confirm | inform | wait | waitCancelable]
        theme: 'default', // popup theme class name
        // default popup position on screen; set 36% / 50% to center; should be equal to _popup.scss > .prefix-popup class left/top properties
        posTop: '36%',
        posLeft: '50%',
        maxWidth: 640,
        minWidth: 240,
        margin: 5, // margin from viewport border
        modal: true, // if true show popup as modal (dim all other areas of screen)
        modalHideOnClose: true, // if true hide modal when closing popup
        draggable: false, // if true allow popup to be draggable
        $handle: null, // handle for dragging
        resizable: false, // if true allow to resize popup
        freeResize: false, // if true popup can be freely resized without any limits (no min/max width/height, no auto-size height to width)
        maxSizeMode: false, // if true popup will open in full viewport size
        resetOnOpen: true, // if true restet poup position and size on open; otherwise keep recent position/size
        centerVertically: false, // if true ALWAYS center popup vertically , otherwise only if small screens
        scrollable: false,  // if true and popup with contents don't fit on viewport make popup contnets/body scrollable; this can be also element object to scroll [true | false | jQueryObject ] 
        closeBtn: true, // show close button if true; otherwise clsoe buttons will not be generated or will be removed if exists
        closeOnEsc: true, // close popup on ESC hit
        closeOnOverlayClick: false, // if true close popup on overlay click
        onTitleDbClick: 'none', // action to execute on titlebar double click [none | maximize | minimize ]
        timeout: null, // [s] time after popup will be closed
        timeoutProgressbar: false, // if true how timeout progress-bar on popup's top
        animOpen: 'fade-and-scale', // open popup transition; format: @see mtsoftUiAnim
        animClose: null, // open popup transition; format: @see mtsoftUiAnim
        //
        // dynamically generated content
        //
        html: null, // all popup contents as html code
        title: null, // popup title
        icon: '', // popup title icon (none by default)
        body: null, // popup body; use {bodyHtml} expression where 'bodyHtml' contents should be neseted (only if required)
        bodyHtml: null, // popup text/html code append to existing body; usable for some template types (ex.wait) - will replace 'bodyHtml' markup
        buttons: null, // popup bottom buttons; see below sample syntax
        /*
         buttons: {
         popup_confirm_ok: { // this will be used as button id attribute
         label: 'Yes, close it',
         default: true,
         close: false, // don't close on button hit
         callback: function(e, $popup) { 
         // ... do something ... 
         // and close popup
         popup.close(); 
         }
         }
         }
         ...
         */
        // contentnt entered by ajax
        ajax: {
            url: null, // url of ajax request; request result should be html code
            $target: null, // jQuery where to put html contents
            onLoaded: function() {   // callback executed once ajax contents has been read

            }
        },
        //
        // callbacks
        //
        beforeOpen: function() {
            return true;
        },
        onOpen: function(cfg) {
        },
        beforeClose: function() {
            return true;
        },
        onClose: function() {
        },
        txt: {
            close: 'close',
            loading: 'loading'
        },
        // close button icon 
        //icoClose: '#ico-close',
        icons: {
            close: '<svg viewBox="0 0 25 32"><path d="M23.179 23.607q0 0.714-0.5 1.214l-2.429 2.429q-0.5 0.5-1.214 0.5t-1.214-0.5l-5.25-5.25-5.25 5.25q-0.5 0.5-1.214 0.5t-1.214-0.5l-2.429-2.429q-0.5-0.5-0.5-1.214t0.5-1.214l5.25-5.25-5.25-5.25q-0.5-0.5-0.5-1.214t0.5-1.214l2.429-2.429q0.5-0.5 1.214-0.5t1.214 0.5l5.25 5.25 5.25-5.25q0.5-0.5 1.214-0.5t1.214 0.5l2.429 2.429q0.5 0.5 0.5 1.214t-0.5 1.214l-5.25 5.25 5.25 5.25q0.5 0.5 0.5 1.214z" /></svg>',
            info: '<svg viewBox="0 0 27 32"><path d="M18.286 24.571v-2.857q0-0.25-0.161-0.411t-0.411-0.161h-1.714v-9.143q0-0.25-0.161-0.411t-0.411-0.161h-5.714q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h1.714v5.714h-1.714q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h8q0.25 0 0.411-0.161t0.161-0.411zM16 8.571v-2.857q0-0.25-0.161-0.411t-0.411-0.161h-3.429q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h3.429q0.25 0 0.411-0.161t0.161-0.411zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" /></svg>',
            confirm: '<svg viewBox="0 0 32 32"><path d="M18.286 24.554v-3.393q0-0.25-0.17-0.42t-0.402-0.17h-3.429q-0.232 0-0.402 0.17t-0.17 0.42v3.393q0 0.25 0.17 0.42t0.402 0.17h3.429q0.232 0 0.402-0.17t0.17-0.42zM18.25 17.875l0.321-8.196q0-0.214-0.179-0.339-0.232-0.196-0.429-0.196h-3.929q-0.196 0-0.429 0.196-0.179 0.125-0.179 0.375l0.304 8.161q0 0.179 0.179 0.295t0.429 0.116h3.304q0.25 0 0.42-0.116t0.188-0.295zM18 1.196l13.714 25.143q0.625 1.125-0.036 2.25-0.304 0.518-0.83 0.821t-1.134 0.304h-27.429q-0.607 0-1.134-0.304t-0.83-0.821q-0.661-1.125-0.036-2.25l13.714-25.143q0.304-0.554 0.839-0.875t1.161-0.321 1.161 0.321 0.839 0.875z" /></svg>',
            success: '<svg viewBox="0 0 27 32"><path d="M22.929 13.107q0-0.5-0.321-0.821l-1.625-1.607q-0.339-0.339-0.804-0.339t-0.804 0.339l-7.286 7.268-4.036-4.036q-0.339-0.339-0.804-0.339t-0.804 0.339l-1.625 1.607q-0.321 0.321-0.321 0.821 0 0.482 0.321 0.804l6.464 6.464q0.339 0.339 0.804 0.339 0.482 0 0.821-0.339l9.696-9.696q0.321-0.321 0.321-0.804zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" /></svg>',
            failed: '<svg viewBox="0 0 27 32"><path d="M13.714 2.286q3.732 0 6.884 1.839t4.991 4.991 1.839 6.884-1.839 6.884-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839zM16 24.554v-3.393q0-0.25-0.161-0.42t-0.393-0.17h-3.429q-0.232 0-0.411 0.179t-0.179 0.411v3.393q0 0.232 0.179 0.411t0.411 0.179h3.429q0.232 0 0.393-0.17t0.161-0.42zM15.964 18.411l0.321-11.089q0-0.214-0.179-0.321-0.179-0.143-0.429-0.143h-3.929q-0.25 0-0.429 0.143-0.179 0.107-0.179 0.321l0.304 11.089q0 0.179 0.179 0.313t0.429 0.134h3.304q0.25 0 0.42-0.134t0.188-0.313z" /></svg>',
            hourglass: '<svg viewBox="0 0 32 32"><path d="M24.96 7.325c0-3.424 0-3.758 0-3.758 0-1.264-4.011-3.566-8.96-3.566s-8.96 2.302-8.96 3.566c0 0 0 0.334 0 3.758 0 3.424 6.203 6.251 6.203 8.675 0 2.426-6.203 5.251-6.203 8.677 0 3.424 0 3.758 0 3.758 0 1.262 4.011 3.565 8.96 3.565s8.96-2.302 8.96-3.566c0 0 0-0.334 0-3.758 0-3.426-6.202-6.251-6.202-8.677 0-2.422 6.202-5.251 6.202-8.674zM9.504 3.726c1.114-0.702 3.202-1.733 6.582-1.733 3.382 0 6.41 1.733 6.41 1.733 0.227 0.138 1.117 0.614 0.507 0.974-1.342 0.795-3.966 1.632-7.005 1.632s-5.573-0.923-6.915-1.718c-0.61-0.362 0.421-0.888 0.421-0.888zM16.802 16c0 1.909 1.594 3.138 3.283 4.778 1.234 1.198 2.92 2.838 2.92 3.899v2.125c-1.55-0.773-6.194-1.53-6.194-4.006 0-1.254-1.622-1.254-1.622 0 0 2.477-4.643 3.234-6.194 4.006v-2.125c0-1.059 1.688-2.701 2.922-3.899 1.688-1.64 3.282-2.869 3.282-4.778 0-1.909-1.594-3.138-3.282-4.778-1.234-1.198-2.922-2.84-2.922-3.898l-0.075-1.6c1.642 0.883 4.245 1.726 7.080 1.726s5.451-0.843 7.093-1.726l-0.088 1.6c0 1.058-1.688 2.699-2.92 3.898-1.69 1.64-3.283 2.869-3.283 4.778z" /></svg>'
        },
        // internal use
        _onOpen: function(cfg) {
            //_animSizeBefore(cfg)
        },
        //_transEndEvent: "transitionend.mtsoftUiAnim webkitTransitionEnd.mtsoftUiAnim oTransitionEnd.mtsoftUiAnim MSTransitionEnd.mtsoftUiAnim",
        _state: 'default',
        _stateStyles: {},
        $scrolledElement: null,
        _timeout: null,
        styles: {}  // inital styles
    };
    $.fn.mtsoftUiPopup.def = {};

    //
    // popup types
    //
    $.fn.mtsoftUiPopup.types = {
        confirm: {
            theme: 'default question',
            icon: 'confirm',
            title: 'Please confirm',
            buttons: {
                popup_confirm_cancel: {
                    label: 'No',
                    callback: function(e, popup) {
                    }
                },
                popup_confirm_ok: {
                    label: 'Yes',
                    default: true,
                    callback: function(e, popup) {
                    }
                }
            }
        },
        inform: {
            theme: 'default info',
            icon: 'info',
            title: 'Information',
            buttons: {
                popup_confirm_ok: {
                    label: 'Close',
                    default: true,
                    callback: function(e, popup) {
                    }
                }
            }
        },
        wait: {
            theme: 'default wait',
            icon: 'hourglass',
            title: 'Please wait ...',
            closeBtn: false,
            closeOnEsc: false,
            maxWidth: 280,
            body: $.icoWait({}, true) + '<br><br><br>{bodyHtml}'
        },
        waitCancelable: {
            theme: 'default wait',
            icon: 'hourglass',
            title: 'Please wait ...',
            closeBtn: false,
            closeOnEsc: false,
            maxWidth: 280,
            body: $.icoWait({}, true) + '<br><br><br>{bodyHtml}',
            buttons: {
                popup_wait_cancel: {
                    label: 'Cancel',
                    close: false,
                    callback: function(e, popup) {
                        popup.hide();
                    }
                }
            }
        }
    };




    // -------------------------------------------------------------------------
    //
    // Public actions 
    // 
    var actions = {
        /**
         * Get / set plugin configuration. 
         *
         * @param {Mixed} param     Configuration parameter name
         *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
         *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
         *                                    All other then given in JOSN argument parameters will be keep untouch;
         *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
         *                           null - resets current configuration (remove all parameters)
         *
         * @param {Mixed} val       configuration parameter value 
         *
         * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
         *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
         */
        config: function(param, val) { 

            var c = 'config.mtsoftUiPopup',  
                    $this = $(this), _cfg = $this.data(c);
            // reset current configuration
            if (param === null) {
                $this.data(c, null);
                return _cfg;
            }

            if (val !== undefined || typeof (param) === 'object') { // setter

                // update single parameter value
                if (val !== undefined) { // single parameter value

                    _cfg[param] = val; // assign value
                    $this.data(c, _cfg); // update

                } else {    // multiple parameters values defined as JSON object {}

                    // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                    //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                    _cfg = $.extend(true, {}, _cfg, param);
                    $this.data(c, _cfg); // update
                }
                return _cfg; 
            } else { // getter

                return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
            }  
        },
        /**
         * Init popup 
         * 
         * @param {type} params 
         * @returns {undefined} 
         */
        init: function(params) {

            var $this = $(this);
            //
            // initial configuration
            //
            var type = params && params.type ? params.type : $this.data('type') ? $this.data('type') : {};

            cfg = $.extend(true, {}, $.fn.mtsoftUiPopup.defaults, {$this: $this}, $this.data(),
                    $.fn.mtsoftUiPopup.types[type], // type of popup is defined
                    $.fn.mtsoftUiPopup.def[$this.attr('id')],
                    params);  // cfg is PLUGIN GLOBAL

            // apply theme
            cfg.$this.removeClass('info success caution failed question wait').addClass(cfg.cssPrefix + cfg.theme);

            // to work properly draggable/resizable behaviours - popup node MUST BE child of body
            cfg.$parent.prepend(cfg.$this); // make popup first child of given popup parent element 

            // if html, title, content or buttons given 
            _build(cfg);

            // remember initial styles
            cfg.styles = {
                position: 'fixed',
                minWidth: cfg.minWidth,
                maxWidth: cfg.maxWidth,
                left: cfg.posLeft,
                top: cfg.posTop,
                bottom: 'auto',
                right: 'auto',
                width: '',
                height: '',
                marginRight: cfg.margin, marginBottom: cfg.margin
            };

            //
            // Build popup elements if defined
            //

            // draggable/resizable area is limited to body, so to cover full viewport size
            // body needs to be 100% width & height
            $('html, body').css({width: '100%', height: '100%'});
            cfg = _attachEventHandlers(cfg);    // attach base event handlers
            cfg = _draggable(cfg); // make draggable
            cfg = _resizable(cfg);  // make resizable
            cfg.$this.attr('data-popup', true);

            //
            // get element to scroll when popup contents don't fit
            //
            if (cfg.scrollable) {

                if (cfg.scrollable === true) { 

                    // scroll popup content or body (if title) 
                    var $body = cfg.$this.find('.' + cfg.cssPrefix + 'body');
                    if ($body.length) {
                        cfg.$scrolledElement = $body;
                    } else {
                        cfg.$scrolledElement = cfg.$this.find('.' + cfg.cssPrefix + 'content');
                    }
                } else {

                    // scroll given element inside popup 
                    cfg.$scrolledElement = $(cfg.scrollable);
                }
            }

        },
        /**
         * Show popup 
         * 
         * @param {Mixed} params    data parameters for opening popup 
         * @param {type} e
         * @returns {undefined}
         */
        open: function(params, e) {

            if (cfg.beforeOpen(params)) { // cna be used to popuplate just opening popup form

                            
                // reset in case when popup contents are acrollable 
                if (cfg.$scrolledElement) {
                    cfg.$scrolledElement.css({height: 'auto', overflowY: 'visible'});                    
                }


                // is there ajax url?
                if (cfg.ajax.url) {

                    actions.load.apply(this);
                }

                // modal?
                if (cfg.modal) {

                    var ov = $('.' + cfg.cssPrefix + 'overlay');
                    if (!ov.length) {

                        ov = $('<div class="' + cfg.cssPrefix + 'overlay"></div>').appendTo('body');

                        // make visible
                        ov.css({visibility: 'visible'});

                        // do overlay transition
                        ov.mtsoftUiAnim().on({name: 'overlay-on', restore: true});
                    }
                }

                cfg._state = 'default';

                // show
                cfg.$this.show();
                actions.normalize.apply(this);

                if (cfg.maxSizeMode) {  // max fit viewport size

                    // .wrap('<div></div>').css({}).parent()
                    cfg.$this.css({
                        position: 'fixed',
                        left: 0, top: 0, right: 0, bottom: 0,
                        maxWidth: 'none',
                        width: 'auto',
                        zIndex: 2000,
                        margin: cfg.margin});
                }

                // focus default button (if any)
                cfg.$this.find('[data-btn-default]').focus();

                // do transition
                cfg.$this.mtsoftUiAnim().on({name: cfg.animOpen, restore: true}, function() {

                    cfg.onOpen(cfg);
                    cfg._onOpen(cfg); // internal 
                });

                cfg.$this.data('_opened', true);

                return true;
            } else {

                return false;
            }
        },
        /**
         * Hide popup
         * 
         * @param {type} params
         * @param {type} e
         * @returns {undefined}
         */
        hide: function(params, e) {

            if (cfg.beforeClose()) { // execute callback

                // do transition
                cfg.$this.mtsoftUiAnim().off(function() {

                    cfg.$this.hide();
                    window.clearTimeout(cfg._timeout);

                    if (cfg.$this.attr('data-dynamic')) { // this is popup generated via JS and should be removed

                        cfg.$this.remove();
                    }

                    cfg.onClose(params);
                });

                if (cfg.modal && cfg.modalHideOnClose) { // use modal

                    $('.' + cfg.cssPrefix + 'overlay').mtsoftUiAnim().off(function() {

                        //$(this).css({visibility: 'hidden'});
                        $(this).remove();
                    });
                }
                
                return true;
            } else {

                return false;
            }
        },
        close: function(params, e) {
            return  actions.hide.apply(this, [params, e]);
        },
        remove: function() {
            cfg.$this.remove();
        },
        /**
         * Load new content to popup (or given target element in popup) using ajax request 
         * 
         * @param {String} url          ajax request url
         * @param {jQUery} $target      target element where new content will be loaded
         * @param {function} callabck   callback exectued after content loaded
         */
        load: function(url, $target, callabck) {
            
            // read popup contents from server via ajax request
            var url = url || cfg.ajax.url, // ajax request url
                    // where to load
                    $target = $target ? $target : (cfg.ajax.$target ? cfg.ajax.$target : cfg.$this.find('.' + cfg.cssPrefix + 'body').length ? cfg.$this.find('.' + cfg.cssPrefix + 'body') : cfg.$this.find('.' + cfg.cssPrefix + 'content')),
                    callback = callback || cfg.ajax.onLoaded || function(r) {
                    // on content loaded callback

                        actions.normalize.call(this, true);

                        // do re-size transition
                        //_animSizeDo(cfg);
                    },
                    // laoding html code/style
                    loading = '<div class="ajax-loading">' +
                    //$.mtsoft.ui.icon('#ico-wait', 'ico-wait', {}, true) +
                    $.icoWait({}, true) +
                    (cfg.txt.loading ? '<br><br>' + cfg.txt.loading : '') + '</div>';
            
            // style popup while loading and send ajax request for new content
            $target.html(loading).load(cfg.ajax.url, callback);

        },
        /**
         * Update popup content (or given target element in popup) 
         * 
         * @param {String} html     html code
         * @param {jQUery} $target  target element which contents should be updated
         */
        update: function(html, $target) {

            var $target = $target ? $target : cfg.$this.find('.' + cfg.cssPrefix + 'body').length ? cfg.$this.find('.' + cfg.cssPrefix + 'body') : cfg.$this.find('.' + cfg.cssPrefix + 'content');
            $target.html('').html(html);
        },
        /**
         * Update popup title
         * @param {String} title
         */
        title: function(title) {
            cfg.$this.find('.' + cfg.cssPrefix + 'title > h1 > span').html(title);
        },
        type: function(type) {
            cfg.$this.removeClass('info success caution failed question wait').addClass(type);
            actions.icon.apply(this, [cfg.icons[type]]);
        },
        /**
         * Update popup icon
         * @param {String} icon
         */
        icon: function(icon) {

            var h1 = cfg.$this.find('.' + cfg.cssPrefix + 'title > h1');
            if (h1.children('i.ico').length) {
                h1.children('i.ico').replaceWith($.mtsoft.ui.icon(icon));
            } else {
                h1.children(':first').before($.mtsoft.ui.icon(icon));
            }
            //h1.children().filter(':first').before('<i></i>');
            //h1.children('i').addClass(icon);
        },
        /**
         * Update popup body
         * @param {String} body HTML
         */
        body: function(body) {
            cfg.$this.find('.' + cfg.cssPrefix + 'body').html(body);
        },
        /**
         * Update popup buttons
         * @param {JSON} buttons definitions
         */
        buttons: function(buttons) {
            cfg.$this.find('.' + cfg.cssPrefix + 'buttons > div').html('');
            cfg.buttons = buttons;
            _buildButtons(cfg);
            cfg.$this.find('[data-close]').off('.mtsoftUiPopup').on('click.mtsoftUiPopup', function(e) {

                _getPopup($(this)).mtsoftUiPopup().hide();
            });
        },
        /**
         * Set popup in given "window" state.
         * @param {String} state    set popup in given state [ default | minimize | maximize | roll ]
         */
        setState: function(state) {

            if (state) {    // target popup stae given directly

                actions[state].apply(cfg.$this);

            } else {    // auto-detect what state should be 

                if (cfg._state !== 'default') {

                    // restore default state
                    actions.restorePrevState.apply(cfg.$this);
                } else {

                    actions[cfg.onTitleDbClick].call(cfg.$this);
                }
            }
        },
        /**
         * Maximize popup; don't force 100% height if not enought contents
         * @returns {undefined}
         */
        maximize: function() {

            actions.saveCurrState.call(cfg.$this);
            cfg.$this.css({
                position: 'absolute',
                left: 0, top: 0,
                margin: cfg.margin,
                width: $(window).width() - 2 * cfg.margin,
                height: !cfg.freeResize ? 'auto' : $(window).height() - 2 * cfg.margin,
                bottom: 'auto',
                maxWidth: 'none'
            });
            cfg._state = 'maximized';
        },
        minimize: function() {

            actions.saveCurrState.call(cfg.$this);
            cfg._stateStyles.left = null;
            cfg._stateStyles.top = null;
            cfg._stateStyles.width = null;
            cfg._stateStyles.height = null;
            cfg._stateStyles.bottom = null;
            //cfg.$this.height(cfg.$this.children('.' + cfg.cssPrefix + 'content').children('.' + cfg.cssPrefix + 'header').outerHeight());
            cfg.$this.children('.' + cfg.cssPrefix + 'content').children().not('.' + cfg.cssPrefix + 'header').hide();
            cfg.$this.css({height: '', bottom: 'auto'});
            cfg._state = 'minimized';
        },
        saveCurrState: function() {

            cfg._stateStyles = {
                position: cfg.$this.css('position'),
                left: cfg.$this.css('left'),
                top: cfg.$this.css('top'),
                right: cfg.$this.css('right'),
                bottom: cfg.$this.css('bottom'),
                marginLeft: cfg.$this.css('marginLeft'),
                marginTop: cfg.$this.css('marginTop'),
                marginRight: cfg.$this.css('marginRight'),
                marginBottom: cfg.$this.css('marginBottom'),
                width: cfg.$this.css('width'),
                height: cfg.$this.css('height'),
                maxWidth: cfg.$this.css('maxWidth')
            };

        },
        /**
         * Restore state before maximized or minimized
         * @returns {undefined}
         */
        restorePrevState: function() {

            //cfg.$this.children('.' + cfg.cssPrefix + 'content').children('.' + cfg.cssPrefix + 'header').css({height: 'auto'});
            cfg.$this.children('.' + cfg.cssPrefix + 'content').children().not('.' + cfg.cssPrefix + 'header').show();
            cfg.$this.css(cfg._stateStyles);

            // in case popup is minimized and after restored popup don't fit (down edge below viewport)
            if (cfg.$this.offset().top + cfg.$this.outerHeight() > $(window).height() - 2 * cfg.margin) {
                cfg.$this.css({top: $(window).height() - cfg.margin - cfg.$this.outerHeight()});
            }

            // when minimized and recent height is fixed set auto instead
            if (cfg._state === 'minimized') {
                cfg.$this.css({height: 'auto'});
            }

            cfg._state = 'default';
        },
        /**
         * Normalize popup;
         * - restore default styles (as on first open)
         * - center popup (only if single popup shown)
         * - fit on current viewport size
         * - if popup with contents don't fit in viewport - make it scrollable (if enabled) 
         * 
         * @param {Bool} animate
         */
        normalize: function(animate) {

            if (!cfg.maxSizeMode) {

                if (cfg.resetOnOpen || !cfg.$this.data('_opened')) {

                    // resore default styles (resets size)
                    actions.restore.apply(this);


                    // do transition
                    /*if (animate) {
                     
                     cfg.$this.addClass(cfg.cssPrefix + 'trans-resize');
                     cfg.$this.on(cfg._transEndEvent, function() {
                     console.log('transEND!');
                     cfg.$this.removeClass(cfg.cssPrefix + 'trans-resize');
                     });
                     }*/

                    // center
                    actions.center.apply(this);

                    // fit
                    actions.fit.apply(this);
                }
            }
        },
        /**
         * Re-position popup on viewport center
         * Use 'margin-left' and 'margin-top' to set right position.
         * 
         * @returns {undefined}
         */
        center: function() {
                        
            var mL = -cfg.$this.outerWidth() / 2, mT = -cfg.$this.outerHeight() / 2;
            cfg.$this.css({
                left: cfg.posLeft, top: cfg.posTop, bottom: 'auto', right: 'auto',
                marginLeft: mL,
                marginTop: mT,
                marginRight: cfg.margin,
                marginBottom: cfg.margin
            });

            // Popup should be centere vertically:
            // if popup top margin is other than 50% and:
            // - margin(top) is lower than top (popup reached viewport top
            // OR
            // - popup height is bigger than 50% of viewport height
            // --> set 50%
            if (cfg.centerVertically || (cfg.$this.offset().top < cfg.margin ||
                    cfg.$this.outerHeight() > $(window).height() * .5)) {

                cfg.$this.css({top: '50%'});
            }

            // remember margins
            if (!cfg.styles.marginLeft) {

                cfg.styles.marginLeft = cfg.$this.css('marginLeft');
                cfg.styles.marginTop = cfg.$this.css('marginTop');
            }
        },
        /**
         * Re-position and/or Re-size popup to fit on viewport
         * @returns {undefined}
         */
        fit: function() {
            
            if (cfg.scrollable) {
                cfg.$scrolledElement.css({
                        overflowY: 'hidden',
                        height: 'auto'
                    });
            }

            var vW = $(window).width(),
                    vH = $(window).height(),
                    pp = cfg.$this.offset(),
                    //pL = pp.left,
                    pT = pp.top, // + parseInt(cfg.$this.css('marginTop')),
                    pW = cfg.$this.outerWidth(),
                    pH = cfg.$this.outerHeight();
            
            //
            // check it fits vertically
            //
            if (vH < pH + 2 * cfg.margin) {

                //
                // popup DON'T fit - is longer than viewport height
                //
                if (cfg.scrollable) {


                    // make popup content or body scrollable
                    cfg.$this.css({                        
                        top: 0,
                        //height: cfg.recentHeight,
                        bottom: 0,
                        marginTop: cfg.margin,
                        marginBottom: cfg.margin
                    });

                   // calculate right popup scrollable element height to fit inside current viewport 
                    cfg.$scrolledElement.css({
                        overflowY: 'scroll',
                        height: cfg.$scrolledElement.parent().height() - cfg.$scrolledElement.siblings().not(cfg.$scrolledElement).outerHeight(true)
                    }); // height: '200px', 

                    /*if (cfg.recentHeight) { // previously measured height which fit 
                        console.log(cfg.$scrolledElement.height()+' - '+cfg.recentHeight);
                        cfg.$scrolledElement.css({height: cfg.recentHeight, overflowY: 'scroll'});                    
                    } else { // no previous height that fit


                    }*/

                   
                } else {
                    
                    // Position absolutely and make popup scrollable with browser 
                    // scrollbars commonly with page contents
                    cfg.$this.css({
                        position: 'absolute',
                        top: 0,
                        //bottom: 0,
                        marginTop: cfg.margin,
                        marginBottom: cfg.margin
                    });

                    if (cfg.draggable) {
                        cfg.$this.draggable("option", "disabled", true);
                    }

                    $(window).scrollTop(0);
                }

            } else {

                //
                // popup FIT - restore fixed position
                //
                cfg.$this.css({
                    position: 'fixed'
                });

                if (cfg.draggable) {
                    cfg.$this.draggable("option", "disabled", false);
                }
                
                if (cfg.$scrolledElement) {
                      
                    cfg.$scrolledElement.css({
                        overflowY: 'hidden',
                        height: 'auto'
                    });
                    // rememeber recent popup height. When popup don't fit on viewport
                    // and popup should be scrollable in this case we need to restore this recent height            
                    //cfg.recentHeight = cfg.$scrolledElement.height();  
                }
            }
            
            //cfg.$scrolledElement.scroll(); // remove space after scroll-bars

            //
            // check if fits horizontally
            //
            if (vW < pW + 2 * cfg.margin) {

                cfg.$this.css({width: 'auto', height: 'auto', maxWidth: cfg.styles.maxWidth});
            }
        },
        restore: function() {
            //restore intial css styles
            cfg.$this.css(cfg.styles);
            // in case popup was minimized previously
            cfg.$this.children('.' + cfg.cssPrefix + 'content').children().not('.' + cfg.cssPrefix + 'header').show();
        }
    };

    /**
     * Build elments of popup
     * 
     * @param {JSON} cfg
     */
    function _build(cfg) {

        if (cfg.html) { // build whole popup content

            cfg.$this.find('.' + cfg.cssPrefix + 'content').append(cfg.html);

        } else {    // build particular elements

            var btnClose = cfg.$this.find('.' + cfg.cssPrefix + 'btn-close');

            // title
            if (cfg.title) {

                if (!cfg.$this.find('.' + cfg.cssPrefix + 'header').length) {
                    
                    cfg.$this.find('.' + cfg.cssPrefix + 'content')
                            .append('<div class="' + cfg.cssPrefix + 'header">' +
                                    '<div class="' + cfg.cssPrefix + 'title"><h1>' + (cfg.icon ? $.mtsoft.ui.icon(cfg.icons[cfg.icon] ? cfg.icons[cfg.icon] : cfg.icon, {}, true) : '') + '<span>' + cfg.title + '</span></h1></div>' +
                                    (cfg.closeBtn ? '<div class="' + cfg.cssPrefix + 'head-buttons"><div class="' + cfg.cssPrefix + 'btn ' + cfg.cssPrefix + 'btn-close" title="' + cfg.txt.close + '" data-close="true">' + $.mtsoft.ui.icon(cfg.icons.close, {}, true) + '</div></div>' : '') +
                                    '<div class="clearfix"></div>' +
                                    '</div>');
                }
            }

            if (!cfg.closeBtn) { // there should be not close button - remove it if exists

                btnClose.remove();
            } else {

                if (!cfg.title && !btnClose.length) {
                    // build top close button (not in title)
                    cfg.$this.append('<div class="' + cfg.cssPrefix + 'head-buttons ' + cfg.cssPrefix + 'abs ' + cfg.cssPrefix + 'light"><div title="' + cfg.txt.close + '" class="' + cfg.cssPrefix + 'btn ' + cfg.cssPrefix + 'btn-close" data-close="true">' + $.mtsoft.ui.icon(cfg.icons.close, {}, true) + '</div></div>');
                }
            }

            // body
            if (cfg.body || cfg.title) {    // if title - create body too

                if (!cfg.$this.find('.' + cfg.cssPrefix + 'body').length) {

                    cfg.$this.find('.' + cfg.cssPrefix + 'content')
                            .append('<div class="' + cfg.cssPrefix + 'body">' +
                                    (cfg.body ? cfg.body.replace('{bodyHtml}', cfg.bodyHtml) : '') +
                                    '</div>');
                }

            }

            // buttons
            if (cfg.buttons) {
                _buildButtons(cfg);
            }
        }
    }

    /**
     * Build bottom buttons panel and buttons
     * 
     * @param {JSON} cfg
     * @returns {undefined}
     */
    function _buildButtons(cfg) {

        if (!cfg.$this.find('.' + cfg.cssPrefix + 'buttons').length) {

            cfg.$this.find('.' + cfg.cssPrefix + 'content')
                    .append('<div class="' + cfg.cssPrefix + 'buttons"><div></div></div>');
        }
        var btnsOut = cfg.$this.find('.' + cfg.cssPrefix + 'buttons > div');

        // particular buttons           
        for (var id in cfg.buttons) {

            btnsOut.append('<button id="' + id + '"' +
                    (cfg.buttons[id].default ? ' data-btn-default' : '') + // is button default?
                    (cfg.buttons[id].close === undefined || cfg.buttons[id].close ? ' data-close' : '') + // button use closes popup
                    ' class="' + (cfg.buttons[id].default ? cfg.cssPrefix + 'btn-default' : '') // default button style
                    + '">' + cfg.buttons[id].label + '</button>');  // button label

            // is there any callback
            if (cfg.buttons[id].callback) {

                $('#' + id).on('click.mtsoftUiPopup', function(e) {

                    cfg.buttons[id].callback(e, _getPopup($(this)).mtsoftUiPopup());    // use popup node as second parameters
                });
            }
        }

    }

    /**
     * Attach event handlers  
     * 
     * @param {JSON} cfg
     */
    function _attachEventHandlers(cfg) {

        // CLOSE on:
        // default popup close button
        cfg.$this.find('.' + cfg.cssPrefix + 'btn-close').attr('data-close', true);
        // any element with data-close set
        cfg.$this.find('[data-close]').off('.mtsoftUiPopup').on('click.mtsoftUiPopup', function(e) {

            _getPopup($(this)).mtsoftUiPopup().hide();
        });

        //
        // on window resize
        //
        $(window).off('.mtsoftUiPopup').on('resize.mtsoftUiPopup', function(e) {

            var $popups = $("." + cfg.cssPrefix + "popup").filter(':visible');

            if ($popups.length === 1 && // center / fit only if ONLY SINGLE popup shown
                    !cfg.maxSizeMode) {     // and not max size mode

                $popups.each(function() {

                    var $this = $(this);
                    /*if (cfg.resizable) {
                     
                     $this.resizable("option", "maxWidth", $(window).width() - 2 * cfg.margin).
                     resizable("option", "maxHeight", $(window).height() - 2 * cfg.margin);
                     
                     }*/

                    // center popup and fit on viewport
                    if (!window._popupResizing) {

                        // if window state is other than default - restore default state
                        if (cfg._state !== 'default') {

                            actions.restorePrevState.apply(cfg.$this);
                        }

                        //actions.restore.apply($this, [e]);
                        actions.center.apply($this, [e]);
                        actions.fit.apply($this, [e]);
                    }
                });
            }
        });

        //
        // close popup on ESC key hit
        //
        if (cfg.closeOnEsc && !window._popupKeyUp) {

            $(document).off('.mtsoftUiPopup').on('keyup.mtsoftUiPopup', function(e) {

                if (e.keyCode === 27) {

                    $("." + cfg.cssPrefix + "popup").filter(':visible').each(function() {
                        actions.hide.apply($(this), [e]);
                    });
                }
            });
            window._popupKeyUp = true;
        }

        //
        // close on overlay click
        //
        if (cfg.closeOnOverlayClick) {

            $('.' + cfg.cssPrefix + 'overlay').on('click.mtsotUiPopup', function(e) {

                $("." + cfg.cssPrefix + "popup").filter(':visible').each(function() {
                    actions.hide.apply($(this), [e]);
                });
            });
        }

        //
        // title bar double click
        //
        if (cfg.onTitleDbClick !== 'none') {

            var $titlebar = cfg.$this.children().children('.' + cfg.cssPrefix + 'header');
            $titlebar.off('dblclick.mtsoftUiPopup').on('dblclick.mtsoftUiPopup', function() {

                _getPopup($(this)).mtsoftUiPopup().setState();
            });
        }

        //
        // timeout?
        //
        if (cfg.timeout) {

            if (cfg.timeoutProgressbar) { // show timeout progress-bar

                if (!cfg.$this.find('.' + cfg.cssPrefix + 'progress').length) { // create progres-bar if don't exists
                    cfg.$this.append('<div class="' + cfg.cssPrefix + 'progress"><div  class="' + cfg.cssPrefix + 'bar"></div></div>');
                }

                cfg.$this.find('.' + cfg.cssPrefix + 'bar').stop().css({width: '0%'}).animate({width: '100%'},
                {duration: cfg.timeout * 1000, easing: "linear"});
            }

            cfg._timeout = window.setTimeout(function() {
                actions.hide.apply($this);
            }, cfg.timeout * 1000);

        } else {

            cfg.$this.find('.' + cfg.cssPrefix + 'progress').remove();
        }

        return cfg;
    }
    /**
     * Make popup draggable
     * 
     * @param {type} cfg
     */
    function _draggable(cfg) {

        // popup draggable?
        if (cfg.draggable) {

            try {

                var $handle = cfg.$handle || cfg.$this.children().children('.' + cfg.cssPrefix + 'header');
                // Below is use to limit draggable area to body only (due left and top margins are negatives)
                /*$handle.off('mousedown.mtsoftUiPopup').on('mousedown.mtsoftUiPopup', function() {
                 
                 var $this = _getPopup($(this));
                 //var $this = $(this);
                 $this.css({
                 //top: $this.offset().top - $(window).scrollTop() - cfg.margin,
                 //left: $this.offset().left - $(window).scrollLeft() - cfg.margin,
                 top: $this.position().top - $(window).scrollTop() - cfg.margin,
                 left: $this.position().left - $(window).scrollLeft() - cfg.margin,
                 marginLeft: cfg.margin,
                 marginTop: cfg.margin
                 });
                 
                 });*/

                // use JQueryUI draggable
                cfg.$this.draggable({
                    cursor: 'move',
                    handle: $handle,
                    containment: "body",
                    stack: '.' + cfg.cssPrefix + 'popup:visible'
                            //,snap: "body"
                            //cursorAt: {top: -$this.outerHeight()/2, left: -$this.outerWidth()/2},
                            //cursorAt: {top: 0, left: 0},
                            /*start: function(event, ui) {
                             
                             //$this.css({width: $this.outerWidth(), height: $this.outerHeight()});
                             },
                             stop: function(event, ui) {
                             
                             }*/
                });

                $handle.css({cursor: 'move'});

            } catch (e) {
            }
        }

        return cfg;
    }
    /**
     * Make popup resizable.
     * @TODO:
     * Fix bug - on Chrome only:
     * When draggable & resizable and srcoll Top offset is greateher than 0
     * while popup dragging starts top position offset drops down (+ scorllTop offset value)
     * 
     * @param {type} cfg
     */
    function _resizable(cfg) {

        // popup resizable?
        if (cfg.resizable) {

            try {
                cfg.$this.resizable({
                    containment: "body",
                    create: function(event, ui) {

                        // use JQueryUI resizable                    
                        cfg.$this.children('.ui-resizable-handle').off('.mtsoftUiPopup').on('mousedown.mtsoftUiPopup', function() {

                            // don't use max-width/height from css stylesheet                            
                            var $this = _getPopup($(this));
                            $this.css({
                                position: 'absolute', // jQUeryUI.resizable sets element absolute, so we do it here too
                                maxWidth: 'none',
                                //width: $this.outerWidth(),
                                //height: $this.outerHeight(),
                                width: $this.css('width'),
                                height: $this.css('height'),
                                //left: $this.position().left, // must be set fixed, otherwise handle in wrong position 
                                //top: $this.position().top,
                                top: $this.offset().top - cfg.margin,
                                left: $this.offset().left - cfg.margin,
                                //marginLeft: 0, //cfg.margin,
                                //marginTop: 0 //cfg.margin
                                marginLeft: cfg.margin,
                                marginTop: cfg.margin
                            });
                        });

                    },
                    start: function(event, ui) {
                        window._popupResizing = true;
                    },
                    stop: function(event, ui) {
                        window._popupResizing = false;
                    },
                    resize: function(event, ui) {

                        if (!cfg.freeResize) {

                            //
                            // corrent popup width/height to keep popup consistency
                            //

                            // don't allow to resize to height lower than minimum height of popup contents
                            var $el = ui.originalElement, h = 0;
                            $el.children('.' + cfg.cssPrefix + 'content').children().filter(':visible').each(function() {
                                h += $(this).outerHeight(true);
                            });
                            cfg.$this.resizable("option", "minHeight", h + $('.ui-resizable-s').height()); // add resizable S direction handle height                            

                            // if resizing horizontal width - decreasing correct hieght to fit all contents                               
                            $el.height(h);
                        }
                    }
                });

            } catch (e) {
            }
        }
        return cfg;
    }

    /*
     function _animSizeBefore(cfg) {

        var _w = cfg.$this.width(),
                _h = cfg.$this.height();
     // read and remember size
     cfg.$this.data('_animSize', {
            width: _w,
            height: _h
            //width: cfg.$this.css('width'),
            //height: cfg.$this.css('height')
        });
        console.log(_w + ', ' + _h);
        //cfg.$this.width(_w).height(_h);
    }

    function _animSizeDo(cfg) {
        var s = cfg.$this.data('_animSize'),
                _w = cfg.$this.width(),
                _h = cfg.$this.height();
        console.log(_w + ', ' + _h);
        // restore orginal size
        cfg.$this.width(s.width).height(s.height);
        // animate to new size
        cfg.$this.animate({width: _w, height: _h}, 'slow');
    }
    */
    /*
     function _animSizeBegin(cfg) {
     
     var s = cfg.$this.data('_animSize'), // previous size
     currSize = {// current size
     width: cfg.$this.width(),
     height: cfg.$this.height()
     };
     
     // restore previous size
     cfg.$this.css(s);
     
     // set transition
     cfg.$this.addClass(cfg.cssPrefix + 'trans-resize');
     
     // set current size (runs transition)
     cfg.$this.css(currSize);
     
     // restore size before
     
     //cfg.$this.removeClass(cfg.cssPrefix + 'trans-resize');
     }
     function _animSizeEnd(cfg) {
     
     var s = cfg.$this.data('_animSize');
     
     // restore size before
     cfg.$this.removeClass(cfg.cssPrefix + 'trans-resize');
     }
     */
    /**
     * Get popup element for given child element
     * @param {type} $el
     * @returns {unresolved}
     */
    function _getPopup($el) {

        return $el.parents('[data-popup]').filter(':first');
    }

    // -------------------------------------------------------------------------
    //
    // apply plugin to default selector (optional)
    //
    /*
     $(function() {
     $("[popup]").mtsoftUiPopup();
     });
     */

})();


