/**
 * CSS animations helper plugin.
 * It allows easy use of css animations form JavaScript (jQuery) code.
 * 
 * (c) 2013 mtsoft.pl (Marek Trybus)
 * 
 * 
 * 
 * Animation(s) definition syntax:
 * -----------------------------------------------------------------------------
 * 
 * 
 *  Single animation definition:
 *        
 *  $(n).anims(a1 [, a2, ...]);
 *  $(n).anims(a1 [, a2, ...] [, callbackFunc]);
 *  $(n).anims({ p1: a1 [, p2: a2, ...] [, callback: callbackFunc] });
 *  $(n).anims({ p1: a1 [, p2: a2, ...] } [, callbackFunc]);
 *  
 *  
 *  Multiple animations definitions:
 *  
 *  $(n).anims([<def1AsArguments> [, <def2AsArguments>, ...] [, commonParameters] [, callbackFunc]]]);
 *  $(n).anims([[<def1AsArgumentsArray>] [, [<def2ArgumentsArray>], ...] [, commonParameters] [, callbackFunc]]]);
 *  $(n).anims([{<def1AsJSON>} [, {<def2AsJSON>}, ...] [, commonParameters] [, callbackFunc]]);
 *  
 *  
 *  Single or mulitple animations definitions as named set (playlist):
 *  
 *  $(n).anims('playlistName', [<definitions>] [, commonParameters]);
 *
 * 
 *  
 *    
 *  Steering:
 * -----------------------------------------------------------------------------
 * 
 *  $(n).anims().play();     // start all defined in default palylist animations for element
 *  $(n).anims().play('playlistName');     // start all defined in given set/playlist animations for element
 *  
 *  $(n).anims().stop();     // stop current animations queue (restore initial element state)
 *  $(n).anims().stop(true); // stop (and go to end animation finish state)
 *  
 *  $(n).anims().pause();    // pause animation
 *  
 *  $(n).anims().reset();    // remove all defined already sets (includeing default) of animations
 *  $(n).anims().clear();    // clear current animation classes (allows to fire new animation)
 *  
 *  
 *  Play SINGLE animation  (only one animation definition is allowed) 
 * -----------------------------------------------------------------------------  
 *  $(n).anims(animDef).play();  // do single animation (independent from defined set(s) of animations)
 *  
 *  
 *  Play single, two-states animation (like CSS transition)
 * -----------------------------------------------------------------------------  
 *  $(n).anims().on(animDef);    // plays given "forward"  animation
 *  
 *  $(n).anims().off();         //  plays given "forward" animation in reverse direction
 *  OR
 *  $(n).anims().off(animDef);  //  plays "back" animation (other than "forward")
 *  $(n).anims().off(CallbackFunc);  //  plays given "forward" animation in reverse direction and runs given callback
 *  
 *  ??? Add custom defined (with some values readvia JS) transition to element 
 * -----------------------------------------------------------------------------  
 *  $(n).anims.trans(transitionDefinition);
 *  
 */
;
(function() {

    var cfg = {};
    // -------------------------------------------------------------------------
    //
    // Plugin constructor
    //
    $.fn.mtsoftUiAnim = function(params) {
        
        var args = arguments;
        $.extend(this, actions);
        
        return this.each(function() {

            var $this = $(this);
            if (!$this.data('config.mtsoftUiAnim') || params) {
                
                //
                // Initialize and save configuration for current element
                //                 
                cfg = actions.config.call($this, actions.init.call($this, args));

                if (params) {
                    actions.define.apply($this, args);
                }

            } else {

                cfg = actions.config.apply($this);
            }            
        });
    };

    // -------------------------------------------------------------------------
    //
    // Public default settings
    //
    $.fn.mtsoftUiAnim.defaults = {
        //
        // animation default parameters
        //
        anim: {
            // default animation parameters - use css parameters
            name: null, // animation name; must be feind in css stylesheet
            duration: null, // [s] animation duration
            timing: null, // animation timing function [ linear | ease-in-out | ease-in | ease-in-out ... ]
            delay: null, // animation start delay
            count: null, // [ 1 ... n | infinite ] animation repaeat count - NOTE: infinite stops queue processing (next animations will not be proceed)
            direction: null, // animation direction [ normal | reverse | alternate | alternate-reverse ]
            restore: false, // if true restore orginal element state (before animation); otherwise elements will be in sate after animation
            visible: true, // if true animated element should be visible (visibility:'visible' is set)
            // callbacks
            callback: function(animationName, elapsedTime) { // callback is run after animation ended
                // this is animated object
            }, // if callback returns false - next in queue animations will not be exectued            
            _exec: function() {
                return true;    // MUST return true to run next animation(s) in queue
            } // internal use only - additional callback to execute on animation end
        },
        // internal use only
        _supported: _supported(), // flag; is there css animations supported by browser
        _prefixes: ["", "-moz-", "-o-animation-", "-webkit-"],
        _animEndEvent: "animationend.mtsoftUiAnim webkitAnimationEnd.mtsoftUiAnim oAnimationEnd.mtsoftUiAnim MSAnimationEnd.mtsoftUiAnim",
        _playlists: {default: []}, // all defined animations list
        _queue: [], // currently processing animations queue
        _anim: null, // currently/previously processed animation
        // ON/OFF type animation
        _styles: {display: null, visibility: null} // animated element initial display/visibility
    };

    // -------------------------------------------------------------------------
    //
    // Public actions
    //
    var actions = {
        
        /**
         * Get / set plugin configuration.
         *
         * @param {Mixed} param     Configuration parameter name
         *                           [String] - exisitng value will be overwritten with new given (for all types of parameter values)
         *                           [JSON] - parameters/values as JSON object, ex. {p1: v1, p2: v2, ...};
         *                                    All other then given in JOSN argument parameters will be keep untouch;
         *                                    In case when parameter value is object/array -  new values will extend exisitng values but NOT overwrite them!)
         *                           null - resets current configuration (remove all parameters)
         *
         * @param {Mixed} val       configuration parameter value
         *
         * @returns {Mixed}        [JSON] all configuration parameters as object (if no arguments or setting some parameter value)
         *                          [mixed] given configuration parameter value (if param argument is configuration parameter name)
         */
        config: function(param, val) {

            var c = 'config.mtsoftUiAnim',
                    $this = $(this), _cfg = $this.data(c);
            // reset current configuration
            if (param === null) {
                $this.data(c, null);
                return _cfg;
            }

            if (val !== undefined || typeof (param) === 'object') { // setter

                // update single parameter value
                if (val !== undefined) { // single parameter value

                    _cfg[param] = val; // assign value
                    $this.data(c, _cfg); // update

                } else {    // multiple parameters values defined as JSON object {}

                    // if parameter value is object or array - it will be EXTENDEND not OVERWRITEN!
                    //  (ex. for existing parameter value as some array adding [] as value will have no influence (will not zero array))
                    _cfg = $.extend(true, {}, _cfg, param);
                    $this.data(c, _cfg); // update
                }
                return _cfg;
            } else { // getter

                return param !== undefined ? _cfg[param] : _cfg; // return single parameter value or whole configuration object
            }
        },
        /**
         * Initialize
         * 
         * @param {Array}   custom arguments
         * 
         * @returns {JSON}  configuration object
         */
        init: function(args) {

            $this = $(this);
            
            //
            // initial configuration
            //
            var cfg;
            if (!$this.data('config.mtsoftUiAnim')) { // not initalized
                
                cfg = $.extend(true, {}, $.fn.mtsoftUiAnim.defaults, {$this: $this}, $this.data());  // cfg is PLUGIN GLOBAL
            } else { // already initialized - just add animation to "playlist"

                cfg = actions.config.apply($this);
            }

            return cfg;
        },
        /**
         * Define animation, set of default animations or named set of animations
         * 
         * @param {type} def
         * @returns {undefined}
         */
        define: function(def) {

            var anims = _definitions(arguments, cfg.anim);
            $.extend(cfg._playlists, anims); // add to animations definitions
            return cfg.$this;
        },
        /**
         * Start all defined animations in playlist
         * 
         * @param {String}  playlist    set of animations name (playlist);
         *                              if none given - use default
         * 
         * @returns {jQuery}
         */
        play: function(playlist) {

            playlist = playlist || 'default';

            // stop & clear all animations
            _reset();
            
            // re-init queue
            cfg._queue = $.extend([], cfg._playlists[playlist]);

            // get first from queue and process
            actions.do(cfg._queue.shift(), true);

            return cfg.$this;
        },
        /**
         * Stop animations queue processing
         * 
         * @param {Bool} gotoEnd    if true execute last animation and keep it's results 
         * 
         * @returns {jQuery}
         */
        stop: function(gotoEnd) {

            if (gotoEnd) {

                // execute last animation
                var lastAnim = cfg._queue.pop();
                if (lastAnim === undefined) {

                    lastAnim = cfg._anim;
                }                
                _reset();
                actions.do.call(cfg.$this, $.extend(lastAnim, {duration: .001}));

            } else {

                _reset();
            }
            return cfg.$this;
        },
        /**
         * Pause animation
         */
        pause: function() {

            var cb = function(i, v) {
                return v === 'paused' ? 'running' : 'paused';
            };
            cfg.$this.css('animation-play-state', cb);
            return cfg.$this;
        },
        /**
         * Reset - remove all already defiend animations from playlist
         * 
         * @returns {jQuery}
         */
        reset: function() {

            _reset();
            cfg = actions.config.call($(this), '_playlists', {default: []}); // remove all animations from playlist
            return cfg.$this;
        },
        /**
         * Do single animation
         * 
         * @param {Mixed} anim          animation parameters; JSON object or array of animation parameters
         * @param {Bool} processNext    process animations queue after given animations end?
         */
        do: function(anim, processNext) {

            if (processNext === undefined) {

                _reset();
            } else {

                _removeCssClasses();
            }

            // get and set animation parameters
            anim = _getDefinitionParams([anim], cfg.anim);
            cfg._anim = anim;

            // make animation visible or not; used to set inital transition state
            cfg.$this.css('visibility', (anim.visible ? 'visible' : 'hidden'));
            _setCssStyles(anim);

            var _animCallback = function(e, cfg) {

                if (cfg._anim.restore) {

                    // element have to be restored to initial state
                    _reset();
                }

                if (cfg._anim._exec()) { // internal callback

                    // process next animation only if callback don't returns false
                    if ((cfg._supported && (cfg._anim.callback).call(cfg.$this, e.originalEvent.animationName, e.originalEvent.elapsedTime) !== false) ||
                            (cfg._anim.callback).call(cfg.$this, {animationName: null}, {elapsedTime: 0.001}) !== false) { 

                        if (cfg._queue.length) { // any animation more?

                            actions.do.call(cfg.$this, cfg._queue.shift(), true);  // animate next from queue
                        }
                    }
                }
            };

            if (cfg._supported) { // animations supported by browser

                // for animations - wait until animation ends and process next animation in queue
                cfg.$this.one(cfg._animEndEvent, function(e) {

                    // DEBUG ONLY
                    //console.log(' >> "' + e.originalEvent.animationName + '" stopped after: ' + e.originalEvent.elapsedTime + '[s]');

                    // unset 'animation end' event handler; 
                    // required because two event can be processed twiced (due prefixed event names)
                    // (ex. transitionend and webkitTransitionEnd)
                    $(this).off(cfg._animEndEvent);

                    // this is callback - read right cfg!
                    cfg = actions.config.apply($(this));

                    _animCallback(e, cfg);
                });
            } else {
                // use timeout instead of animation
                var _cfg = cfg;
                cfg._timeout = window.setTimeout(function() {

                    _animCallback({}, _cfg);
                }, anim.duration * 1000 || 1);

                actions.config.apply(cfg.$this, cfg);
            }

            // add common animation and right animation class 
            cfg.$this.addClass('anim ' + anim.name);

            return cfg.$this;
        },
        /**
         * Do first animation from set of two; 
         * Second animation always returns to element initial state
         * 
         * @param {Mixed} anim
         * @returns {jQuery}
         */
        on: function(anim) {

            // always reset animation
            _reset();

            // remember initial element dispaly/visibility state 
            if (!cfg._playlists['onOffAnim']) {

                cfg._styles = {
                    display: cfg.$this.css('display'),
                    visibility: cfg.$this.css('visibility')
                };
                // get and set characteristic parameters
                anim = _getDefinitionParams(arguments, cfg.anim);

                // keep this kind of animation separated form queue                
                cfg._playlists['onOffAnim'] = anim;
                cfg = actions.config.call(cfg.$this, cfg);

            } else { // on is executed for second (or more) time

                anim = cfg._playlists['onOffAnim'];
            }

            return actions.do.apply(cfg.$this, [anim]);
        },
        /**
         * Do second animation form set of two
         * 
         * @param {JSON} anim   animation definition
         */
        off: function(anim) {

            if (cfg._playlists['onOffAnim']) {

                if (anim) { // full animation or callback defined

                    if (typeof (anim) === 'function') { // only callback defined
                        
                        anim = $.extend({}, cfg._playlists['onOffAnim'], {callback: anim});

                    } else { // some parameters given
                        anim = $.extend({}, _getDefinitionParams(arguments, cfg._playlists['onOffAnim']));
                    }
                } else {

                    // use ON animation (with all parameters), but in reverse 
                    anim = $.extend({}, cfg._playlists['onOffAnim']);
                }
                anim.direction = 'reverse'; // off ALWAYS play anim in reverse 
                _removeCssClasses(anim.name); // to re-run this same animation
                anim._exec = function() {

                    // restore initial element display/visibility state 
                    cfg.$this.css({
                        display: cfg._styles.display,
                        visibility: cfg._styles.visibility
                    });
                    return true;
                };
                // remove current on/off animation
                cfg._playlists['onOffAnim'] = null;
                cfg = actions.config.call(cfg.$this, cfg);
                
                return actions.do.apply(cfg.$this, [anim]);
            } else {

                return cfg.$this;
            }
        },
        /**
         * Define transition for element (makes sense?) 
         * 
         * @param {Stirng} transitions definition;
         *                  ex. "height 0.3s ease-in-out, box-shadow 0.6s linear"
         *                  
         * @returns {undefined}
         */
        /*trans: function(t) {

            var px = cfg._prefixes.length;

            // apply animation parameters            
            while (px--) {

                cfg.$this.css(cfg._prefixes[px] + "transition", t);
            }
        }*/
    };


    // -------------------------------------------------------------------------
    //
    // define private functions (internal use only)
    //

    /**
     * Genearte named set of definitions
     * 
     * @param {Array} args      arguments array
     * @param {String} name     name for set of parameters
     * @param {JSON} defaults  object with default parameters
     * 
     * @returns {JSON}         object with named sets of parameters; 
     *                          each parameters set is arrays of pnamed parameters objects
     *                          ex.
     *                          {
     *                             default: [{params1}, {params2}, ...], 
     *                             setName1: [{params1}, {params2}, ...], 
     *                             ...
     *                           }
     */
    function _namedDefinitionsSet(args, name, defaults) {

        name = name || 'default';
        defaults = defaults || {};
        var set = {}, _args = args[0], commonParams = {};

        // check is there are common parameters
        if (args[1]) {
            var a = args;
            a[0] = '';
            commonParams = _getDefinitionParams(a);
        }

        // if named set don't exists - defin new
        if (set[name] === undefined) {
            set[name] = [];
        }

        // define all parameters object for given set
        while (_args.length) {
            
            var _a = _args.shift();
            set[name].push(_getDefinitionParams((_a.constructor !== Array ? [_a] : _a), $.extend({}, defaults, commonParams)));
        }

        return set;
    }

    /**
     * Get set(s) of definitions
     * @param {Mixed} args      variaus syntax parameters
     *                          Avilable <definitions> syntax:
     *                    
     *  Single definition:
     *        
     *  $(n).pluginName(a1 [, a2, ...]);
     *  $(n).pluginName(a1 [, a2, ...] [, callbackFunc]);
     *  $(n).pluginName({p1: a1 [, p2: a2, ..., callback: callbackFunc]});
     *  $(n).pluginName({p1: a1 [, p2: a2, ...]} [, callbackFunc]);
     *  
     *  
     *  Mulitple definitions:
     *  
     *  $(n).pluginName([<def1AsArguments> [, <def2AsArguments>, ...] [, commonParameters] [, callbackFunc]]]);
     *  $(n).pluginName([[<def1AsArgumentsArray>] [, [<def2ArgumentsArray>], ...] [, commonParameters] [, callbackFunc]]]);
     *  $(n).pluginName([{<def1AsJSON>} [, {<def2AsJSON>}, ...] [, commonParameters] [, callbackFunc]]);
     *  
     *  
     *  Single or mulitple definitions as named set:
     *  
     *  $(n).pluginName(setName, [<definitions>] [, commonParameters]);
     *  
     *  @param {JSON} defaults      default parameters values
     *  
     */
    function _definitions(args, defaults) {

        defaults = defaults || {};
        var params = {default: []};
        if (typeof (args[0]) === 'object') {

            if (args[0].constructor !== Array) {

                // this is single animation defined as JSON parameters
                params.default.push(_getDefinitionParams(args, defaults));

            } else {

                // this is mulitple animations definitions (default set)
                params = _namedDefinitionsSet(args, 'default', defaults);
            }
        } else {

            if (args[1] && args[1].constructor === Array) {

                // this is named set of single or multiple animations definitions
                params = _namedDefinitionsSet([args[1]], args[0], defaults);

            } else {

                // this is single animation defined as comma separated parameters
                params.default.push(_getDefinitionParams(args, defaults));
            }
        }

        return params;
    }

    /**
     * Convert to JSON format parameters from given arguments.
     * Possible 'arguments' syntax:
     * 
     *  p1 [,p2 [,p3 ...]] [,function(){callback}]
     *  {p1: 'v1', p2: 'v2', ...}
     *  {p1: 'v1', p2: 'v2', ...} [,function(){callback}]
     * 
     * @param {Array} args      input arguments
     * @param {JSON} defaults
     * 
     * @returns {JSON}      final parameters as JSON object
     */
    function _getDefinitionParams(args, defaults) {

        defaults = defaults || {};
        var params = $.extend({}, defaults);

        if (typeof (args[0]) === 'object') {

            // used syntax: ({...});
            $.extend(params, args[0]);

            if (args[1] && typeof (args[1]) === 'function') {

                // used syntax: ({...}, callback);
                params.callback = args[1];
            }

        } else {

            // define properties
            var props = ['name',
                'duration',
                'timing',
                'delay',
                'count',
                'direction',
                'restore',
                // ---
                'callback'];

            for (var i = 0; i < props.length; i++) {

                if (typeof (args[i]) !== 'function') {

                    // used syntax: (a1, a2, ...);
                    if (args[i] !== undefined) {

                        params[props[i]] = args[i];

                    } else {
                        break;
                    }
                } else {

                    // used syntax: (a1, a2, ..., callback);
                    params.callback = args[i];
                    break;
                }               
            }
        }

        return params;
    }
    
    /**
     * Set animation parameters
     * 
     * @param {JSON} anim
     */
    function _setCssStyles(anim) {

        var px = cfg._prefixes.length;

        // apply animation parameters            
        while (px--) {

            // animations
            if (anim.duration)
                cfg.$this.css(cfg._prefixes[px] + "animation-duration", anim.duration + "s");
            if (anim.timing)
                cfg.$this.css(cfg._prefixes[px] + "animation-timing-function", anim.timing);
            if (anim.delay)
                cfg.$this.css(cfg._prefixes[px] + "animation-delay", anim.delay + "s");
            if (anim.count)
                cfg.$this.css(cfg._prefixes[px] + "animation-iteration-count", anim.count);
            if (anim.direction)
                cfg.$this.css(cfg._prefixes[px] + "animation-direction", anim.direction);
        }
    }

    /**
     *  Unset animation parameters
     *  
     */
    function _unsetCssStyles() {

        var px = cfg._prefixes.length;

        // reset animation parameters            
        while (px--) {

            cfg.$this.css(cfg._prefixes[px] + "animation-duration", '');
            cfg.$this.css(cfg._prefixes[px] + "animation-timing-function", '');
            cfg.$this.css(cfg._prefixes[px] + "animation-delay", '');
            cfg.$this.css(cfg._prefixes[px] + "animation-iteration-count", '');
            cfg.$this.css(cfg._prefixes[px] + "animation-direction", '');
        }
        cfg.$this.css("animation-play-state", 'running'); // set running (in case of pause used)
    }

    /**
     * Removes all (or given) animtion(s) related css classes for animated element
     * 
     * @param {String}      animName    animation name (to reset it results)
     */
    function _removeCssClasses(animName) {

        if (animName) {

            // remove single, given animation
            cfg.$this.removeClass(animName);

        } else {

            // remove all animations

            // remove common anim class
            cfg.$this.removeClass('anim');

            // remove all animations classes from playlist
            for (var i in cfg._playlists) {

                for (var j in cfg._playlists[i]) {
                    try {
                        cfg.$this.removeClass(cfg._playlists[i][j].name);
                    } catch (e) {
                    }
                }
            }

            // current animation
            if (cfg._anim) {
                cfg.$this.removeClass(cfg._anim.name);
            }
        }
        
        // -> triggering reflow /* The actual magic to reset css animation */
        cfg.$this[0].offsetWidth = cfg.$this[0].offsetWidth;
    }

    /**
     * Reset animations for element:
     * - clears all animations (or given) related css classes
     * - clears current animation end eevent handler
     * - unsets all animation css parameter styles
     * 
     * @param {String}      animName    animation name (to reset it results)
     * 
     */
    function _reset(animName) {

        // clear 'animation end' event handler
        cfg.$this.off(cfg._animEndEvent);
        window.clearTimeout(cfg._timeout); // in case animations not supported

        // remove animations classes and 're-initialize' element animation state
        _removeCssClasses(animName);

        // unset animation parameters (as element direct css styles)
        _unsetCssStyles();

        // clear current queue
        actions.config.call(cfg.$this, '_queue', []);
    }
    /**
     * Checks browser support css animations
     */
    function _supported() {

        function cssTransitions() {

            var div = document.createElement("div");
            var p, ext, pre = ["ms", "O", "Webkit", "Moz"];
            for (p in pre) {
                if (div.style[ pre[p] + "Transition" ] !== undefined) {
                    ext = pre[p];
                    break;
                }
            }
            delete div;
            return ext;
        };
        return cssTransitions();
    };

    //
    // shortcut: $.mtsoftUiAnim -> $.anims
    //
    $.fn.anims = $.fn.mtsoftUiAnim;

})();