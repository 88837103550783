/* 
 * Commonly used icons on client side JS (more than once) 
 */
$.mtsoft.ui.icons = {
    wait: '<svg viewBox="0 0 27 32"><path d="M26.982 18.857q0 0.089-0.018 0.125-1.143 4.786-4.786 7.759t-8.536 2.973q-2.607 0-5.045-0.982t-4.348-2.804l-2.304 2.304q-0.339 0.339-0.804 0.339t-0.804-0.339-0.339-0.804v-8q0-0.464 0.339-0.804t0.804-0.339h8q0.464 0 0.804 0.339t0.339 0.804-0.339 0.804l-2.446 2.446q1.268 1.179 2.875 1.821t3.339 0.643q2.393 0 4.464-1.161t3.321-3.196q0.196-0.304 0.946-2.089 0.143-0.411 0.536-0.411h3.429q0.232 0 0.402 0.17t0.17 0.402zM27.429 4.571v8q0 0.464-0.339 0.804t-0.804 0.339h-8q-0.464 0-0.804-0.339t-0.339-0.804 0.339-0.804l2.464-2.464q-2.643-2.446-6.232-2.446-2.393 0-4.464 1.161t-3.321 3.196q-0.196 0.304-0.946 2.089-0.143 0.411-0.536 0.411h-3.554q-0.232 0-0.402-0.17t-0.17-0.402v-0.125q1.161-4.786 4.821-7.759t8.571-2.973q2.607 0 5.071 0.991t4.375 2.795l2.321-2.304q0.339-0.339 0.804-0.339t0.804 0.339 0.339 0.804z" /></svg>'
    , add: '<svg viewBox="0 0 25 32"><path d="M25.143 13.143v3.429q0 0.714-0.5 1.214t-1.214 0.5h-7.429v7.429q0 0.714-0.5 1.214t-1.214 0.5h-3.429q-0.714 0-1.214-0.5t-0.5-1.214v-7.429h-7.429q-0.714 0-1.214-0.5t-0.5-1.214v-3.429q0-0.714 0.5-1.214t1.214-0.5h7.429v-7.429q0-0.714 0.5-1.214t1.214-0.5h3.429q0.714 0 1.214 0.5t0.5 1.214v7.429h7.429q0.714 0 1.214 0.5t0.5 1.214z" /></svg>'
    , caution: '<svg viewBox="0 0 32 32"><path d="M18.286 24.554v-3.393q0-0.25-0.17-0.42t-0.402-0.17h-3.429q-0.232 0-0.402 0.17t-0.17 0.42v3.393q0 0.25 0.17 0.42t0.402 0.17h3.429q0.232 0 0.402-0.17t0.17-0.42zM18.25 17.875l0.321-8.196q0-0.214-0.179-0.339-0.232-0.196-0.429-0.196h-3.929q-0.196 0-0.429 0.196-0.179 0.125-0.179 0.375l0.304 8.161q0 0.179 0.179 0.295t0.429 0.116h3.304q0.25 0 0.42-0.116t0.188-0.295zM18 1.196l13.714 25.143q0.625 1.125-0.036 2.25-0.304 0.518-0.83 0.821t-1.134 0.304h-27.429q-0.607 0-1.134-0.304t-0.83-0.821q-0.661-1.125-0.036-2.25l13.714-25.143q0.304-0.554 0.839-0.875t1.161-0.321 1.161 0.321 0.839 0.875z" /></svg>'
    , blocked: '<svg viewBox="0 0 32 32"><path d="M16 0.64c-8.483 0-15.36 6.877-15.36 15.36s6.877 15.36 15.36 15.36c8.485 0 15.36-6.877 15.36-15.36s-6.875-15.36-15.36-15.36zM24.266 7.738l-0.003-0.003c0 0.002 0.003 0.003 0.003 0.003zM4.314 16c0-6.454 5.232-11.686 11.686-11.686 2.806 0 5.382 0.989 7.398 2.637l-16.446 16.445c-1.65-2.014-2.638-4.589-2.638-7.395zM7.734 24.262l0.003 0.003c-0.002-0.002-0.002-0.002-0.003-0.003zM16 27.688c-2.806 0-5.381-0.99-7.395-2.638l16.442-16.445c1.65 2.014 2.64 4.589 2.64 7.395 0.002 6.454-5.234 11.688-11.686 11.688z" /></svg>'
    , close: '<svg viewBox="0 0 25 32"><path d="M23.179 23.607q0 0.714-0.5 1.214l-2.429 2.429q-0.5 0.5-1.214 0.5t-1.214-0.5l-5.25-5.25-5.25 5.25q-0.5 0.5-1.214 0.5t-1.214-0.5l-2.429-2.429q-0.5-0.5-0.5-1.214t0.5-1.214l5.25-5.25-5.25-5.25q-0.5-0.5-0.5-1.214t0.5-1.214l2.429-2.429q0.5-0.5 1.214-0.5t1.214 0.5l5.25 5.25 5.25-5.25q0.5-0.5 1.214-0.5t1.214 0.5l2.429 2.429q0.5 0.5 0.5 1.214t-0.5 1.214l-5.25 5.25 5.25 5.25q0.5 0.5 0.5 1.214z" /></svg>'
    , location: '<svg viewBox="0 0 32 32"><path d="M16 3.2c-4.419 0-8 3.582-8 8 0 7.638 8 17.6 8 17.6s8-9.962 8-17.6c0-4.418-3.581-8-8-8zM16 15.616c-2.386 0-4.32-1.934-4.32-4.32s1.934-4.32 4.32-4.32 4.32 1.934 4.32 4.32-1.934 4.32-4.32 4.32z" /></svg>'
    , checkmark: '<svg viewBox="0 0 32 32"><path d="M27 4l-15 15-7-7-5 5 12 12 20-20z"></path></svg>'
            //, info: '<svg viewBox="0 0 32 32"><path d="M18.286 24.571v-2.857q0-0.25-0.161-0.411t-0.411-0.161h-1.714v-9.143q0-0.25-0.161-0.411t-0.411-0.161h-5.714q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h1.714v5.714h-1.714q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h8q0.25 0 0.411-0.161t0.161-0.411zM16 8.571v-2.857q0-0.25-0.161-0.411t-0.411-0.161h-3.429q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h3.429q0.25 0 0.411-0.161t0.161-0.411zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" /></svg>'
};

/**
 * Generate icon node;
 * Supports:
 *  SVG icons
 *  Font icons
 *  Image icons
 *  
 * @param {String} icon         icon html code, id, class name or image url
 *                              ex.:
 *                                  html code (ex. "<svg>....</svg>")
 *                                  svg element id (ex. "#element-id") 
 *                                  path and image filename (ex. "img/icons/icon.png")
 *                                  css class name (ex. "icon-user")
 *                                                                
 * @param {JSON} params         icon additional params:
 * 
 *                                  size - [px] icon size (common width and height; ex. 64) 
 *                                  color - icon color (ex "white")
 *                                  bg - icon backgorund (ex. "black")
 *                                  fontSize - font size  (ex 3.2rem)
 *                                  class - css class name (ex. "icon-additional-class") 
 *                                  style - css styles
 *                                  
 * @param {Bool} html            return icon as html code (no default jQuery object) 
 * 
 * @returns {jQUery}            icon node ready to nesete in DOM 
 */
$.mtsoft.ui.icon = function(icon, params, html) {

    if (icon && icon.length > 1) {

        var $ico, ico, _class = '', style = '', wh = '', _iconBuildParams = function(params) {

            var wh = '';
            if (params.size) {
                var u = typeof (params.size) === 'number' ? 'px' : '';
                style += 'width:' + params.size + u + ';height:' + params.size + u + ';';
                wh += 'width="' + params.size + '" height="' + params.size + '"';
            }
            if (params.color) {

                style += 'fill:' + params.color + ';color:' + params.color + ';';
            }
            if (params.bg) {

                style += 'background:' + params.bg + ';';
            }
            if (params.fontSize) {

                style += 'font-size:' + params.fontSize + ';width:auto;height:auto;';
            }
            if (params.style) {

                style += params.style;
            }

            return {style: style, wh: wh};
        };

        // prepare icon params 
        if (params) {

            _class = params.class ? ' ' + params.class : '';
            var _s = _iconBuildParams(params);
            style = _s.style !== '' ? ' style="' + _s.style + '"' : '';
            wh = _s.wh !== '' ? ' ' + _s.wh : '';
        }

        var c = '';
        if (icon.indexOf('<i') === 0) {
            // this is full icon html code <i>...</i>
            c = icon;
        } else if (icon.indexOf('<') === 0) {
            // this is direct html code (svg, img, any other) 
            c = '<i class="ico' + _class + '"' + style + '>' + icon + '</i>';
        } else if (icon.indexOf('#') === 0) {
            // this SVG object id reference 
            c = '<i class="ico' + _class + '"' + style + '><svg><use' + wh + ' xlink:href="' + icon + '"></svg></i>';
        } else if (icon.indexOf('.') > 0) {
            // this is image (.png, .jpg) 
            c = '<i class="ico' + _class + '"' + style + '><img src="' + icon + '" alt="icon" /><i></i></i>';
        } else {
            // this is font icon or image/svg sprites or other class

            //var name = icon.replace(/^\#*ico-/i, '');
            // try to use pre-defined icon (from $.mtsoft.ui.icons) 
            if ($.mtsoft.ui.icons[icon]) {

                c = '<i class="ico ' + icon + _class + '"' + style + '>' + $.mtsoft.ui.icons[icon] + '</i>';
            } else {

                c = '<i class="ico ' + icon + _class + '"' + style + '></i>';
            }
        }

        $ico = $(c);

        if (html) { // convert nodes to html code

            var n = $('<div>');
            n.append($ico);
            ico = n.html();
            n.remove();
            // return as html code 
            return ico;
        } else {
            // returen as jQuery object 
            return $ico;
        }

    } else {

        return ''; // empty 
    }
};
// shortcut 
$.ico = $.mtsoft.ui.icon;
$.icoWait = function(params, html) {

    return $.mtsoft.ui.icon('wait', $.extend({class: 'ico-wait'}, params), html);
};